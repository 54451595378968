/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.elektromeister.state

import com.meistercharts.annotations.Window
import com.meistercharts.canvas.MouseCursor
import com.meistercharts.elektromeister.model.MutablePlacedElectricalComponent
import com.meistercharts.events.EventConsumption
import it.neckar.events.MouseButton
import it.neckar.events.MouseDownEvent
import it.neckar.events.MouseDragEvent
import it.neckar.events.MouseUpEvent
import it.neckar.geometry.Coordinates

/**
 * Creation mode for a connection line
 */
class ConnectionLineCreationMode(
  var start: MutablePlacedElectricalComponent?,
  /**
   * The potential end of the line - if the mouse is hovering over a component
   */
  var potentialEnd: MutablePlacedElectricalComponent?,
  /**
   * The temporary end coordinates of the line.
   * Used to highlight the current location of the end
   */
  var temporaryEndCoordinates: @Window Coordinates?,

  //val startPort: ElectricalComponent.Port,
  //val startPortDirection: ElectricalComponent.PortDirection,
) : AbstractUiState() {

  /**
   * Resets the state to the initial state - prepares for a new/next line
   */
  fun reset() {
    start = null
    potentialEnd = null
    temporaryEndCoordinates = null
  }

  override fun toString(): String {
    return "ConnectionLineCreationMode(start=$start, temporaryEndCoordinates=$temporaryEndCoordinates)"
  }

  override fun updateStateForMouseLocation(mouseLocation: @Window Coordinates?, context: GestureContext) {
    //Always update the temporary end coordinates
    temporaryEndCoordinates = mouseLocation

    val componentUnderMouse: MutablePlacedElectricalComponent? = context.findElectricalComponent(mouseLocation)

    potentialEnd = if (start == componentUnderMouse) {
      //Mouse hovering over the start component
      null
    } else {
      //might be null
      componentUnderMouse
    }


    when {
      start == null -> {
        //We are looking for the start
        cursor = if (componentUnderMouse != null) {
          //Signal that a connection can be created
          MouseCursor.Pointer
        } else {
          null
        }
      }

      start != null -> {
        //we have a start, we are looking for the end
        cursor = when (componentUnderMouse) {
          null -> {
            //No component under mouse, just the line
            MouseCursor.CrossHair
          }

          else -> {
            if (componentUnderMouse == start) {
              //Mouse hovering over the start component
              MouseCursor.NotAllowed
            } else {
              //Signal that a connection can be created
              MouseCursor.Pointer
            }
          }
        }
      }
    }
  }

  override fun mouseDown(event: MouseDownEvent, context: GestureContext): EventConsumption {
    when (event.button) {
      MouseButton.Primary -> {
        //Handled in mouse up
        return EventConsumption.Consumed
      }

      MouseButton.Secondary -> {
        reset()
        context.markAsDirty()
        return EventConsumption.Consumed
      }

      else -> {
        return EventConsumption.Ignored
      }
    }
  }

  override fun mouseUp(event: MouseUpEvent, context: GestureContext): EventConsumption {
    when (event.button) {
      MouseButton.Primary -> {
        @Window val mouseCoordinates = event.coordinates
        val componentUnderMouse: MutablePlacedElectricalComponent? = context.findElectricalComponent(mouseCoordinates)

        val currentStart = start

        if (currentStart == null) {
          //Always update start, if start is none
          this.start = componentUnderMouse
        } else {
          if (componentUnderMouse != null) {
            context.addConnectionLine(currentStart, componentUnderMouse)
            reset() //prepare for the next line
          }
        }

        //Consume all events
        return EventConsumption.Consumed
      }

      MouseButton.Secondary -> {
        reset()
        context.markAsDirty()
        return EventConsumption.Consumed

      }

      else -> {
        return EventConsumption.Ignored
      }
    }
  }

  override fun mouseDragged(event: MouseDragEvent, context: GestureContext): EventConsumption {
    return EventConsumption.Ignored
  }

  override fun deleteByKey(context: GestureContext): EventConsumption {
    reset()
    context.markAsDirty()
    return EventConsumption.Consumed
  }
}
