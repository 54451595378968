package com.meistercharts.charts.lizergy.stringsPlanning

import com.benasher44.uuid.Uuid
import it.neckar.open.observable.ObservableList

/**
 * Contains the [RoofString]s
 */
class RoofStringsConfiguration(initialRoofStrings: List<RoofString> = emptyList()) {
  /**
   * The [RoofString] property
   */
  val roofStringsProperty: ObservableList<RoofString> = ObservableList(initialRoofStrings)

  /**
   * Getter for the current [RoofString]
   */
  val roofStrings: List<RoofString> by roofStringsProperty

  /**
   * Returns the [RoofString] count
   */
  val count: Int
    get() = roofStrings.size

  fun clear() {
    roofStringsProperty.value = emptyList()
  }

  fun add(roofString: RoofString) {
    roofStringsProperty.getAndSet {
      it.plus(roofString)
    }
  }

  fun addAll(newRoofString: Iterable<RoofString>) {
    roofStringsProperty.getAndSet {
      it.plus(newRoofString)
    }
  }

  /**
   * Sets the [RoofString]s
   */
  fun set(roofStrings: Iterable<RoofString>) {
    roofStringsProperty.value = roofStrings.toMutableList()
  }

  operator fun get(index: Int): RoofString {
    return roofStrings[index]
  }

  operator fun get(uuid: Uuid): RoofString? {
    return roofStrings.find { it.uuid == uuid }
  }

  /**
   * Removes the given [RoofString]
   */
  fun removeAll(toRemove: Set<RoofString>) {
    removeAll {
      toRemove.contains(it)
    }
  }

  fun removeAll(predicate: (RoofString) -> Boolean) {
    roofStringsProperty.getAndSet {
      it.toMutableList().apply {
        removeAll(predicate)
      }
    }
  }

  fun remove(roofString: RoofString) {
    roofStringsProperty.getAndSet {
      it.minus(roofString)
    }
  }

  override fun toString(): String {
    return "RSC(count=$count, roofStrings=${roofStrings.map { it.count }})"
  }

}
