/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.elektromeister.state

import com.meistercharts.annotations.Window
import com.meistercharts.canvas.MouseCursor
import com.meistercharts.elektromeister.model.MutablePlacedElectricalComponent
import com.meistercharts.events.EventConsumption
import it.neckar.events.MouseDownEvent
import it.neckar.events.MouseDragEvent
import it.neckar.events.MouseUpEvent
import it.neckar.geometry.Coordinates

/**
 * Default state
 */
data object DefaultElektroMeisterUiState : AbstractUiStateWithMouseOver() {
  override fun updateStateForMouseLocation(mouseLocation: Coordinates?, context: GestureContext) {
    val mouseOverComponent = updateMouseOverComponent(mouseLocation, context)

    cursor = if (mouseOverComponent != null) {
      MouseCursor.Hand
    } else {
      null
    }
  }

  override fun mouseDown(event: MouseDownEvent, context: GestureContext): EventConsumption {
    return EventConsumption.Ignored
  }

  override fun mouseUp(event: MouseUpEvent, context: GestureContext): EventConsumption {
    @Window val mouseCoordinates = event.coordinates
    val model = context.model

    val foundPlacedElectricalComponent: MutablePlacedElectricalComponent? = context.electricalComponentsFinder(mouseCoordinates)
    if (foundPlacedElectricalComponent != null) {
      model.stateMachine.transitionToSelectedComponent(foundPlacedElectricalComponent, mouseCoordinates, context)
      context.markAsDirty()
      return EventConsumption.Consumed
    }

    return EventConsumption.Ignored
  }

  override fun mouseDragged(event: MouseDragEvent, context: GestureContext): EventConsumption {
    return EventConsumption.Ignored
  }

  override fun deleteByKey(context: GestureContext): EventConsumption {
    return EventConsumption.Ignored
  }
}
