/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.demo.descriptors


import com.benasher44.uuid.Uuid
import com.benasher44.uuid.uuidFrom
import com.meistercharts.algorithms.layers.AbstractLayer
import com.meistercharts.algorithms.layers.LayerPaintingContext
import com.meistercharts.algorithms.layers.LayerType
import com.meistercharts.algorithms.layers.addClearBackground
import com.meistercharts.algorithms.layers.interpolate
import com.meistercharts.color.Color
import com.meistercharts.animation.Easing
import com.meistercharts.canvas.DirtyReason
import com.meistercharts.canvas.animation.AnimationRepeatType
import com.meistercharts.canvas.animation.Tween
import com.meistercharts.canvas.fill
import com.meistercharts.canvas.stroke
import com.meistercharts.demo.MeisterchartsDemo
import com.meistercharts.demo.MeisterchartsDemoDescriptor
import com.meistercharts.demo.DemoCategory
import com.meistercharts.demo.PredefinedConfiguration
import com.meistercharts.demo.configurableDouble
import com.meistercharts.demo.configurableEnum
import com.meistercharts.demo.configurableList
import com.meistercharts.demo.section
import it.neckar.geometry.Rectangle
import it.neckar.open.time.nowMillis
import it.neckar.open.unit.si.ms
import kotlin.math.max
import kotlin.reflect.KMutableProperty0

/**
 *
 */
class TweenDemoDescriptor : MeisterchartsDemoDescriptor<Nothing> {
  override val uuid: Uuid = uuidFrom("781d7dec-46c1-4664-b22d-5b6ea469f123")
  override val name: String = "Tween"
  override val category: DemoCategory = DemoCategory.Calculations

  override fun prepareDemo(configuration: PredefinedConfiguration<Nothing>?): MeisterchartsDemo {
    return MeisterchartsDemo {
      meistercharts {
        configure {
          layers.addClearBackground()

          val width = 200.0
          val height = 200.0

          val layer = object : AbstractLayer() {
            var tweenX: Tween = Tween(nowMillis() + 500, 2800.0, Easing.inOutQuad, AnimationRepeatType.RepeatAutoReverse)
            var tweenY: Tween = Tween(nowMillis(), 2000.0, Easing.inOutQuad, AnimationRepeatType.RepeatAutoReverse)

            override val type: LayerType = LayerType.Content
            override fun paint(paintingContext: LayerPaintingContext) {
              val gc = paintingContext.gc
              gc.translate(gc.width / 2.0 - width / 2.0, gc.height / 2.0 - height / 2.0)


              gc.stroke(Color.blue)
              gc.strokeRect(Rectangle(0.0, 0.0, width, height))

              //draw the line
              kotlin.run {
                val maxDuration = max(tweenX.duration, tweenY.duration) * 8
                val startTimeX = tweenX.startTime

                @ms var time = startTimeX
                while (time <= startTimeX + maxDuration) {
                  val x = width * tweenX.interpolate(time)
                  val y = width * tweenY.interpolate(time)

                  gc.fill(Color.lightgray)
                  gc.fillOvalCenter(x, y, 1.0, 1.0)

                  time += 10.0
                }
              }

              //Paint the current position
              val x = width * tweenX.interpolate(paintingContext)
              val y = width * tweenY.interpolate(paintingContext)
              gc.fill(Color.orangered)
              gc.fillOvalCenter(x, y, 10.0, 10.0)

              chartSupport.markAsDirty(DirtyReason.Animation)
            }
          }
          layers.addLayer(layer)


          section("Tween X")
          configureTween(layer::tweenX)

          section("Tween Y")
          configureTween(layer::tweenY)
        }
      }
    }
  }

  private fun MeisterchartsDemo.configureTween(tweenProperty: KMutableProperty0<Tween>) {
    configurableDouble("Duration", tweenProperty.get().duration) {
      max = 10_000.0

      onChange {
        tweenProperty.set(tweenProperty.get().withDuration(duration = it))
      }
    }

    configurableEnum("Repeat Type", tweenProperty.get().repeatType, kotlin.enums.enumEntries<AnimationRepeatType>().toList()) {
      onChange {
        tweenProperty.set(tweenProperty.get().withRepeatType(repeatType = it))
      }
    }

    configurableList("Easing", availableEasings[0], availableEasings) {
      onChange {
        tweenProperty.set(tweenProperty.get().withEasing(it.easing))
      }

      converter {
        it.description
      }
    }

    declare {
      button("Restart") {
        tweenProperty.set(tweenProperty.get().copy(startTime = nowMillis()))
      }
    }
  }
}

private fun Tween.withEasing(easing: Easing): Tween {
  return this.copy(definition = definition.copy(interpolator = easing))
}

private fun Tween.withRepeatType(repeatType: AnimationRepeatType): Tween {
  return this.copy(definition = definition.copy(repeatType = repeatType))
}

private fun Tween.withDuration(duration: Double): Tween {
  return this.copy(definition = definition.copy(duration = duration))
}
