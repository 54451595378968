package com.meistercharts.charts.lizergy.stringsPlanning

import kotlin.jvm.JvmInline

/**
 * Annotates string indices
 */
@JvmInline
value class StringIndex(val value: Int) {
  init {
    require(value >= 0) { "StringIndex must be positive" }
  }
}
