package it.neckar.ktor.client

import io.ktor.client.*
import io.ktor.client.engine.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.client.plugins.logging.*
import io.ktor.serialization.kotlinx.json.*
import it.neckar.logging.LoggerFactory
import it.neckar.logging.debug

/**
 * Creates a http client.
 *
 * ATTENTION: Sets expectSuccess to true
 */
fun createHttpClient(engine: HttpClientEngine, clientName: String, config: HttpClientConfig<*>.() -> Unit = {}): HttpClient {
  return HttpClient(engine) {
    defaultConfig(clientName, config)
  }
}

fun <T : HttpClientEngineConfig> createHttpClient(engine: HttpClientEngineFactory<T>?, clientName: String, configBlock: HttpClientConfig<T>.() -> Unit = {}): HttpClient {
  //Create the client with or without engine
  //Because jvmMain/io/ktor/client/HttpClientJvm.FACTORY is private, we cannot improve the code here...
  return if (engine == null) {
    HttpClient {
      defaultConfig(clientName, configBlock as HttpClientConfig<*>.() -> Unit)
    }
  } else {
    HttpClient(engine) {
      defaultConfig(clientName, configBlock)
    }
  }
}

/**
 * Default configuration that is called for every client
 */
private fun <T : HttpClientEngineConfig> HttpClientConfig<T>.defaultConfig(clientName: String, config: HttpClientConfig<T>.() -> Unit) {
  //Call expect success first to allow overriding in the config block
  expectSuccess = true

  //Apply the config block here
  config()

  //Apply default plugins.
  //Plugins are only applied once, the second time they are ignored

  install(ContentNegotiation) {
    json()
  }

  Logging {
    logger = object : Logger {
      val internalLogger: it.neckar.logging.Logger = LoggerFactory.getLogger("ktor.http.client.$clientName")

      override fun log(message: String) {
        internalLogger.debug { message }
      }
    }
  }
}

/**
 * Creates a http client with the default configuration applied as well as the given configuration
 */
fun createHttpClient(clientName: String, config: HttpClientConfig<*>.() -> Unit = {}): HttpClient {
  return createHttpClient<HttpClientEngineConfig>(null, clientName, config)
}
