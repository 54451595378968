@file:UseSerializers(UuidSerializer::class)

package it.neckar.rest

import it.neckar.uuid.UuidSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers

/**
 * Represents the role of a client.
 *
 * ATTENTION: This is **not** a security feature. It is just a way to identify the client.
 */
@Serializable
data class ClientRole(val value: String) {
  override fun toString(): String {
    return value
  }

  companion object {
    /**
     * Well-known client role for the ui.
     */
    val UI: ClientRole = ClientRole("ui")
  }
}
