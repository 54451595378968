package it.neckar.elektromeister.rest.quote

import it.neckar.elektromeister.rest.FloorPlan
import it.neckar.elektromeister.rest.PlacedElectricalComponent
import it.neckar.elektromeister.rest.components.ElectricalComponentType
import it.neckar.financial.currency.Money
import it.neckar.logging.Logger
import it.neckar.logging.LoggerFactory

/**
 * Calculates a quote for Elektromeister.
 */
class ElektromeisterQuoteBuilder {
  companion object {
    internal val logger: Logger = LoggerFactory.getLogger("it.neckar.elektromeister.rest.quote.QuoteBuilder")
  }
}

/**
 * Creates a new quote for the given floor plan
 */
fun FloorPlan.createQuote(): ElektromeisterQuote {
  val componentsPerRoom = this.calculateComponentsPerRoom()

  val roomQuotes = rooms.map { room ->
    val componentsInRoom = componentsPerRoom[room] ?: emptyList()

    calculateRoomQuote(room.label, componentsInRoom)
  }

  val unknownRoomComponent = componentsPerRoom[null]
  val roomQuotesWithUnknown = if (unknownRoomComponent == null) {
    roomQuotes
  } else {
    roomQuotes + calculateRoomQuote("Unbekannt", unknownRoomComponent)
  }

  val quote: ElektromeisterQuote = ElektromeisterQuote(
    totalPrice = Money.fastSumBy(roomQuotes) { it.totalPrice },
    rooms = roomQuotesWithUnknown,
  )

  return quote
}

/**
 * Calculates the quote for a single room
 */
private fun calculateRoomQuote(roomLabel: String, componentsInRoom: List<PlacedElectricalComponent>): RoomQuote {
  val groupedByType = componentsInRoom.groupBy { it.component.type }

  val roomQuoteElements = groupedByType
    .filter { it.value.isNotEmpty() }
    .map { (type, components) ->
      val component = components.first()

      val totalCount = components.size
      val inclusiveCount = inclusiveCount(type).coerceAtMost(totalCount)
      val extraCount = (totalCount - inclusiveCount).coerceAtLeast(0)

      RoomQuoteElement(
        type = type,
        items = totalCount,
        included = inclusiveCount,
        extra = extraCount,
        unitPrice = component.component.price,
        totalPrice = component.component.price * extraCount,
      )
    }

  return RoomQuote(
    name = roomLabel,
    totalPrice = Money.fastSumBy(roomQuoteElements) { it.totalPrice },
    elements = roomQuoteElements,
  )
}

/**
 * The number of inclusive elements of a given type
 */
private val inclusiveMap = mapOf<ElectricalComponentType, Int>(
  ElectricalComponentType.LightSwitch to 1,
  ElectricalComponentType.SocketOutlet to 2,
  ElectricalComponentType.LightFixture to 1,
)

fun inclusiveCount(type: ElectricalComponentType): Int {
  return inclusiveMap[type] ?: 0
}
