package it.neckar.resource.loading

import it.neckar.logging.Logger
import it.neckar.logging.LoggerFactory
import it.neckar.open.http.Url

/**
 * Loads a resource as a byte array from the provided URL
 */
suspend fun loadResource(url: String): ByteArray {
  return loadResource(Url.parse(url))
}

expect suspend fun loadResource(url: Url): ByteArray

/**
 * Loads a resource as UTF8 string
 */
suspend fun loadResourceAsStringUtf8(url: String): String {
  return loadResourceAsStringUtf8(Url.parse(url))
}

suspend fun loadResourceAsStringUtf8(url: Url): String {
  val loadResource = loadResource(url)
  return loadResource.decodeToString()
}


internal val logger: Logger = LoggerFactory.getLogger("it.neckar.resource.loading.LoadResource")
