/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.elektromeister.model

import com.meistercharts.annotations.Domain
import it.neckar.elektromeister.rest.ConnectionLine
import it.neckar.elektromeister.rest.components.ElectricalComponent
import it.neckar.geometry.Coordinates
import it.neckar.open.dispose.Disposable
import it.neckar.open.observable.ConsumeChangesAction
import it.neckar.open.observable.ObservableProperties
import it.neckar.open.observable.ObservablePropertiesSupport
import it.neckar.open.observable.ReadOnlyObservableObject
import it.neckar.open.unit.si.mm

/**
 * Represents a connection between two components
 */
class MutableConnectionLine(
  /**
   * The start of the connection line
   */
  val start: MutablePlacedElectricalComponent,
  /**
   * The end of the connection line
   */
  val end: MutablePlacedElectricalComponent,
) : ObservableProperties {

  private val observablePropertiesSupport = ObservablePropertiesSupport(
    start, end
  )

  override fun consumeAllPropertiesChanges(action: ConsumeChangesAction<Any?>): Disposable {
    return observablePropertiesSupport.consumeAllPropertiesChanges(action)
  }

  fun toConnectionLine(): ConnectionLine {
    return ConnectionLine(
      start.electricalComponent,
      end.electricalComponent
    )
  }

  /**
   * Returns true if the connection line is connected to a component with the given id.
   */
  fun isConnectedTo(id: ElectricalComponent.Id): Boolean {
    return start.id == id || end.id == id
  }

  /**
   * Returns the current end location
   */
  val endLocation: @Domain @mm Coordinates
    get() {
      return end.transient.locationToPaint
    }

  val endLocationProperty: ReadOnlyObservableObject<Coordinates>
    get() {
      return end.transient.locationToPaintProperty
    }

  /**
   * Returns the current start location
   */
  val startLocation: @Domain @mm Coordinates
    get() {
      return start.transient.locationToPaint
    }

  val startLocationProperty: ReadOnlyObservableObject<Coordinates>
    get() {
      return start.transient.locationToPaintProperty
    }
}

/**
 * Converts a connection line to a mutable connection line
 */
fun ConnectionLine.toMutable(resolver: (id: ElectricalComponent.Id) -> MutablePlacedElectricalComponent): MutableConnectionLine {
  val mutableStart = resolver(start.id)
  val mutableEnd = resolver(end.id)

  return MutableConnectionLine(mutableStart, mutableEnd)
}
