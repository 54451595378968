/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.data.rest

import it.neckar.open.http.Url

/**
 * URL support for the data server
 */
class DataServerUrlSupport(val portForDev: Int) {

  val baseUrl: Url.Absolute = calculateBaseUrl(portForDev)

  val putUrl: Url.Absolute = baseUrl + MeisterchartsApiConstants.historyUrl

  val getConfigUrl: Url.Absolute = baseUrl + "${MeisterchartsApiConstants.historyUrl}/config"


  fun getUrl(resolution: Int, start: Double): Url.Absolute {
    return baseUrl + "${MeisterchartsApiConstants.historyUrl}/$resolution/$start"
  }

  fun registerUrl(clientId: ClientId): Url.Absolute {
    return baseUrl + "${MeisterchartsApiConstants.historyUrl}/register/$clientId"
  }

  fun deregisterUrl(clientId: ClientId): Url.Absolute {
    return baseUrl + "${MeisterchartsApiConstants.historyUrl}/deregister/$clientId"
  }

  fun getUpdatesUrl(clientId: ClientId): Url.Absolute {
    return baseUrl + "${MeisterchartsApiConstants.historyUrl}/updates/$clientId"
  }

  fun queryUrl(resolution: Double, from: Double, to: Double): Url.Absolute {
    return baseUrl + "${MeisterchartsApiConstants.historyUrl}/$resolution/$from/$to"
  }

  fun calculateBaseUrl(portForDev: Int): Url.Absolute {
    return Url.absolute("http://localhost:$portForDev/")
  }

}
