package it.neckar.csv

import com.github.doyaaaaaken.kotlincsv.dsl.csvReader


actual suspend fun convertCsv(csvByteArray: ByteArray, charset: String): List<List<String>> {
  val csvString: String = csvByteArray.toString()
  return csvReader{
    delimiter = ';'
    this.charset = charset
  }.readAll(csvString)
}


