/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.elektromeister.state

import com.meistercharts.annotations.Domain
import com.meistercharts.annotations.Window
import com.meistercharts.calc.DomainChartCalculator
import com.meistercharts.canvas.DirtyReason
import com.meistercharts.canvas.MouseCursor
import com.meistercharts.elektromeister.ElektromeisterDemoData
import com.meistercharts.elektromeister.model.MutablePlacedElectricalComponent
import com.meistercharts.events.EventConsumption
import it.neckar.elektromeister.rest.components.ElectricalComponent
import it.neckar.elektromeister.rest.components.ElectricalComponentType
import it.neckar.events.MouseButton
import it.neckar.events.MouseDownEvent
import it.neckar.events.MouseDragEvent
import it.neckar.events.MouseUpEvent
import it.neckar.geometry.Coordinates
import it.neckar.geometry.Direction
import it.neckar.open.unit.si.mm

/**
 * Creation mode for an electric component
 */
data class ElementCreationMode(
  /**
   * The component type to create
   */
  val componentType: ElectricalComponentType,
) : AbstractUiStateWithMouseOver() {


  /**
   * Creates the component
   */
  fun createComponent(): ElectricalComponent {
    val price = ElektromeisterDemoData.price(componentType)
    return ElectricalComponent.createComponent(type = componentType, label = "label", price = price)
  }

  override fun updateStateForMouseLocation(mouseLocation: @Window Coordinates?, context: GestureContext) {
    updateMouseOverComponent(mouseLocation, context)
    cursor = if (mouseOverComponent == null) {
      MouseCursor.CrossHair
    } else {
      //Do not allow creating a component on top of another component
      MouseCursor.NotAllowed
    }
  }

  override fun mouseDown(event: MouseDownEvent, context: GestureContext): EventConsumption {
    return when (event.button) {
      MouseButton.Primary -> EventConsumption.Consumed
      MouseButton.Secondary -> EventConsumption.Ignored
      else -> EventConsumption.Ignored
    }
  }

  override fun mouseUp(event: MouseUpEvent, context: GestureContext): EventConsumption {
    val chartSupport = context.chartSupport
    @Window val mouseCoordinates = event.coordinates
    val componentUnderMouse: MutablePlacedElectricalComponent? = context.findElectricalComponent(mouseCoordinates)
    val model = context.model


    when (event.button) {
      MouseButton.Primary -> {
        if (componentUnderMouse != null) {
          //Ignore if there is a component under the mouse, avoid "stacks"
          //TODO later: add groups, later
          return EventConsumption.Consumed
        }

        val domainChartCalculator = DomainChartCalculator(chartSupport.rootChartState, model.floorPlanImage.rangeX, model.floorPlanImage.rangeY)
        @Domain @mm val coordinatesDomain = domainChartCalculator.window2domain(mouseCoordinates)

        val anchorDirection = Direction.Center //TODO calculate!

        val createdComponent = createComponent()
        val placedElectricalComponent = MutablePlacedElectricalComponent(createdComponent, coordinatesDomain, anchorDirection)

        model.addPlacedElectricalComponent(placedElectricalComponent)
        context.markAsDirty(reason = DirtyReason.UserInteraction)

        return EventConsumption.Consumed
      }

      else -> {
        return EventConsumption.Ignored
      }
    }
  }

  override fun mouseDragged(event: MouseDragEvent, context: GestureContext): EventConsumption {
    return EventConsumption.Ignored
  }

  override fun deleteByKey(context: GestureContext): EventConsumption {
    return EventConsumption.Ignored
  }
}
