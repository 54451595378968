package it.neckar.financial.currency

import it.neckar.open.kotlin.lang.percent
import it.neckar.open.formatting.formatAsPercentage
import it.neckar.open.i18n.I18nConfiguration
import it.neckar.open.kotlin.lang.WhitespaceConfig
import it.neckar.open.unit.other.pct
import kotlinx.serialization.Serializable
import kotlin.jvm.JvmInline

@Serializable
@JvmInline
value class ValueAddedTax(val vat: @pct Double) {
  operator fun plus(otherPercentage: @pct Double): ValueAddedTax {
    return ValueAddedTax(vat + otherPercentage)
  }

  fun format(i18nConfiguration: I18nConfiguration = I18nConfiguration.Germany, whitespaceConfig: WhitespaceConfig = WhitespaceConfig.NonBreaking): String {
    return "MwSt. ${vat.formatAsPercentage(numberOfDecimals = 0, i18nConfiguration = i18nConfiguration, whitespaceConfig=whitespaceConfig)}"
  }

  companion object {
    val zero: ValueAddedTax = ValueAddedTax(0.0.percent)
    val sevenPercent: ValueAddedTax = ValueAddedTax(7.0.percent)
    val nineteenPercent: ValueAddedTax = ValueAddedTax(19.0.percent)
  }
}
