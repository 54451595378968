/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.demo.descriptors


import com.benasher44.uuid.Uuid
import com.benasher44.uuid.uuidFrom
import com.meistercharts.algorithms.layers.AbstractLayer
import com.meistercharts.algorithms.layers.FillBackgroundLayer
import com.meistercharts.algorithms.layers.LayerPaintingContext
import com.meistercharts.algorithms.layers.LayerType
import com.meistercharts.algorithms.layers.addClearBackground
import com.meistercharts.algorithms.layers.text.TextLayer
import com.meistercharts.canvas.CanvasRenderingContext
import com.meistercharts.canvas.layer.LayerSupport
import com.meistercharts.canvas.paintable.ButtonColorProvider
import com.meistercharts.canvas.paintable.ButtonState
import com.meistercharts.charts.lizergy.solar.LizergyDesign
import com.meistercharts.color.Color
import com.meistercharts.demo.DemoCategory
import com.meistercharts.demo.DemoQuality
import com.meistercharts.demo.MeisterchartsDemo
import com.meistercharts.demo.MeisterchartsDemoDescriptor
import com.meistercharts.demo.PredefinedConfiguration
import com.meistercharts.demo.VariabilityType
import com.meistercharts.design.CurrentTheme
import com.meistercharts.design.DarkDesign
import com.meistercharts.design.DebugTheme
import com.meistercharts.design.DefaultTheme
import com.meistercharts.design.NeckarITDesign
import com.meistercharts.design.SegoeUiTheme
import com.meistercharts.design.Theme
import com.meistercharts.font.FontDescriptorFragment
import com.meistercharts.geometry.DirectionBasedBasePointProvider
import it.neckar.geometry.Direction
import it.neckar.open.kotlin.lang.asProvider
import it.neckar.open.kotlin.lang.fastFor
import it.neckar.open.provider.MultiProvider

/**
 *
 */
class ThemeDemo2Descriptor : MeisterchartsDemoDescriptor<Theme?> {
  override val uuid: Uuid = uuidFrom("5daa3f83-a9f0-49df-a47e-740d6936c99f")
  override val name: String = "Themes"
  override val category: DemoCategory = DemoCategory.ShowCase
  override val quality: DemoQuality = DemoQuality.High
  override val variabilityType: VariabilityType = VariabilityType.Stable

  override val predefinedConfigurations: List<PredefinedConfiguration<Theme?>> = listOf(
    PredefinedConfiguration(uuidFrom("18a6af0a-9b9a-4880-83f2-7fc0403ab6a4"), null, "null"),
    PredefinedConfiguration(uuidFrom("fa317332-19bf-4cb1-8784-a81d77426791"), DefaultTheme.Instance, "Default Theme"),
    PredefinedConfiguration(uuidFrom("52e18eb4-fb72-4718-bf46-97739c88ce0e"), DarkDesign, "Dark Theme"),
    PredefinedConfiguration(uuidFrom("0b062f8e-5934-4749-9e6c-b6e7baf83f7b"), NeckarITDesign, "Neckar IT"),
    PredefinedConfiguration(uuidFrom("5852bda8-4b0d-4fd9-8df0-ece9b97b1021"), SegoeUiTheme, "Segoe UI"),
    PredefinedConfiguration(uuidFrom("5b5ab0a0-a778-4a81-84b0-f4a327488554"), DebugTheme, "Debug"),
    PredefinedConfiguration(uuidFrom("01971935-042d-4787-95e2-ac90dee69d7a"), LizergyDesign, "Lizergy"),
  )

  override fun prepareDemo(configuration: PredefinedConfiguration<Theme?>?): MeisterchartsDemo {
    return MeisterchartsDemo {
      val selectedTheme = configuration?.payload ?: CurrentTheme
      var gapTop = 0.0
      meistercharts {
        configure {
          layers.addClearBackground()
          layers.addLayer(FillBackgroundLayer(selectedTheme.canvasBackgroundColor))
          addLayer(selectedTheme.h1, selectedTheme.h1Color, gapTop, "H1")
          gapTop += 60.0
          addLayer(selectedTheme.h2, selectedTheme.h2Color, gapTop, "H2")
          gapTop += 55.0
          addLayer(selectedTheme.h3, selectedTheme.h3Color, gapTop, "H3")
          gapTop += 40.0
          addLayer(selectedTheme.h4, selectedTheme.h4Color, gapTop, "H4")
          gapTop += 30.0
          addLayer(selectedTheme.h5, selectedTheme.h5Color, gapTop, "H5")
          gapTop += 30.0
          addLayer(selectedTheme.textFont, selectedTheme.chartColors, selectedTheme.textColor, gapTop, "Chart colors")
          gapTop += 30.0
          addLayer(selectedTheme.textFont, selectedTheme.enumColors, selectedTheme.textColor, gapTop, "Enum colors")
          gapTop += 30.0
          addLayer(selectedTheme.textColor, selectedTheme.textColor, gapTop, "Text")
          gapTop += 30.0
          addLayer(selectedTheme.primaryColor, selectedTheme.textColor, gapTop, "Primary")
          gapTop += 30.0
          addLayer(selectedTheme.primaryColorDarker, selectedTheme.textColor, gapTop, "Primary darker")
          gapTop += 30.0
          addLayer(selectedTheme.primaryColorLighter, selectedTheme.textColor, gapTop, "Primary lighter")
          gapTop += 30.0
          addLayer(selectedTheme.secondaryColor, selectedTheme.textColor, gapTop, "Secondary")
          gapTop += 30.0
          addLayer(selectedTheme.primaryBackgroundColor, selectedTheme.textColor, gapTop, "Background primary")
          gapTop += 30.0
          addLayer(selectedTheme.secondaryBackgroundColor, selectedTheme.textColor, gapTop, "Background secondary")
          gapTop += 30.0
          addLayer(selectedTheme.backgroundColorActive, selectedTheme.textColor, gapTop, "Background active")
          gapTop += 30.0
          addLayer(selectedTheme.textFont, selectedTheme.backgroundZebraColors, selectedTheme.textColor, gapTop, "Background zebra")
          gapTop += 30.0
          addLayer(selectedTheme.defaultLineColor, selectedTheme.textColor, gapTop, "Default line color")
          gapTop += 30.0
          addLayer(selectedTheme.inactiveElementBorder, selectedTheme.textColor, gapTop, "Inactive element border")
          gapTop += 30.0
          addLayer(selectedTheme.primaryButtonBackgroundColors, selectedTheme.primaryButtonForegroundColors, selectedTheme.textColor, gapTop, "Primary button colors")
          gapTop += 30.0
          addLayer(selectedTheme.secondaryButtonBackgroundColors, selectedTheme.secondaryButtonForegroundColors, selectedTheme.textColor, gapTop, "Secondary button colors")
          gapTop += 30.0
          addLayer(selectedTheme.stateOk, selectedTheme.textColor, gapTop, "State OK color")
          gapTop += 30.0
          addLayer(selectedTheme.stateWarning, selectedTheme.textColor, gapTop, "State warning color")
          gapTop += 30.0
          addLayer(selectedTheme.stateError, selectedTheme.textColor, gapTop, "State error color")
          gapTop += 30.0
          addLayer(selectedTheme.stateUnknown, selectedTheme.textColor, gapTop, "State unknown color")
        }
      }
    }
  }

  private fun LayerSupport.addLayer(fontDescriptorFragment: FontDescriptorFragment, color: Color, gapTop: Double, description: String) {
    val text = "$description: $fontDescriptorFragment"

    layers.addLayer(TextLayer({ _, _ ->
      listOf(
        text
      )
    }) {
      font = fontDescriptorFragment.asProvider()
      textColor = color.asProvider()
      anchorDirection = Direction.TopCenter
      anchorPointProvider = DirectionBasedBasePointProvider(Direction.TopCenter)
      anchorGapVertical = gapTop
    })
  }

  private fun LayerSupport.addLayer(fontDescriptorFragment: FontDescriptorFragment, colors: MultiProvider<ThemeKeyResolutionDemoDescriptor, Color>, textColor: Color, gapTop: Double, description: String) {
    val text = description
    layers.addLayer(object : AbstractLayer() {
      override val type: LayerType
        get() = LayerType.Content

      override fun paint(paintingContext: LayerPaintingContext) {
        val gc = paintingContext.gc
        val centerX = gc.width * 0.5
        gc.font(fontDescriptorFragment)
        gc.fill(textColor)
        gc.fillText(text, centerX, gapTop, Direction.TopRight)
        10.fastFor {
          gc.fill(colors.valueAt(it))
          gc.fillRect(centerX + it * 24.0 + 10.0, gapTop, 20.0, 20.0)
        }
      }
    })
  }

  private fun LayerSupport.addLayer(color: Color, textColor: Color, gapTop: Double, description: String) {
    val text = "$description:"
    layers.addLayer(object : AbstractLayer() {
      override val type: LayerType
        get() = LayerType.Content

      override fun paint(paintingContext: LayerPaintingContext) {
        val gc = paintingContext.gc
        val centerX = gc.width * 0.5
        gc.fill(textColor)
        gc.fillText(text, centerX, gapTop, Direction.TopRight)
        gc.fill(color)
        gc.fillRect(centerX + 10.0, gapTop, 20.0, 20.0)
      }
    })
  }

  private fun LayerSupport.addLayer(
    backgroundColors: ButtonColorProvider,
    foregroundColors: ButtonColorProvider,
    textColor: Color,
    gapTop: Double,
    description: String,
  ) {
    val text = "$description:"
    layers.addLayer(object : AbstractLayer() {
      override val type: LayerType
        get() = LayerType.Content

      override fun paint(paintingContext: LayerPaintingContext) {
        val gc = paintingContext.gc
        var centerX = gc.width * 0.5
        gc.fill(textColor)
        gc.fillText(text, centerX, gapTop, Direction.TopRight)

        paintButtonPreview(gc, backgroundColors, foregroundColors, ButtonState.default, centerX, gapTop, "E")
        centerX += 24.0
        paintButtonPreview(gc, backgroundColors, foregroundColors, ButtonState.disabled, centerX, gapTop, "D")
        centerX += 24.0
        paintButtonPreview(gc, backgroundColors, foregroundColors, ButtonState(focused = true), centerX, gapTop, "F")
        centerX += 24.0
        paintButtonPreview(gc, backgroundColors, foregroundColors, ButtonState(hover = true), centerX, gapTop, "H")
        centerX += 24.0
        paintButtonPreview(gc, backgroundColors, foregroundColors, ButtonState(pressed = true), centerX, gapTop, "P")
      }
    })
  }

  private fun paintButtonPreview(
    gc: CanvasRenderingContext,
    backgroundColors: ButtonColorProvider,
    foregroundColors: ButtonColorProvider,
    buttonState: ButtonState,
    centerX: Double,
    gapTop: Double,
    text: String,
  ) {
    gc.fill(backgroundColors(buttonState))
    gc.fillRect(centerX + 10.0, gapTop, 20.0, 20.0)
    gc.fill(foregroundColors(buttonState))
    gc.fillText(text, centerX + 20.0, gapTop + 10.0, Direction.Center)
  }
}
