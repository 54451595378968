/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.elektromeister.state

import com.meistercharts.annotations.Window
import com.meistercharts.canvas.ChartSupport
import com.meistercharts.canvas.DirtyReason
import com.meistercharts.elektromeister.ElektromeisterMeisterchartsModel
import com.meistercharts.elektromeister.model.MutablePlacedElectricalComponent
import it.neckar.geometry.Coordinates

/**
 * The context when calling gesture methods
 */
class GestureContext(
  val chartSupport: ChartSupport,
  val model: ElektromeisterMeisterchartsModel,
  val electricalComponentsFinder: (coordinates: @Window Coordinates) -> MutablePlacedElectricalComponent?,
) {

  /**
   * Returns the state machine from the model
   */
  val stateMachine: ElektromeisterUiStateMachine
    get() {
      return model.stateMachine
    }

  /**
   * Returns the electrical component at the given coordinates
   */
  fun findElectricalComponent(coordinates: @Window Coordinates?): MutablePlacedElectricalComponent? {
    if (coordinates == null) return null
    return electricalComponentsFinder(coordinates)
  }

  /**
   * Marks the chart as dirty
   */
  fun markAsDirty(reason: DirtyReason = DirtyReason.UserInteraction) {
    chartSupport.markAsDirty(reason = reason)
  }

  //TODO inline?
  fun addConnectionLine(start: MutablePlacedElectricalComponent, end: MutablePlacedElectricalComponent) {
    model.addConnectionLine(start, end)
    markAsDirty()
  }
}
