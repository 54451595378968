package com.meistercharts.charts.lizergy.roofPlanning

import it.neckar.geometry.Rectangle
import it.neckar.open.collections.fastForEach
import it.neckar.open.collections.fastForEachIndexed
import it.neckar.open.observable.ObservableList
import it.neckar.open.observable.ObservableObject
import it.neckar.open.unit.si.mm

/**
 * Contains the [ModuleArea]s
 */
class ModuleAreas(initialList: List<ModuleArea> = emptyList()) {
  /**
   * The [ModuleAreas] property
   */
  val moduleAreasProperty: ObservableList<ModuleArea> = ObservableList(initialList)

  /**
   * Getter for the current [ModuleArea]
   */
  val moduleAreas: List<ModuleArea> by moduleAreasProperty

  /**
   * Returns the [ModuleArea] count
   */
  val count: Int
    get() = moduleAreas.size

  val moduleCount: Int
    get() = moduleAreas.sumOf { it.modules.count }

  val visibleModuleCount: Int
    get() = moduleAreas.sumOf { it.modules.visibleModulesCount }

  fun clear() {
    moduleAreasProperty.value = emptyList()
  }

  fun add(moduleArea: ModuleArea) {
    moduleAreasProperty.getAndSet {
      it.plus(moduleArea)
    }
  }

  fun addAll(newModuleAreas: Iterable<ModuleArea>) {
    moduleAreasProperty.getAndSet {
      it.plus(newModuleAreas)
    }
  }

  /**
   * Sets the unusableAreas
   */
  fun set(moduleAreas: Iterable<ModuleArea>) {
    moduleAreasProperty.value = moduleAreas.toMutableList()
  }

  operator fun get(index: Int): ModuleArea {
    return moduleAreas[index]
  }

  /**
   * Removes the given unusableAreas
   */
  fun removeAll(toRemove: Set<ModuleArea>) {
    removeAll {
      toRemove.contains(it)
    }
  }

  fun removeAll(predicate: (ModuleArea) -> Boolean) {
    moduleAreasProperty.getAndSet {
      it.toMutableList().apply {
        removeAll(predicate)
      }
    }
  }

  fun remove(moduleArea: ModuleArea) {
    moduleAreasProperty.getAndSet {
      it.minus(moduleArea)
    }
  }

  /**
   * Returns true if any unusable area overlaps with the given rectangle
   */
  fun overlaps(rectangle: @RoofRelative @mm Rectangle): Boolean {
    return moduleAreas.any {
      it.overlaps(rectangle)
    }
  }

  inline fun fastForEachModule(action: (Module) -> Unit) {
    moduleAreas.fastForEach { moduleArea ->
      moduleArea.modules.fastForEachModule(action)
    }
  }

  inline fun fastForEachModuleIndexed(action: (Int, Module) -> Unit) {
    var moduleAreaOffset = 0
    moduleAreas.fastForEachIndexed { moduleAreaIndex, moduleArea ->
      moduleArea.modules.fastForEachModuleIndexed { moduleIndex, module ->
        action(moduleAreaOffset + moduleIndex, module)
      }
      moduleAreaOffset += moduleArea.modules.count
    }
  }

  inline fun fastForEachVisibleModule(action: (Module) -> Unit) {
    moduleAreas.fastForEach { moduleArea ->
      moduleArea.modules.fastForEachVisibleModule(action)
    }
  }

  inline fun fastForEachModuleVisibleIndexed(action: (Int, Module) -> Unit) {
    var moduleAreaOffset = 0
    moduleAreas.fastForEachIndexed { moduleAreaIndex, moduleArea ->
      moduleArea.modules.fastForEachVisibleModuleIndexed { moduleIndex, module ->
        action(moduleAreaOffset + moduleIndex, module)
      }
      moduleAreaOffset += moduleArea.modules.visibleModulesCount
    }
  }

  fun modulePlacementToIndex(modulePlacement: ModulePlacement): Int {
    var moduleAreaOffset = 0
    moduleAreas.fastForEach { moduleArea ->
      if (modulePlacement.moduleArea == moduleArea) {
        var moduleOffset = 0
        moduleArea.modules.fastForEachVisibleModuleIndexed { moduleIndex, module ->
          if (module.modulePlacement == modulePlacement) return moduleAreaOffset + moduleOffset
          moduleOffset++
        }
      }
      moduleAreaOffset += moduleArea.modules.visibleModulesCount
    }
    return -1
  }
}
