/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.range

import com.meistercharts.annotations.Domain
import com.meistercharts.annotations.DomainRelative

/**
 * Special class that offers additional methods for binary value range (0..1)
 *
 */
object BinaryValueRange : LinearValueRange(0.0, 1.0) {
  @DomainRelative
  fun toDomainRelative(@Domain value: Boolean): Double {
    return if (value) {
      1.0
    } else 0.0
  }

  @Domain
  fun toDomainBoolean(@DomainRelative domainRelative: Double): Boolean {
    if (domainRelative == 1.0) {
      return true
    }
    if (domainRelative == 0.0) {
      return false
    }

    throw IllegalArgumentException("Invalid domain value <$domainRelative>")
  }
}
