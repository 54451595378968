package com.meistercharts.charts.lizergy.roofPlanning

import it.neckar.geometry.Coordinates
import it.neckar.geometry.Rectangle
import it.neckar.geometry.Shape
import it.neckar.open.observable.ObservableObject
import it.neckar.open.unit.si.mm

/**
 * Represents a module that is placed on a roof
 */
data class Module(
  /**
   * The size of the module (in cm)
   * ATTENTION: Does *not* respect the layout orientation
   */
  val size: @mm ModuleSize,

  /**
   * The placement information of the module within the grid
   */
  val modulePlacement: ModulePlacement,

  ) : Clickable {

  /**
   * Returns true if this (grid) module has been deleted
   */
  var deletedProperty: ObservableObject<Boolean> = ObservableObject(false)
  var deleted: Boolean by deletedProperty

  val orientation: ModuleOrientation
    get() {
      return modulePlacement.orientation
    }

  /**
   * Returns the bounds of the module
   * Attention: This method creates a new rectangle every time
   */
  val bounds: @RoofRelative @mm Rectangle
    get() {
      return Rectangle(location, size.toSize(orientation))
    }

  /**
   * The location of the module (in cm)
   */
  val location: @RoofRelative Coordinates by modulePlacement::location

  /**
   * Returns the width - respecting the orientation
   */
  val width: @mm Int
    get() {
      return when (modulePlacement.orientation) {
        ModuleOrientation.Vertical -> size.shorter
        ModuleOrientation.Horizontal -> size.longer
      }
    }

  /**
   * Returns the height - respecting the orientation
   */
  val height: @mm Int
    get() {
      return when (modulePlacement.orientation) {
        ModuleOrientation.Vertical -> size.longer
        ModuleOrientation.Horizontal -> size.shorter
      }
    }

  /**
   * Returns true if the module is vertical (height > width)
   */
  fun isVertical(): Boolean {
    return modulePlacement.orientation == ModuleOrientation.Vertical
  }

  /**
   * Returns true if this module overlaps with the given rectangle
   */
  fun overlaps(shape: @RoofRelative @mm Shape): Boolean {
    return bounds.overlaps(shape)
  }

  override fun toString(): String {
    return "Module(size=$size, modulePlacement=$modulePlacement,deleted=$deleted, orientation=$orientation, bounds=$bounds, location=$location, width=$width, height=$height)"
  }


}
