/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.elektromeister.state

import com.meistercharts.annotations.Window
import com.meistercharts.elektromeister.model.MutablePlacedElectricalComponent
import com.meistercharts.events.EventConsumption
import it.neckar.events.MouseMoveEvent
import it.neckar.geometry.Coordinates
import it.neckar.open.kotlin.lang.setIfDifferent

/**
 * Supports mouse over support for electrical components
 */
abstract class AbstractUiStateWithMouseOver : AbstractUiState() {
  /**
   * The component that is currently hovered over
   */
  internal var mouseOverComponent: MutablePlacedElectricalComponent? = null

  /**
   * Stores the component under the given coordinates.
   * Returns the found component if there is one
   */
  fun updateMouseOverComponent(coordinates: @Window Coordinates?, context: GestureContext): MutablePlacedElectricalComponent? {
    val componentUnderMouse: MutablePlacedElectricalComponent? = context.findElectricalComponent(coordinates)
    updateMouseOverComponent(componentUnderMouse, context)
    return componentUnderMouse
  }

  /**
   * Stores the given component as the current mouse over component
   */
  fun updateMouseOverComponent(componentUnderMouse: MutablePlacedElectricalComponent?, context: GestureContext) {
    this::mouseOverComponent.setIfDifferent(componentUnderMouse) {
      context.markAsDirty()
    }
  }
}
