@file:UseSerializers(UuidSerializer::class)

package it.neckar.rest

import it.neckar.uuid.NanoId
import it.neckar.uuid.UuidSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers

/**
 * Represents a client id - used to identify the client
 */
@Serializable
data class ClientId(val value: NanoId) {
  override fun toString(): String {
    return value.toString()
  }

  companion object {
    /**
     * The default generator for client ids
     */
    val generator: NanoId.NanoIdGenerator = NanoId.generator(10, NanoId.AlphaNumeric)

    /**
     * Creates a random client id
     */
    fun random(): ClientId {
      return ClientId(generator.generate())
    }
  }
}
