package it.neckar.elektromeister.rest

import com.benasher44.uuid.Uuid
import it.neckar.geometry.Coordinates
import it.neckar.geometry.Polygon
import it.neckar.open.unit.si.mm
import it.neckar.uuid.ValueClassUuid
import it.neckar.uuid.randomUuid4

/**
 * Represents a single room
 */
data class Room(
  val id: Id,

  /**
   * The label of the room
   */
  val label: String,
  /**
   * The bounds of the room - relative to the origin of the floor
   */
  val bounds: @mm @RelativeToFloorOrigin Polygon,
) {
  /**
   * Returns true if the given location is inside the room
   */
  fun contains(location: @mm @RelativeToFloorOrigin Coordinates): Boolean {
    return bounds.contains(location)
  }

  /**
   * The id of the room
   */
  data class Id(override val uuid: Uuid) : ValueClassUuid {
    companion object {
      fun random(): Id = Id(randomUuid4())
    }
  }

  companion object {}
}
