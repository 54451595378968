package com.meistercharts.charts.lizergy.roofPlanning

/**
 * describes the types of elements (modules, unusable areas)
 */
enum class PvElementType {
  /**
   * A module that is layouted by the grid
   */
  ModuleArea,

  /**
   * A single unusable area
   */
  UnusableArea;
}
