package com.meistercharts.charts.sick.beams

import com.meistercharts.algorithms.layers.AbstractLayer
import com.meistercharts.algorithms.layers.LayerPaintingContext
import com.meistercharts.algorithms.layers.LayerType
import com.meistercharts.annotations.Zoomed
import com.meistercharts.canvas.ConfigurationDsl
import com.meistercharts.color.Color
import com.meistercharts.color.ColorProvider
import com.meistercharts.font.FontDescriptorFragment
import it.neckar.geometry.Direction
import it.neckar.open.unit.other.px

/**
 * Returns the location of a beam
 */
typealias BeamsLocationProvider = (beamIndex: Int) -> @Zoomed Double

/**
 * Paints the vertical zone markers
 */
class ZonesLayer(
  val configuration: Configuration,
  additionalConfiguration: Configuration.() -> Unit = {},
) : AbstractLayer() {

  constructor(
    zonesProvider: ZonesProvider,
    beamLocationProvider: BeamsLocationProvider,
    additionalConfiguration: Configuration.() -> Unit = {},
  ) : this(Configuration(zonesProvider, beamLocationProvider), additionalConfiguration)

  init {
    configuration.additionalConfiguration()
  }

  override val type: LayerType = LayerType.Content

  override fun paint(paintingContext: LayerPaintingContext) {
    val gc = paintingContext.gc
    val chartCalculator = paintingContext.chartCalculator

    gc.translate(chartCalculator.contentArea2windowX(0.0), chartCalculator.contentArea2windowY(0.0))
    //Origin of canvas

    val zonesCount = configuration.zonesProvider.count

    for (zoneIndex in 0 until zonesCount) {
      val startIndex = configuration.zonesProvider.startIndex(zoneIndex)
      val endIndex = configuration.zonesProvider.endIndex(zoneIndex)

      @Zoomed val startLocation = configuration.beamLocationProvider(startIndex)
      @Zoomed val endLocation = configuration.beamLocationProvider(endIndex)

      @Zoomed val x = (zoneIndex + 0.5) * configuration.zonesGap

      val isActive = configuration.zonesProvider.isActive(zoneIndex)
      gc.stroke(configuration.color(isActive))

      //vertical line
      gc.lineWidth = configuration.lineWidth
      gc.strokeLine(x, startLocation, x, endLocation)

      //horizontal top + bottom
      val segmentHalfSize = configuration.horizontalLineSegmentLength / 2.0

      gc.strokeLine(x - segmentHalfSize, startLocation, x + segmentHalfSize, startLocation)
      gc.strokeLine(x - segmentHalfSize, endLocation, x + segmentHalfSize, endLocation)

      //The label
      gc.font(configuration.labelFont)
      gc.fillText(configuration.zoneLabelProvider(zoneIndex), x, startLocation, Direction.BottomCenter, 5.0, 5.0)
    }
  }

  @ConfigurationDsl
  class Configuration(
    var zonesProvider: ZonesProvider,

    /**
     * Returns the window y location (relative to the content area origin) for a beam for a given beam index
     */
    var beamLocationProvider: BeamsLocationProvider,
  ) {
    fun color(selected: Boolean): Color {
      return if (selected) {
        activeColor()
      } else {
        inactiveColor()
      }
    }

    var labelFont: FontDescriptorFragment = FontDescriptorFragment.DefaultSize

    /**
     * The horizontal gap between two zone indicators (center to center)
     */
    var zonesGap: @px Double = 15.0

    /**
     * The color for the selected beam
     */
    var activeColor: ColorProvider = Color.black

    /**
     * The color for the unselected beam
     */
    var inactiveColor: ColorProvider = Color.darkgray

    /**
     * The width of the line for zones
     */
    var lineWidth: @Zoomed Double = 1.0

    /**
     * The total length of the top/bottom line
     */
    var horizontalLineSegmentLength: @Zoomed Double = 4.0

    /**
     * Provides the label for the zone
     */
    var zoneLabelProvider: (zoneIndex: Int) -> String = { zoneIndex -> "${zoneIndex + 1}" }
  }
}

interface ZonesProvider {
  /**
   * The number of zones
   */
  val count: Int

  /**
   * Returns the index of the start beam of the zone
   */
  fun startIndex(zoneIndex: Int): Int

  /**
   * Returns the index of the end beam of the zone
   */
  fun endIndex(zoneIndex: Int): Int

  /**
   * Returns true if the zone is active, false otherwise
   */
  fun isActive(zoneIndex: Int): Boolean
}
