package com.meistercharts.charts.lizergy.roofPlanning

import com.meistercharts.algorithms.layers.LayerPaintingContext
import com.meistercharts.algorithms.painter.FillAndStrokeStyle
import com.meistercharts.annotations.Zoomed
import com.meistercharts.calc.ChartCalculator
import com.meistercharts.canvas.fill
import com.meistercharts.canvas.paintable.Paintable
import com.meistercharts.canvas.saved
import com.meistercharts.color.Color
import com.meistercharts.color.RgbaColor
import com.meistercharts.color.withAlpha
import com.meistercharts.resources.Icons
import it.neckar.geometry.Direction
import it.neckar.geometry.Size
import it.neckar.open.formatting.format
import it.neckar.open.kotlin.lang.abs
import it.neckar.open.kotlin.lang.asProvider

/**
 * Paints an unusable area
 */
class ModuleAreaPainter {
  /**
   * The configuration for the module area painter
   */
  val configuration: Configuration = Configuration()

  /**
   * Paints the unusable area
   */
  fun paint(
    paintingContext: LayerPaintingContext,
    x: @Zoomed Double,
    y: @Zoomed Double,
    width: @Zoomed Double,
    height: @Zoomed Double,
    moduleArea: ModuleArea,
    chartCalculator: ChartCalculator,
    mode: Mode,
  ) {
    val gc = paintingContext.gc
    gc.lineWidth = 0.5
    mode.fillAndStroke.apply(gc)

    // The rect itself
    gc.fillRect(x, y, width, height)

    // Border
    gc.strokeRect(x, y, width, height)

    // Cross
    gc.strokeLine(x, y + height, x + width, y)
    gc.strokeLine(x, y, x + width, y + height)

    // Paint the values
    if (mode.showLocationAndSize) {
      gc.fill(Color.white)

      drawMeasures(gc, chartCalculator, x, y, width, height, moduleArea.location.x, moduleArea.location.y, moduleArea.size.width, moduleArea.size.height, configuration.unusableAreaSizeInfoGap)

      // Paint the size (top center)
      gc.fillText(
        text = moduleArea.size.let {
          "${(it.width / 1000.0).format(2)}m x ${(it.height / 1000.0).format(2)}m"
        },
        x = x,
        y = y,
        anchorDirection = Direction.BottomLeft,
        gapHorizontal = configuration.unusableAreaSizeInfoGap,
        gapVertical = configuration.unusableAreaSizeInfoGap,
        maxWidth = width.abs() - configuration.unusableAreaSizeInfoGap * 2,
      )
    }


    //Paint the delete icons
    if (mode.showDeleteIcon) {
      gc.saved {
        configuration.deleteIcon.paint(paintingContext, x + width / 2.0, y + height / 2.0)
      }
    }
    if (mode.showArmedDeleteIcon) {
      gc.saved {
        configuration.deleteIconArmed.paint(paintingContext, x + width / 2.0, y + height / 2.0)
      }
    }

    //Show the rotate button for manual modules only
    if (mode.showRotateIcon) {
      gc.saved {
        configuration.rotateIcon.paintInBoundingBox(paintingContext, x + width, y + height, Direction.TopRight, 10.0)
      }
    }
    //Show the rotate button for manual modules only
    if (mode.showArmedRotateIcon) {
      gc.saved {
        configuration.rotateIconArmed.paintInBoundingBox(paintingContext, x + width, y + height, Direction.TopRight, 10.0)
      }
    }
  }


  class Configuration {
    /**
     * The gap within the area size info
     */
    var unusableAreaSizeInfoGap: @Zoomed Double = 3.0

    /**
     * The delete icon that is painted on hover
     */
    val deleteIcon: Paintable = Icons.delete(Size.PX_30)

    /**
     * The delete icon while hovering above is
     */
    val deleteIconArmed: Paintable = Icons.delete(Size.PX_30, Color.red)

    /**
     * The rotate icon that is painted on manual modules on hover
     */
    val rotateIcon: Paintable = Icons.rotate(Size.PX_30)

    /**
     * The rotate icon on manual modules while hovering above it
     */
    val rotateIconArmed: Paintable = Icons.rotate(Size.PX_30, Color.blue)
  }

  /**
   * Describes the mode
   */
  enum class Mode(
    /**
     * If set to true the location and size are shown
     */
    val showLocationAndSize: Boolean,

    /**
     * Returns true if the deleted icon is shown.
     * Attention this is different than [showArmedDeleteIcon]
     */
    val showDeleteIcon: Boolean,

    val showArmedDeleteIcon: Boolean,

    val showRotateIcon: Boolean,

    val showArmedRotateIcon: Boolean,

    /**
     * The fill and stroke that is used for that mode
     */
    val fillAndStroke: FillAndStrokeStyle,
  ) {

    /**
     * Default mode - nothing special
     */
    Default(
      showLocationAndSize = false,
      showDeleteIcon = false,
      showArmedDeleteIcon = false,
      showRotateIcon = false,
      showArmedRotateIcon = false,
      fillAndStroke = FillAndStrokeStyle(Color.white.withAlpha(0.5), RgbaColor(86, 86, 86).asProvider())
    ),

    Invisible(
      showLocationAndSize = false,
      showDeleteIcon = false,
      showArmedDeleteIcon = false,
      showRotateIcon = false,
      showArmedRotateIcon = false,
      fillAndStroke = FillAndStrokeStyle(Color.white.withAlpha(0.0), Color.white.withAlpha(0.0))
    ),

    /**
     * Unselected, but the mouse is hovering
     */
    Hover(
      showLocationAndSize = true,
      showDeleteIcon = false,
      showArmedDeleteIcon = false,
      showRotateIcon = false,
      showArmedRotateIcon = false,
      fillAndStroke = FillAndStrokeStyle(Color.web("#75b72600"), Color.web("#75b726"))
    ),

    /**
     * Selected - but the mouse is *not* hovering
     */
    Selected(
      showLocationAndSize = true,
      showDeleteIcon = false,
      showArmedDeleteIcon = false,
      showRotateIcon = false,
      showArmedRotateIcon = false,
      fillAndStroke = FillAndStrokeStyle(Color.web("#75b7264D"), Color.web("#75b726"))
    ),

    /**
     * Selected - and hovering
     */
    SelectedHovering(
      showLocationAndSize = true,
      showDeleteIcon = true,
      showArmedDeleteIcon = false,
      showRotateIcon = true,
      showArmedRotateIcon = false,
      fillAndStroke = FillAndStrokeStyle(Color.web("#75b7267D"), Color.web("#75b726"))
    ),

    /**
     * Is currently dragged
     */
    Dragging(
      showLocationAndSize = true,
      showDeleteIcon = false,
      showArmedDeleteIcon = false,
      showRotateIcon = false,
      showArmedRotateIcon = false,
      fillAndStroke = FillAndStrokeStyle(Color.white.withAlpha(0.2), Color.white)
    ),

    /**
     * delete is armed (selected or unselected)
     */
    DeleteArmed(
      showLocationAndSize = false,
      showDeleteIcon = false,
      showArmedDeleteIcon = true,
      showRotateIcon = false,
      showArmedRotateIcon = false,
      fillAndStroke = FillAndStrokeStyle(Color.red.withAlpha(0.3), Color.red)
    ),

    /**
     * delete is armed (selected or unselected)
     */
    RotateArmed(
      showLocationAndSize = false,
      showDeleteIcon = false,
      showArmedDeleteIcon = false,
      showRotateIcon = false,
      showArmedRotateIcon = true,
      fillAndStroke = FillAndStrokeStyle(Color.blue.withAlpha(0.3), Color.blue)
    );
  }
}
