package it.neckar.elektromeister.rest

/**
 * Represents a floor plan
 */
data class FloorPlan(
  /**
   * The rooms for this floor
   */
  val rooms: List<Room>,
  /**
   * All placed components (for all rooms)
   */
  val placedElectricalComponents: List<PlacedElectricalComponent>,

  /**
   * The connection lines between the components
   */
  val connectionLines: List<ConnectionLine>,
) {
  /**
   * Returns the components assigned to each room
   */
  fun calculateComponentsPerRoom(): Map<Room?, List<PlacedElectricalComponent>> {
    return placedElectricalComponents.groupBy { component -> findRoomForComponent(component) }
  }

  /**
   * Returns the room for the given component
   */
  fun findRoomForComponent(component: PlacedElectricalComponent): Room? {
    return rooms.find { room -> room.contains(component.location) }
  }
}
