package com.meistercharts.charts.lizergy.roofPlanning

import it.neckar.geometry.Rectangle
import it.neckar.open.observable.ObservableList
import it.neckar.open.unit.si.mm

/**
 * Contains the unusable areas
 */
class UnusableAreas {
  /**
   * The unusableAreas property
   */
  val unusableAreasProperty: ObservableList<UnusableArea> = ObservableList.empty()

  /**
   * Getter for the current unusableAreas
   */
  val unusableAreas: List<UnusableArea> by unusableAreasProperty

  /**
   * Returns the unusableAreas count
   */
  val count: Int
    get() = unusableAreas.size

  fun clear() {
    unusableAreasProperty.value = emptyList()
  }

  fun add(unusableArea: UnusableArea) {
    unusableAreasProperty.getAndSet {
      it.plus(unusableArea)
    }
  }

  fun addAll(unusableAreas: Iterable<UnusableArea>) {
    unusableAreasProperty.getAndSet {
      it.plus(unusableAreas)
    }
  }

  /**
   * Sets the unusableAreas
   */
  fun set(unusableAreas: Iterable<UnusableArea>) {
    unusableAreasProperty.value = unusableAreas.toMutableList()
  }

  operator fun get(index: Int): UnusableArea {
    return unusableAreas[index]
  }

  /**
   * Removes the given unusableAreas
   */
  fun removeAll(toRemove: Set<UnusableArea>) {
    removeAll {
      toRemove.contains(it)
    }
  }

  fun removeAll(predicate: (UnusableArea) -> Boolean) {
    unusableAreasProperty.getAndSet {
      it.toMutableList().apply {
        removeAll(predicate)
      }
    }
  }

  fun remove(unusableArea: UnusableArea) {
    unusableAreasProperty.getAndSet {
      it.minus(unusableArea)
    }
  }

  /**
   * Returns true if any unusable area overlaps with the given rectangle
   */
  fun overlaps(rectangle: @RoofRelative @mm Rectangle): Boolean {
    return unusableAreas.any {
      it.overlaps(rectangle)
    }
  }
}
