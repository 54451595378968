package com.meistercharts.charts.lizergy.roofPlanning

import it.neckar.open.collections.fastForEach
import it.neckar.open.collections.fastForEachIndexed
import it.neckar.open.observable.ObservableList
import it.neckar.open.observable.ObservableObject
import it.neckar.open.observable.ReadOnlyObservableList
import it.neckar.open.observable.ReadOnlyObservableObject

/**
 * Contains the moduls
 */
class Modules {
  /**
   * The list of visible modules
   */
  val visibleModulesProperty: ObservableList<Module> = ObservableList.empty()

  /**
   * Contains all modules with grid layout
   */
  val modulesWithGridLayoutProperty: ReadOnlyObservableList<Module> = visibleModulesProperty

  val modules: List<Module> by modulesWithGridLayoutProperty

  /**
   * Getter for the current modules
   */
  val visibleModules: List<Module> by visibleModulesProperty

  operator fun get(index: Int): Module {
    return visibleModules[index]
  }

  /**
   * Returns the modules count
   */
  val count: Int
    get() = modules.size

  val visibleModulesCount: Int
    get() = visibleModules.size

  fun clear() {
    visibleModulesProperty.value = listOf()
  }

  /**
   * Removes all modules that have the grid layout
   */
  fun removeModulesWithGridLayout(moduleArea: ModuleArea) {
    removeAll {
      it.modulePlacement.moduleArea == moduleArea
    }
  }

  fun add(module: Module) {
    visibleModulesProperty.getAndSet {
      it.plus(module)
    }
  }

  fun addAll(modules: Iterable<Module>) {
    visibleModulesProperty.getAndSet {
      it.plus(modules)
    }
  }

  /**
   * Sets the modules
   */
  fun set(modules: Iterable<Module>) {
    visibleModulesProperty.value = modules.toMutableList()
  }

  /**
   * Removes the given modules
   */
  fun removeAll(toRemove: Iterable<Module>) {
    removeAll {
      toRemove.contains(it)
    }
  }

  fun removeAll(predicate: (Module) -> Boolean) {
    visibleModulesProperty.getAndSet {
      it.toMutableList().apply {
        removeAll(predicate)
      }
    }
  }

  fun remove(module: Module) {
    visibleModulesProperty.getAndSet {
      it.minus(module)
    }
  }

  /**
   * Removes the module with the given index
   */
  fun remove(moduleIndex: ModuleIndex) {
    removeAll { module ->
      module.modulePlacement.moduleIndex == moduleIndex
    }
  }

  /**
   * Returns true if any module area overlaps with the given module
   */
  fun overlaps(module: Module): Boolean {
    return visibleModules.any {
      it != module &&
          it.overlaps(module.bounds)
    }
  }

  inline fun fastForEachModule(action: (Module) -> Unit) {
    modules.fastForEach(action)
  }

  inline fun fastForEachModuleIndexed(action: (Int, Module) -> Unit) {
    modules.fastForEachIndexed(action)
  }

  inline fun fastForEachVisibleModule(action: (Module) -> Unit) {
    visibleModules.fastForEach(action)
  }

  inline fun fastForEachVisibleModuleIndexed(action: (Int, Module) -> Unit) {
    visibleModules.fastForEachIndexed(action)
  }
}
