/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.elektromeister.model

import it.neckar.elektromeister.rest.FloorPlan
import it.neckar.elektromeister.rest.Room
import it.neckar.elektromeister.rest.components.ElectricalComponent
import it.neckar.geometry.Coordinates
import it.neckar.open.dispose.Disposable
import it.neckar.open.observable.ConsumeChangesAction
import it.neckar.open.observable.ObservableList
import it.neckar.open.observable.ObservableProperties
import it.neckar.open.observable.ObservablePropertiesSupport
import it.neckar.open.unit.si.mm

/**
 * A mutable floor plan - used for editing the floor plan
 */
class MutableFloorPlan : ObservableProperties {
  /**
   * The rooms for this floor
   */
  val roomsProperty: ObservableList<Room> = ObservableList.empty()
  var rooms: List<Room> by roomsProperty

  /**
   * All placed components (for all rooms)
   */
  val placedElectricalComponentsProperty: ObservableList<MutablePlacedElectricalComponent> = ObservableList(emptyList())
  var placedElectricalComponents: List<MutablePlacedElectricalComponent> by placedElectricalComponentsProperty

  /**
   * The connection lines between the components
   */
  val connectionLinesProperty: ObservableList<MutableConnectionLine> = ObservableList(emptyList())
  var connectionLines: List<MutableConnectionLine> by connectionLinesProperty


  private val observablePropertiesSupport: ObservablePropertiesSupport = ObservablePropertiesSupport.create {
    addObservableListNoSelected(roomsProperty)
    addObservableListSelected(placedElectricalComponentsProperty) {
      it
    }
    addObservableListSelected(connectionLinesProperty) { it }
  }

  override fun consumeAllPropertiesChanges(action: ConsumeChangesAction<Any?>): Disposable {
    return observablePropertiesSupport.consumeAllPropertiesChanges(action)
  }

  fun addPlacedElectricalComponent(placedElectricalComponent: MutablePlacedElectricalComponent) {
    placedElectricalComponents = placedElectricalComponents + placedElectricalComponent
  }

  fun removePlacedElectricalComponent(component: MutablePlacedElectricalComponent) {
    placedElectricalComponents = placedElectricalComponents.filter { it.id != component.id }

    //Also, delete all connection lines that are connected to this component
    connectionLines = connectionLines.filter { it.isConnectedTo(component.id).not() }
  }

  fun addConnectionLine(start: MutablePlacedElectricalComponent, end: MutablePlacedElectricalComponent): MutableConnectionLine {
    val startMutable = placedElectricalComponent(start.id)
    val endMutable = placedElectricalComponent(end.id)

    val connectionLine = MutableConnectionLine(startMutable, endMutable)
    connectionLines = connectionLines + connectionLine

    return connectionLine
  }

  fun placedElectricalComponent(id: ElectricalComponent.Id): MutablePlacedElectricalComponent {
    //TODO introduce map(?)
    return placedElectricalComponents.first { it.electricalComponent.id == id }
  }

  /**
   * Returns the first room that contains the provided coordinates
   */
  fun findRoom(coordinates: @mm Coordinates): Room? {
    return rooms.firstOrNull { room ->
      room.bounds.contains(coordinates)
    }
  }

  /**
   * Converts this mutable floor plan to a [FloorPlan]
   */
  fun toFloorPlan(): FloorPlan {
    return FloorPlan(
      rooms = rooms,
      placedElectricalComponents = placedElectricalComponents.map { it.toPlacedElectricalComponent() },
      connectionLines = connectionLines.map { it.toConnectionLine() }
    )
  }
}

/**
 * Creates a new instance of [MutableFloorPlan] from the provided [FloorPlan]
 */
fun FloorPlan.toMutableFloorPlan(): MutableFloorPlan {
  val floorPlan = this

  val mutableElectricalComponents = floorPlan.placedElectricalComponents.map { it.toMutable() }

  return MutableFloorPlan().apply {
    rooms = floorPlan.rooms
    placedElectricalComponents = mutableElectricalComponents
    connectionLines = floorPlan.connectionLines.map { connectionLine ->
      connectionLine.toMutable { id: ElectricalComponent.Id ->
        mutableElectricalComponents.first { it.electricalComponent.id == id }
      }
    }
  }
}
