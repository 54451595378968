/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.elektromeister

import com.meistercharts.algorithms.layers.AbstractLayer
import com.meistercharts.algorithms.layers.LayerPaintingContext
import com.meistercharts.algorithms.layers.LayerType
import com.meistercharts.algorithms.layers.domainChartCalculator
import com.meistercharts.annotations.Window
import com.meistercharts.annotations.Zoomed
import com.meistercharts.canvas.DebugFeature
import com.meistercharts.canvas.calculateOffsetXWithAnchor
import com.meistercharts.canvas.calculateOffsetYWithAnchor
import com.meistercharts.canvas.fillRoundedRect
import com.meistercharts.canvas.fillStyle
import com.meistercharts.canvas.layout.cache.BoundsMultiCache
import com.meistercharts.canvas.layout.cache.CoordinatesMultiCache
import com.meistercharts.canvas.layout.cache.ObjectMultiCache
import com.meistercharts.canvas.paintMark
import com.meistercharts.canvas.paintable.ObjectFit
import com.meistercharts.canvas.saved
import com.meistercharts.canvas.strokeRoundedRect
import com.meistercharts.canvas.strokeStyle
import com.meistercharts.color.Color
import com.meistercharts.color.ColorProvider
import com.meistercharts.elektromeister.model.MutablePlacedElectricalComponent
import com.meistercharts.resources.svg.PathPaintable
import com.meistercharts.style.Palette
import it.neckar.elektromeister.rest.components.CircuitBreaker
import it.neckar.elektromeister.rest.components.Doorbell
import it.neckar.elektromeister.rest.components.EthernetSocket
import it.neckar.elektromeister.rest.components.GarageDoorOpener
import it.neckar.elektromeister.rest.components.LightFixture
import it.neckar.elektromeister.rest.components.LightSwitch
import it.neckar.elektromeister.rest.components.MotionSensor
import it.neckar.elektromeister.rest.components.PowerDistributionBoard
import it.neckar.elektromeister.rest.components.SmokeDetector
import it.neckar.elektromeister.rest.components.SocketOutlet
import it.neckar.elektromeister.rest.components.Thermostat
import it.neckar.geometry.Coordinates
import it.neckar.geometry.Direction
import it.neckar.geometry.Rectangle
import it.neckar.geometry.Size
import it.neckar.open.provider.fastForEachIndexed
import it.neckar.open.unit.other.px

/**
 * Paints all electrical components
 */
class ElektromeisterElectricalComponentsLayer(
  val configuration: Configuration,
) : AbstractLayer() {

  override val type: LayerType = LayerType.Content

  override fun paintingVariables(): PaintingVariables {
    return paintingVariables
  }

  private val paintingVariables = object : PaintingVariables {
    override fun findElectricalComponent(coordinates: @Window Coordinates): MutablePlacedElectricalComponent? {
      @ElectricalComponentIndex val foundIndex = boundingBoxesCache.findIndex(coordinates) ?: return null
      return configuration.electricalComponents.getOrNull(foundIndex)
    }

    /**
     * The exact locations of each component (anchor points)
     */
    @Window
    @ElectricalComponentIndex
    val anchorCoordinatesCache = CoordinatesMultiCache()

    /**
     * Contains the bounding boxes for each paintable for each component.
     *
     * The bounding box has been "normalized": Paint from the top left corner.
     */
    @Window
    @ElectricalComponentIndex
    val boundingBoxesCache = BoundsMultiCache()

    /**
     * The anchor directions of each component
     */
    @ElectricalComponentIndex
    val anchorDirectionCache = ObjectMultiCache(Direction.Center)

    /**
     * Contains the paintables for each component
     */
    @ElectricalComponentIndex
    val componentPathPaintableCache = ObjectMultiCache<PathPaintable?>(null)


    @ElectricalComponentIndex
    val paintModeCache = ObjectMultiCache(PaintMode.Default)

    override fun calculate(paintingContext: LayerPaintingContext) {
      val chartSupport = paintingContext.chartSupport
      val gc = chartSupport.canvas.gc
      val chartCalculator = chartSupport.domainChartCalculator(domainRangeX = configuration.rangeX, domainRangeY = configuration.rangeY)

      val electricalComponentsCount = configuration.electricalComponents.size()

      boundingBoxesCache.prepare(electricalComponentsCount)
      anchorCoordinatesCache.prepare(electricalComponentsCount)
      componentPathPaintableCache.prepare(electricalComponentsCount)
      anchorDirectionCache.prepare(electricalComponentsCount)
      paintModeCache.prepare(electricalComponentsCount)

      configuration.electricalComponents.fastForEachIndexed {
          electricalComponentIndex: @ElectricalComponentIndex Int,
          placedElectricalComponent: MutablePlacedElectricalComponent,
        ->

        val isMouseOverComponent = placedElectricalComponent == configuration.model.stateMachine.mouseOverComponent
        val isSelectedComponent = placedElectricalComponent == configuration.model.stateMachine.selectedComponent
        val isStartOrEndOfTemporaryConnection: Boolean = configuration.model.stateMachine.connectionLineCreationMode?.let { connectionLineCreationMode ->
          placedElectricalComponent == connectionLineCreationMode.start || placedElectricalComponent == connectionLineCreationMode.potentialEnd
        } ?: false
        val isMoving = configuration.model.stateMachine.movedComponent == placedElectricalComponent


        val paintMode = when {
          isMoving -> {
            PaintMode.Moving
          }

          isStartOrEndOfTemporaryConnection -> {
            PaintMode.StartOrEndOfTemporaryConnectionLine
          }

          isMouseOverComponent && isSelectedComponent -> {
            PaintMode.SelectedHover
          }

          isSelectedComponent -> {
            PaintMode.Selected
          }

          isMouseOverComponent -> {
            PaintMode.Hover
          }

          else -> {
            PaintMode.Default
          }
        }
        paintModeCache[electricalComponentIndex] = paintMode


        //The exact location of the anchor point
        @Window val anchorX = chartCalculator.domain2windowX(placedElectricalComponent.transient.locationToPaint.x)
        @Window val anchorY = chartCalculator.domain2windowY(placedElectricalComponent.transient.locationToPaint.y)

        //Store the coordinates of the anchor points
        anchorCoordinatesCache.set(electricalComponentIndex, anchorX, anchorY)
        anchorDirectionCache[electricalComponentIndex] = placedElectricalComponent.anchorDirection

        //Fetch the paintable
        val paintable = when (placedElectricalComponent.electricalComponent) {
          is SocketOutlet -> ElektroMeisterSvgs.Socket
          is LightSwitch -> ElektroMeisterSvgs.Switch
          is LightFixture -> ElektroMeisterSvgs.Lights

          is Doorbell -> ElektroMeisterSvgs.Thermostat
          is CircuitBreaker -> ElektroMeisterSvgs.Thermostat
          is EthernetSocket -> ElektroMeisterSvgs.Thermostat
          is GarageDoorOpener -> ElektroMeisterSvgs.Thermostat
          is MotionSensor -> ElektroMeisterSvgs.Thermostat
          is PowerDistributionBoard -> ElektroMeisterSvgs.Thermostat
          is SmokeDetector -> ElektroMeisterSvgs.Thermostat
          is Thermostat -> ElektroMeisterSvgs.Thermostat
        }
        componentPathPaintableCache[electricalComponentIndex] = paintable


        //Calculate the bounding box
        val iconSize = configuration.componentIconSize(paintMode)

        val anchorDirection = placedElectricalComponent.anchorDirection
        @Zoomed val offsetX = anchorDirection.horizontalAlignment.calculateOffsetXWithAnchor(width = iconSize.width, anchorGapHorizontal = 0.0)
        @Zoomed val offsetY = anchorDirection.verticalAlignment.calculateOffsetYWithAnchor(height = iconSize.height, anchorGapVertical = 0.0, gc = gc)

        @Window val boundingBox = Rectangle(x = anchorX + offsetX, y = anchorY + offsetY, width = iconSize.width, height = iconSize.height)

        boundingBoxesCache[electricalComponentIndex] = boundingBox
      }
    }
  }

  override fun paint(paintingContext: LayerPaintingContext) {
    val gc = paintingContext.gc

    paintingVariables.boundingBoxesCache.fastForEachIndexed { electricalComponentIndex: @ElectricalComponentIndex Int, x, y, width, height ->
      val pathPaintable: PathPaintable? = paintingVariables.componentPathPaintableCache[electricalComponentIndex]

      if (pathPaintable != null) {
        gc.saved {
          val paintMode = paintingVariables.paintModeCache[electricalComponentIndex]
          val direction = paintingVariables.anchorDirectionCache[electricalComponentIndex]

          when (paintMode) {
            PaintMode.Default -> {
              //Paint the background + Border
              gc.fillStyle(configuration.backgroundColor)
              gc.fillRoundedRect(x = x, y = y, width = width, height = height, radius = configuration.borderRoundedRadius)
              gc.strokeStyle(configuration.borderColor)
              gc.lineWidth = configuration.borderWidth
              gc.strokeRoundedRect(x = x, y = y, width = width, height = height, radius = configuration.borderRoundedRadius)
            }

            PaintMode.Moving -> {
              //Paint the background + Border
              gc.fillStyle(configuration.backgroundColorMoving)
              gc.fillRoundedRect(x = x, y = y, width = width, height = height, radius = configuration.borderRoundedRadius)
              gc.strokeStyle(configuration.borderColorMoving)
              gc.lineWidth = configuration.borderWidth
              gc.strokeRoundedRect(x = x, y = y, width = width, height = height, radius = configuration.borderRoundedRadius)
            }

            PaintMode.Hover -> {
              //Paint the background + Border
              gc.fillStyle(configuration.backgroundColorHover)
              gc.fillRoundedRect(x = x, y = y, width = width, height = height, radius = configuration.borderRoundedRadius)
              gc.strokeStyle(configuration.borderColor)
              gc.lineWidth = configuration.borderWidth
              gc.strokeRoundedRect(x = x, y = y, width = width, height = height, radius = configuration.borderRoundedRadius)
            }

            PaintMode.StartOrEndOfTemporaryConnectionLine -> {
              //Paint the background + Border
              gc.fillStyle(configuration.backgroundColorStartOrEndOfTemporaryConnectionLine)
              gc.fillRoundedRect(x = x, y = y, width = width, height = height, radius = configuration.borderRoundedRadius)
              gc.strokeStyle(configuration.borderColor)
              gc.lineWidth = configuration.borderWidth
              gc.strokeRoundedRect(x = x, y = y, width = width, height = height, radius = configuration.borderRoundedRadius)
            }

            PaintMode.Selected -> {
              //Paint the background + Border
              gc.fillStyle(configuration.backgroundColorSelected)
              @px val additionalPadding = configuration.selectedBoxAdditionalPadding
              gc.fillRoundedRect(x - additionalPadding, y - additionalPadding, width + additionalPadding * 2, height + additionalPadding * 2, radius = configuration.borderRoundedRadius)

              gc.strokeStyle(configuration.selectedBorderColor)
              gc.lineWidth = configuration.selectedBorderWidth
              gc.strokeRoundedRect(x = x - additionalPadding, y = y - additionalPadding, width = width + additionalPadding * 2, height = height + additionalPadding * 2, radius = configuration.borderRoundedRadius)
            }

            PaintMode.SelectedHover -> {
              //Paint the background + Border
              gc.fillStyle(configuration.backgroundColorHoverSelected)
              @px val additionalPadding = configuration.selectedBoxAdditionalPadding
              gc.fillRoundedRect(x - additionalPadding, y - additionalPadding, width + additionalPadding * 2, height + additionalPadding * 2, radius = configuration.borderRoundedRadius)

              gc.strokeStyle(configuration.selectedBorderColor)
              gc.lineWidth = configuration.selectedBorderWidth
              gc.strokeRoundedRect(x = x - additionalPadding, y = y - additionalPadding, width = width + additionalPadding * 2, height = height + additionalPadding * 2, radius = configuration.borderRoundedRadius)
            }
          }

          //The bounding box has been normalized, use TopLeft
          pathPaintable.paintInBoundingBox(paintingContext, x, y, anchorDirection = Direction.TopLeft, width = width, height = height, objectFit = ObjectFit.Fill)
        }

        if (gc.debug[DebugFeature.ShowAnchors]) {
          gc.strokeStyle(Color.lightgray)
          gc.paintMark(
            x = paintingVariables.anchorCoordinatesCache.x(electricalComponentIndex),
            y = paintingVariables.anchorCoordinatesCache.y(electricalComponentIndex)
          )
        }

        if (gc.debug[DebugFeature.ShowBounds]) {
          gc.strokeStyle(Color.lightgray)
          gc.strokeRect(x, y, width, height)
        }
      }
    }
  }

  internal enum class PaintMode {
    /**
     * Nothing special, just paint the component as is
     */
    Default,

    /**
     * The element is selected (but *not* hover).
     * Show context "menu" (e.g., trash button etc).
     */
    Selected,

    /**
     * The mouse is hovering over the (unselected) component
     */
    Hover,

    /**
     * The component is currently moved
     */
    Moving,

    /**
     * The element is selected and the mouse is hovering over it.
     *
     * Show context "menu" (e.g., trash button etc).
     * Also visualize the hovering effect
     */
    SelectedHover,

    /**
     * The component is the start of a temporary connection line.
     *
     * Start: Selected by click
     * End: Hovering while in connection line mode
     *
     * The component should be highlighted.
     */
    StartOrEndOfTemporaryConnectionLine,
  }

  class Configuration(
    override val model: ElektromeisterMeisterchartsModel,
  ) : AbstractElektromeisterConfiguration() {

    /**
     * The size of the component icon
     */
    var componentIconSize: @Zoomed Size = Size.PX_24

    /**
     * The size of the component icon - if it is active (e.g. hover)
     */
    var componentIconSizeActive: @Zoomed Size = Size.PX_30

    /**
     * Returns the size of the component icon - depending on the paint mode
     */
    internal fun componentIconSize(paintMode: PaintMode): Size {
      return when (paintMode) {
        PaintMode.Default,
        PaintMode.StartOrEndOfTemporaryConnectionLine,
        PaintMode.Moving,
          -> componentIconSize

        PaintMode.Selected,
        PaintMode.SelectedHover,
        PaintMode.Hover,
          -> componentIconSizeActive

      }
    }

    /**
     * The background color
     */
    var backgroundColor: ColorProvider = Color.white
    var backgroundColorMoving: ColorProvider = Color.lightgray
    var backgroundColorHover: ColorProvider = Color.lightgray
    var backgroundColorStartOrEndOfTemporaryConnectionLine: ColorProvider = Palette.getPrimaryColor(2)

    /**
     * The background color if the component is selected
     */
    var backgroundColorSelected: ColorProvider = Color.white
    var backgroundColorHoverSelected: ColorProvider = Color.lightgray

    /**
     * The border color
     */
    var borderColor: ColorProvider = Palette.getPrimaryColor(0)
    var borderColorMoving: ColorProvider = Color.darkgray
    var selectedBorderColor: ColorProvider = Palette.getPrimaryColor(0)
    var borderWidth: @px Double = 1.0
    var selectedBorderWidth: @px Double = 2.0
    val borderRoundedRadius: @px Double = 6.0

    /**
     * The additional size (on each side) for the selected box
     */
    val selectedBoxAdditionalPadding: @px Double = 1.0
  }

  interface PaintingVariables : com.meistercharts.algorithms.layers.PaintingVariables {
    fun findElectricalComponent(coordinates: @Window Coordinates): MutablePlacedElectricalComponent?
  }
}
