package com.meistercharts.charts.sick.lisa

import com.meistercharts.algorithms.layers.AxisConfiguration
import com.meistercharts.algorithms.layers.DefaultCategoryLayouter
import com.meistercharts.algorithms.layers.addClearBackground
import com.meistercharts.algorithms.layers.addTooltipLayer
import com.meistercharts.algorithms.layers.axis.ValueAxisLayer
import com.meistercharts.algorithms.layers.axis.withMaxNumberOfTicks
import com.meistercharts.algorithms.layers.barchart.CategoryAxisLayer
import com.meistercharts.algorithms.layers.barchart.CategoryChartOrientation
import com.meistercharts.algorithms.layers.barchart.CategoryLayer
import com.meistercharts.algorithms.layers.barchart.GreedyCategoryAxisLabelPainter
import com.meistercharts.algorithms.layers.barchart.createAxisLayer
import com.meistercharts.algorithms.layers.clipped
import com.meistercharts.annotations.Domain
import com.meistercharts.axis.IntermediateValuesMode
import com.meistercharts.canvas.ConfigurationDsl
import com.meistercharts.canvas.DirtyReason
import com.meistercharts.canvas.FixedContentAreaWidth
import com.meistercharts.canvas.MeisterchartBuilder
import com.meistercharts.charts.ChartGestalt
import com.meistercharts.charts.ContentViewportGestalt
import com.meistercharts.model.Insets
import com.meistercharts.model.Vicinity
import com.meistercharts.model.category.CategoryIndex
import com.meistercharts.model.category.CategorySeriesModel
import com.meistercharts.model.category.SeriesIndex
import com.meistercharts.range.ValueRange
import com.meistercharts.resize.ResetToDefaultsOnWindowResize
import com.meistercharts.zoom.OriginToContentViewport
import it.neckar.geometry.AxisSelection
import it.neckar.geometry.Side
import it.neckar.open.formatting.decimalFormat
import it.neckar.open.formatting.decimalFormat1digit
import it.neckar.open.formatting.intFormat
import it.neckar.open.i18n.I18nConfiguration
import it.neckar.open.i18n.TextService
import it.neckar.open.kotlin.lang.coerceAtLeastOrNull
import it.neckar.open.kotlin.lang.findMagnitudeValueCeil
import it.neckar.open.observable.ObservableObject
import it.neckar.open.provider.DoubleProvider
import it.neckar.open.provider.DoublesProvider
import it.neckar.open.unit.other.px
import kotlin.jvm.JvmOverloads

/**
 * For SLG2
 */
class LisaChartGestalt @JvmOverloads constructor(
  val configuration: Configuration,
  additionalConfiguration: Configuration.() -> Unit = {},
) : ChartGestalt {

  constructor(
    signalLevelValues: @Domain DoublesProvider = DoublesProvider.forDoubles(100.0, 150.0, 130.0, 70.4, 0.0, 1.0, 0.1),
    teachInSignalLevelValues: @Domain DoublesProvider = DoublesProvider.forDoubles(90.0, 130.0, 150.0, 33.7, 1.0, 0.1),
    thresholdOnValues: @Domain DoublesProvider = DoublesProvider.forDoubles(10.0, 10.5, 10.3, 2.0, 2.1),
    thresholdOffValues: @Domain DoublesProvider = DoublesProvider.forDoubles(40.0, 40.5, 55.5, 1.0, 2.0),
    thresholdMonitorValues: @Domain DoublesProvider = DoublesProvider.forDoubles(50.0, 50.5, 50.3, 50.4),
    gainValues: @Domain DoublesProvider = DoublesProvider.forDoubles(5.4, 3.5, 7.3, 2.4),
    errorCounterValues: @Domain DoublesProvider = DoublesProvider.forDoubles(11.0, 12.0, 13.0, 14.0),
    additionalConfiguration: Configuration.() -> Unit = {},
  ) : this(
    Configuration(
      signalLevelValues, teachInSignalLevelValues, thresholdOnValues, thresholdOffValues,
      thresholdMonitorValues, gainValues, errorCounterValues
    ), additionalConfiguration
  )

  init {
    configuration.additionalConfiguration()
  }

  val categorySeriesModel: CategorySeriesModel = object : CategorySeriesModel {
    override val numberOfCategories: Int
      get() {
        return configuration.maxSize
      }

    //Filtering of threshold visibility is done in LisaCategoryPainter
    override val numberOfSeries: Int = 6
    override fun valueAt(categoryIndex: CategoryIndex, seriesIndex: SeriesIndex): Double {
      return when (LisaDataType.forIndex(seriesIndex.value)) {
        LisaDataType.SignalStrength -> configuration.signalLevelValues.getOrElse(categoryIndex.value, Double.NaN)
        LisaDataType.TeachInSignal -> configuration.teachInSignalLevelValues.getOrElse(categoryIndex.value, Double.NaN)
        LisaDataType.Gain -> configuration.gainValues.getOrElse(categoryIndex.value, Double.NaN)

        LisaDataType.ThresholdOn -> configuration.thresholdOnValues.getOrElse(categoryIndex.value, Double.NaN)
        LisaDataType.ThresholdOff -> configuration.thresholdOffValues.getOrElse(categoryIndex.value, Double.NaN)
        LisaDataType.ThresholdMonitor -> configuration.thresholdMonitorValues.getOrElse(categoryIndex.value, Double.NaN)
      }
    }

    override fun categoryNameAt(categoryIndex: CategoryIndex, textService: TextService, i18nConfiguration: I18nConfiguration): String {
      return "${categoryIndex.value + 1}"
    }
  }

  val categoryPainter: LisaCategoryPainter = LisaCategoryPainter {
    strengthThresholdValueRange = { configuration.signalValueRange }
    gainValueRange = { configuration.gainValueRange }

    configuration.visibleCurvesModeProperty.consumeImmediately {
      thresholdGainVisible = it.thresholdGainVisible
    }
  }

  /**
   * The layer that paints the bars
   */
  val categoryLayer: CategoryLayer<CategorySeriesModel> = CategoryLayer({ categorySeriesModel }) {
    orientation = CategoryChartOrientation.VerticalLeft
    categoryPainter = this@LisaChartGestalt.categoryPainter
    layoutCalculator = DefaultCategoryLayouter {
      minCategorySizeProvider = {
        configuration.barSize
      }

      maxCategorySizeProvider = {
        configuration.barSize
      }
    }.apply {
      style.gapSize = DoubleProvider { 0.0 }
    }
  }

  /**
   * The layer that paints the labels of the bars
   */
  val categoryAxisLayer: CategoryAxisLayer = categoryLayer.createAxisLayer {
    tickOrientation = Vicinity.Outside
    axisLabelPainter = GreedyCategoryAxisLabelPainter {
      categoryLabelGap = 8.0
    }
    side = Side.Bottom
    titleProvider = { _, _ -> "Channels" }
  }

  /**
   * The value axis
   */
  val signalValueAxisLayer: ValueAxisLayer = ValueAxisLayer(ValueAxisLayer.Configuration { configuration.signalValueRange }) {
    tickOrientation = Vicinity.Outside
    paintRange = AxisConfiguration.PaintRange.ContentArea
    ticksFormat = intFormat
    side = Side.Left
    ticks = ticks.withMaxNumberOfTicks(10)
    titleProvider = { _, _ -> "Signal Strength / Thresholds" }
    titleColor = categoryPainter.style.signalStrengthColorOk
    tickLabelColor = categoryPainter.style.signalStrengthColorOk
  }

  val gainValueAxisLayer: ValueAxisLayer = ValueAxisLayer(ValueAxisLayer.Configuration { configuration.gainValueRange }) {
    tickOrientation = Vicinity.Outside
    paintRange = AxisConfiguration.PaintRange.ContentArea
    ticksFormat = decimalFormat1digit
    side = Side.Right
    ticks = ticks.withMaxNumberOfTicks(10)
    titleProvider = { _, _ -> "Gain" }
    titleColor = categoryPainter.style.gainColor
    tickLabelColor = categoryPainter.style.gainColor
  }

  /**
   * Shows the tooltips
   */
  val lisaTooltipContentLayer: LisaTooltipContentLayer = LisaTooltipContentLayer(
    layoutProvider = { categoryLayer.paintingVariables().layout },
    tooltipTextProvider = { segmentIndex ->
      fun Double?.formatOrEmpty(): String {
        return if (this == null) {
          ""
        } else {
          decimalFormat.format(this)
        }
      }

      buildList {
        add("Ch. ${segmentIndex + 1}")
        add("Signal: ${configuration.signalLevelValues.getOrNull(segmentIndex).formatOrEmpty()}")
        add("Teach-in Signal: ${configuration.teachInSignalLevelValues.getOrNull(segmentIndex).formatOrEmpty()}")
        add("Gain: ${configuration.gainValues.getOrNull(segmentIndex).formatOrEmpty()}")
        add("On-Threshold: ${configuration.thresholdOnValues.getOrNull(segmentIndex).formatOrEmpty()}")
        add("Monitor-Threshold: ${configuration.thresholdMonitorValues.getOrNull(segmentIndex).formatOrEmpty()}")
        add("Off-Threshold: ${configuration.thresholdOffValues.getOrNull(segmentIndex).formatOrEmpty()}")
        add("Error Counter: ${configuration.errorCounterValues.getOrNull(segmentIndex).formatOrEmpty()}")
      }
    }
  )


  val viewportGestalt: ContentViewportGestalt = ContentViewportGestalt(Insets.of(10.0, 75.0, 40.0, 75.0))

  init {
    viewportGestalt.contentViewportMarginProperty.consumeImmediately {
      signalValueAxisLayer.configuration.size = it[signalValueAxisLayer.configuration.side]
      gainValueAxisLayer.configuration.size = it[gainValueAxisLayer.configuration.side]

      categoryAxisLayer.configuration.size = it[categoryAxisLayer.configuration.side]
    }
  }

  override fun configure(meisterChartBuilder: MeisterchartBuilder) {
    viewportGestalt.configure(meisterChartBuilder)

    meisterChartBuilder.zoomAndTranslationDefaults = OriginToContentViewport
    meisterChartBuilder.contentAreaSizingStrategy = FixedContentAreaWidth(256 * configuration.barSize) //256 beams * 15 px/beam

    //Disable zooming
    meisterChartBuilder.zoomAndTranslationConfiguration {
      translateAxisSelection = AxisSelection.X
    }

    meisterChartBuilder.zoomAndTranslationModifier {
      disableZoom()
      contentAlwaysCompletelyVisible(AxisSelection.X)
    }

    meisterChartBuilder.configure {
      chartSupport.windowResizeBehavior = ResetToDefaultsOnWindowResize

      layers.addClearBackground()
      layers.addLayer(categoryLayer.clipped {
        //Do not paint in the axis area
        it.chartState.contentViewportMargin
      })
      layers.addLayer(categoryAxisLayer)
      layers.addLayer(signalValueAxisLayer)
      layers.addLayer(gainValueAxisLayer)
      layers.addLayer(lisaTooltipContentLayer)
      layers.addTooltipLayer()

      configuration.visibleCurvesModeProperty.consume {
        markAsDirty(DirtyReason.UiStateChanged)
      }
    }
  }

  /**
   * Recalculates the value ranges to fit the current data
   */
  fun recalculateAutoScaleValueRanges() {
    //Calculate gain
    configuration.gainValues.maxOrNull()?.let { max ->
      val maxWidened = IntermediateValuesMode.Also5.findSmaller(max.findMagnitudeValueCeil()) {
        it > max
      }

      configuration.gainValueRange = ValueRange.linear(0.0, maxWidened)
    }

    //Calculate signal strength
    configuration.signalThresholdMax()?.let { max ->
      val maxWidened = IntermediateValuesMode.Also5and2.findSmaller(max.findMagnitudeValueCeil()) {
        it > max
      }
      configuration.signalValueRange = ValueRange.linear(0.0, maxWidened)
    }
  }

  @ConfigurationDsl
  class Configuration(
    /**
     * The signal strength values (painted as bars)
     */
    var signalLevelValues: @Domain DoublesProvider = DoublesProvider.forDoubles(100.0, 150.0, 130.0, 70.4, 0.0, 1.0, 0.1),

    /**
     * Teach-in signal strength (painted as marks)
     */
    var teachInSignalLevelValues: @Domain DoublesProvider = DoublesProvider.forDoubles(90.0, 130.0, 150.0, 33.7, 1.0, 0.1),

    /**
     * The threshold on values (painted as marks)
     */
    var thresholdOnValues: @Domain DoublesProvider = DoublesProvider.forDoubles(10.0, 10.5, 10.3, 2.0, 2.1),

    /**
     * The threshold on values (painted as marks)
     */
    var thresholdOffValues: @Domain DoublesProvider = DoublesProvider.forDoubles(40.0, 40.5, 55.5, 1.0, 2.0),

    /**
     * The threshold on values (painted as marks)
     */
    var thresholdMonitorValues: @Domain DoublesProvider = DoublesProvider.forDoubles(50.0, 50.5, 50.3, 50.4),

    /**
     * The gain values
     */
    var gainValues: @Domain DoublesProvider = DoublesProvider.forDoubles(5.4, 3.5, 7.3, 2.4),

    /**
     * The error values - only used for tooltips!
     */
    var errorCounterValues: @Domain DoublesProvider = DoublesProvider.forDoubles(11.0, 12.0, 13.0, 14.0),
  ) {

    /**
     * Returns the max value for signals and threshold.
     * Returns null if no value is available
     */
    fun signalThresholdMax(): Double? {
      val signal = signalLevelValues.maxOrNull()
      val teachIn = teachInSignalLevelValues.maxOrNull()
      val thresholdOnMax = thresholdOnValues.maxOrNull()
      val thresholdOffMax = thresholdOffValues.maxOrNull()
      val thresholdMonitorMax = thresholdMonitorValues.maxOrNull()

      return signal
        .coerceAtLeastOrNull(teachIn)
        .coerceAtLeastOrNull(thresholdOnMax)
        .coerceAtLeastOrNull(thresholdOffMax)
        .coerceAtLeastOrNull(thresholdMonitorMax)
    }

    /**
     * Returns the max size from all providers
     */
    val maxSize: Int
      get() {
        return maxOf(signalLevelValues.size(), teachInSignalLevelValues.size(), thresholdOnValues.size(), thresholdOffValues.size(), thresholdMonitorValues.size(), gainValues.size())
      }
    var barSize: @px Double = 10.0

    /**
     * The value range for the signal and threshold
     */
    var signalValueRange: ValueRange = ValueRange.linear(0.0, 200.0)

    /**
     * The gain value range
     */
    var gainValueRange: ValueRange = ValueRange.linear(0.0, 20.0)

    val visibleCurvesModeProperty: ObservableObject<VisibleCurvesMode> = ObservableObject(VisibleCurvesMode.All)
    var visibleCurvesMode: VisibleCurvesMode by visibleCurvesModeProperty
  }

  enum class LisaDataType(val index: Int) {
    SignalStrength(0),
    TeachInSignal(1),

    Gain(2),
    ThresholdOn(3),
    ThresholdOff(4),
    ThresholdMonitor(5),
    ;

    /**
     * Returns the value from the provider with the index corresponding to this
     */
    fun getFrom(provider: DoublesProvider): Double {
      return provider[index]
    }

    companion object {
      /**
       * Returns the value for the given index
       */
      fun forIndex(index: Int): LisaDataType {
        return when (index) {
          SignalStrength.index -> SignalStrength
          TeachInSignal.index -> TeachInSignal
          Gain.index -> Gain
          ThresholdOn.index -> ThresholdOn
          ThresholdOff.index -> ThresholdOff
          ThresholdMonitor.index -> ThresholdMonitor
          else -> throw IllegalArgumentException("Unsupported index <$index>")
        }
      }
    }
  }

  enum class VisibleCurvesMode {
    /**
     * All curves are visible (all 6)
     */
    All,

    /**
     * The threshold values are not visible (3 threshold curves are hidden, 3 still visible)
     */
    OnlySignal;

    val thresholdGainVisible: Boolean
      get() {
        return this == All
      }
  }
}
