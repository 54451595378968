/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.elektromeister

import it.neckar.elektromeister.rest.ConnectionLine
import it.neckar.elektromeister.rest.FloorPlan
import it.neckar.elektromeister.rest.PlacedElectricalComponent
import it.neckar.elektromeister.rest.Room
import it.neckar.elektromeister.rest.components.ElectricalComponent
import it.neckar.elektromeister.rest.components.ElectricalComponentType
import it.neckar.elektromeister.rest.components.LightFixture
import it.neckar.elektromeister.rest.components.LightSwitch
import it.neckar.elektromeister.rest.components.SocketOutlet
import it.neckar.financial.currency.Money
import it.neckar.financial.currency.euro
import it.neckar.geometry.Coordinates
import it.neckar.geometry.Direction
import it.neckar.geometry.Polygon

/**
 *
 */
object ElektromeisterDemoData {
  fun price(type: ElectricalComponentType): Money {
    return when (type) {
      ElectricalComponentType.LightSwitch -> 34.71.euro
      ElectricalComponentType.LightFixture -> 48.99.euro
      ElectricalComponentType.SocketOutlet -> 12.99.euro
      ElectricalComponentType.CircuitBreaker -> 24.44.euro
      ElectricalComponentType.Doorbell -> 66.99.euro
      ElectricalComponentType.EthernetSocket -> 18.92.euro
      ElectricalComponentType.GarageDoorOpener -> 99.99.euro
      ElectricalComponentType.MotionSensor -> 144.23.euro
      ElectricalComponentType.PowerDistributionBoard -> 279.99.euro
      ElectricalComponentType.SmokeDetector -> 18.49.euro
      ElectricalComponentType.Thermostat -> 49.99.euro
    }
  }

  val placedElectricalComponents = buildList {
    //Waschküche/Technik
    add(
      PlacedElectricalComponent(
        component = LightSwitch(ElectricalComponent.Id.random(), "Lichtschalter", price(ElectricalComponentType.LightSwitch)),
        location = Coordinates(670.0, 3411.0),
        anchorDirection = Direction.Center,
      )
    )
    add(
      PlacedElectricalComponent(
        component = LightSwitch(ElectricalComponent.Id.random(), "Lichtschalter", price(ElectricalComponentType.LightSwitch)),
        location = Coordinates(1806.0, 5657.0),
        anchorDirection = Direction.Center,
      )
    )

    add(
      PlacedElectricalComponent(
        component = LightFixture(ElectricalComponent.Id.random(), "Deckenleuchte", price(ElectricalComponentType.LightFixture)),
        location = Coordinates(2940.0, 3711.0),
        anchorDirection = Direction.Center,
      )
    )
    add(
      PlacedElectricalComponent(
        component = SocketOutlet(ElectricalComponent.Id.random(), "Steckdose", price(ElectricalComponentType.SocketOutlet)),
        location = Coordinates(6760.0, 4582.0),
        anchorDirection = Direction.Center,
      )
    )


    //Next room

    add(
      PlacedElectricalComponent(
        component = LightSwitch(ElectricalComponent.Id.random(), "Lichtschalter", price(ElectricalComponentType.LightSwitch)),
        location = Coordinates(1766.0, 6210.0),
        anchorDirection = Direction.Center,
      )
    )
    add(
      PlacedElectricalComponent(
        component = LightFixture(ElectricalComponent.Id.random(), "Deckenleuchte", price(ElectricalComponentType.LightFixture)),
        location = Coordinates(1404.0, 7200.0),
        anchorDirection = Direction.Center,
      )
    )
    add(
      PlacedElectricalComponent(
        component = LightSwitch(ElectricalComponent.Id.random(), "Lichtschalter", price(ElectricalComponentType.LightSwitch)),
        location = Coordinates(1766.0, 8221.0),
        anchorDirection = Direction.Center,
      )
    )

  }

  val floorPlan: FloorPlan = FloorPlan(
    rooms = listOf(
      Room(
        id = Room.Id.random(),
        label = "Waschküche/Technik",
        bounds = Polygon(
          listOf(
            Coordinates(540.0, 2710.0),
            Coordinates(540.0, 5810.0),
            Coordinates(3216.0, 5810.0),
            Coordinates(3216.0, 6090.0),
            Coordinates(6950.0, 6090.0),
            Coordinates(6950.0, 2710.0),
          )
        )
      ),
      Room(
        id = Room.Id.random(),
        label = "Flur",
        bounds = Polygon(
          listOf(
            Coordinates(540.0, 6028.0),
            Coordinates(540.0, 8507.0),
            Coordinates(3131.0, 8507.0),
            Coordinates(3131.0, 6028.0),
          )
        )
      ),
      Room(
        id = Room.Id.random(),
        label = "Keller",
        bounds = Polygon(
          listOf(
            Coordinates(3233.0, 6260.0),
            Coordinates(3233.0, 8508.0),
            Coordinates(6950.0, 8508.0),
            Coordinates(6950.0, 6260.0),
          )
        )
      ),
      Room(
        id = Room.Id.random(),
        label = "Hobbykeller",
        bounds = Polygon(
          listOf(
            Coordinates(540.0, 8675.0),
            Coordinates(540.0, 12880.0),
            Coordinates(6950.0, 12880.0),
            Coordinates(6950.0, 8675.0),
          )
        )
      ),
    ),
    placedElectricalComponents = placedElectricalComponents,

    connectionLines = listOf(
      ConnectionLine(placedElectricalComponents[0].component, placedElectricalComponents[1].component),
      ConnectionLine(placedElectricalComponents[1].component, placedElectricalComponents[2].component),
      ConnectionLine(placedElectricalComponents[4].component, placedElectricalComponents[5].component),
      ConnectionLine(placedElectricalComponents[6].component, placedElectricalComponents[5].component),
    )
  )
}
