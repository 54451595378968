package com.meistercharts.charts.lizergy.solar

import com.meistercharts.algorithms.layers.barchart.GreedyCategoryAxisLabelPainter
import com.meistercharts.algorithms.layers.linechart.LineStyle
import com.meistercharts.algorithms.painter.SplineLinePainter
import com.meistercharts.canvas.MeisterchartBuilder
import com.meistercharts.charts.CategoryLineChartGestalt
import com.meistercharts.charts.ChartGestalt
import com.meistercharts.color.Color
import com.meistercharts.model.Insets
import com.meistercharts.model.category.FilteredCategorySeriesModel
import com.meistercharts.painter.XyCategoryLinePainter
import com.meistercharts.range.ValueRange
import it.neckar.datetime.minimal.Year
import it.neckar.open.formatting.appendUnit
import it.neckar.open.formatting.cached
import it.neckar.open.formatting.decimalFormat2digits
import it.neckar.open.provider.MultiProvider
import it.neckar.open.unit.currency.Cent
import it.neckar.open.unit.currency.EUR
import it.neckar.open.unit.other.pct

/**
 * Data Source: https://www.bdew.de/service/daten-und-grafiken/bdew-strompreisanalyse/ → Ganz unten als PDF anzeigen lassen
 * (Stand Juli 2023: https://www.bdew.de/media/documents/230724_BDEW-Strompreisanalyse_Juli_2023_24.07.2023.pdf) und dann runterscrollen bis 'Strompreis-Bestandteile für Haushalte' (Stand Juli 2023 auf Folie 10)
 */
class PvElectricityPriceGestalt : ChartGestalt {
  val gestalt: CategoryLineChartGestalt = CategoryLineChartGestalt()

  init {
    gestalt.configuration.valueRange = ValueRange.linear(0.09, 0.5)
    //gestalt.configuration.valueRange = ValueRange.linear(9.0, 50.0)

    gestalt.configuration.crossWireValueLabelFormat = decimalFormat2digits.appendUnit("€").cached()

    gestalt.configuration.categorySeriesModel = FilteredCategorySeriesModel(
      "Durchschnittlicher Strompreis für einen Haushalt in €/kWh",
      year2PriceEUR
    )

    gestalt.categoryLinesLayer.configuration.linePainters = MultiProvider.always(XyCategoryLinePainter(snapXValues = true, snapYValues = true, SplineLinePainter(true, true)))

    gestalt.categoryLinesLayer.configuration.pointPainters = MultiProvider.alwaysNull()
    gestalt.categoryLinesLayer.configuration.lineStyles = MultiProvider.always(LineStyle(Color.red, 2.0))
    gestalt.configuration.minCategorySize = 5.0
    gestalt.valueAxisLayer.configuration.ticksFormat = decimalFormat2digits.appendUnit("€").cached()
    gestalt.categoryAxisLayer.configuration.axisLabelPainter = GreedyCategoryAxisLabelPainter()
    gestalt.categoryAxisLayer.configuration.tickLength = 5.0
    gestalt.categoryAxisLayer.configuration.tickLineWidth = 1.0
    gestalt.categoryAxisLayer.configuration.tickLabelGap = 2.0
    gestalt.contentViewportMargin = Insets.Companion.of(10.0, 10.0, 50.0, 50.0)
    gestalt.configuration.showValuesGrid = false

  }

  override fun configure(meisterChartBuilder: MeisterchartBuilder) {
    gestalt.configure(meisterChartBuilder)

  }

  companion object {
    val year2Price: Map<Year, @Cent Double> = mapOf(
      //Year(1998) to 17.11,
      //Year(1999) to 16.53,
      //Year(2000) to 13.94,
      //Year(2001) to 14.32,
      //Year(2002) to 16.11,
      //Year(2003) to 17.19,
      Year(2004) to 17.96,
      Year(2005) to 18.66,
      Year(2006) to 19.46,
      Year(2007) to 20.64,
      Year(2008) to 21.65,
      Year(2009) to 23.21,
      Year(2010) to 23.69,
      Year(2011) to 25.23,
      Year(2012) to 25.89,
      Year(2013) to 28.84,
      Year(2014) to 29.14,
      Year(2015) to 28.70,
      Year(2016) to 28.80,
      Year(2017) to 29.28,
      Year(2018) to 29.47,
      Year(2019) to 30.46,
      Year(2020) to 31.81,
      Year(2021) to 32.16,
      Year(2022) to 38.57,
      Year(2023) to 46.27,
      Year(2024) to 47.89,
    )

    val year2PriceEUR: Map<Year, @EUR Double> = mapOf(
      Year(2003) to 0.17,
      Year(2004) to 0.17,
      Year(2005) to 0.18,
      Year(2006) to 0.19,
      Year(2007) to 0.20,
      Year(2008) to 0.21,
      Year(2009) to 0.23,
      Year(2010) to 0.23,
      Year(2011) to 0.25,
      Year(2012) to 0.25,
      Year(2013) to 0.28,
      Year(2014) to 0.29,
      Year(2015) to 0.28,
      Year(2016) to 0.28,
      Year(2017) to 0.29,
      Year(2018) to 0.29,
      Year(2019) to 0.30,
      Year(2020) to 0.31,
      Year(2021) to 0.32,
      Year(2022) to 0.38,
      Year(2023) to 0.46,
      Year(2024) to 0.47,
    )

  }

}

object PvElectricityPriceSupport {
  /**
   * Calculates the average yearly increase/decrease of the electricity prices.
   * @param range is used to calculate the first year (default: 20)
   */
  fun calcAvgYearlyIncrease(year2Price: Map<Year, @Cent Double>, range: Int = 20): @pct Double {
    val latestYear = year2Price.keys.max()
    val startYear = latestYear - range
    require(latestYear > startYear)

    @Cent val latestValue = year2Price[latestYear] ?: throw IllegalStateException("Could not find latest year for calculating yearly growth: $latestYear")
    @Cent val startValue = year2Price[startYear] ?: throw IllegalStateException("Could not find earliest year for calculating yearly growth: $startYear")

    @pct val totalIncrease = ((latestValue - startValue) / startValue)
    return totalIncrease / range
  }

  /**
   * Checks if the 'mehr als verdoppelt' string in details.html is still valid.
   */
  fun checkTotalIncrease(year2Price: Map<Year, @Cent Double>) {
    @Cent val firstYearPrice = year2Price[year2Price.keys.min()] ?: throw IllegalStateException("Could not find value for first year")
    @Cent val lastYearPrice = year2Price[year2Price.keys.max()] ?: throw IllegalStateException("Could not find value for last year")
    @pct val ratioFirstLastYearPrice = lastYearPrice / firstYearPrice
    require(ratioFirstLastYearPrice in 2.0..3.0) {
      "The electricity price of the last year is not two times larger than the price of the first year! The string 'mehr als verdoppelt' must be adjusted in details.html"
    }
  }
}


