package it.neckar.financial.currency

import it.neckar.financial.currency.io.MoneySerializer
import it.neckar.open.collections.fastSumByLong
import it.neckar.open.formatting.formatEuro
import it.neckar.open.i18n.CurrentI18nConfiguration
import it.neckar.open.i18n.I18nConfiguration
import it.neckar.open.kotlin.lang.WhitespaceConfig
import it.neckar.open.kotlin.lang.requireFinite
import it.neckar.open.unit.currency.Cent
import it.neckar.open.unit.currency.EUR
import it.neckar.open.unit.number.IsFinite
import it.neckar.open.unit.other.pct
import kotlinx.serialization.Serializable
import kotlin.jvm.JvmInline
import kotlin.math.roundToLong

/**
 * Represents a money value
 */
@Serializable(with = MoneySerializer::class)
@JvmInline
value class Money internal constructor(
  /**
   * Do not call this constructor directly.
   * Instead, use the factory methods.
   */
  val cents: Long,
) : Comparable<Money> {

  /**
   * Returns a double value that represents the double value.
   *
   * ATTENTION: Do *NOT* use this value for calculations to avoid rounding errors.
   */
  val euros: @EUR Double
    get() = cents / 100.0

  /**
   * Formats the money
   */
  fun format(i18nConfiguration: I18nConfiguration = CurrentI18nConfiguration, whitespaceConfig: WhitespaceConfig = WhitespaceConfig.NonBreaking): String {
    return euros.formatEuro(i18nConfiguration, whitespaceConfig)
  }

  /**
   * Returns the fallback value if the value is "0.0"
   */
  fun formatOrIfZero(fallbackIfZero: String, i18nConfiguration: I18nConfiguration = CurrentI18nConfiguration, whitespaceConfig: WhitespaceConfig = WhitespaceConfig.NonBreaking): String {
    if (euros == 0.0)
      return fallbackIfZero

    return format(i18nConfiguration, whitespaceConfig)
  }

  override operator fun compareTo(other: Money): Int {
    return this.cents.compareTo(other.cents)
  }

  operator fun minus(other: Money): Money {
    return cents(this.cents - other.cents)
  }

  operator fun plus(other: Money): Money {
    return cents(this.cents + other.cents)
  }

  override fun toString(): String {
    return format(I18nConfiguration.US, WhitespaceConfig.Spaces)
  }

  operator fun div(other: @EUR Money): @pct Double {
    return this.cents.toDouble() / other.cents.toDouble()
  }

  operator fun div(other: Double): @pct Money {
    other.requireFinite()
    return cents((this.cents / other).roundToLong())
  }

  operator fun times(factor: Double): Money {
    factor.requireFinite()
    return cents((this.cents * factor).roundToLong())
  }

  operator fun times(factor: Int): Money {
    return cents((cents * factor))
  }

  operator fun unaryMinus(): Money {
    return cents(-cents)
  }

  //TODO move this method(?)
  operator fun times(vat: ValueAddedTax): Money {
    return this * vat.vat
  }

  /**
   * Calculates the delta value - for debugging purposes
   */
  internal fun delta(other: Money): @Cent Long {
    return this.cents - other.cents
  }

  /**
   * Returns true if this value is zero
   */
  fun isZero(): Boolean {
    return cents == 0L
  }

  companion object {
    val Zero: Money = cents(0)

    fun cents(cents: Int): Money {
      return Money(cents = cents.toLong())
    }

    fun cents(cents: Long): Money {
      return Money(cents = cents)
    }

    /**
     * Creates a new instance - rounds the value to two digits
     */
    fun euros(euros: @IsFinite Double): Money {
      require(euros.isFinite()) { "Finite value required but was <$euros>" }

      val cents = (euros * 100.0).roundToLong()
      return Money(cents)
    }

    fun format(euros: Double, i18nConfiguration: I18nConfiguration = CurrentI18nConfiguration, whitespaceConfig: WhitespaceConfig = WhitespaceConfig.NonBreaking): String {
      return euros.formatEuro(i18nConfiguration, whitespaceConfig)
    }

    /**
     * Calculates the sum for the given elements
     */
    inline fun <T> fastSumBy(elements: List<T>, callback: (value: T) -> Money): Money {
      val cents = elements.fastSumByLong { callback(it).cents }
      return Money.cents(cents)
    }
  }

  //TODO add precision enum (2 digits, 3 digits, ...)
}

/**
 * Converts a double to a money object
 */
val Double.euro: Money
  get() {
    return Money.euros(this)
  }

val Int.euro: Money
  get() {
    return Money.euros(this.toDouble())
  }

/**
 * Calculates the sum
 */
fun Iterable<Money>.sum(): Money {
  val totalCents = sumOf { it.cents }
  return Money.cents(totalCents)
}
