package it.neckar.rest.annotations

/**
 * Annotated classes are part of the REST api.
 *
 * This is an optional annotation that must only be used for documentation purposes.
 */
@Retention(AnnotationRetention.SOURCE)
@MustBeDocumented
@Target(AnnotationTarget.CLASS)
annotation class RestAPI
