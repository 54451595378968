/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.elektromeister

import com.meistercharts.range.LinearValueRange
import it.neckar.elektromeister.rest.Room
import it.neckar.geometry.Coordinates
import it.neckar.geometry.Size
import it.neckar.open.http.Url
import it.neckar.open.unit.other.px
import it.neckar.open.unit.si.mm

/**
 * Represents a floor plan image
 */
data class FloorPlanImage(
  val url: Url,
  /**
   * The size of the image in pixels
   */
  val sizeInPixels: @px Size,

  /**
   * The factor to convert from pixels to millimeters
   */
  val pixels2mm: Double,
) {

  /**
   * Converts pixels to millimeters (for both axes)
   */
  fun pixel2mm(pixels: @px Double): @mm Double {
    return pixels * pixels2mm
  }

  /**
   * Converts millimeters to pixels (for both axes)
   */
  fun mm2pixel(mm: @mm Double): @px Double {
    return mm / pixels2mm
  }

  /**
   * The size of the image in millimeters
   */
  val sizeInMm: @mm Size = Size(
    width = pixel2mm(sizeInPixels.width),
    height = pixel2mm(sizeInPixels.height),
  )

  /**
   * The horizontal range in millimeters for the image
   */
  val rangeX: @mm LinearValueRange = LinearValueRange(
    start = 0.0,
    end = sizeInMm.width,
  )

  /**
   * The vertical range in millimeters for the image
   */
  val rangeY: @mm LinearValueRange = LinearValueRange(
    start = 0.0,
    end = sizeInMm.height,
  )
}
