package com.meistercharts.charts.sick.beams

import com.meistercharts.algorithms.layers.AbstractLayer
import com.meistercharts.algorithms.layers.LayerPaintingContext
import com.meistercharts.algorithms.layers.LayerType
import com.meistercharts.algorithms.layers.PaintingVariables
import com.meistercharts.algorithms.layers.linechart.Dashes
import com.meistercharts.algorithms.layers.linechart.LineStyle
import com.meistercharts.algorithms.layers.resolve
import com.meistercharts.algorithms.painter.Arrows
import com.meistercharts.annotations.ContentArea
import com.meistercharts.annotations.Window
import com.meistercharts.annotations.Zoomed
import com.meistercharts.canvas.ChartSupport
import com.meistercharts.canvas.ConfigurationDsl
import com.meistercharts.canvas.StrokeLocation
import com.meistercharts.canvas.events.CanvasMouseEventHandler
import com.meistercharts.canvas.fill
import com.meistercharts.canvas.layout.cache.DoubleMultiCache
import com.meistercharts.canvas.paintable.Paintable
import com.meistercharts.canvas.paintable.mirror
import com.meistercharts.canvas.saved
import com.meistercharts.color.Color
import com.meistercharts.color.ColorProvider
import com.meistercharts.events.EventConsumption
import com.meistercharts.font.FontDescriptorFragment
import com.meistercharts.resources.localResourceOrNull
import it.neckar.events.ModifierCombination
import it.neckar.events.MouseClickEvent
import it.neckar.geometry.Coordinates
import it.neckar.geometry.Direction
import it.neckar.geometry.Size
import it.neckar.geometry.VerticalAlignment
import it.neckar.open.collections.fastForEach
import it.neckar.open.http.Url
import it.neckar.open.i18n.TextKey
import kotlin.math.max
import kotlin.math.min

/**
 * Visualizes beams
 */
class BeamsLayer(
  val configuration: Configuration,
  additionalConfiguration: Configuration.() -> Unit = {},
) : AbstractLayer() {

  constructor(
    beamProvider: BeamProvider,
    additionalConfiguration: Configuration.() -> Unit = {},
  ): this(Configuration(beamProvider), additionalConfiguration)

  init {
    configuration.additionalConfiguration()
  }

  override val type: LayerType = LayerType.Content

  override fun paintingVariables(): PaintingVariables {
    return paintingVariables
  }

  /**
   * Holds the layout information
   */
  private val paintingVariables = object : PaintingVariables {
    /**
     * If set to true the connector is at the bottom
     */
    var connectorAtBottom: Boolean = false

    /**
     * The margin at the top above the devices
     */
    var deviceMarginTop: @Zoomed Double = 0.0

    var deviceWidth: @Zoomed Double = 0.0
    var deviceHeight: @Zoomed Double = 0.0

    val deviceCenterY: @Zoomed Double
      get() = deviceHeight / 2.0

    var beamLength: @Zoomed Double = 0.0

    /**
     * The distance between two beams (center to center)
     */
    var beamsDistanceZoomed: @Zoomed Double = 0.0

    /**
     * The y locations of the beams - relative to origin of the content area.
     *
     * ATTENTION: [deviceMarginTop] is *not* included
     */
    var beamYLocations: @Zoomed DoubleMultiCache = DoubleMultiCache()

    override fun calculate(paintingContext: LayerPaintingContext) {
      val gc = paintingContext.gc
      val chartCalculator = paintingContext.chartCalculator

      val beamProvider = configuration.beamProvider
      val beamsCount = beamProvider.count

      connectorAtBottom = configuration.connectorLocation == VerticalAlignment.Bottom
      deviceMarginTop = if (connectorAtBottom) {
        //Only the label is painted at top
        (configuration.deviceLabelFont.size?.size ?: 30.0) + configuration.deviceLabelGapVertical + 10.0
      } else {
        //Move down if the connector is painted at top
        configuration.connectorIcon?.boundingBox(paintingContext)?.getHeight() ?: 0.0
      }

      //Optimal device height from beams
      @ContentArea val totalBeamsHeight = (beamsCount + 1) * configuration.beamsDistance

      deviceWidth = configuration.deviceWidth
      deviceHeight = chartCalculator.contentArea2zoomedY(max(configuration.deviceMinHeight, totalBeamsHeight))

      //Calculate the length of the beams
      beamLength = chartCalculator.chartState.windowWidth - deviceWidth * 2 - configuration.deviceBeamGap * 2

      //The distance from beam to beam (zoomed!)
      beamsDistanceZoomed = chartCalculator.contentArea2zoomedY(configuration.beamsDistance)

      //Calculate the beam start/end locations
      beamYLocations.ensureSize(beamsCount)
      for (beamIndex in 0 until beamsCount) {
        val state = beamProvider.beamState(beamIndex)

        //The y position of the beam
        @Zoomed val beamY = beamsDistanceZoomed * (beamIndex + 1)
        beamYLocations[beamIndex] = beamY
      }
    }
  }

  override fun paint(paintingContext: LayerPaintingContext) {
    val gc = paintingContext.gc
    val chartCalculator = paintingContext.chartCalculator
    val chartState = chartCalculator.chartState

    val windowWidth = chartState.windowWidth

    //Translate to the origin of the canvas
    gc.translate(chartCalculator.contentArea2windowX(0.0), chartCalculator.contentArea2windowY(0.0))
    //Origin of canvas

    //Move to top of the device
    gc.translate(0.0, paintingVariables.deviceMarginTop)

    val beamProvider = configuration.beamProvider
    val beamsCount = beamProvider.count
    val crossBeamsConfig = beamProvider.crossBeamsConfig

    //Paint the device
    run {
      gc.fill(configuration.deviceFill)
      gc.stroke(configuration.deviceStroke)

      //Left side device
      gc.fillRect(0.0, 0.0, paintingVariables.deviceWidth, paintingVariables.deviceHeight)
      gc.strokeRect(0.0, 0.0, paintingVariables.deviceWidth, paintingVariables.deviceHeight, strokeLocation = StrokeLocation.Inside)

      //Right side device
      gc.fillRect(windowWidth - paintingVariables.deviceWidth, 0.0, paintingVariables.deviceWidth, paintingVariables.deviceHeight)
      gc.strokeRect(windowWidth - paintingVariables.deviceWidth, 0.0, paintingVariables.deviceWidth, paintingVariables.deviceHeight, strokeLocation = StrokeLocation.Inside)


      //The label under the device
      gc.font(configuration.deviceLabelFont)
      gc.fill(configuration.deviceLabelColor)


      if (paintingVariables.connectorAtBottom) {
        //Labels at top, connector at bottom
        configuration.leftDeviceLabel?.let {
          gc.fillText(it.resolve(paintingContext), 0.0, 0.0, Direction.BottomLeft, gapHorizontal = configuration.deviceLabelGapHorizontal, gapVertical = configuration.deviceLabelGapVertical)
        }
        configuration.rightDeviceLabel?.let {
          gc.fillText(it.resolve(paintingContext), windowWidth, 0.0, Direction.BottomRight, gapHorizontal = configuration.deviceLabelGapHorizontal, gapVertical = configuration.deviceLabelGapVertical)
        }
      } else {
        //Labels at bottom, connector at top
        configuration.leftDeviceLabel?.let {
          gc.fillText(it.resolve(paintingContext), 0.0, paintingVariables.deviceHeight, Direction.TopLeft, gapHorizontal = configuration.deviceLabelGapHorizontal, gapVertical = configuration.deviceLabelGapVertical)
        }
        configuration.rightDeviceLabel?.let {
          gc.fillText(it.resolve(paintingContext), windowWidth, paintingVariables.deviceHeight, Direction.TopRight, gapHorizontal = configuration.deviceLabelGapHorizontal, gapVertical = configuration.deviceLabelGapVertical)
        }
      }

      paintingContext.gc.saved {
        configuration.deviceIconLeft?.paintInBoundingBox(paintingContext, 0.0, paintingVariables.deviceCenterY, Direction.CenterLeft)
      }

      paintingContext.gc.saved {
        configuration.deviceIconRight?.paintInBoundingBox(paintingContext, windowWidth, paintingVariables.deviceCenterY, Direction.CenterRight)
      }


      //The y for the connectors
      val connectorY = when (configuration.connectorLocation) {
        VerticalAlignment.Bottom -> paintingVariables.deviceHeight
        VerticalAlignment.Top -> 0.0
        else -> throw UnsupportedOperationException("Unsupported for <${configuration.connectorLocation}")
      }

      //Right side connector
      gc.saved {
        configuration.connectorIcon
          ?.mirror(false, !paintingVariables.connectorAtBottom)
          ?.paint(paintingContext, windowWidth - paintingVariables.deviceWidth / 2.0, connectorY)
      }

      //Left side connector
      gc.saved {
        configuration.connectorIcon
          ?.mirror(true, !paintingVariables.connectorAtBottom)
          ?.paint(paintingContext, paintingVariables.deviceWidth / 2.0, connectorY)
      }
    }

    //translate to the begin of the beam area
    gc.translate(paintingVariables.deviceWidth, 0.0)
    gc.translate(configuration.deviceBeamGap, 0.0)

    //Paint the beams
    for (beamIndex in 0 until beamsCount) {
      //The model beam index - the beams are arranged from bottom to top
      val modelBeamIndex = beamsCount - beamIndex - 1

      val state = beamProvider.beamState(modelBeamIndex)

      //The y position of the beam
      @Zoomed val beamY = paintingVariables.beamYLocations[beamIndex]

      gc.saved {
        state.toLineStyle().also {
          it.apply(gc)
          gc.fill(it.color) //also apply the fill - for the arrow heads
        }

        gc.lineWidth = configuration.beamLineWidth

        //Stroke the (horizontal) beam itself
        gc.strokeLine(configuration.beamStartAtArrowOffset, beamY, paintingVariables.beamLength, beamY)

        //the cross beams
        for (crossBeamIndex in 0 until crossBeamsConfig.connectedNeighborsCountOnEachSide) {
          //the left position is fixed, only the right (sender) side is updated
          val crossBeamOffset = crossBeamIndex + 1

          //The "virtual" index the beam is connected to on the right side
          val calculatedTopIndex = beamIndex - crossBeamOffset
          val calculatedBottomIndex = beamIndex + crossBeamOffset

          if (calculatedTopIndex >= 0) {
            gc.strokeLine(configuration.beamStartAtArrowOffset, beamY, paintingVariables.beamLength, paintingVariables.beamYLocations[calculatedTopIndex])
          }

          if (calculatedBottomIndex < beamsCount) {
            gc.strokeLine(configuration.beamStartAtArrowOffset, beamY, paintingVariables.beamLength, paintingVariables.beamYLocations[calculatedBottomIndex])
          }
        }

        //Paint the arrow
        gc.saved {
          gc.translate(0.0, beamY)

          val minArrowHeadWidth = min(paintingVariables.beamsDistanceZoomed - 2, configuration.beamArrowHeadWidth)
          gc.lineWidth = 1.0
          val arrow = Arrows.to(Direction.CenterLeft, 0.0, arrowHeadHeight = configuration.beamArrowHeadHeight, arrowHeadWidth = minArrowHeadWidth)
          gc.fill(arrow)
          gc.clearLineDash()
          gc.stroke(arrow)
        }

        //paint the label
        beamProvider.label(modelBeamIndex)?.let { label ->
          gc.font(configuration.beamLabelFont)
          gc.fill(configuration.beamLabelColor)
          gc.fillText(label, 0.0, beamY, Direction.BottomLeft, configuration.beamLabelBottomGap, maxHeight = paintingVariables.beamsDistanceZoomed - configuration.beamLabelBottomGap)
        }
      }
    }
  }

  /**
   * Returns the line style for the state
   */
  private fun BeamState.toLineStyle(): LineStyle {
    return when (this) {
      BeamState.Made -> configuration.beamMadeLineStyle
      BeamState.MadeWithAlarm -> configuration.beamMadeWithAlarmLineStyle
      BeamState.Blocked -> configuration.beamBlockedLineStyle
      BeamState.BlockedWithAlarm -> configuration.beamBlockedWithAlarmLineStyle
      BeamState.Blanked -> configuration.beamBlankedLineStyle
    }
  }

  override val mouseEventHandler: CanvasMouseEventHandler = object : CanvasMouseEventHandler {
    override fun onClick(event: MouseClickEvent, chartSupport: ChartSupport): EventConsumption {
      super.onClick(event, chartSupport)

      @Window val clickY = event.coordinates.y
      @Zoomed val delta = min(5.0, paintingVariables.beamsDistanceZoomed / 2.0)

      @Window val sensitiveRange = clickY - delta..clickY + delta

      @Window val baseY = chartSupport.chartCalculator.contentAreaRelative2windowY(0.0)

      var clickedBeamIndex = -1
      paintingVariables.beamYLocations.fastForEachIndexed { index, beamLocationY ->
        if (sensitiveRange.contains(baseY + paintingVariables.deviceMarginTop + beamLocationY)) {
          clickedBeamIndex = index
        }
      }

      if (clickedBeamIndex < 0) {
        return EventConsumption.Ignored
      }

      val beamsCount = configuration.beamProvider.count
      val modelBeamIndex = beamsCount - clickedBeamIndex - 1

      beamClickHandlers.fastForEach {
        it.beamClicked(modelBeamIndex, event.modifierCombination)
      }

      return EventConsumption.Consumed
    }
  }

  /**
   * The beam click handlers that have been registered
   */
  private val beamClickHandlers: MutableList<BeamClickHandler> = mutableListOf()

  /**
   * Registers a beam click handler
   */
  fun onBeamClicked(handler: BeamClickHandler) {
    beamClickHandlers.add(handler)
  }

  /**
   * Returns the location of a beam
   */
  fun getBeamLocation(beamIndex: Int): @Zoomed Double {
    return paintingVariables.deviceMarginTop +
      paintingVariables.beamYLocations.getOrElse(beamIndex) {
        paintingVariables.beamYLocations.lastOr(0.0)
      }
  }

  @ConfigurationDsl
  class Configuration(
    val beamProvider: BeamProvider,
  ) {
    var leftDeviceLabel: TextKey? = TextKey.simple("Receiver")
    var rightDeviceLabel: TextKey? = TextKey.simple("Transmitter")

    val beamMadeLineStyle: LineStyle = LineStyle(Color.green)
    val beamMadeWithAlarmLineStyle: LineStyle = LineStyle(Color.orange)
    val beamBlockedLineStyle: LineStyle = LineStyle(Color.red, dashes = Dashes.LargeDashes)
    val beamBlockedWithAlarmLineStyle: LineStyle = LineStyle(Color.darkorange, dashes = Dashes.LargeDashes)
    val beamBlankedLineStyle: LineStyle = LineStyle(Color.silver)

    /**
     * The location of the connector
     * Currently only bottom and top are supported
     */
    var connectorLocation: VerticalAlignment = VerticalAlignment.Bottom
      set(value) {
        require(value == VerticalAlignment.Bottom || value == VerticalAlignment.Top) {
          "Unsupported connector location <$value>"
        }

        field = value
      }

    /**
     * The gap between beam start and the arrow pointer
     */
    var beamStartAtArrowOffset: @Zoomed Double = 4.0

    var beamArrowHeadHeight: @Zoomed Double = 12.0
    var beamArrowHeadWidth: @Zoomed Double = 10.0

    /**
     * The font for the beam label
     */
    var beamLabelFont: FontDescriptorFragment = FontDescriptorFragment.XS

    var beamLabelColor: ColorProvider = Color.black

    /**
     * The font for the device label
     */
    var deviceLabelFont: FontDescriptorFragment = FontDescriptorFragment.DefaultSize

    /**
     * The font color for the device label
     */
    var deviceLabelColor: ColorProvider = Color.black

    var deviceLabelGapHorizontal: @Zoomed Double = 5.0
    var deviceLabelGapVertical: @Zoomed Double = 5.0

    /**
     * The distance between the beam label and the beam center
     */
    var beamLabelBottomGap: @Zoomed Double = 6.0

    /**
     * The beam line width
     */
    var beamLineWidth: @Zoomed Double = 1.0

    /**
     * The fill for the device
     */
    var deviceFill: Color = Color.web("#0070C0")

    var deviceStroke: Color = Color.web("#0070C0")

    /**
     * The min width of the device
     */
    var deviceWidth: @Zoomed Double = 60.0

    /**
     * The minimum height of the device (Attention: @ContentArea)
     */
    var deviceMinHeight: @ContentArea Double = 80.0

    /**
     * The gap between the devices and the beams
     */
    var deviceBeamGap: @Zoomed Double = 5.0

    /**
     * The distance from beam to beams (center to center)
     * (Attention: @ContentArea)
     */
    var beamsDistance: @ContentArea Double = 30.0

    /**
     * The paintable id for the icon for the receiver device (on the left side)
     */
    var deviceIconLeft: Paintable? = Paintable.localResourceOrNull(Url.relative("ReceiverIcon.png"), Size.PX_60)

    /**
     * The paintable id for the icon for the sender device (on the right side)
     */
    var deviceIconRight: Paintable? = Paintable.localResourceOrNull(Url.relative("SenderIcon.png"), Size.PX_60)

    /**
     * The paintable that represents the cable (opposite the head)
     */
    var connectorIcon: Paintable? = Paintable.localResourceOrNull(Url.relative("cable.png"), Size(434 / 4, 160 / 4), Coordinates(-5.0, 0.0))
  }
}

enum class CrossBeamsConfig(
  /**
   * The number of connected neighbors on every side
   */
  val connectedNeighborsCountOnEachSide: Int,
) {
  None(0),

  /**
   * Includes the neighbors
   */
  FirstDegree(1),
  SecondDegree(2),
  ThirdDegree(3),
  FourthDegree(4),
  FifthDegree(5),
  SixthDegree(6),
  SeventhDegree(7)
}

/**
 * The state of the beam
 */
enum class BeamState {
  /**
   * The beam is not interrupted
   */
  Made,

  /**
   * The beam is not interrupted, but there is an alarm at that beam
   */
  MadeWithAlarm,

  /**
   * The beam is blocked by something
   */
  Blocked,

  /**
   * The beam is blocked by something and there is an alarm at that beam
   */
  BlockedWithAlarm,

  /**
   * The beam has been blanked and is not evaluated
   */
  Blanked
}

/**
 * Handler that is notified when the user clicks on a beam
 */
fun interface BeamClickHandler {
  /**
   * Is called when a mouse click has been detected on a beam
   */
  fun beamClicked(modelBeamIndex: Int, modifierCombination: ModifierCombination)
}
