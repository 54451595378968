/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.demojs

import kotlinx.browser.document
import kotlinx.html.dom.append
import kotlinx.html.h4
import kotlinx.html.id
import kotlinx.html.js.div
import kotlinx.html.js.hr
import kotlinx.html.js.p
import kotlinx.html.style
import org.w3c.dom.Element

object DemoRunnerHTMLSupport {
  init {
    document.body!!.append {
      div {
        id = "exceptionBanner"
        style = "position: fixed; top:0; left: 0; background-color: red; color: white; width: 100%;"

      }
    }

  }

  val banner: Element = document.getElementById("exceptionBanner") ?: throw IllegalStateException("Could not find Element with id: exceptionBanner")

  fun displayNullError(message: dynamic) {
    banner.append {
      div {
        style = "margin-left: 10px; margin-right: 10px;"
        h4 { +"NULL Error!" }
        p { +"Message: $message" }

      }
      hr { }
    }
  }

  fun displayError(throwable: Throwable) {
    banner.append {
      div {
        style = "margin-left: 10px; margin-right: 10px;"
        h4 { +"JS Error!" }
        p { +"Throwable: $throwable" }
        p { +"Message: ${throwable.message}" }
        p { +"Cause: ${throwable.cause}" }
      }
      hr { }
    }
  }

  fun displayOtherError(message: dynamic, error: Any) {
    banner.append {
      div {
        style = "margin-left: 10px; margin-right: 10px;"
        h4 { +"JS OTHER Error!" }
        p { +"Error: $error" }
        p { +"Message: $message" }
      }
      hr { }
    }
  }


}
