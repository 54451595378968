/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.elektromeister.state

import com.meistercharts.annotations.Window
import com.meistercharts.elektromeister.ElektromeisterMeisterchartsModel
import com.meistercharts.elektromeister.model.MutablePlacedElectricalComponent
import it.neckar.elektromeister.rest.components.ElectricalComponent
import it.neckar.elektromeister.rest.components.ElectricalComponentType
import it.neckar.geometry.Coordinates
import it.neckar.logging.Logger
import it.neckar.logging.LoggerFactory
import it.neckar.open.kotlin.lang.requireNotNull
import it.neckar.open.observable.ObservableObject
import it.neckar.open.observable.ReadOnlyObservableBoolean
import it.neckar.open.observable.ReadOnlyObservableObject

/**
 * Represents the current state of the Elektromeister UI
 */
class ElektromeisterUiStateMachine(
  val model: ElektromeisterMeisterchartsModel,
) {

  /**
   * The active ui state
   */
  private val _currentUiStateProperty: ObservableObject<ElektroMeisterUiState> = ObservableObject(DefaultElektroMeisterUiState)
  val currentUiStateProperty: ReadOnlyObservableObject<ElektroMeisterUiState> = _currentUiStateProperty

  /**
   * Contains the current UI state
   */
  val currentUiState: ElektroMeisterUiState by currentUiStateProperty

  /**
   * Transitions to the new state.
   * This method also initializes the new state
   */
  private fun transitionTo(newState: ElektroMeisterUiState, mouseLocation: @Window Coordinates?, context: GestureContext) {
    logger.debug("Transition to new state: $newState (from current state: $currentUiState)")

    _currentUiStateProperty.value = newState.also {
      it.initialMouseLocation(mouseLocation, context)
    }
  }

  /**
   * Switches back to the default mode
   */
  fun transitionToDefault(mouseLocation: @Window Coordinates?, context: GestureContext) {
    val newState = DefaultElektroMeisterUiState
    transitionTo(newState, mouseLocation, context)
  }

  /**
   * Toggles the creation type (called by the toolbar).
   */
  fun transitionToCreationMode(type: ElectricalComponentType, coordinates: @Window Coordinates?, context: GestureContext) {
    transitionTo(ElementCreationMode(type), coordinates, context)
  }

  fun transitionToConnectionLineMode(coordinates: @Window Coordinates?, context: GestureContext) {
    transitionTo(ConnectionLineCreationMode(start = null, potentialEnd = null, temporaryEndCoordinates = null), coordinates, context)
  }

  fun transitionToSelectedComponent(selected: MutablePlacedElectricalComponent, mouseCoordinates: @Window Coordinates?, context: GestureContext) {
    transitionTo(ElectricalComponentSelected(selected), mouseCoordinates, context)
  }

  fun transitionToMoving(componentToMove: MutablePlacedElectricalComponent, mouseCoordinates: @Window Coordinates, context: GestureContext) {
    transitionTo(
      ElectricalComponentMoving(
        movedComponent = componentToMove,
        initialMouseCoordinates = mouseCoordinates,
        initialLocation = componentToMove.location,
      ), mouseCoordinates, context
    )
  }

  /**
   * Holds a property for each [ElectricalComponent.ElectricalComponentType] which is set to true if the creation mode for the given type is enabled.
   */
  private val creationModeEnabledPropertiesMap = ElectricalComponentType.entries.associateWith { type ->
    currentUiStateProperty.mapBoolean {
      it.isCreate(type)
    }
  }

  /**
   * Returns the property for the given type which is set to true if the creation mode for the given type is enabled.
   */
  fun creationModeEnabledProperty(type: ElectricalComponentType): ReadOnlyObservableBoolean {
    return creationModeEnabledPropertiesMap[type].requireNotNull()
  }

  val connectionLineModeEnabledProperty: ReadOnlyObservableBoolean = currentUiStateProperty.mapBoolean {
    it.isConnectionLine()
  }

  val defaultModeEnabledProperty: ReadOnlyObservableObject<Boolean> = currentUiStateProperty.map {
    it.isDefault()
  }

  val selectionModeEnabledProperty: ReadOnlyObservableObject<Boolean> = currentUiStateProperty.map {
    it.isElectricalComponentSelected()
  }

  val moveModeEnabledProperty: ReadOnlyObservableObject<Boolean> = currentUiStateProperty.map {
    it.isMoveMode()
  }

  /**
   * Returns the [ElektroMeisterUiState.ElementCreationMode] if the current state is a creation mode
   */
  fun elementCreationModeOrNull(): ElementCreationMode? {
    currentUiState.let {
      if (it is ElementCreationMode) {
        return it
      }
    }

    return null
  }

  /**
   * Returns the currently selected component - if there is one
   */
  val selectedComponent: MutablePlacedElectricalComponent?
    get() {
      return when (val state = currentUiState) {
        is ElectricalComponentSelected -> state.selected
        else -> null
      }
    }

  /**
   * Returns the currently moved component - if there is one
   */
  val movedComponent: MutablePlacedElectricalComponent?
    get() {
      return when (val state = currentUiState) {
        is ElectricalComponentMoving -> state.movedComponent
        else -> null
      }
    }

  /**
   * Returns the component that is currently hovered over - if there is one
   */
  val mouseOverComponent: MutablePlacedElectricalComponent?
    get() {
      return when (val state = currentUiState) {
        is AbstractUiStateWithMouseOver -> state.mouseOverComponent
        else -> null
      }
    }

  /**
   * Returns the connection line creation mode - if this is the current state
   */
  val connectionLineCreationMode: ConnectionLineCreationMode?
    get() {
      return currentUiState as? ConnectionLineCreationMode
    }

  companion object {
    private val logger: Logger = LoggerFactory.getLogger("com.meistercharts.elektromeister.state.ElektromeisterUiStateMachine")
  }
}


