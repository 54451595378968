package com.meistercharts.charts.sick.beams

import it.neckar.open.collections.getModulo
import it.neckar.open.kotlin.lang.getModulo

/**
 * Example beam provider
 */
class ExampleBeamProvider(
  override var count: Int = 7
) : BeamProvider {
  override var crossBeamsConfig: CrossBeamsConfig = CrossBeamsConfig.None

  override fun beamState(index: Int): BeamState {
    return BeamState.entries.getModulo(index)
  }

  override fun label(index: Int): String? {
    return "Beam $index"
  }
}
