/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.elektromeister.state

import com.meistercharts.annotations.Window
import com.meistercharts.canvas.MouseCursor
import com.meistercharts.events.EventConsumption
import it.neckar.events.MouseMoveEvent
import it.neckar.geometry.Coordinates
import it.neckar.open.observable.ObservableObject

/**
 * Abstract base class for ui states
 */
abstract class AbstractUiState : ElektroMeisterUiState {
  /**
   * Contains the cursor for this state.
   * The cursor is automatically applied to the canvas
   */
  final override val cursorProperty: ObservableObject<MouseCursor?> = ObservableObject(MouseCursor.Default)

  /**
   * The cursor for this state
   */
  var cursor: MouseCursor? by cursorProperty

  /**
   * Default implementation that transitions to the default state
   */
  override fun resetByKey(context: GestureContext): EventConsumption {
    context.model.stateMachine.transitionToDefault(mouseLocation = context.chartSupport.mouseEvents.mousePosition, context = context)
    return EventConsumption.Consumed
  }

  override fun initialMouseLocation(mouseCoordinates: @Window Coordinates?, context: GestureContext) {
    updateStateForMouseLocation(mouseCoordinates, context)
  }

  override fun mouseMoved(event: MouseMoveEvent, context: GestureContext): EventConsumption {
    updateStateForMouseLocation(event.coordinates, context)
    return EventConsumption.Ignored
  }

  /**
   * This method is called when:
   * - the state is entered
   * - the mouse is moved
   *
   * It should update the state based on the current mouse location.
   * Update mouse over effects and the mouse cursor.
   */
  protected abstract fun updateStateForMouseLocation(mouseLocation: Coordinates?, context: GestureContext)
}
