/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.demo.descriptors


import com.benasher44.uuid.Uuid
import com.benasher44.uuid.uuidFrom
import com.meistercharts.algorithms.layers.AbstractLayer
import com.meistercharts.algorithms.layers.LayerPaintingContext
import com.meistercharts.algorithms.layers.LayerType
import com.meistercharts.algorithms.layers.addClearBackground
import com.meistercharts.canvas.CanvasRenderingContext
import com.meistercharts.canvas.ChartSupport
import com.meistercharts.canvas.events.CanvasKeyEventHandler
import com.meistercharts.canvas.saved
import com.meistercharts.demo.MeisterchartsDemo
import com.meistercharts.demo.MeisterchartsDemoDescriptor
import com.meistercharts.demo.DemoCategory
import com.meistercharts.demo.PredefinedConfiguration
import it.neckar.geometry.Direction
import com.meistercharts.events.EventConsumption
import it.neckar.events.KeyDownEvent
import it.neckar.events.KeyEvent
import it.neckar.events.KeyTypeEvent
import it.neckar.events.KeyUpEvent

/**
 *
 */
class KeyEventsDemoDescriptor : MeisterchartsDemoDescriptor<Nothing> {
  override val uuid: Uuid = uuidFrom("7e2a7154-f3c5-42b4-bff6-58b62b47132a")
  override val name: String = "Key events"
  override val category: DemoCategory = DemoCategory.Interaction

  //language=HTML
  override val description: String = """
    Visualizes Key Events

    <h3>Expected Results for Ctrl-Alt-Shift-A</h3>
    Ctrl + Alt + Shift + "A" (65)

    <p>(Tested on all platforms)</p>

  """.trimIndent()

  override fun prepareDemo(configuration: PredefinedConfiguration<Nothing>?): MeisterchartsDemo {
    return MeisterchartsDemo {
      meistercharts {
        configure {
          layers.addClearBackground()

          layers.addLayer(object : AbstractLayer() {
            override val type: LayerType = LayerType.Content

            var lastDownEvent: KeyDownEvent? = null
            var lastUpEvent: KeyUpEvent? = null
            var lastTypedEvent: KeyTypeEvent? = null

            override val keyEventHandler: CanvasKeyEventHandler = object : CanvasKeyEventHandler {
              override fun onDown(event: KeyDownEvent, chartSupport: ChartSupport): EventConsumption {
                lastDownEvent = event
                markAsDirty()
                return EventConsumption.Ignored
              }

              override fun onUp(event: KeyUpEvent, chartSupport: ChartSupport): EventConsumption {
                lastUpEvent = event
                markAsDirty()
                return EventConsumption.Ignored
              }

              override fun onType(event: KeyTypeEvent, chartSupport: ChartSupport): EventConsumption {
                lastTypedEvent = event
                markAsDirty()
                return EventConsumption.Ignored
              }
            }

            override fun paint(paintingContext: LayerPaintingContext) {
              val gc = paintingContext.gc
              gc.translate(10.0, 10.0)

              gc.saved {
                gc.printHeader()
              }

              gc.translate(00.0, 30.0)

              gc.fillText("Down", 0.0, 0.0, Direction.TopLeft)
              lastDownEvent?.let { event ->
                gc.saved {
                  gc.printDebug(event)
                }
              }

              gc.translate(0.0, 20.0)
              gc.fillText("Up", 0.0, 0.0, Direction.TopLeft)
              lastUpEvent?.let { event ->
                gc.saved {
                  gc.printDebug(event)
                }
              }

              gc.translate(0.0, 20.0)
              gc.fillText("Typed", 0.0, 0.0, Direction.TopLeft)
              lastTypedEvent?.let { event ->
                gc.printDebug(event)
              }
            }
          })
        }

      }
    }
  }
}

private fun CanvasRenderingContext.printHeader() {
  translate(100.0, 0.0)
  fillText("Type", 0.0, 0.0, Direction.TopLeft)

  translate(70.0, 0.0)
  fillText("Relative Time", 0.0, 0.0, Direction.TopLeft)

  translate(200.0, 0.0)
  fillText("Code", 0.0, 0.0, Direction.TopLeft)

  translate(70.0, 0.0)
  fillText("as Char", 0.0, 0.0, Direction.TopLeft)

  translate(70.0, 0.0)
  fillText("Alt", 0.0, 0.0, Direction.TopLeft)

  translate(50.0, 0.0)
  fillText("Ctrl", 0.0, 0.0, Direction.TopLeft)

  translate(50.0, 0.0)
  fillText("Meta", 0.0, 0.0, Direction.TopLeft)

  translate(50.0, 0.0)
  fillText("Shift", 0.0, 0.0, Direction.TopLeft)
}

private fun CanvasRenderingContext.printDebug(keyEvent: KeyEvent) {
  translate(100.0, 0.0)
  fillText(keyEvent.text, 0.0, 0.0, Direction.TopLeft)

  translate(70.0, 0.0)
  fillText(keyEvent.timestamp.toString(), 0.0, 0.0, Direction.TopLeft)

  translate(200.0, 0.0)
  val keyStroke = keyEvent.keyStroke
  fillText(keyStroke.keyCode.code.toString(), 0.0, 0.0, Direction.TopLeft)

  translate(70.0, 0.0)
  fillText(keyStroke.keyCode.code.toChar().toString(), 0.0, 0.0, Direction.TopLeft)

  translate(70.0, 0.0)
  fillText(keyStroke.modifierCombination.alt.toCharRepresentation(), 0.0, 0.0, Direction.TopLeft)

  translate(50.0, 0.0)
  fillText(keyStroke.modifierCombination.control.toCharRepresentation(), 0.0, 0.0, Direction.TopLeft)

  translate(50.0, 0.0)
  fillText(keyStroke.modifierCombination.meta.toCharRepresentation(), 0.0, 0.0, Direction.TopLeft)

  translate(50.0, 0.0)
  fillText(keyStroke.modifierCombination.shift.toCharRepresentation(), 0.0, 0.0, Direction.TopLeft)
}

private fun Boolean.toCharRepresentation(): String {
  return if (this) "+" else "-"
}
