package com.meistercharts.charts.lizergy.roofPlanning

import com.meistercharts.algorithms.layers.LayerPaintingContext
import com.meistercharts.algorithms.painter.FillAndStrokeStyle
import com.meistercharts.annotations.Zoomed
import com.meistercharts.calc.ChartCalculator
import com.meistercharts.canvas.CanvasRenderingContext
import com.meistercharts.canvas.fill
import com.meistercharts.canvas.paintable.Paintable
import com.meistercharts.canvas.saved
import com.meistercharts.color.Color
import com.meistercharts.color.RgbaColor
import com.meistercharts.resources.Icons
import it.neckar.geometry.Direction
import it.neckar.geometry.RightTriangleType.MissingCornerInFirstQuadrant
import it.neckar.geometry.RightTriangleType.MissingCornerInFourthQuadrant
import it.neckar.geometry.RightTriangleType.MissingCornerInSecondQuadrant
import it.neckar.geometry.RightTriangleType.MissingCornerInThirdQuadrant
import it.neckar.geometry.Size
import it.neckar.open.formatting.format
import it.neckar.open.kotlin.lang.abs

/**
 * Paints an unusable area
 */
class UnusableAreaPainter {
  val style: Style = Style()

  /**
   * If set to true, the location and size are painted
   */
  var showValuesAsText: Boolean = false


  /**
   * Paints the unusable area
   */
  fun paint(
    paintingContext: LayerPaintingContext,
    x: @Zoomed Double,
    y: @Zoomed Double,
    width: @Zoomed Double,
    height: @Zoomed Double,
    unusableArea: UnusableArea,
    mode: Mode,
  ) {
    val chartCalculator = paintingContext.chartCalculator

    val gc = paintingContext.gc
    gc.lineWidth = 0.5
    mode.fillAndStroke.apply(gc)

    if (unusableArea.rightTriangleType != null) {
      // The triangle itself
      gc.fillTriangleRightAngled(x, y, width, height, unusableArea.rightTriangleType ?: throw IllegalStateException("The shape needs to be defined as a triangle but was not !"))

      // Border
      gc.strokeTriangleRightAngled(x, y, width, height, unusableArea.rightTriangleType ?: throw IllegalStateException("The shape needs to be defined as a triangle but was not !"))
    } else {
      // The rect itself
      gc.fillRect(x, y, width, height)

      // Border
      gc.strokeRect(x, y, width, height)
    }

    // Cross
    if (unusableArea.rightTriangleType == null) {
      gc.strokeLine(x, y + height, x + width, y)
      gc.strokeLine(x, y, x + width, y + height)
    }

    // Paint the values
    if (mode.showLocationAndSize) {
      gc.fill(Color.white)

      drawMeasures(gc, chartCalculator, x, y, width, height, unusableArea.location.x, unusableArea.location.y, unusableArea.size.width, unusableArea.size.height, style.unusableAreaSizeInfoGap)

      // Paint the size (top center)
      gc.fillText(
        text = unusableArea.size.let {
          "${(it.width / 1000.0).format(2)}m x ${(it.height / 1000).format(2)}m"
        },
        x = when (unusableArea.rightTriangleType) {
          MissingCornerInFirstQuadrant, MissingCornerInSecondQuadrant, null -> x
          MissingCornerInThirdQuadrant, MissingCornerInFourthQuadrant -> x + width
        },
        y = when (unusableArea.rightTriangleType) {
          MissingCornerInFirstQuadrant, MissingCornerInFourthQuadrant, null -> y
          MissingCornerInSecondQuadrant, MissingCornerInThirdQuadrant -> y + height
        },
        anchorDirection = when (unusableArea.rightTriangleType) {
          MissingCornerInFirstQuadrant, null -> Direction.BottomLeft
          MissingCornerInSecondQuadrant -> Direction.TopLeft
          MissingCornerInThirdQuadrant -> Direction.TopRight
          MissingCornerInFourthQuadrant -> Direction.BottomRight
        },
        gapHorizontal = style.unusableAreaSizeInfoGap,
        gapVertical = style.unusableAreaSizeInfoGap,
        maxWidth = width.abs() - style.unusableAreaSizeInfoGap * 2,
      )
    }


    //Paint the delete icons
    if (mode.showDeleteIcon) {
      gc.saved {
        style.deleteIcon.paint(paintingContext, x + width / 2.0, y + height / 2.0)
      }
    }
    if (mode.showArmedDeleteIcon) {
      gc.saved {
        style.deleteIconArmed.paint(paintingContext, x + width / 2.0, y + height / 2.0)
      }
    }
  }


  class Style {
    /**
     * The gap within the area size info
     */
    var unusableAreaSizeInfoGap: @Zoomed Double = 3.0

    /**
     * The delete icon that is painted on hover
     */
    val deleteIcon: Paintable = Icons.delete(Size.PX_24)

    /**
     * The delete icon while hovering above is
     */
    val deleteIconArmed: Paintable = Icons.delete(Size.PX_24, Color.red)
  }

  /**
   * Describes the mode
   */
  enum class Mode(
    /**
     * If set to true the location and size are shown
     */
    val showLocationAndSize: Boolean,

    /**
     * Returns true if the deleted icon is shown.
     * Attention this is different than [showArmedDeleteIcon]
     */
    val showDeleteIcon: Boolean,

    val showArmedDeleteIcon: Boolean,

    /**
     * The fill and stroke that is used for that mode
     */
    val fillAndStroke: FillAndStrokeStyle,
  ) {

    /**
     * Default mode - nothing special
     */
    Default(
      showLocationAndSize = false,
      showDeleteIcon = false,
      showArmedDeleteIcon = false,
      fillAndStroke = FillAndStrokeStyle(Color.white().withAlpha(0.5), RgbaColor(86, 86, 86))
    ),

    /**
     * Unselected, but the mouse is hovering
     */
    Hover(
      true,
      showDeleteIcon = true,
      showArmedDeleteIcon = false,
      fillAndStroke = FillAndStrokeStyle(Color.web("#75b72633"), Color.web("#75b726"))
    ),

    /**
     * Selected - but the mouse is *not* hovering
     */
    Selected(
      showLocationAndSize = true,
      showDeleteIcon = false,
      showArmedDeleteIcon = false,
      fillAndStroke = FillAndStrokeStyle(Color.web("#75b7264D"), Color.web("#75b726"))
    ),

    /**
     * Selected - and hovering
     */
    SelectedHovering(
      showLocationAndSize = true,
      showDeleteIcon = true,
      showArmedDeleteIcon = false,
      fillAndStroke = FillAndStrokeStyle(Color.web("#75b7267D"), Color.web("#75b726"))
    ),

    /**
     * Is currently dragged
     */
    Dragging(
      showLocationAndSize = true,
      showDeleteIcon = false,
      showArmedDeleteIcon = false,
      fillAndStroke = FillAndStrokeStyle(Color.white().withAlpha(0.2), Color.white())
    ),

    /**
     * delete is armed (selected or unselected)
     */
    DeleteArmed(
      showLocationAndSize = false,
      showDeleteIcon = false,
      showArmedDeleteIcon = true,
      fillAndStroke = FillAndStrokeStyle(Color.red().withAlpha(0.3), Color.red())
    );
  }
}


fun drawMeasures(
  gc: CanvasRenderingContext,
  chartCalculator: ChartCalculator,
  x: @Zoomed Double,
  y: @Zoomed Double,
  width: @Zoomed Double,
  height: @Zoomed Double,
  locationX: Double,
  locationY: Double,
  sizeWidth: Double,
  sizeHeight: Double,
  sizeInfoGap: @Zoomed Double,
) {
  val roofStartX = chartCalculator.domainRelative2windowX(0.0)
  val roofStartY = chartCalculator.domainRelative2windowY(0.0)

  strokeHorizontalBoundedLine(gc, roofStartX, y - 10, x, y - 10)
  gc.fillText(
    text = "${(locationX / 1000.0).format(2)}m",
    x = x,
    y = y - 10,
    anchorDirection = Direction.BottomRight,
    gapHorizontal = sizeInfoGap,
    gapVertical = sizeInfoGap,
  )

  strokeVerticalBoundedLine(gc, x + 10, roofStartY, x + 10, y)
  gc.fillText(
    text = "${(locationY / 1000.0).format(2)}m",
    x = x + 10,
    y = y,
    anchorDirection = Direction.TopRight,
    gapHorizontal = sizeInfoGap,
    gapVertical = sizeInfoGap,
  )

  strokeHorizontalBoundedLine(gc, roofStartX, y + height - 10, x + width, y + height - 10)
  gc.fillText(
    text = "${((locationX + sizeWidth) / 1000.0).format(2)}m",
    x = x + width,
    y = y + height - 10,
    anchorDirection = Direction.BottomRight,
    gapHorizontal = sizeInfoGap,
    gapVertical = sizeInfoGap,
  )

  strokeVerticalBoundedLine(gc, x + width + 10, roofStartY, x + width + 10, y + height)
  gc.fillText(
    text = "${((locationY + sizeHeight) / 1000.0).format(2)}m",
    x = x + width + 10,
    y = y + height,
    anchorDirection = Direction.TopLeft,
    gapHorizontal = sizeInfoGap,
    gapVertical = sizeInfoGap,
  )
}

fun strokeHorizontalBoundedLine(gc: CanvasRenderingContext, startX: Double, startY: Double, endX: Double, endY: Double, boundingLength: Double = 20.0) {
  gc.strokeLine(startX, startY, endX, endY)
  gc.strokeLine(startX, startY - boundingLength / 2.0, startX, startY + boundingLength / 2.0)
  gc.strokeLine(endX, endY - boundingLength / 2.0, endX, endY + boundingLength / 2.0)
}

fun strokeVerticalBoundedLine(gc: CanvasRenderingContext, startX: Double, startY: Double, endX: Double, endY: Double, boundingLength: Double = 20.0) {
  gc.strokeLine(startX, startY, endX, endY)
  gc.strokeLine(startX - boundingLength / 2.0, startY, startX + boundingLength / 2.0, startY)
  gc.strokeLine(endX - boundingLength / 2.0, endY, endX + boundingLength / 2.0, endY)
}
