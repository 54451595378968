/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.demo.descriptors


import com.benasher44.uuid.Uuid
import com.benasher44.uuid.uuidFrom
import com.meistercharts.charts.lizergy.solar.PvElectricityPriceGestalt
import com.meistercharts.demo.DemoCategory
import com.meistercharts.demo.DemoQuality
import com.meistercharts.demo.MeisterchartsDemo
import com.meistercharts.demo.MeisterchartsDemoDescriptor
import com.meistercharts.demo.PredefinedConfiguration
import com.meistercharts.demo.VariabilityType
import com.meistercharts.demo.configurableDouble

class ExceptionDemoDescriptor : MeisterchartsDemoDescriptor<(gestalt: PvElectricityPriceGestalt) -> Unit> {
  override val uuid: Uuid = uuidFrom("c9477905-eb38-43e6-a64f-0bb328dbcad5")
  override val name: String = "PV PDF Category Line Chart"
  override val category: DemoCategory = DemoCategory.Other

  override val quality: DemoQuality = DemoQuality.Low

  override fun prepareDemo(configuration: PredefinedConfiguration<(gestalt: PvElectricityPriceGestalt) -> Unit>?): MeisterchartsDemo {
    throw Exception("Test Exception")
  }


}
