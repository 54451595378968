package com.meistercharts.charts.lizergy.solar

import com.meistercharts.algorithms.layers.LayerPaintingContext
import com.meistercharts.annotations.Zoomed
import com.meistercharts.canvas.fill
import com.meistercharts.canvas.paintable.Paintable
import com.meistercharts.color.Color
import com.meistercharts.color.ColorProvider
import it.neckar.geometry.Coordinates
import it.neckar.geometry.Rectangle
import it.neckar.geometry.Size
import it.neckar.open.kotlin.lang.asProvider

class RectangleWithBorderPaintable(
  val width: @Zoomed Double,
  val height: @Zoomed Double,
  val fill: ColorProvider,
  val borderColor: ColorProvider,
  val borderWidth: @Zoomed Double,
) : Paintable {

  constructor(
    width: @Zoomed Double,
    height: @Zoomed Double,
    fill: Color,
    borderColor: Color,
    borderWidth: @Zoomed Double,
  ) : this(width, height, fill.asProvider(), borderColor.asProvider(), borderWidth)

  val boundingBox: Rectangle = Rectangle(Coordinates.origin, Size(width, height))

  override fun boundingBox(paintingContext: LayerPaintingContext): Rectangle {
    return boundingBox
  }

  override fun paint(paintingContext: LayerPaintingContext, x: Double, y: Double) {
    val gc = paintingContext.gc
    gc.fill(borderColor)
    gc.fillRect(x, y, width, height)
    gc.fill(fill)
    gc.fillRect(x + borderWidth, y + borderWidth, width - 2 * borderWidth, height - 2 * borderWidth)
  }

}

