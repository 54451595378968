/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.demo.elevator.gestalt

import com.meistercharts.algorithms.layers.BackgroundImageLayer
import com.meistercharts.algorithms.layers.LayerPaintingContext
import com.meistercharts.algorithms.layers.addClearBackground
import com.meistercharts.algorithms.layers.addShowLoadingOnMissingResources
import com.meistercharts.algorithms.layers.debug.addVersionNumberHidden
import com.meistercharts.algorithms.layers.toolbar.ToolbarButtonFactory
import com.meistercharts.annotations.ContentArea
import com.meistercharts.annotations.Zoomed
import com.meistercharts.calc.ChartCalculator
import com.meistercharts.canvas.ConfigurationDsl
import com.meistercharts.canvas.DirtyReason
import com.meistercharts.canvas.FixedContentAreaSize
import com.meistercharts.canvas.MeisterchartBuilder
import com.meistercharts.canvas.fill
import com.meistercharts.canvas.paintable.Button
import com.meistercharts.canvas.paintable.ButtonState
import com.meistercharts.canvas.paintable.CombinedPaintable
import com.meistercharts.canvas.paintable.Paintable
import com.meistercharts.charts.ChartGestalt
import com.meistercharts.charts.ToolbarGestalt
import com.meistercharts.color.Color
import com.meistercharts.design.CurrentTheme
import com.meistercharts.model.Zoom
import com.meistercharts.resize.ResetToDefaultsOnWindowResize
import com.meistercharts.zoom.ZoomAndTranslationDefaults
import it.neckar.geometry.Direction
import it.neckar.geometry.Distance
import it.neckar.geometry.Orientation
import it.neckar.geometry.Rectangle
import it.neckar.geometry.Size

/**
 *
 */
@ExperimentalStdlibApi
class ElevatorGestalt(
  val configuration: Configuration = Configuration(),
  additionalConfiguration: Configuration.() -> Unit = {},
) : ChartGestalt {

  private val roofHeight = @ContentArea 50.0
  private val storyHeight = @ContentArea 200.0

  val model: ElevatorModel = ElevatorModel() //TOdo move to configuration
  val elevatorAnimationManager: ElevatorAnimationManager = ElevatorAnimationManager(model)

  init {
    configuration.additionalConfiguration()
  }

  override fun configure(meisterChartBuilder: MeisterchartBuilder) {
    with(meisterChartBuilder) {
      @Zoomed val marginOther = 15.0

      configure {
        chartSupport.windowResizeBehavior = ResetToDefaultsOnWindowResize
      }

      zoomAndTranslationDefaults {
        object : ZoomAndTranslationDefaults {
          override fun defaultZoom(chartCalculator: ChartCalculator): Zoom {
            val chartState = chartCalculator.chartState
            if (chartState.hasAnyZeroSize) {
              return Zoom.default
            }

            @Zoomed val roofHeightZoomed = chartCalculator.contentArea2zoomedY(roofHeight)

            @Zoomed val windowSize = chartState.windowSize

            @Zoomed val windowNetWidth = windowSize.width - marginOther - marginOther
            @Zoomed val windowNetHeight = windowSize.height - roofHeightZoomed - 2 * marginOther

            if (windowNetHeight <= 0.0 || windowNetHeight <= 0.0) {
              return Zoom.default
            }

            val contentAreaSize = chartState.contentAreaSize

            return Zoom.of(
              1.0 / contentAreaSize.width * windowNetWidth,
              1.0 / contentAreaSize.height * windowNetHeight
            ).smallerValueForBoth()
          }

          override fun defaultTranslation(chartCalculator: ChartCalculator): Distance {
            @Zoomed val roofHeightZoomed = chartCalculator.contentArea2zoomedY(roofHeight)
            return Distance.of(marginOther, roofHeightZoomed + marginOther)
          }
        }
      }

      enableZoomAndTranslation = false


      contentAreaSizingStrategy = FixedContentAreaSize {
        Size(1000.0, model.floorRange.numberOfFloors * storyHeight)
      }


      configure {
        chartSupport.onRender(elevatorAnimationManager)

        model.requestedFloorsProperty.consume {
          markAsDirty(DirtyReason.DataUpdated)
        }
        model.elevatorTargetProperty.consume {
          markAsDirty(DirtyReason.DataUpdated)
        }
      }

      val toolbarButtonFactory = ToolbarButtonFactory()
      val buttons = buildList {
        add(toolbarButtonFactory.button(getButtonPaintableProvider("EG"), ElevatorResources.buttonSize) {
          elevatorAnimationManager.pressedFloorButton(0)
        }.also { toggleButton ->
          toggleButton.selectedIfFloorRequested(0)
        })

        //add the remaining buttons
        for (targetFloor in 1..4) {
          add(toolbarButtonFactory.button(getButtonPaintableProvider("$targetFloor"), ElevatorResources.buttonSize) {
            elevatorAnimationManager.pressedFloorButton(targetFloor)
          }.also { toggleButton ->
            toggleButton.selectedIfFloorRequested(targetFloor)
          })
        }
      }

      val elevatorToolbarGestalt = ToolbarGestalt(
        buttons
      ).also {
        it.toolbarLayer.configuration.anchorDirection = Direction.CenterRight
        it.toolbarLayer.configuration.layoutOrientation = Orientation.Vertical
      }

      configure {
        layers.addClearBackground()

        layers.addLayer(BackgroundImageLayer().also {
          it.configuration.backgroundImage = ElevatorResources.backgroundImage
        })

        layers.addLayer(ElevatorLayer(model))

        layers.addShowLoadingOnMissingResources()
        layers.addVersionNumberHidden()
      }

      if (false) {
        //Add again if zooming and panning is enabled
        ToolbarGestalt().configure(this)
      }
      elevatorToolbarGestalt.configure(this)
    }
  }

  /**
   * Binds the selected state of this button.
   * If the floor number is requested, the selected state is set to true, false otherwise
   */
  private fun Button.selectedIfFloorRequested(targetFloor: Int) {
    selectedProperty.bind(model.requestedFloorsProperty.map {
      it.contains(targetFloor)
    })
  }

  private fun getButtonPaintableProvider(label: String): (ButtonState) -> Paintable {
    return { buttonState ->
      val image: Paintable = when (buttonState.simpleToggle) {
        ButtonState.SimpleToggle.Default -> ElevatorResources.floorElevatorButton
        ButtonState.SimpleToggle.Pressed, ButtonState.SimpleToggle.Selected -> ElevatorResources.floorElevatorButtonActive
        ButtonState.SimpleToggle.Hover -> ElevatorResources.floorElevatorButtonHover
      }

      val combinedPaintable = CombinedPaintable(image, object : Paintable {
        override fun boundingBox(paintingContext: LayerPaintingContext): Rectangle {
          return Rectangle.zero
        }

        override fun paint(paintingContext: LayerPaintingContext, x: Double, y: Double) {
          val gc = paintingContext.gc
          gc.font(CurrentTheme.h3)
          gc.fill(
            if (buttonState.pressed || buttonState.selected) {
              Color.lime
            } else {
              Color.white
            }
          )
          gc.fillText(label, x, y, Direction.Center)
        }
      }, Distance.none)

      combinedPaintable
    }
  }

  @ConfigurationDsl
  class Configuration
}
