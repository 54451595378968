/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.elektromeister.state

import com.meistercharts.annotations.Window
import com.meistercharts.canvas.MouseCursor
import com.meistercharts.events.EventConsumption
import it.neckar.elektromeister.rest.components.ElectricalComponentType
import it.neckar.events.MouseDownEvent
import it.neckar.events.MouseDragEvent
import it.neckar.events.MouseMoveEvent
import it.neckar.events.MouseUpEvent
import it.neckar.geometry.Coordinates
import it.neckar.open.observable.ReadOnlyObservableObject
import kotlin.contracts.contract

/**
 * Represents a state of the UI
 */
sealed interface ElektroMeisterUiState {
  /**
   * The cursor property for this state
   */
  val cursorProperty: ReadOnlyObservableObject<MouseCursor?>

  /**
   * Initializes the state with the given mouse location.
   * This method is called with the current mouse location, when the state is entered.
   */
  fun initialMouseLocation(mouseCoordinates: @Window Coordinates?, context: GestureContext)

  /**
   * Is called when the mouse is moved
   */
  fun mouseMoved(event: MouseMoveEvent, context: GestureContext): EventConsumption

  /**
   * Is called on mouse down events
   */
  fun mouseDown(event: MouseDownEvent, context: GestureContext): EventConsumption

  /**
   * Is called on mouse up events
   */
  fun mouseUp(event: MouseUpEvent, context: GestureContext): EventConsumption

  /**
   * Is called on mouse drag events
   */
  fun mouseDragged(event: MouseDragEvent, context: GestureContext): EventConsumption

  /**
   * Reset is called by the shortcut key
   */
  fun resetByKey(context: GestureContext): EventConsumption

  /**
   * Deletes the "current" element?
   */
  fun deleteByKey(context: GestureContext): EventConsumption
}


fun ElektroMeisterUiState.isDefault(): Boolean {
  contract {
    returns(true) implies (this@isDefault is DefaultElektroMeisterUiState)
  }
  return this is DefaultElektroMeisterUiState
}

fun ElektroMeisterUiState.isConnectionLine(): Boolean {
  contract {
    returns(true) implies (this@isConnectionLine is ConnectionLineCreationMode)
  }

  return this is ConnectionLineCreationMode
}

/**
 * Returns true if the state is a creation mode for the given type
 */
fun ElektroMeisterUiState.isCreate(type: ElectricalComponentType): Boolean {
  return this is ElementCreationMode && componentType == type
}

fun ElektroMeisterUiState.isElementCreationMode(): Boolean {
  contract {
    returns(true) implies (this@isElementCreationMode is ElementCreationMode)
  }
  return this is ElementCreationMode
}

fun ElektroMeisterUiState.isElectricalComponentSelected(): Boolean {
  contract {
    returns(true) implies (this@isElectricalComponentSelected is ElectricalComponentSelected)
  }
  return this is ElectricalComponentSelected
}

fun ElektroMeisterUiState.isMoveMode(): Boolean {
  contract {
    returns(true) implies (this@isMoveMode is ElectricalComponentMoving)
  }
  return this is ElectricalComponentMoving
}

/**
 * Returns the [ElementCreationMode] if the current state is a creation mode
 */
fun ElektroMeisterUiState.electricalComponentSelected(): ElectricalComponentSelected? {
  return this as? ElectricalComponentSelected
}
