package com.meistercharts.charts.lizergy.solar

import com.meistercharts.algorithms.layers.AbstractLayer
import com.meistercharts.algorithms.layers.CategoryLinesLayer
import com.meistercharts.algorithms.layers.DefaultCategoryLayouter
import com.meistercharts.algorithms.layers.DomainRelativeGridLayer
import com.meistercharts.algorithms.layers.LayerPaintingContext
import com.meistercharts.algorithms.layers.LayerType
import com.meistercharts.algorithms.layers.TranslationLayer
import com.meistercharts.algorithms.layers.addClearBackground
import com.meistercharts.algorithms.layers.barchart.CategoryAxisLayer
import com.meistercharts.algorithms.layers.barchart.CategoryChartOrientation
import com.meistercharts.algorithms.layers.barchart.CategoryLayer
import com.meistercharts.algorithms.layers.barchart.CategorySeriesModelColorsProvider
import com.meistercharts.algorithms.layers.barchart.GreedyCategoryAxisLabelPainter
import com.meistercharts.algorithms.layers.barchart.GroupedBarsPainter
import com.meistercharts.algorithms.layers.barchart.LabelVisibleCondition
import com.meistercharts.algorithms.layers.barchart.createAxisLayer
import com.meistercharts.algorithms.layers.clipped
import com.meistercharts.algorithms.layers.linechart.Dashes
import com.meistercharts.algorithms.layers.linechart.LineStyle
import com.meistercharts.algorithms.layers.text.TextLayer
import com.meistercharts.algorithms.layers.translate
import com.meistercharts.algorithms.layout.BoxIndex
import com.meistercharts.algorithms.painter.SplineLinePainter
import com.meistercharts.annotations.Domain
import com.meistercharts.annotations.Window
import com.meistercharts.annotations.Zoomed
import com.meistercharts.canvas.CanvasRenderingContext
import com.meistercharts.canvas.ConfigurationDsl
import com.meistercharts.canvas.MeisterchartBuilder
import com.meistercharts.canvas.fill
import com.meistercharts.canvas.text.CanvasStringShortener
import com.meistercharts.canvas.textService
import com.meistercharts.charts.ChartGestalt
import com.meistercharts.charts.FixedChartGestalt
import com.meistercharts.color.Color
import com.meistercharts.color.ColorProvider
import com.meistercharts.font.FontDescriptorFragment
import com.meistercharts.font.FontDescriptorFragmentProvider
import com.meistercharts.font.FontSize
import com.meistercharts.font.FontWeight
import com.meistercharts.geometry.BasePointProvider
import com.meistercharts.model.Insets
import com.meistercharts.model.Vicinity
import com.meistercharts.model.category.CategoryIndex
import com.meistercharts.model.category.CategorySeriesModel
import com.meistercharts.model.category.Series
import com.meistercharts.model.category.SeriesIndex
import com.meistercharts.painter.XyCategoryLinePainter
import com.meistercharts.painter.emptyCategoryPointPainter
import com.meistercharts.range.LinearValueRange
import com.meistercharts.range.ValueRange
import it.neckar.financial.currency.Money
import it.neckar.geometry.Coordinates
import it.neckar.geometry.Direction
import it.neckar.geometry.HorizontalAlignment
import it.neckar.geometry.Orientation
import it.neckar.geometry.Side
import it.neckar.open.i18n.I18nConfiguration
import it.neckar.open.i18n.Locale
import it.neckar.open.i18n.TextKey
import it.neckar.open.i18n.TextService
import it.neckar.open.i18n.resolve
import it.neckar.open.i18n.resolve.MapBasedTextResolver
import it.neckar.open.kotlin.lang.abs
import it.neckar.open.kotlin.lang.asProvider
import it.neckar.open.kotlin.lang.asProvider1
import it.neckar.open.kotlin.lang.ifNaN
import it.neckar.open.kotlin.lang.requireFinite
import it.neckar.open.observable.ObservableObject
import it.neckar.open.provider.DoublesProvider
import it.neckar.open.provider.MultiProvider
import it.neckar.open.provider.SizedProvider
import it.neckar.open.provider.asSizedProvider
import it.neckar.open.provider.fastMaxBy
import it.neckar.open.provider.fastMinBy
import it.neckar.open.unit.currency.EUR
import it.neckar.open.unit.other.px

/**
 * Visualizes the profit of a photovoltaic system after 20 years
 */
@Deprecated("Replace with better chart!")
class PvProfitGestalt(
  val configuration: Configuration,
  additionalConfiguration: Configuration.() -> Unit = {},
) : ChartGestalt {

  /**
   * Delegate the configures the chart to have a fixed zoom and translation
   */
  private val fixedChartGestalt: FixedChartGestalt = FixedChartGestalt()

  private val valueRange: LinearValueRange // throws exception if first cash flow is larger than last cash flow
    get() {
      val min = configuration.accumulatedCashFlowPerYear.fastMinBy(){
        it.euros
      }.requireFinite()

      val max = configuration.accumulatedCashFlowPerYear.fastMaxBy(){
      it.euros
      }.requireFinite()

      return ValueRange.linear(min, max)
    }

  /**
   * Paints the negative part of the bar
   */
  val groupedBarsPainterNegative: GroupedBarsPainter = GroupedBarsPainter {
    valueRangeProvider = { valueRange }
    showValueLabel = false
    colorsProvider = CategorySeriesModelColorsProvider { _, _ -> colorLightGray() }
    setBarSizeRange(22.0, 22.0)
  }

  /**
   * Paints the positive part of the bar
   */
  val groupedBarsPainterPositive: GroupedBarsPainter = GroupedBarsPainter {
    valueRangeProvider = { valueRange }
    showValueLabel = false
    colorsProvider = CategorySeriesModelColorsProvider { _, _ -> LizergyDesign.primaryColorLighter }
    setBarSizeRange(22.0, 22.0)
  }

  private fun computeHalfBarSize(): @Zoomed Double {
    return groupedBarsPainterPositive.paintingVariables().actualSize * 0.5
  }

  /**
   * The single series (accumulated cash flow)
   */
  val accumulatedCashFlowPerYearSeries: Series = object : Series {
    override fun valueAt(index: Int): Double {
      return configuration.accumulatedCashFlowPerYear.valueAt(index).euros
    }

    override fun seriesName(textService: TextService, i18nConfiguration: I18nConfiguration): String? = null

    override fun size(): Int {
      return configuration.accumulatedCashFlowPerYear.size()
    }
  }

  /**
   * The model used by the [categoryLayerNegative]
   */
  val categoryLayerNegativeModel: CategorySeriesModel = object : CategorySeriesModel {
    override val numberOfCategories: Int
      get() {
        return configuration.accumulatedCashFlowPerYear.size()
      }

    override val numberOfSeries: Int = 1
    override fun valueAt(categoryIndex: CategoryIndex, seriesIndex: SeriesIndex): Double {
      return if (categoryIndex.isEqual(configuration.indexYearOfInterest)) -configuration.totalInvestment.euros else 0.0
    }

    fun seriesAt(seriesIndex: Int): Series {
      require(seriesIndex == 0) { "Only one series is supported" }
      return accumulatedCashFlowPerYearSeries
    }

    override fun categoryNameAt(categoryIndex: CategoryIndex, textService: TextService, i18nConfiguration: I18nConfiguration): String {
      return if (categoryIndex.isEqual(configuration.indexYearOfInterest)) "${configuration.indexYearOfInterest + 1} ${labelYearsSubstantive.resolve(textService, i18nConfiguration)}" else ""
    }
  }

  /**
   * The model used by the [categoryLayerPositive]
   */
  val categoryLayerPositiveModel: CategorySeriesModel = object : CategorySeriesModel {
    override val numberOfCategories: Int
      get() {
        return configuration.accumulatedCashFlowPerYear.size()
      }

    override val numberOfSeries: Int = 1

    override fun valueAt(categoryIndex: CategoryIndex, seriesIndex: SeriesIndex): Double {
      return if (categoryIndex.isEqual(configuration.indexYearOfInterest)) configuration.accumulatedCashFlowPerYear.valueAt(categoryIndex.value).euros else 0.0
    }

    fun seriesAt(seriesIndex: Int): Series {
      require(seriesIndex == 0) { "Only one series is supported" }
      return accumulatedCashFlowPerYearSeries
    }

    override fun categoryNameAt(categoryIndex: CategoryIndex, textService: TextService, i18nConfiguration: I18nConfiguration): String {
      return if (categoryIndex.isEqual(configuration.indexYearOfInterest)) "${configuration.indexYearOfInterest + 1} ${labelYearsSubstantive.resolve(textService, i18nConfiguration)}" else ""
    }
  }

  /**
   * The model used by the [categoryLinesLayer]
   */
  val categoryLinesLayerModel: CategorySeriesModel = object : CategorySeriesModel {
    override val numberOfCategories: Int
      get() {
        return configuration.accumulatedCashFlowPerYear.size()
      }
    override val numberOfSeries: Int = 1

    override fun valueAt(categoryIndex: CategoryIndex, seriesIndex: SeriesIndex): Double {
      return accumulatedCashFlowPerYearSeries.valueAt(categoryIndex.value).requireFinite()
    }

    override fun categoryNameAt(categoryIndex: CategoryIndex, textService: TextService, i18nConfiguration: I18nConfiguration): String {
      return ""
    }

    fun seriesAt(seriesIndex: Int): Series {
      require(seriesIndex == 0) { "Only one series is supported" }
      return accumulatedCashFlowPerYearSeries
    }
  }

  val defaultCategoryLayouter: DefaultCategoryLayouter = DefaultCategoryLayouter {
    minCategorySize = 10.0
  }

  /**
   * The layer that paints the negative part of the bar
   */
  val categoryLayerNegative: CategoryLayer<CategorySeriesModel> = CategoryLayer({ categoryLayerNegativeModel }) {
    orientation = CategoryChartOrientation.VerticalLeft
    categoryPainter = groupedBarsPainterNegative
    layoutCalculator = defaultCategoryLayouter
  }

  /**
   * The layer that paints the positive part of the bar
   */
  val categoryLayerPositive: CategoryLayer<CategorySeriesModel> = CategoryLayer({ categoryLayerNegativeModel }) {
    orientation = CategoryChartOrientation.VerticalLeft
    categoryPainter = groupedBarsPainterPositive
    layoutCalculator = defaultCategoryLayouter
  }

  /**
   * The layer that paints the label at 20 years
   */
  val categoryAxisLayer: CategoryAxisLayer = categoryLayerPositive.createAxisLayer {
    tickOrientation = Vicinity.Outside
    side = Side.Bottom
    axisLabelPainter = GreedyCategoryAxisLabelPainter {}
    labelVisibleCondition = LabelVisibleCondition { labelIndex, _, _ -> labelIndex.value == configuration.indexYearOfInterest }
    lineColor = colorLightGray
    tickLabelColor = colorDarkGray
    titleColor = colorDarkGray
    tickFont = textFont

    hideTicks()
  }

  /**
   * Paints the line that visualizes the accumulated cash flow
   */
  val categoryLinesLayer: CategoryLinesLayer = CategoryLinesLayer(categoryLinesLayerModel) {
    layoutCalculator = defaultCategoryLayouter
    lineStyles = MultiProvider.always(LineStyle(colorDarkGray, 2.0))
    pointPainters = MultiProvider.always(emptyCategoryPointPainter)
    linePainters = MultiProvider.always(XyCategoryLinePainter(snapXValues = false, snapYValues = false, SplineLinePainter(false, false)))
  }

  /**
   * The [categoryLinesLayer] must be translated so the line touches the top left corner of the bar
   */
  val translatedCategoryLinesLayer: TranslationLayer = categoryLinesLayer.translate(
    translateX = { -computeHalfBarSize() },
    translateY = { 0.0 }
  )

  private fun updateCategoryLinesLayer() {
    categoryLinesLayer.configuration.valueRange = valueRange
  }

  /**
   * Paints the line where the accumulated cash flow is 0 (break even)
   */
  val gridLayer: DomainRelativeGridLayer = DomainRelativeGridLayer().apply {
    configuration.orientationProvider = { Orientation.Horizontal }
    configuration.lineStyles = LineStyle(color = colorLightGray, lineWidth = 2.0, dashes = Dashes(5.0, 5.0)).asProvider1()
  }

  private fun updateGridLayer() {
    gridLayer.configuration.valuesProvider = DoublesProvider.forValues(valueRange.toDomainRelative(0.0))
  }

  val totalInvestmentTextLayer: TextLayer = TextLayer({ textService, i18nConfiguration ->
    listOf(
      configuration.totalInvestment.format(i18nConfiguration),
      textService.get(labelInvested, i18nConfiguration)
    )
  }) {
    textColor = colorDarkGray
    horizontalAlignment = HorizontalAlignment.Right
    font = textFont
    anchorDirection = Direction.BottomRight
  }

  init {
    configuration.additionalConfiguration()
    configuration.contentAreaMarginProperty.consumeImmediately {
      fixedChartGestalt.contentViewportMargin = it
      gridLayer.configuration.passpartout = it
      categoryAxisLayer.configuration.size = it[categoryAxisLayer.configuration.side]
    }
    configuration.accumulatedCashFlowPerYearProperty.consumeImmediately {
      updateGridLayer()
      updateCategoryLinesLayer()
    }
    configuration.totalInvestmentProperty.consumeImmediately {
      updateGridLayer()
      updateCategoryLinesLayer()
    }
  }

  private fun computeTextHeight(lines: Int, gc: CanvasRenderingContext, textLayerStyle: TextLayer.Configuration): @Zoomed Double {
    gc.font(textLayerStyle.font())
    val fontMetrics = gc.getFontMetrics()
    val totalHeight = fontMetrics.totalHeight
    @px val spaceBetweenLines = totalHeight * textLayerStyle.lineSpacing.spacePercentage
    return lines * (totalHeight + spaceBetweenLines)
  }

  override fun configure(meisterChartBuilder: MeisterchartBuilder) {
    fixedChartGestalt.configure(meisterChartBuilder)

    with(meisterChartBuilder) {
      configure {

        chartSupport.textService.addTextResolverAtFirst(MapBasedTextResolver().also {
          it.setText(Locale.Germany, labelYearsAccusative, "Jahren")
          it.setText(Locale.Germany, labelYearsSubstantive, "Jahre")
          it.setText(Locale.Germany, labelInvested, "investiert")
          it.setText(Locale.Germany, labelProfit, "Gewinn")
          it.setText(Locale.Germany, labelGainAfter, "Zuwachs nach")
        })

        totalInvestmentTextLayer.configuration.anchorPointProvider = BasePointProvider { boundingBox ->
          @Window val barCenterX = chartSupport.chartCalculator.zoomed2windowX(categoryLinesLayer.paintingVariables().layout.calculateCenter(BoxIndex.zero))
          @Window val barBottomY = boundingBox.bottom - configuration.contentAreaMargin.bottom
          @Window val translationX = translatedCategoryLinesLayer.configuration.translateX()
          Coordinates(barCenterX + translationX, barBottomY - 5.0)
        }

        layers.addClearBackground()
        layers.addLayer(gridLayer)
        layers.addLayer(categoryLayerNegative.clipped { configuration.contentAreaMargin })
        layers.addLayer(categoryLayerPositive.clipped { configuration.contentAreaMargin })
        layers.addLayer(translatedCategoryLinesLayer.clipped { configuration.contentAreaMargin })
        layers.addLayer(categoryAxisLayer)
        layers.addLayer(totalInvestmentTextLayer)

        //Paints the profit into the green bar
        layers.addLayer(object : AbstractLayer() {
          override val type: LayerType = LayerType.Content

          override fun paint(paintingContext: LayerPaintingContext) {
            val gc = paintingContext.gc
            val chartCalculator = paintingContext.chartCalculator
            val valueRange = groupedBarsPainterPositive.configuration.valueRangeProvider()

            gc.translate(configuration.contentAreaMargin.left, 0.0)

            @Zoomed val columnCenterX = categoryLayerPositive.paintingVariables().layout.calculateCenter(BoxIndex(configuration.indexYearOfInterest))
            @Domain val minimumBarValueAfter20Years = categoryLayerNegativeModel.valueAt(CategoryIndex(configuration.indexYearOfInterest), SeriesIndex.zero).coerceAtLeast(0.0).ifNaN { throw IllegalArgumentException("no value at ${configuration.indexYearOfInterest}/0") }
            @Domain val profitAfter20years = categoryLayerPositive.configuration.modelProvider().valueAt(CategoryIndex(configuration.indexYearOfInterest), SeriesIndex.zero).ifNaN { throw IllegalArgumentException("no value at ${configuration.indexYearOfInterest}/0") }

            @Window @px val barStart = chartCalculator.domainRelative2windowY(valueRange.toDomainRelative(minimumBarValueAfter20Years))
            @Window @px val barEnd = chartCalculator.domainRelative2windowY(valueRange.toDomainRelative(profitAfter20years))
            @Window val barCenter = (barStart + barEnd) / 2.0

            gc.fill(configuration.profitInBarColor)
            gc.translate(columnCenterX - 2, barCenter)
            gc.rotateDegrees(-90.0)
            gc.font(textFont())
            gc.fillText(Money.euros(profitAfter20years).format(paintingContext.i18nConfiguration), 0.0, 0.0, Direction.Center, maxWidth = (barEnd - barStart).abs(), stringShortener = CanvasStringShortener.AllOrNothing)
          }
        })

        //Disabled for now
        //layers.addLayer(profitTextLayer)
        //layers.addLayer(gainAfterYearsTextLayer)
        //layers.addLayer(gainAfterYearsAmountTextLayer)
      }
    }
  }

  @ConfigurationDsl
  class Configuration {
    /**
     * How much money was invested to set up the photovoltaic system
     */
    val totalInvestmentProperty: ObservableObject<@EUR Money> = ObservableObject(Money.euros(17821.56))
    var totalInvestment: @EUR Money by totalInvestmentProperty

    /**
     * The year for which the accumulated cash flow should be shown
     */
    var indexYearOfInterest: Int = 19;

    /**
     * Provides the accumulated cash flow for each year
     */
    val accumulatedCashFlowPerYearProperty: ObservableObject<SizedProvider<Money>> = ObservableObject(
      listOf(
        Money.euros(-16891.45), // 1. year
        Money.euros(-15829.80), // 2. year
        Money.euros(-14745.40), // 3. year
        Money.euros(-13637.55), // 4. year
        Money.euros(-12505.57), // 5. year
        Money.euros(-11348.72), // 6. year
        Money.euros(-10166.26), // 7. year
        Money.euros(-8957.42), // 8. year
        Money.euros(-7721.41), // 9. year
        Money.euros(-6457.41), // 10. year
        Money.euros(-5164.58), // 11. year
        Money.euros(-3842.07), // 12. year
        Money.euros(-2488.97), // 13. year
        Money.euros(-1104.38), // 14. year
        Money.euros(312.66), // 15. year
        Money.euros(1763.12), // 16. year
        Money.euros(3248.00), // 17. year
        Money.euros(4768.33), // 18. year
        Money.euros(6325.17), // 19. year
        Money.euros(7919.63), // 20. year
        Money.euros(9432.54), // 21. year
        Money.euros(10445.45), // 22. year
        Money.euros(11358.36), // 23. year
        Money.euros(12171.27), // 24. year
        Money.euros(12884.18), // 25. year
      ).asSizedProvider()

    )
    var accumulatedCashFlowPerYear: SizedProvider<Money> by accumulatedCashFlowPerYearProperty

    /**
     * The margin of the content area (this is not(!) a margin around the chart)
     */
    val contentAreaMarginProperty: @Zoomed ObservableObject<Insets> = (ObservableObject(Insets.of(0.0, 0.0, 25.0, 90.0)))
    var contentAreaMargin: Insets by contentAreaMarginProperty

    val profitInBarColor: ColorProvider = Color.white
  }

  companion object {
    val colorLightGray: ColorProvider = Color.web("#D9DADA").asProvider()
    val colorDarkGray: ColorProvider = Color.web("#373939").asProvider()
    val textFont: FontDescriptorFragmentProvider = FontDescriptorFragment(familyConfiguration = LizergyDesign.defaultFontFamily, size = FontSize(13.0), weight = FontWeight.Normal).asProvider()
    val boldTextFont: FontDescriptorFragmentProvider = FontDescriptorFragment(familyConfiguration = LizergyDesign.defaultFontFamily, size = FontSize(13.0), weight = FontWeight.Bold).asProvider()

    val labelYearsAccusative: TextKey = TextKey("yearsAccusative", "years")
    val labelYearsSubstantive: TextKey = TextKey("yearsSubstantive", "years")
    val labelInvested: TextKey = TextKey.simple("invested")
    val labelProfit: TextKey = TextKey.simple("Profit")
    val labelGainAfter: TextKey = TextKey.simple("Gain after")
  }
}
