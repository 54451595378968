package it.neckar.elektromeister.rest.quote

import it.neckar.financial.currency.Money
import it.neckar.rest.annotations.RestAPI
import kotlinx.serialization.Serializable
import kotlin.js.JsExport

/**
 * Contains the quote for a single room
 */
@RestAPI
@Serializable
@JsExport
data class RoomQuote(
  val name: String,
  val totalPrice: Money,
  val elements: List<RoomQuoteElement>,
)
