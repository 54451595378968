package it.neckar.ktor.client

import io.ktor.client.*
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import io.ktor.utils.io.charsets.*
import it.neckar.rest.ClientId
import it.neckar.rest.tracking.XClientId


/**
 * Adds the Client ID to the request
 */
fun HttpClientConfig<*>.installClientIdHeader(clientId: ClientId = ClientId.random()) {
  install(DefaultRequest) {
    headers {
      append(HttpHeaders.XClientId, clientId.toString())
    }
  }
}

/**
 * Returns the body as string - only if the response was successful.
 * Throws an exception if the response was not successful.
 */
suspend fun HttpResponse.bodyAsTextSuccess(fallbackCharset: Charset = Charsets.UTF_8): String {
  if (status.isSuccess().not()) {
    throw IllegalStateException("Response was not successful: $status\n${bodyAsText(fallbackCharset)}")
  }
  return bodyAsText(fallbackCharset)
}
