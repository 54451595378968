package it.neckar.ktor.client

import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import it.neckar.commons.js.isFailToFetch
import it.neckar.open.http.Url
import it.neckar.open.http.UrlParameterName
import it.neckar.open.kotlin.serializers.verifyPlausibleForSerialization


/**
 * Extension methods for Ktor client
 */

/**
 * Posts the given object using JSON
 */
suspend inline fun <reified S : Any> HttpClient.postJson(url: Url, objectToSend: S, config: HttpRequestBuilder.() -> Unit = {}): HttpResponse {
  S::class.verifyPlausibleForSerialization()

  val response = post(url) {
    accept(ContentType.Application.Json)
    contentType(ContentType.Application.Json)

    setBody(objectToSend)

    //Additional configuration
    config()
  }
  return response
}

/**
 * Puts the given object using JSON
 */
suspend inline fun <reified S : Any> HttpClient.putJson(url: Url, objectToSend: S, config: HttpRequestBuilder.() -> Unit = {}): HttpResponse {
  S::class.verifyPlausibleForSerialization()

  val response = put(url) {
    accept(ContentType.Application.Json)
    contentType(ContentType.Application.Json)

    setBody(objectToSend)

    //Additional configuration
    config()

    setBody<S>(objectToSend)
  }

  return response
}

/**
 * Gets an object from the given URL
 */
suspend inline fun <reified R> HttpClient.getJson(url: Url, config: HttpRequestBuilder.() -> Unit = {}): R {
  val response = get(url) {
    accept(ContentType.Application.Json)

    //Additional configuration
    config()
  }

  return response.body()
}


/**
 * Executes a safe fetch function and returns the onFailure value if:
 * * fetch fails (network error).
 * * authentication fails (401).
 */
suspend fun <T> safeTryFetch(onFailure: T, fetchFunction: suspend () -> T): T {
  return try {
    fetchFunction()
  } catch (e: ClientRequestException) {
    when (e.response.status) {
      HttpStatusCode.Unauthorized -> onFailure
      else -> throw e
    }
  } catch (e: Throwable) {
    if (e.isFailToFetch()) return onFailure
    throw e
  }
}

suspend inline fun HttpClient.request(url: Url, block: HttpRequestBuilder.() -> Unit = {}): HttpResponse {
  return request(url.value, block)
}

/**
 * Extension method that takes an url object
 */
suspend inline fun HttpClient.get(url: Url, block: HttpRequestBuilder.() -> Unit = {}): HttpResponse {
  return get(url.value, block)
}

suspend inline fun HttpClient.post(url: Url, block: HttpRequestBuilder.() -> Unit = {}): HttpResponse {
  return post(url.value, block)
}

suspend inline fun HttpClient.put(url: Url, block: HttpRequestBuilder.() -> Unit = {}): HttpResponse {
  return put(url.value, block)
}

suspend inline fun HttpClient.delete(url: Url, block: HttpRequestBuilder.() -> Unit = {}): HttpResponse {
  return delete(url.value, block)
}

suspend inline fun HttpClient.head(url: Url, block: HttpRequestBuilder.() -> Unit = {}): HttpResponse {
  return head(url.value, block)
}

suspend inline fun HttpClient.options(url: Url, block: HttpRequestBuilder.() -> Unit = {}): HttpResponse {
  return options(url.value, block)
}


/**
 * Appends a parameter to the request
 */
inline fun HttpRequestBuilder.parameter(key: UrlParameterName, value: Any?): Unit {
  parameter(key.value, value)
}
