/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.demo.descriptors


import com.benasher44.uuid.Uuid
import com.benasher44.uuid.uuidFrom
import com.meistercharts.algorithms.layers.AxisConfiguration
import com.meistercharts.algorithms.layers.AxisTitleLocation
import com.meistercharts.algorithms.layers.addClearBackground
import com.meistercharts.algorithms.layers.barchart.CategoryAxisLayer
import com.meistercharts.algorithms.layout.BoxLayoutCalculator
import com.meistercharts.algorithms.layout.LayoutDirection
import com.meistercharts.charts.ContentViewportGestalt
import com.meistercharts.charts.support.CategoryAxisSupport
import com.meistercharts.demo.MeisterchartsDemo
import com.meistercharts.demo.MeisterchartsDemoDescriptor
import com.meistercharts.demo.DemoCategory
import com.meistercharts.demo.DemoQuality
import com.meistercharts.demo.PredefinedConfiguration
import com.meistercharts.demo.VariabilityType
import com.meistercharts.demo.configurableEnum
import com.meistercharts.demo.configurableFont
import com.meistercharts.demo.configurableInsetsSeparate
import com.meistercharts.model.Insets
import com.meistercharts.model.Vicinity
import com.meistercharts.provider.SizedLabelsProvider
import it.neckar.open.i18n.I18nConfiguration
import it.neckar.open.i18n.TextService
import it.neckar.open.kotlin.lang.asProvider

class CategoryAxisSupportDemoDescriptor : MeisterchartsDemoDescriptor<Nothing> {
  override val uuid: Uuid = uuidFrom("6ebd529f-d8fc-4881-a88f-ef3dd7969f53")
  override val name: String = "Category Axes Support"
  override val quality: DemoQuality = DemoQuality.High
  override val variabilityType: VariabilityType = VariabilityType.Stable

  //language=HTML
  override val category: DemoCategory = DemoCategory.Support

  override fun prepareDemo(configuration: PredefinedConfiguration<Nothing>?): MeisterchartsDemo {
    return MeisterchartsDemo {
      meistercharts {

        val myAxisConfiguration: CategoryAxisLayer.Configuration.(MyKeyEnum, CategoryAxisLayer, AxisTitleLocation) -> Unit = { myKeyEnum, axis, _ ->
          titleProvider = { textService, i18nConfiguration -> "Title for $myKeyEnum" }

          when (myKeyEnum) {
            MyKeyEnum.Axis0 -> size = 100.0

            MyKeyEnum.Axis1 -> {
              size = 100.0
              margin = Insets.of(120.0)
              paintRange = AxisConfiguration.PaintRange.ContentArea
            }
          }
        }

        val labelsProvider: SizedLabelsProvider = object : SizedLabelsProvider {
          override fun valueAt(index: Int, param1: TextService, param2: I18nConfiguration): String {
            return "Label for $index"
          }

          override fun size(param1: TextService, param2: I18nConfiguration): Int {
            return 7
          }
        }

        val layoutProvider = BoxLayoutCalculator.layout(500.0, 7, LayoutDirection.TopToBottom)

        val support = CategoryAxisSupport<MyKeyEnum>(
          labelsProvider = { labelsProvider },
          layoutProvider = { layoutProvider }
        ) {
          this.axisConfiguration = myAxisConfiguration
        }

        val contentViewportGestalt = ContentViewportGestalt(Insets.of(40.0, 10.0, 10.0, 10.0))
        contentViewportGestalt.configure(this)

        configure {
          layers.addClearBackground()

          support.addLayers(this, MyKeyEnum.Axis0)
          support.addLayers(this, MyKeyEnum.Axis1)
        }

        configurableEnum("Title Location", support::preferredAxisTitleLocation)
        configurableEnum("Tick Location", Vicinity.Outside) {
          onChange {
            support.configuration.axisConfiguration = { myKeyEnum, axis, categoryAxisTitleLocation ->
              myAxisConfiguration(myKeyEnum, axis, categoryAxisTitleLocation) //delegate to the "main" config
              axis.configuration.tickOrientation = it
              markAsDirty()
            }
          }
        }

        configurableFont("Title Font", support.getTopTitleLayer(MyKeyEnum.Axis1).configuration.titleFont()) {
          onChange {
            support.configuration.topTitleLayerConfiguration = { myKeyEnum, axis ->
              axis.configuration.titleFont = it.asProvider()
            }
            markAsDirty()
          }
        }

        configurableInsetsSeparate("Content VP Margin", contentViewportGestalt::contentViewportMargin)
      }
    }
  }

  enum class MyKeyEnum {
    Axis0,
    Axis1,
  }
}
