/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.demo.descriptors


import com.benasher44.uuid.Uuid
import com.benasher44.uuid.uuidFrom
import com.meistercharts.charts.sick.lisa.LisaChartGestalt
import com.meistercharts.demo.DemoCategory
import com.meistercharts.demo.DemoQuality
import com.meistercharts.demo.MeisterchartsDemo
import com.meistercharts.demo.MeisterchartsDemoDescriptor
import com.meistercharts.demo.PredefinedConfiguration
import com.meistercharts.demo.VariabilityType
import com.meistercharts.demo.configurableEnum
import com.meistercharts.design.SegoeUiTheme
import com.meistercharts.design.setDefaultTheme
import it.neckar.open.kotlin.lang.random
import it.neckar.open.provider.DoublesProvider

/**
 * Lisa Chart for SLG2
 */
class LisaChartDemoDescriptor : MeisterchartsDemoDescriptor<Nothing> {
  override val uuid: Uuid = uuidFrom("6cb43c54-f95d-4c6d-a007-2efc22315098")
  override val name: String = "Lisa Chart"
  override val category: DemoCategory = DemoCategory.Automation
  override val quality: DemoQuality = DemoQuality.High
  override val variabilityType: VariabilityType = VariabilityType.Stable

  override fun prepareDemo(configuration: PredefinedConfiguration<Nothing>?): MeisterchartsDemo {
    return MeisterchartsDemo {
      meistercharts {
        val lisaChartGestaltConfiguration = LisaChartGestalt.Configuration()

        val gestalt = LisaChartGestalt(lisaChartGestaltConfiguration)
        gestalt.configure(this)

        configure {
          setDefaultTheme(SegoeUiTheme)

          configurableEnum("Mode", gestalt.configuration.visibleCurvesModeProperty)

          declare {
            button("240") {
              fillWithRandomData(lisaChartGestaltConfiguration, 240)
              markAsDirty()
            }

            button("256") {
              fillWithRandomData(lisaChartGestaltConfiguration, 256)
              markAsDirty()
            }

            button("10") {
              fillWithRandomData(lisaChartGestaltConfiguration, 10)
              markAsDirty()
            }

            button("40") {
              fillWithRandomData(lisaChartGestaltConfiguration, 40)
              markAsDirty()
            }
          }
        }
      }
    }
  }

  private fun fillWithRandomData(data: LisaChartGestalt.Configuration, count: Int) {
    data.signalLevelValues = DoublesProvider.forList(List(count) {
      random.nextDouble(1000.0)
    })
    data.gainValues = DoublesProvider.forList(List(count) {
      random.nextDouble(20.0)
    })
    data.thresholdOnValues = DoublesProvider.forList(List(count) {
      random.nextDouble(650.0, 850.0)
    })
    data.thresholdOffValues = DoublesProvider.forList(List(count) {
      random.nextDouble(100.0, 300.0)
    })
    data.thresholdMonitorValues = DoublesProvider.forList(List(count) {
      random.nextDouble(350.0, 500.0)
    })
    data.teachInSignalLevelValues = DoublesProvider.forList(List(count) {
      random.nextDouble(100.0, 200.0)
    })
  }

}
