/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.demo.descriptors


import com.benasher44.uuid.Uuid
import com.benasher44.uuid.uuidFrom
import com.meistercharts.charts.lizergy.solar.PvSavingsGestalt
import com.meistercharts.demo.MeisterchartsDemo
import com.meistercharts.demo.MeisterchartsDemoDescriptor
import com.meistercharts.demo.DemoCategory
import com.meistercharts.demo.DemoQuality
import com.meistercharts.demo.PredefinedConfiguration
import com.meistercharts.demo.VariabilityType
import com.meistercharts.demo.configurableDouble
import com.meistercharts.demo.section
import it.neckar.financial.currency.euro

/**
 *
 */
class PvSavingsGestaltDemoDescriptor : MeisterchartsDemoDescriptor<Nothing> {
  override val uuid: Uuid = uuidFrom("af7ab7a6-fbf2-4f9a-96ac-02f98c864b3f")
  override val name: String = "PV Savings"
  override val category: DemoCategory = DemoCategory.Lizergy
  override val quality: DemoQuality = DemoQuality.High
  override val variabilityType: VariabilityType = VariabilityType.Stable

  override fun prepareDemo(configuration: PredefinedConfiguration<Nothing>?): MeisterchartsDemo {
    return MeisterchartsDemo {
      meistercharts {

        val values = object {
          var feedInCompensation = 150.0
          var savingsOwnConsumption = 120.0
          var saved = 130.12
        }

        val pvSavingsGestalt = PvSavingsGestalt(
          feedInCompensation = { values.feedInCompensation.euro },
          savingsOwnConsumption = { values.savingsOwnConsumption.euro },
          saved = { values.saved.euro }
        )
        pvSavingsGestalt.configure(this)


        configurableDouble("Feed in compensation", values::feedInCompensation) {
          max = 1000.0
        }
        configurableDouble("Savings on consumption", values::savingsOwnConsumption) {
          max = 1000.0
        }
        configurableDouble(values::saved) {
          max = 1000.0
        }

        section("Style")

        configurableDouble(pvSavingsGestalt.pvSavingsLayer.style::barWidth) {
          max = 100.0
        }
        configurableDouble(pvSavingsGestalt.pvSavingsLayer.style::gapBetweenBars) {
          max = 200.0
        }
        configurableDouble(pvSavingsGestalt.pvSavingsLayer.style::gapBetweenSegments) {
          max = 200.0
        }

        configurableDouble(pvSavingsGestalt.pvSavingsLayer.style::savingsLineWidth) {
          max = 10.0
        }
        configurableDouble(pvSavingsGestalt.pvSavingsLayer.style::savingsEndMarkerWidth) {
          max = 30.0
        }
      }
    }
  }
}
