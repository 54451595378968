package com.meistercharts.charts.sick.lisa

import com.meistercharts.algorithms.layers.AbstractLayer
import com.meistercharts.algorithms.layers.LayerPaintingContext
import com.meistercharts.algorithms.layers.LayerType
import com.meistercharts.algorithms.layout.BoxIndex
import com.meistercharts.algorithms.layout.EquisizedBoxLayout
import com.meistercharts.annotations.Zoomed
import com.meistercharts.canvas.ConfigurationDsl
import com.meistercharts.canvas.TooltipContent
import com.meistercharts.canvas.tooltipSupport
import com.meistercharts.events.EventConsumption
import it.neckar.open.kotlin.lang.betweenInclusive
import it.neckar.open.kotlin.lang.fastFor
import it.neckar.open.observable.clear

/**
 * Sets the tooltip cointent for the lisa chart
 */
class LisaTooltipContentLayer(
  val configuration: Configuration,
  additionalConfiguration: Configuration.() -> Unit = {},
) : AbstractLayer() {

  constructor(
    layoutProvider: () -> EquisizedBoxLayout?,
    tooltipTextProvider: (segmentIndex: Int) -> List<String>,
    additionalConfiguration: Configuration.() -> Unit = {},
  ): this(Configuration(layoutProvider, tooltipTextProvider), additionalConfiguration)

  init {
    configuration.additionalConfiguration()
  }

  override val type: LayerType = LayerType.Notification

  override fun initialize(paintingContext: LayerPaintingContext) {
    super.initialize(paintingContext)

    val layerSupport = paintingContext.layerSupport

    val tooltipContent = layerSupport.chartSupport.tooltipSupport.tooltipProperty(this)

    layerSupport.mouseEvents.onMove { event ->
      val mousePosition = event.coordinates

      if (mousePosition == null) {
        tooltipContent.clear()
        return@onMove EventConsumption.Ignored
      }

      val layout = configuration.layoutProvider()
      if (layout == null) {
        tooltipContent.clear()
        return@onMove EventConsumption.Ignored
      }

      //calculate the location relative to the content area
      val chartCalculator = paintingContext.chartCalculator
      val relative = chartCalculator.window2contentArea(mousePosition)

      //there is a layout
      val numberSegments = layout.numberOfBoxes
      numberSegments.fastFor { segmentIndex ->
        @Zoomed val start = layout.calculateStart(BoxIndex(segmentIndex))
        @Zoomed val end = layout.calculateEnd(BoxIndex(segmentIndex))

        if (relative.x.betweenInclusive(start, end)) {
          tooltipContent.value = TooltipContent(configuration.tooltipTextProvider(segmentIndex))
          return@onMove EventConsumption.Consumed
        }
      }

      //nothing found
      tooltipContent.clear()
      return@onMove EventConsumption.Ignored
    }

  }

  override fun paint(paintingContext: LayerPaintingContext) {
  }

  @ConfigurationDsl
  class Configuration(
    /**
     * provides the segments layout
     */
    var layoutProvider: () -> EquisizedBoxLayout?,

    /**
     * Provides the tooltip text for the given segment index
     */
    var tooltipTextProvider: (segmentIndex: Int) -> List<String>,
    )
}
