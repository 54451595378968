/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.elektromeister

import com.meistercharts.algorithms.layers.AbstractLayer
import com.meistercharts.algorithms.layers.LayerPaintingContext
import com.meistercharts.algorithms.layers.LayerType
import com.meistercharts.algorithms.layers.domainChartCalculator
import com.meistercharts.annotations.Domain
import com.meistercharts.annotations.Window
import com.meistercharts.calc.DomainChartCalculator
import com.meistercharts.canvas.layout.cache.CoordinatesMultiCache
import com.meistercharts.canvas.strokeStyle
import com.meistercharts.color.Color
import com.meistercharts.color.ColorProvider
import com.meistercharts.elektromeister.state.ConnectionLineCreationMode
import com.meistercharts.style.Palette
import it.neckar.elektromeister.rest.RelativeToFloorOrigin
import it.neckar.geometry.Coordinates
import it.neckar.open.provider.MultiProvider
import it.neckar.open.provider.fastForEachIndexed
import it.neckar.open.unit.other.px
import it.neckar.open.unit.si.mm

/**
 * Paints the connection lines between the electrical components
 */
class ElektromeisterConnectionLineLayer(val configuration: Configuration) : AbstractLayer() {
  override val type: LayerType = LayerType.Content

  override fun paintingVariables(): PaintingVariables {
    return paintingVariables
  }

  private val paintingVariables = object : PaintingVariables {

    @mm
    @RelativeToFloorOrigin
    @Domain
    val startCoordinates = CoordinatesMultiCache()

    @mm
    @RelativeToFloorOrigin
    @Domain
    val endCoordinates = CoordinatesMultiCache()


    /**
     * Contains (at most) two temporary coordinates for the connection line creation
     */
    val temporaryCoordinates = CoordinatesMultiCache()

    override fun calculate(paintingContext: LayerPaintingContext) {
      val chartSupport = paintingContext.chartSupport
      val chartCalculator = chartSupport.domainChartCalculator(domainRangeX = configuration.rangeX, domainRangeY = configuration.rangeY)

      val uiState = configuration.model.stateMachine.currentUiState
      val connectionLines = configuration.model.connectionLines
      val connectionLinesCount = connectionLines.size()

      startCoordinates.prepare(connectionLinesCount)
      endCoordinates.prepare(connectionLinesCount)

      connectionLines.fastForEachIndexed { index, connectionLine ->
        @mm @RelativeToFloorOrigin val start = connectionLine.startLocation
        startCoordinates.set(index, chartCalculator.domain2windowX(start.x), chartCalculator.domain2windowY(start.y))

        @mm @RelativeToFloorOrigin val end = connectionLine.endLocation
        endCoordinates.set(index, chartCalculator.domain2windowX(end.x), chartCalculator.domain2windowY(end.y))
      }


      //Calculate the coordinates for the temporary connection line
      temporaryCoordinates.prepare(0)
      if (uiState is ConnectionLineCreationMode) {
        @mm @RelativeToFloorOrigin @Domain val startLocation = uiState.start?.transient?.locationToPaint

        @Window val temporaryEndCoordinates = guessTemporaryEndCoordinates(uiState, chartCalculator)

        if (startLocation != null && temporaryEndCoordinates != null) {
          temporaryCoordinates.prepare(2)

          chartCalculator.domain2windowX(startLocation.x)
          chartCalculator.domain2windowY(startLocation.y)

          temporaryCoordinates.set(0, chartCalculator.domain2windowX(startLocation.x), chartCalculator.domain2windowY(startLocation.y))
          temporaryCoordinates.set(1, temporaryEndCoordinates.x, temporaryEndCoordinates.y)
        }
      }
    }

    private fun guessTemporaryEndCoordinates(
      connectionLineCreationMode: ConnectionLineCreationMode,
      chartCalculator: DomainChartCalculator,
    ): @Window Coordinates? {
      @Window val temporaryEndCoordinates = connectionLineCreationMode.temporaryEndCoordinates
      @Domain val potentialEndLocation = connectionLineCreationMode.potentialEnd?.transient?.locationToPaint

      @Suppress("FoldInitializerAndIfToElvis")
      if (potentialEndLocation == null) {
        return temporaryEndCoordinates
      }

      //Use the location of the potential end component
      return Coordinates(
        chartCalculator.domain2windowX(potentialEndLocation.x),
        chartCalculator.domain2windowY(potentialEndLocation.y)
      )
    }
  }

  override fun paint(paintingContext: LayerPaintingContext) {
    val gc = paintingContext.gc
    val paintingVariables = paintingVariables

    paintingVariables.startCoordinates.fastForEachIndexed { index: @ConnectionLineIndex Int, startX: @Window Double, startY: @Window Double ->
      @Window val endX = paintingVariables.endCoordinates.x(index)
      @Window val endY = paintingVariables.endCoordinates.y(index)

      gc.lineWidth = configuration.lineWidth
      gc.strokeStyle(configuration.lineColor.valueAt(index))

      gc.strokeLine(startX, startY, endX, endY)
    }

    //Paint the temporary connection line
    if (paintingVariables.temporaryCoordinates.size == 2) {
      @Window val startX = paintingVariables.temporaryCoordinates.x(0)
      @Window val startY = paintingVariables.temporaryCoordinates.y(0)

      @Window val endX = paintingVariables.temporaryCoordinates.x(1)
      @Window val endY = paintingVariables.temporaryCoordinates.y(1)

      gc.lineWidth = configuration.lineWidth
      gc.strokeStyle(Color.lightgray)

      gc.strokeLine(startX, startY, endX, endY)
    }
  }

  class Configuration(
    override val model: ElektromeisterMeisterchartsModel,
  ) : AbstractElektromeisterConfiguration() {

    /**
     * The line width of the connection lines
     */
    var lineWidth: @px Double = 1.0

    /**
     * The color of the connection lines
     */
    val lineColor: MultiProvider<ConnectionLineIndex, ColorProvider> = MultiProvider.always(Palette.primaryColors[0])
  }

  interface PaintingVariables : com.meistercharts.algorithms.layers.PaintingVariables {
  }
}

