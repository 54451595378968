package com.meistercharts.charts.lizergy.solar

import com.meistercharts.algorithms.layers.DefaultCategoryLayouter
import com.meistercharts.algorithms.layers.TranslationLayer
import com.meistercharts.algorithms.layers.axis.NoTicksProvider
import com.meistercharts.algorithms.layers.legend.LegendLayer
import com.meistercharts.algorithms.layers.text.TextLayer
import com.meistercharts.algorithms.layers.translate
import com.meistercharts.algorithms.layers.visibleIf
import com.meistercharts.algorithms.layout.BoxIndex
import com.meistercharts.canvas.ConfigurationDsl
import com.meistercharts.canvas.MeisterchartBuilder
import com.meistercharts.canvas.paintable.SymbolAndTextKeyPaintable
import com.meistercharts.canvas.textService
import com.meistercharts.charts.BarChartStackedGestalt
import com.meistercharts.charts.ChartGestalt
import com.meistercharts.color.Color
import com.meistercharts.color.ColorProvider
import com.meistercharts.font.FontDescriptorFragment
import com.meistercharts.font.FontSize
import com.meistercharts.font.FontWeight
import com.meistercharts.geometry.BasePointProvider
import com.meistercharts.model.Insets
import com.meistercharts.model.category.CategoryIndex
import com.meistercharts.model.category.CategorySeriesModel
import com.meistercharts.model.category.Series
import com.meistercharts.model.category.SeriesIndex
import com.meistercharts.range.ValueRange
import it.neckar.financial.currency.Money
import it.neckar.geometry.Coordinates
import it.neckar.geometry.Direction
import it.neckar.geometry.HorizontalAlignment
import it.neckar.geometry.Orientation
import it.neckar.open.i18n.I18nConfiguration
import it.neckar.open.i18n.Locale
import it.neckar.open.i18n.TextKey
import it.neckar.open.i18n.TextService
import it.neckar.open.i18n.resolve
import it.neckar.open.i18n.resolve.MapBasedTextResolver
import it.neckar.open.kotlin.lang.asProvider
import it.neckar.open.kotlin.lang.requireFinite
import it.neckar.open.observable.ObservableObject
import it.neckar.open.provider.DoubleProvider
import it.neckar.open.provider.DoublesProvider
import it.neckar.open.provider.MultiProvider
import it.neckar.open.provider.asSizedProvider
import it.neckar.open.unit.currency.EUR

class PvExpensesPerMonthGestalt(
  val configuration: Configuration = Configuration(),
) : ChartGestalt {

  val barChartData: BarChartStackedGestalt = BarChartStackedGestalt(
    initialCategorySeriesModel = object : CategorySeriesModel {
      override val numberOfCategories: Int = 2
      override val numberOfSeries: Int = 2

      val seriesElectricityBill: Series = object : Series {
        override fun size(): Int = 2
        override fun valueAt(index: Int): Double {
          return when (index) {
            0 -> configuration.expensesPerMonthWithoutPv.euros
            1 -> configuration.expensesPerMonthWithPv.euros
            else -> throw UnsupportedOperationException("unsupported index $index")
          }
        }

        override fun seriesName(textService: TextService, i18nConfiguration: I18nConfiguration): String {
          return labelElectricityBill.resolve(textService, i18nConfiguration)
        }
      }

      val seriesPhotovoltaicCosts: Series = object : Series {
        override fun size(): Int = 2
        override fun valueAt(index: Int): Double {
          return when (index) {
            0 -> 0.0
            1 -> configuration.photovoltaicCostsPerMonth.euros
            else -> throw UnsupportedOperationException("unsupported index $index")
          }
        }

        override fun seriesName(textService: TextService, i18nConfiguration: I18nConfiguration): String {
          return labelPhotovoltaicCosts.resolve(textService, i18nConfiguration)
        }
      }

      override fun valueAt(categoryIndex: CategoryIndex, seriesIndex: SeriesIndex): Double {
        return when (seriesIndex) {
          SeriesIndex.zero -> seriesElectricityBill.valueAt(categoryIndex.value).requireFinite()
          SeriesIndex.one -> seriesPhotovoltaicCosts.valueAt(categoryIndex.value).requireFinite()
          else -> throw UnsupportedOperationException("unsupported series index $seriesIndex")
        }
      }

      override fun categoryNameAt(categoryIndex: CategoryIndex, textService: TextService, i18nConfiguration: I18nConfiguration): String {
        return when (categoryIndex) {
          CategoryIndex.zero -> labelWithoutPv.resolve(textService, i18nConfiguration)
          CategoryIndex.one -> labelWithPv.resolve(textService, i18nConfiguration)
          else -> throw UnsupportedOperationException("unsupported category index $categoryIndex")
        }
      }
    }
  ).apply {
  }

  val barChartStackedGestalt: BarChartStackedGestalt = barChartData.apply {

    configuration.applyHorizontalConfiguration()
    contentViewportMargin = Insets.of(20.0, 0.0, 20.0, 120.0)

    categoryAxisLayer.configuration.hideAxisLine()
    categoryAxisLayer.configuration.tickFont = FontDescriptorFragment(familyConfiguration = LizergyDesign.defaultFontFamily, size = FontSize(13.0)).asProvider()
    categoryAxisLayer.configuration.categoryLabelColor = MultiProvider.forListModulo(
      listOf(
        Color.web("#CD1013"),
        LizergyDesign.primaryColorLighter
      )
    )

    (categoryLayer.configuration.layoutCalculator as DefaultCategoryLayouter).apply {
      style.gapSize = DoubleProvider { 5.0 }
      style.minCategorySize = 0.0
      style.maxCategorySize = 40.0
    }

    valueAxisLayer.configuration.hideAxisLine()
    valueAxisLayer.configuration.hideTicks()
    valueAxisLayer.configuration.ticks = NoTicksProvider

    //Paint a line at zero
    gridLayer.configuration.valuesProvider = DoublesProvider.of(1) {
      configuration.valueRange.toDomainRelative(0.0)
    }

    stackedBarsPainter.style.maxBarSize = 23.0

    stackedBarsPainter.stackedBarPaintable.style.apply {
      colorsProvider = MultiProvider.forListModuloProvider(
        listOf(
          colorElectricityBill,
          colorPhotovoltaicCosts
        )
      )
      paintBackground = false
      showValueLabels = false
      showRemainderAsSegment = false
      segmentsGap = 0.0
      showBorder = true
      borderColor = colorElectricityBill
      borderLineWidth = 2.0
    }

  }

  val legendLayer: LegendLayer = LegendLayer(
    listOf(
      SymbolAndTextKeyPaintable(RectangleWithBorderPaintable(16.0, 16.0, colorElectricityBill, colorElectricityBill, 0.0), labelElectricityBill) {
        textColor = colorElectricityBill
        textFont = FontDescriptorFragment(familyConfiguration = LizergyDesign.defaultFontFamily, size = FontSize(13.0))
      },
      SymbolAndTextKeyPaintable(RectangleWithBorderPaintable(16.0, 16.0, colorPhotovoltaicCosts, colorElectricityBill, 2.0), labelPhotovoltaicCosts) {
        textColor = colorElectricityBill
        textFont = FontDescriptorFragment(familyConfiguration = LizergyDesign.defaultFontFamily, size = FontSize(13.0))
      },
    ).asSizedProvider(),
    Orientation.Horizontal,
  ) {
    anchorDirection = Direction.BottomLeft
    entriesGap = 30.0
    horizontalGap = 0.0 // translated via translation layer
    verticalGap = 0.0 // translated via translation layer
  }

  val legendLayerTranslated: TranslationLayer = legendLayer.translate(
    translateX = { barChartStackedGestalt.contentViewportMargin.left },
    translateY = { 0.0 }
  )

  val titleTextLayer: TextLayer = TextLayer({ textService, i18nConfiguration ->
    listOf(textService[titleExpensesPerMonth, i18nConfiguration])
  }) {
    textColor = LizergyDesign.primaryColorDarker.asProvider()
    font = FontDescriptorFragment(LizergyDesign.defaultFontFamily, size = FontSize(13.0), weight = FontWeight.Bold).asProvider()
    anchorPointProvider = BasePointProvider { Coordinates(barChartStackedGestalt.contentViewportMargin.left, 0.0) }
    anchorDirection = Direction.TopLeft
  }

  val savingsPerMonth: @EUR Money
    get() {
      return configuration.expensesPerMonthWithoutPv - configuration.expensesPerMonthWithPv - configuration.photovoltaicCostsPerMonth
    }

  val savingsPerMonthTextLayer: TextLayer = TextLayer({ textService, i18nConfiguration ->
    listOf(
      savingsPerMonth.format(i18nConfiguration),
      textService[labelSaved, i18nConfiguration]
    )
  }) {
    textColor = LizergyDesign.primaryColorLighter.asProvider()
    horizontalAlignment = HorizontalAlignment.Right
    font = FontDescriptorFragment(familyConfiguration = LizergyDesign.defaultFontFamily, size = FontSize(13.0)).asProvider()
    anchorDirection = Direction.CenterRight
    anchorGapHorizontal = 0.0
    anchorGapVertical = 0.0
  }

  private fun updateValueRange() {
    val valueRangeEnd = configuration.expensesPerMonthWithoutPv.coerceAtLeast(configuration.expensesPerMonthWithPv + configuration.photovoltaicCostsPerMonth)
    val valueRangeStart = Money.Zero.coerceAtMost(configuration.photovoltaicCostsPerMonth) //photovoltaicCostsPerMonth may be negative
    barChartStackedGestalt.configuration.valueRange = ValueRange.linear(valueRangeStart.euros, valueRangeEnd.euros)
  }

  init {
    configuration.expensesPerMonthWithoutPvProperty.consume {
      updateValueRange()
    }
    configuration.expensesPerMonthWithPvProperty.consume {
      updateValueRange()
    }
    configuration.photovoltaicCostsPerMonthProperty.consume {
      updateValueRange()
    }

    updateValueRange()
  }

  override fun configure(meisterChartBuilder: MeisterchartBuilder) {
    with(meisterChartBuilder) {

      barChartStackedGestalt.configure(this)

      configure {
        chartSupport.textService.addTextResolverAtFirst(MapBasedTextResolver().also {
          it.setText(Locale.Germany, labelElectricityBill, "Stromrechnung")
          it.setText(Locale.Germany, labelPhotovoltaicCosts, "PV-Anlage")
          it.setText(Locale.Germany, titleExpensesPerMonth, "Stromausgaben im Monat")
          it.setText(Locale.Germany, labelWithoutPv, "ohne PV-Anlage")
          it.setText(Locale.Germany, labelWithPv, "mit PV-Anlage")
          it.setText(Locale.Germany, labelSaved, "gespart")
        })

        savingsPerMonthTextLayer.configuration.anchorPointProvider = BasePointProvider { boundingBox ->
          Coordinates(
            boundingBox.right - barChartStackedGestalt.contentViewportMargin.right,
            chartSupport.chartCalculator.zoomed2windowY(barChartStackedGestalt.categoryLayer.paintingVariables().layout.calculateCenter(BoxIndex.one))
          )
        }

        layers.addLayer(legendLayerTranslated)
        layers.addLayer(titleTextLayer)
        layers.addLayer(savingsPerMonthTextLayer.visibleIf { savingsPerMonth > Money.Zero })
      }

    }
  }

  @ConfigurationDsl
  class Configuration {
    /**
     * The expenses for electricity per month without a photovoltaic system in cents
     */
    val expensesPerMonthWithoutPvProperty: ObservableObject<@EUR Money> = ObservableObject(Money.euros(80.00))
    var expensesPerMonthWithoutPv: @EUR Money by expensesPerMonthWithoutPvProperty

    /**
     * The expenses for electricity per month with a photovoltaic system in cents
     */
    val expensesPerMonthWithPvProperty: ObservableObject<@EUR Money> = ObservableObject(Money.euros(15.00))
    var expensesPerMonthWithPv: @EUR Money by expensesPerMonthWithPvProperty

    /**
     * How much a photovoltaic system costs each month in cents
     */
    val photovoltaicCostsPerMonthProperty: ObservableObject<@EUR Money> = ObservableObject(Money.euros(50.00))
    var photovoltaicCostsPerMonth: @EUR Money by photovoltaicCostsPerMonthProperty
  }

  companion object {
    private val colorPhotovoltaicCosts: ColorProvider = Color.web("#D9DADA").asProvider()
    private val colorElectricityBill: ColorProvider = Color.web("#373939").asProvider()

    val labelElectricityBill: TextKey = TextKey.simple("Electricity bill")
    val labelPhotovoltaicCosts: TextKey = TextKey.simple("Photovoltaic costs")
    val titleExpensesPerMonth: TextKey = TextKey.simple("Expenses for electricity per month")
    val labelWithoutPv: TextKey = TextKey.simple("without PV system")
    val labelWithPv: TextKey = TextKey.simple("with PV system")
    val labelSaved: TextKey = TextKey.simple("saved")
  }
}
