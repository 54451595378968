/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.elektromeister.model

import com.meistercharts.annotations.Window
import it.neckar.elektromeister.rest.PlacedElectricalComponent
import it.neckar.elektromeister.rest.components.ElectricalComponent
import it.neckar.geometry.Coordinates
import it.neckar.geometry.Direction
import it.neckar.open.dispose.Disposable
import it.neckar.open.kotlin.lang.requireNotNull
import it.neckar.open.observable.ConsumeChangesAction
import it.neckar.open.observable.ObservableObject
import it.neckar.open.observable.ObservableProperties
import it.neckar.open.observable.ObservablePropertiesSupport
import it.neckar.open.observable.ReadOnlyObservableObject
import it.neckar.open.unit.si.mm

/**
 * Represents a placed electrical component - that is mutable.
 * For usage in the UI.
 */
class MutablePlacedElectricalComponent(
  /**
   * The electrical component that has been placed
   */
  val electricalComponent: ElectricalComponent,
  /**
   * The initial location of the component
   */
  initialLocation: @mm Coordinates,
  /**
   * The initial anchor direction of this component
   */
  initialAnchorDirection: Direction = Direction.Center,
) : ObservableProperties {

  val id: ElectricalComponent.Id
    get() = electricalComponent.id

  /**
   * The location of the component
   */
  private val locationProperty: ObservableObject<@mm Coordinates> = ObservableObject(initialLocation)

  var location: @mm Coordinates by locationProperty
    private set

  /**
   * Updates the location after the component has been moved
   */
  fun updateLocation(newLocation: @mm Coordinates) {
    location = newLocation
  }

  val anchorDirectionProperty: ObservableObject<Direction> = ObservableObject(initialAnchorDirection)
  var anchorDirection: Direction by anchorDirectionProperty


  private val observablePropertiesSupport = ObservablePropertiesSupport.create {
    addObservable(locationProperty)
    addObservable(anchorDirectionProperty)
  }

  override fun consumeAllPropertiesChanges(action: ConsumeChangesAction<Any?>): Disposable {
    return observablePropertiesSupport.consumeAllPropertiesChanges(action)
  }

  /**
   * Contains the transient properties of the component.
   * These components are *not* published when calling [consumeAllPropertiesChanges]
   */
  val transient: TransientProperties = TransientProperties()

  inner class TransientProperties {
    /**
     * The location while the component is being moved
     */
    val movementLocationProperty: ObservableObject<@mm Coordinates?> = ObservableObject(null)
    var movementLocation: @mm Coordinates? by movementLocationProperty

    /**
     * The location where the component should be painted
     */
    val locationToPaintProperty: ReadOnlyObservableObject<Coordinates> = locationProperty.map(movementLocationProperty) { location, movementLocation ->
      movementLocation ?: location
    }

    val locationToPaint: Coordinates by locationToPaintProperty

    /**
     * Updates the movement location
     */
    fun startMoving(location: @mm Coordinates) {
      require(transient.movementLocation == null) {
        "Movement location already set"
      }
      transient.movementLocation = location
    }

    /**
     * Finish moving the component
     */
    fun finishMoving() {
      val relevantLocation = transient.movementLocation.requireNotNull { "No movementLocation found" }
      updateLocation(relevantLocation)
      transient.movementLocation = null
    }
  }

  override fun toString(): String {
    return "MutablePlacedElectricalComponent(id=$id, location=$location)"
  }

  /**
   * Creates a new instance of a placed electrical component from this mutable placed electrical component
   */
  fun toPlacedElectricalComponent(): PlacedElectricalComponent {
    return PlacedElectricalComponent(
      component = electricalComponent,
      location = location,
      anchorDirection = anchorDirection
    )
  }
}

/**
 * Converts a placed electrical component to a mutable placed electrical component
 */
fun PlacedElectricalComponent.toMutable(): MutablePlacedElectricalComponent {
  return MutablePlacedElectricalComponent(electricalComponent = component, initialLocation = location, initialAnchorDirection = anchorDirection)
}
