package it.neckar.rest.tracking

import io.ktor.http.*
import it.neckar.rest.ClientRole


/**
 * The header key for the client role
 */
@Suppress("UnusedReceiverParameter")
val HttpHeaders.XClientRole: String
  get() {
    return "X-Client-Role"
  }

/**
 * Returns the client role of the request
 */
val Headers.findClientRole: ClientRole?
  get() {
    return this[HttpHeaders.XClientRole]?.let { ClientRole(it) }
  }

/**
 * Returns true if the header contains the expected client role
 */
fun Headers.hasClientRole(expectedRole: ClientRole): Boolean {
  val clientRole: ClientRole? = findClientRole
  return clientRole == expectedRole
}

fun Headers.hasAnyClientRole(expectedRoles: Set<ClientRole>): Boolean {
  val clientRole: ClientRole = findClientRole ?: return false
  return clientRole in expectedRoles
}

