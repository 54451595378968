package com.meistercharts.charts.lizergy.roofPlanning

/**
 * Contains a selection
 */
data class RoofSelection(
  val selection: Clickable?,
) {

  val resizable: Resizable? = when (selection) {
    is Module -> selection.modulePlacement.moduleArea
    is UnusableArea, is ModuleArea -> selection
    null -> null
  }

  /**
   * Returns true if the given element is currently selected
   */
  fun isSelected(clickable: Clickable): Boolean {
    return selection == clickable ||
      (selection is Module && selection.modulePlacement.moduleArea == clickable) ||
      (clickable is Module && selection == clickable.modulePlacement.moduleArea)
  }

  fun isEmpty(): Boolean {
    return selection == null
  }

  companion object {
    /**
     * Empty selection
     */
    val empty: RoofSelection = RoofSelection(null)
  }
}
