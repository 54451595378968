/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.demo.descriptors


import com.benasher44.uuid.Uuid
import com.benasher44.uuid.uuidFrom
import com.meistercharts.algorithms.layers.text.addTexts
import com.meistercharts.charts.sick.beams.BeamChartGestalt
import com.meistercharts.charts.sick.beams.ExampleBeamProvider
import com.meistercharts.demo.MeisterchartsDemo
import com.meistercharts.demo.MeisterchartsDemoDescriptor
import com.meistercharts.demo.DemoCategory
import com.meistercharts.demo.DemoQuality
import com.meistercharts.demo.PredefinedConfiguration
import com.meistercharts.demo.configurableBoolean
import com.meistercharts.demo.configurableEnum
import com.meistercharts.demo.configurableInt
import it.neckar.geometry.VerticalAlignment

/**
 */
class BeamChartGestaltDemoDescriptor : MeisterchartsDemoDescriptor<Nothing> {
  override val uuid: Uuid = uuidFrom("72d0f04b-479b-4275-900a-1cfad23deec5")
  override val name: String = "Beam Chart Gestalt"
  override val category: DemoCategory = DemoCategory.Automation

  /**
   * last clicked beam initial text "?"  should be replaced with
   * something like "last clicked beam: None" or something similar
   */
  override val quality: DemoQuality = DemoQuality.Low

  override fun prepareDemo(configuration: PredefinedConfiguration<Nothing>?): MeisterchartsDemo {
    return MeisterchartsDemo {
      meistercharts {
        val gestalt = BeamChartGestalt()
        gestalt.configure(this)


        configure {
          var lastClickedBeam = -1

          gestalt.beamsLayer.onBeamClicked { modelBeamIndex, _ ->
            lastClickedBeam = modelBeamIndex
            markAsDirty()
          }

          layers.addTexts { _, _ ->
            if (lastClickedBeam >= 0) {
              listOf("Last Clicked beam: $lastClickedBeam")
            } else {
              listOf()
            }
          }
        }


        configurableBoolean("Zones visible", gestalt.configuration::zonesLayerVisible)

        configurableInt("Beams Count", (gestalt.configuration.beamProvider as ExampleBeamProvider)::count) {
          max = 240
        }

        configurableEnum("Connector Location", gestalt.beamsLayer.configuration::connectorLocation, listOf(VerticalAlignment.Bottom, VerticalAlignment.Top))
      }
    }
  }
}
