package com.meistercharts.charts.sick.lisa

import com.meistercharts.algorithms.layers.LayerPaintingContext
import com.meistercharts.algorithms.layers.barchart.CategoryPainter
import com.meistercharts.algorithms.layers.barchart.CategoryPainterPaintingVariables
import com.meistercharts.annotations.Domain
import com.meistercharts.annotations.Window
import com.meistercharts.annotations.Zoomed
import com.meistercharts.canvas.ConfigurationDsl
import com.meistercharts.canvas.fill
import com.meistercharts.canvas.fillRectCoordinates
import com.meistercharts.canvas.stroke
import com.meistercharts.charts.sick.lisa.LisaChartGestalt.LisaDataType
import com.meistercharts.color.Color
import com.meistercharts.color.ColorProvider
import com.meistercharts.design.Theme
import com.meistercharts.design.valueAt
import com.meistercharts.model.category.CategoryIndex
import com.meistercharts.model.category.CategorySeriesModel
import com.meistercharts.model.category.valuesAt
import com.meistercharts.provider.ValueRangeProvider
import com.meistercharts.range.ValueRange
import it.neckar.geometry.Orientation
import it.neckar.open.kotlin.lang.letIfFinite
import it.neckar.open.unit.other.px

/**
 * Paints the categories for a lisa diagram
 */
class LisaCategoryPainter(
  styleConfiguration: Style.() -> Unit = {},
) : CategoryPainter<CategorySeriesModel> {

  val style: Style = Style().also(styleConfiguration)

  override fun paintingVariables(): CategoryPainterPaintingVariables {
    return paintingVariables
  }

  private val paintingVariables = object : CategoryPainterPaintingVariables {
    /**
     * The actual size of the bar
     */
    override var actualSize: @Zoomed Double = 0.0
  }

  override fun layout(paintingContext: LayerPaintingContext, categorySize: Double, categoryModel: CategorySeriesModel, categoryOrientation: Orientation) {
    paintingVariables.actualSize = categorySize
  }

  override fun paintCategoryVertical(
    paintingContext: LayerPaintingContext,
    categoryWidth: Double,
    categoryIndex: CategoryIndex,
    isLast: Boolean,
    categoryModel: CategorySeriesModel,
  ) {
    val valuesProvider = categoryModel.valuesAt(categoryIndex)

    if (valuesProvider.isEmpty()) {
      return
    }

    val valueRange = style.strengthThresholdValueRange()

    val chartCalculator = paintingContext.chartCalculator
    val gc = paintingContext.gc

    @Window val baseY = chartCalculator.domainRelative2windowY(0.0)
    @Window val fromX = 0.0 - categoryWidth / 2.0 //left
    @Window val toX = 0.0 + categoryWidth / 2.0 //right

    @Domain val teachInSignal = LisaDataType.TeachInSignal.getFrom(valuesProvider)
    @Domain val thresholdOn = LisaDataType.ThresholdOn.getFrom(valuesProvider)
    @Domain val thresholdOff = LisaDataType.ThresholdOff.getFrom(valuesProvider)
    @Domain val thresholdMonitor = LisaDataType.ThresholdMonitor.getFrom(valuesProvider)

    //Paint the signal strength
    LisaDataType.SignalStrength.getFrom(valuesProvider).letIfFinite { signalStrength: @Domain Double ->
      val color: ColorProvider = if (style.signalsColored) {
        when {
          signalStrength > thresholdMonitor || thresholdMonitor.isNaN() -> style.signalStrengthColorOk
          signalStrength > thresholdOn || thresholdOn.isNaN() -> style.signalStrengthColorYellow
          signalStrength > thresholdOff || thresholdOff.isNaN() -> style.signalStrengthColorOrange
          else -> style.signalStrengthColorRed
        }
      } else {
        style.signalStrengthColorOk
      }

      gc.fill(color)
      gc.fillRectCoordinates(fromX + style.barMarginSide, baseY, toX - style.barMarginSide, chartCalculator.domain2windowY(signalStrength, valueRange))
    }

    //Gain as horizontal line
    if (style.thresholdGainVisible) {
      LisaDataType.Gain.getFrom(valuesProvider).letIfFinite { gain ->
        @Window val gainOnY = chartCalculator.domain2windowY(gain, style.gainValueRange())
        gc.stroke(style.gainColor)
        gc.lineWidth = 3.0
        gc.strokeLine(fromX, gainOnY, toX, gainOnY)
      }

      //Teaching signal level
      teachInSignal.letIfFinite { thresholdOn ->
        @Window val y = chartCalculator.domain2windowY(thresholdOn, valueRange)
        gc.stroke(style.teachInSignalColor)
        gc.lineWidth = 3.0
        gc.strokeLine(fromX, y, toX, y)
      }

      //Threshold On
      thresholdOn.letIfFinite { thresholdOn ->
        @Window val y = chartCalculator.domain2windowY(thresholdOn, valueRange)
        gc.stroke(style.thresholdOnColor)
        gc.lineWidth = 3.0
        gc.strokeLine(fromX, y, toX, y)
      }

      //Threshold Off
      thresholdOff.letIfFinite { thresholdOff ->
        @Window val y = chartCalculator.domain2windowY(thresholdOff, valueRange)
        gc.stroke(style.thresholdOffColor)
        gc.lineWidth = 3.0
        gc.strokeLine(fromX, y, toX, y)
      }

      //Threshold Monitor
      thresholdMonitor.letIfFinite { thresholdMonitor ->
        @Window val y = chartCalculator.domain2windowY(thresholdMonitor, valueRange)
        gc.stroke(style.thresholdMonitorColor)
        gc.lineWidth = 3.0
        gc.strokeLine(fromX, y, toX, y)
      }
    }
  }

  override fun paintCategoryHorizontal(paintingContext: LayerPaintingContext, categoryHeight: Double, categoryIndex: CategoryIndex, isLast: Boolean, categoryModel: CategorySeriesModel) {
    throw UnsupportedOperationException("horizontal painting not implemented!")
  }


  @ConfigurationDsl
  class Style {
    /**
     * The bar margin (for each side)
     */
    var barMarginSide: @px Double = 3.0

    /**
     * The value range used for signal strength and threshold
     */
    var strengthThresholdValueRange: ValueRangeProvider = { ValueRange.default }
    var gainValueRange: ValueRangeProvider = { ValueRange.default }

    /**
     * The default color for signal strengths
     */
    var signalStrengthColorOk: ColorProvider = Theme.chartColors.valueAt(0)
    var signalStrengthColorYellow: ColorProvider = Color.yellow
    var signalStrengthColorOrange: ColorProvider = Color.orange
    var signalStrengthColorRed: ColorProvider = Color.red

    var teachInSignalColor: ColorProvider = Theme.chartColors.valueAt(6)
    var gainColor: ColorProvider = Theme.chartColors.valueAt(4)

    var thresholdOnColor: ColorProvider = Color.orange
    var thresholdOffColor: ColorProvider = Color.red
    var thresholdMonitorColor: ColorProvider = Color.yellow

    var thresholdGainVisible: Boolean = true

    /**
     * If set to true, the signal bars are colored depending on the value relative to the thresholds
     */
    var signalsColored: Boolean = true
  }
}
