package com.meistercharts.charts.lizergy.stringsPlanning

import com.benasher44.uuid.uuidFrom
import com.meistercharts.charts.lizergy.roofPlanning.ModuleAreas
import com.meistercharts.charts.lizergy.roofPlanning.ModuleIndex

/**
 *
 */
object PvStringsSampleModels {

  fun getSimpleString(moduleAreas: ModuleAreas): PvStringsPlanningModel {
    return PvStringsPlanningModel().also { model ->
      val moduleArea = moduleAreas.moduleAreas.first()
      model.addRoofString(
        RoofString(
          uuid = uuidFrom("c8d9a8a2-6bf8-46b8-ab8f-1e6e058d649b"),
          moduleLayoutUuid = uuidFrom("f30dfd8a-f8cf-48c6-97cc-b26a46c119e7"),
          inverterIndex = 0,
          mpptInputIndex = 0,
          stringIndex = StringIndex(0),
          previousModulesCount = 0,
          followingModulesCount = 0,
          initialModules = listOf(
            moduleArea.getModule(ModuleIndex(0, 0)),
            moduleArea.getModule(ModuleIndex(0, 1)),
          )
        )
      )
    }
  }

}
