package com.meistercharts.charts.lizergy.modulePlanning

import com.meistercharts.charts.lizergy.roofPlanning.Clickable
import com.meistercharts.charts.lizergy.roofPlanning.Module
import com.meistercharts.charts.lizergy.roofPlanning.ModuleArea
import com.meistercharts.charts.lizergy.roofPlanning.PvElementType
import com.meistercharts.charts.lizergy.roofPlanning.Resizable
import com.meistercharts.charts.lizergy.roofPlanning.RoofSelection
import com.meistercharts.charts.lizergy.roofPlanning.UnusableArea

/**
 * Sealed interface for all possible UI states for the [PvModulePlanningLayer]
 */
sealed interface RoofPlanningUiState {
  val data: PvModulePlanningLayer.Configuration

  /**
   * The current selection
   */
  val roofSelection: RoofSelection

  /**
   * Is called on a start drag event.
   * This method returns the updated ui state (if there is one)
   */
  fun startDragging(clickable: Clickable): RoofPlanningUiState {
    return Dragging(data, clickable)
  }

  /**
   * Is called on mouse up event
   */
  fun mouseUp(): RoofPlanningUiState {
    return this
  }

  /**
   * Mouse has been moved out of the canvas
   */
  fun movedOutOfCanvas(): RoofPlanningUiState {
    return this
  }

  /**
   * Move over nothing (but within the canvas)
   */
  fun moveOverNothing(): RoofPlanningUiState {
    return this
  }

  /**
   * Mouse is hovering above the delete button of an unusable area
   */
  fun hoveringOver(clickable: Clickable): RoofPlanningUiState {
    return HoveringAndButtonsVisible(data, clickable, roofSelection)
  }

  fun hoveringOverDeleteAction(clickable: Clickable): RoofPlanningUiState {
    return ArmedForDelete(data, clickable, roofSelection)
  }

  fun hoveringOverRotateAction(clickable: Clickable): RoofPlanningUiState {
    return ArmedForRotate(data, clickable, roofSelection)
  }

  fun downOnAction(clickable: Clickable): RoofPlanningUiState {
    return this
  }

  fun downOn(clickable: Clickable): RoofPlanningUiState {
    return this
  }

  fun startResizing(resizable: Resizable): RoofPlanningUiState {
    return Resizing(data, resizable)
  }

  fun resizingFinished(): RoofPlanningUiState {
    return this
  }

  /**
   * Down somewhere without any special areas
   */
  fun downOnNothing(): RoofPlanningUiState {
    return DefaultState(data, RoofSelection.Companion.empty)
  }

  /**
   * Formats the roof selection
   */
  fun formatSelection(): String {
    return if (roofSelection.isEmpty()) {
      "-"
    } else {
      "TODO"
    }
  }
}

/**
 * Base class that holds an active element
 */
sealed class UiStateWithActiveElement(
  override val data: PvModulePlanningLayer.Configuration,
  val clickable: Clickable?,
) : RoofPlanningUiState {

  val clickableNonNull: Clickable
    get() {
      requireNotNull(clickable) {
        "Clickable must not be null"
      }
      return clickable
    }

  /**
   * Returns the type of the element that is active (e.g. hovering over or dragged)
   */
  val activeElementType: PvElementType
    get() {
      return when (clickable) {
        is UnusableArea -> PvElementType.UnusableArea
        is Module, is ModuleArea -> PvElementType.ModuleArea
        else -> throw IllegalStateException("unusable area or module required")
      }
    }

  /**
   * Returns true if the active unusable area matches the given unusable area
   */
  fun isActive(clickable: Clickable): Boolean {
    return this.clickable == clickable ||
        (this.clickable is Module && this.clickable.modulePlacement.moduleArea == clickable) ||
        (clickable is Module && this.clickable == clickable.modulePlacement.moduleArea)
  }

  /**
   * Returns true for an exact match or if the grid matches [matchesForGrid]
   */
  fun matchesLoose(module: Module): Boolean {
    return module.deleted.not() && (isActive(module) || (clickable is Module && clickable.modulePlacement.moduleArea == module.modulePlacement.moduleArea))
  }

  override fun toString(): String {
    val formatSelection = formatSelection()
    return "${this::class.simpleName}: $activeElementType: [$formatSelection]"
  }
}

/**
 * Default state that does not have any special elements selected or anything at all
 */
class DefaultState(
  override val data: PvModulePlanningLayer.Configuration,
  /**
   * The roof selection
   */
  override val roofSelection: RoofSelection,
) : RoofPlanningUiState {

  override fun toString(): String {
    val formatSelection = formatSelection()
    return "${this::class.simpleName}: [$formatSelection]"
  }
}

/**
 * Hovering above an element with a visible buttons:
 * * remove button
 * * rotate for manual modules
 */
class HoveringAndButtonsVisible(
  data: PvModulePlanningLayer.Configuration,
  clickable: Clickable?,
  override val roofSelection: RoofSelection,
) : UiStateWithActiveElement(data, clickable),
  RoofPlanningUiState {

  override fun moveOverNothing(): RoofPlanningUiState {
    return DefaultState(data, roofSelection)
  }

  override fun movedOutOfCanvas(): RoofPlanningUiState {
    return DefaultState(data, roofSelection)
  }
}

/**
 * Hovering above the delete button. Ready to be deleted
 */
class ArmedForDelete(
  data: PvModulePlanningLayer.Configuration,
  clickable: Clickable?,
  override val roofSelection: RoofSelection,
) : UiStateWithActiveElement(data, clickable),
  RoofPlanningUiState {

  override fun downOnAction(clickable: Clickable): RoofPlanningUiState {
    require(clickable == this.clickable) {
      "Areas do not match: ${this.clickable} -- $clickable"
    }

    when (clickable) {
      is UnusableArea -> data.model.removeUnusableArea(clickable)
      is Module -> clickable.deleted = clickable.deleted.not()
      is ModuleArea -> {
        clickable.clear()
        data.model.moduleAreas.remove(clickable)
      }
    }

    return DefaultState(data, RoofSelection.Companion.empty)
  }

  override fun moveOverNothing(): RoofPlanningUiState {
    return DefaultState(data, roofSelection)
  }

  override fun movedOutOfCanvas(): RoofPlanningUiState {
    return DefaultState(data, roofSelection)
  }
}

/**
 * Hovering above the delete button. Ready to be deleted
 */
class ArmedForRotate(
  data: PvModulePlanningLayer.Configuration,
  clickable: Clickable?,
  override val roofSelection: RoofSelection,
) : UiStateWithActiveElement(data, clickable),
  RoofPlanningUiState {

  override fun downOnAction(clickable: Clickable): RoofPlanningUiState {
    require(clickable == this.clickable) {
      "Areas do not match: ${this.clickable} -- $clickable"
    }
    require(clickable is ModuleArea) {
      "Only ModuleAreas can be ArmedForRotate"
    }

    clickable.rotate()

    return DefaultState(data, roofSelection)
  }

  override fun moveOverNothing(): RoofPlanningUiState {
    return DefaultState(data, roofSelection)
  }

  override fun movedOutOfCanvas(): RoofPlanningUiState {
    return DefaultState(data, roofSelection)
  }
}

/**
 * An element is currently being dragged
 */
class Dragging(
  data: PvModulePlanningLayer.Configuration,
  clickable: Clickable,
) : UiStateWithActiveElement(data, clickable),
  RoofPlanningUiState {

  override val roofSelection: RoofSelection = RoofSelection.Companion.empty

  override fun mouseUp(): RoofPlanningUiState {
    //Stop dragging
    return HoveringAndButtonsVisible(data, clickableNonNull, RoofSelection(clickable))
  }
}

/**
 * Hovering above a "deleted" grid module - the add button is visible
 */
class HoveringOverDeletedGridModuleAddButtonVisible(
  data: PvModulePlanningLayer.Configuration,
  resizable: Resizable?,
  override val roofSelection: RoofSelection = RoofSelection(resizable),
) : UiStateWithActiveElement(data, resizable),
  RoofPlanningUiState {

  override fun moveOverNothing(): RoofPlanningUiState {
    return DefaultState(data, roofSelection)
  }

  override fun movedOutOfCanvas(): RoofPlanningUiState {
    return DefaultState(data, roofSelection)
  }
}

/**
 * Hovering above the add button on a "deleted" grid module
 */
class ArmedForAdd private constructor(
  data: PvModulePlanningLayer.Configuration,
  resizable: Resizable?,
) : UiStateWithActiveElement(data, resizable),
  RoofPlanningUiState {

  override val roofSelection: RoofSelection = RoofSelection.Companion.empty

  override fun moveOverNothing(): RoofPlanningUiState {
    return DefaultState(data, roofSelection)
  }

  override fun movedOutOfCanvas(): RoofPlanningUiState {
    return DefaultState(data, roofSelection)
  }
}

/**
 * Currently in the process of resizing
 */
class Resizing(
  data: PvModulePlanningLayer.Configuration,
  resizable: Resizable,
) : UiStateWithActiveElement(data, resizable),
  RoofPlanningUiState {

  override val roofSelection: RoofSelection = RoofSelection(resizable)

  override fun resizingFinished(): RoofPlanningUiState {
    return HoveringAndButtonsVisible(data, clickableNonNull, roofSelection)
  }
}

