package it.neckar.financial.currency.io

import it.neckar.financial.currency.Money
import kotlinx.serialization.KSerializer
import kotlinx.serialization.builtins.serializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

/**
 * Compatible serializer - since we used to serialize the money object als cents (Int)
 */
object MoneySerializer : KSerializer<Money> {
  override val descriptor: SerialDescriptor = Double.serializer().descriptor

  override fun serialize(encoder: Encoder, value: Money) {
    encoder.encodeLong(value.cents)
  }

  override fun deserialize(decoder: Decoder): Money {
    val cents = decoder.decodeLong() //Decode with double - works with Int and Long, too
    return Money.cents(cents)
  }
}
