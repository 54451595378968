/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.demo.descriptors


import com.benasher44.uuid.Uuid
import com.benasher44.uuid.uuidFrom
import com.meistercharts.algorithms.layers.AbstractLayer
import com.meistercharts.algorithms.layers.LayerPaintingContext
import com.meistercharts.algorithms.layers.LayerType
import com.meistercharts.algorithms.layers.addBackgroundChecker
import com.meistercharts.algorithms.layers.addShowLoadingOnMissingResources
import com.meistercharts.demo.DemoCategory
import com.meistercharts.demo.MeisterchartsDemo
import com.meistercharts.demo.MeisterchartsDemoDescriptor
import com.meistercharts.demo.PredefinedConfiguration
import com.meistercharts.resources.LocalResourcePaintable
import it.neckar.open.http.Url

/**
 *
 */
class ResourceLoadingDemoDescriptor : MeisterchartsDemoDescriptor<Nothing> {
  override val uuid: Uuid = uuidFrom("43c230b3-5eac-43e3-ad02-a0a0365c5dc8")
  override val name: String = "Resources Loading"

  override val category: DemoCategory = DemoCategory.LowLevelTests

  override fun prepareDemo(configuration: PredefinedConfiguration<Nothing>?): MeisterchartsDemo {
    return MeisterchartsDemo {
      meistercharts {
        configure {
          layers.addBackgroundChecker()


          val resourceLoadingLayer = object : AbstractLayer() {
            override val type: LayerType = LayerType.Content

            val localResourcePaintable = LocalResourcePaintable(Url.relative("elevator/elevator.png"))

            override fun paint(paintingContext: LayerPaintingContext) {
              localResourcePaintable.paint(paintingContext, 10.0, 10.0)
            }
          }

          layers.addLayer(resourceLoadingLayer)
          layers.addShowLoadingOnMissingResources()
        }
      }
    }
  }
}
