package it.neckar.elektromeister.rest.components

import it.neckar.rest.annotations.RestAPI
import kotlin.js.JsExport

/**
 * Defines the type of electrical components
 *
 * This enum matches the class hierarchy of [ElectricalComponentType]
 */
@JsExport
@RestAPI
enum class ElectricalComponentType(val productionReady: Boolean) {
  CircuitBreaker(false),
  Doorbell(false),
  EthernetSocket(false),
  GarageDoorOpener(false),
  LightFixture(true),
  LightSwitch(true),
  MotionSensor(false),
  PowerDistributionBoard(false),
  SmokeDetector(false),
  SocketOutlet(true),
  Thermostat(true),
}
