/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.data.client

import com.benasher44.uuid.uuidOf
import com.meistercharts.data.rest.ClientId
import com.meistercharts.data.rest.DataServerUrlSupport
import com.meistercharts.data.rest.HistoryBucketRestResponse
import com.meistercharts.history.HistoryBucket
import com.meistercharts.history.HistoryBucketDescriptor
import com.meistercharts.history.HistoryConfiguration
import com.meistercharts.history.HistoryUpdateInfo
import com.meistercharts.history.SamplingPeriod
import com.meistercharts.history.rest.HistoryBucketList
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.statement.*
import it.neckar.ktor.client.get
import it.neckar.ktor.client.post
import it.neckar.ktor.client.putJson
import it.neckar.open.kotlin.lang.random
import it.neckar.open.unit.si.ms

/**
 * Provides access to the history data - for a client
 *
 */
class DataClient(val client: HttpClient, port: Int) {
  val urlSupport: DataServerUrlSupport = DataServerUrlSupport(port)
  val clientId: ClientId = ClientId(uuidOf(random.nextBytes(16)))

  suspend fun get(samplingPeriod: SamplingPeriod, start: Double): HistoryBucket? {
    val received = client.get(urlSupport.getUrl(samplingPeriod.distance.toInt(), start)).body<HistoryBucketRestResponse>()
    return received.createHistoryBucket()
  }

  suspend fun get(descriptor: HistoryBucketDescriptor): HistoryBucket? {
    return get(descriptor.bucketRange.samplingPeriod, descriptor.start)
  }

  suspend fun query(samplingPeriod: SamplingPeriod, from: @ms Double, to: @ms Double): HistoryBucketList {
    return client.get(urlSupport.queryUrl(samplingPeriod.distance, from, to)).body()
  }

  suspend fun put(bucket: HistoryBucket): HttpResponse {
    return client.putJson(urlSupport.putUrl, bucket)
  }

  suspend fun getConfig(): HistoryConfiguration {
    return client.get(urlSupport.getConfigUrl).body()
  }


  suspend fun registerAtServer(): HttpResponse {
    return client.post(urlSupport.registerUrl(clientId))
  }

  suspend fun deregisterAtServer(): HttpResponse {
    return client.post(urlSupport.deregisterUrl(clientId))
  }

  suspend fun getUpdates(): MutableList<HistoryUpdateInfo> {
    return client.get(urlSupport.getUpdatesUrl(clientId)).body<MutableList<HistoryUpdateInfo>>()
  }

}
