/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.elektromeister

import com.meistercharts.elektromeister.model.MutablePlacedElectricalComponent
import com.meistercharts.range.LinearValueRange
import it.neckar.elektromeister.rest.PlacedElectricalComponent
import it.neckar.elektromeister.rest.Room
import it.neckar.geometry.Coordinates
import it.neckar.open.provider.SizedProvider
import it.neckar.open.unit.si.mm

/**
 * Base class for (all) configuration objects related to Elektromeister.
 */
abstract class AbstractElektromeisterConfiguration {
  /**
   * The model that holds the data of the Elektromeister.
   * The model is updated on changes
   */
  abstract val model: ElektromeisterMeisterchartsModel

  /**
   * Returns the rooms
   */
  val rooms: SizedProvider<Room> = SizedProvider.forListProvider {
    model.floorPlan.rooms
  }

  /**
   * Provides the electrical components
   */
  val electricalComponents: SizedProvider<MutablePlacedElectricalComponent>
    get() {
      return model.electricalComponents
    }

  val roomFinder: (coordinates: @mm Coordinates) -> Room?
    get() {
      return model.roomFinder
    }

  val rangeX: @mm LinearValueRange
    get() {
      return model.rangeX
    }

  val rangeY: @mm LinearValueRange
    get() {
      return model.rangeY
    }

}
