/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.demo.descriptors


import com.benasher44.uuid.Uuid
import com.benasher44.uuid.uuidFrom
import com.meistercharts.range.ValueRange
import com.meistercharts.zoom.FittingWithMargin
import com.meistercharts.algorithms.layers.axis.ValueAxisLayer
import com.meistercharts.algorithms.layers.addClearBackground
import com.meistercharts.algorithms.layers.createGrid
import com.meistercharts.algorithms.layers.linechart.Dashes
import com.meistercharts.algorithms.layers.linechart.LineStyle
import com.meistercharts.demo.MeisterchartsDemo
import com.meistercharts.demo.MeisterchartsDemoDescriptor
import com.meistercharts.demo.DemoCategory
import com.meistercharts.demo.PredefinedConfiguration
import com.meistercharts.demo.configurableColor
import com.meistercharts.demo.configurableDouble
import com.meistercharts.demo.configurableList
import com.meistercharts.demo.createEnumConfigs
import com.meistercharts.model.Insets
import it.neckar.geometry.Side
import com.meistercharts.model.Vicinity
import it.neckar.open.kotlin.lang.asProvider
import it.neckar.open.kotlin.lang.asProvider1

/**
 */
class GridWithValueAxisDemoDescriptor() : MeisterchartsDemoDescriptor<Side> {
  override val uuid: Uuid = uuidFrom("6464f2fd-b62c-4ca8-8232-9a176dfce040")
  override val name: String = "Grid + Value Axis"
  override val description: String = "Grid connected to a value axis"
  override val category: DemoCategory = DemoCategory.Layers

  override val predefinedConfigurations: List<PredefinedConfiguration<Side>> = createEnumConfigs(uuid)

  override fun prepareDemo(configuration: PredefinedConfiguration<Side>?): MeisterchartsDemo {
    require(configuration != null)
    val axisSide: Side = configuration.payload

    return MeisterchartsDemo {
      meistercharts {

        val insets = when (axisSide) {
          Side.Left -> Insets(0.0, 0.0, 0.0, 90.0)
          Side.Right -> Insets(0.0, 90.0, 0.0, 0.0)
          Side.Top -> Insets(60.0, 0.0, 0.0, 0.0)
          Side.Bottom -> Insets(0.0, 0.0, 60.0, 0.0)
        }

        zoomAndTranslationDefaults {
          FittingWithMargin(insets)
        }

        configure {
          layers.addClearBackground()

          val valueRange = ValueRange.linear(-7.0, 92.5)
          val valueAxisLayer = ValueAxisLayer(ValueAxisLayer.Configuration(valueRangeProvider = { valueRange })) {
            titleProvider = { _, _ -> "Performance [c/h²]" }
            size = insets[axisSide]
            tickOrientation = Vicinity.Outside
            side = axisSide
          }

          val gridLayer = valueAxisLayer.createGrid() {
            passpartout = insets
          }

          layers.addLayer(valueAxisLayer)
          layers.addLayer(gridLayer)

          var (gridColor, gridLineWidth, gridLineStyle) = gridLayer.configuration.lineStyles(0.0)

          configurableColor("Grid Color", gridColor()) {
            onChange {
              gridColor = it.asProvider()
              gridLayer.configuration.lineStyles = LineStyle(color = gridColor, lineWidth = gridLineWidth, dashes = gridLineStyle).asProvider1()
              markAsDirty()
            }
          }

          configurableDouble("Line Width", gridLineWidth) {
            max = 10.0

            onChange {
              gridLineWidth = it
              gridLayer.configuration.lineStyles = LineStyle(color = gridColor, lineWidth = gridLineWidth, dashes = gridLineStyle).asProvider1()
              markAsDirty()
            }
          }

          configurableList("Line style", gridLineStyle, Dashes.predefined) {
            onChange {
              gridLineStyle = it
              gridLayer.configuration.lineStyles = LineStyle(color = gridColor, lineWidth = gridLineWidth, dashes = gridLineStyle).asProvider1()
              markAsDirty()
            }
          }
        }
      }
    }
  }
}
