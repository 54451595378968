package it.neckar.resource.loading

import it.neckar.logging.debug
import it.neckar.open.http.Url
import kotlinx.browser.window
import kotlinx.coroutines.*
import org.w3c.fetch.RequestInit
import org.w3c.fetch.Response


/**
 * Loads a resource into a byte array
 */
actual suspend fun loadResource(url: Url): ByteArray {
  return fetchByteArray(url)
}

/**
 * Fetches a resource as byte array from the provided URL
 */
suspend fun fetchByteArray(url: Url): ByteArray {
  val fetch = window.fetch(url)
  val response = fetch.await()
  val responseBody = response.body.await()

  logger.debug { "response code: ${response.status} \n" }
  logger.debug { "response code text: ${response.statusText} \n" }
  logger.debug { "response: $response \n" }
  logger.debug { "response text: $responseBody \n" }

  return if (response.ok) responseBody as ByteArray else throw createException(response)
}


suspend fun <T> request(url: Url, method: String = "GET", body: dynamic = null): T {
  val response = window.fetch(url.toString(), object : RequestInit {
    override var method: String? = method
    override var body: dynamic = body
    //override var headers: dynamic = json("Accept" to "application/json")
  }).await()
  val responseText = response.text().await()

  logger.debug { "response code: ${response.status} \n" }
  logger.debug { "response code text: ${response.statusText} \n" }
  logger.debug { "response: $response \n" }
  logger.debug { "response text: $responseText \n" }

  return if (response.ok) responseText as T else throw createException(response)
}

class FetchException(message: String, val status: Short, val response: dynamic) : Exception(message)

/**
 * Creates (but does not throw) a [FetchException] from a [Response]
 */
fun createException(response: Response): FetchException {
  val errorResponse: dynamic = response.text()
  return FetchException("Request failed: ${response.status}, ${response.statusText}", response.status, errorResponse)
}


