package com.meistercharts.charts.lizergy.solar

import com.meistercharts.algorithms.layers.AbstractLayer
import com.meistercharts.algorithms.layers.LayerPaintingContext
import com.meistercharts.algorithms.layers.LayerType
import com.meistercharts.algorithms.layers.addClearBackground
import com.meistercharts.annotations.Zoomed
import com.meistercharts.canvas.ConfigurationDsl
import it.neckar.geometry.AxisOrientationY
import com.meistercharts.font.FontDescriptorFragment
import com.meistercharts.font.FontSize
import com.meistercharts.canvas.MeisterchartBuilder
import com.meistercharts.canvas.fillRectCoordinates
import com.meistercharts.canvas.paintTextBox
import com.meistercharts.canvas.saved
import com.meistercharts.canvas.stroke
import com.meistercharts.charts.ChartGestalt
import com.meistercharts.charts.FixedChartGestalt
import com.meistercharts.color.Color
import it.neckar.geometry.Direction
import com.meistercharts.model.Insets
import com.meistercharts.range.ValueRange
import it.neckar.financial.currency.Money
import it.neckar.financial.currency.euro
import it.neckar.open.unit.other.px

/**
 * Shows the savings on the first page of the Lizergy PDF
 */
class PvSavingsGestalt(
  val configuration: Configuration,
  additionalConfiguration: Configuration.() -> Unit = {}
) : ChartGestalt {

  constructor(
    feedInCompensation: () -> Money,
    savingsOwnConsumption: () -> Money,
    saved: () -> Money,
    additionalConfiguration: Configuration.() -> Unit = {}
  ): this(Configuration(feedInCompensation, savingsOwnConsumption, saved), additionalConfiguration)


  init {
    //
    // ATTENTION: Register listeners in init block to ensure all objects have been created
    //
    configuration.additionalConfiguration()
  }

  val fixedChartGestalt: FixedChartGestalt = FixedChartGestalt(Insets.onlyBottom(2.0))

  val pvSavingsLayer: PvSavingsLayer = PvSavingsLayer(configuration)

  override fun configure(meisterChartBuilder: MeisterchartBuilder) {
    with(meisterChartBuilder) {
      fixedChartGestalt.configure(meisterChartBuilder)

      configure {
        layers.addClearBackground()
        layers.addLayer(pvSavingsLayer)

        chartSupport.rootChartState.axisOrientationY = AxisOrientationY.OriginAtBottom
      }
    }
  }

  /**
   * The configuration (data and style).
   *
   * Attention:
   * This is required:
   * [feedInCompensation] + [savingsOwnConsumption]  == [pvSpending] + [saved]
   */
  @ConfigurationDsl
  class Configuration(
    /**
     * "Einspeisevergütung"
     */
    var feedInCompensation: () -> Money,

    /**
     * "Ersparnis Eigenverbrauch"
     */
    var savingsOwnConsumption: () -> Money,

    /**
     * "gespart"
     */
    var saved: () -> Money,
  ) {
    constructor(
      /**
       * "Einspeisevergütung"
       */
      feedInCompensation: Money = 150.0.euro,

      /**
       * "Ersparnis Eigenverbrauch"
       */
      savingsOwnConsumption: Money = 130.0.euro,

      /**
       *gespart
       */
      saved: Money = 130.0.euro,
    ) : this({ feedInCompensation }, { savingsOwnConsumption }, { saved })

    /**
     * The total height of the bars
     */
    fun total(): Money = feedInCompensation() + savingsOwnConsumption()

    /**
     * "Photovoltaik-Ausgaben"
     */
    fun pvSpending(): Money {
      return total() - saved()
    }

    /**
     * Returns the value range
     */
    val valueRange: ValueRange
      get() {
        //The value range
        val total = total()
        return ValueRange.linear(0.0, total.euros)
      }
  }
}

class PvSavingsLayer(
  val data: PvSavingsGestalt.Configuration,
  styleConfiguration: Style.() -> Unit = {}
) : AbstractLayer() {
  override val type: LayerType = LayerType.Content

  val style: Style = Style().also(styleConfiguration)

  override fun paint(paintingContext: LayerPaintingContext) {
    val gc = paintingContext.gc
    val chartCalculator = paintingContext.chartCalculator

    val feedInCompensation = data.feedInCompensation()
    val savingsOwnConsumption = data.savingsOwnConsumption()
    val pvSpending = data.pvSpending()
    val saved = data.saved()

    val valueRange = data.valueRange


    gc.font(style.font)

    //paint the axis line
    gc.stroke(Color.lightgray)
    gc.strokeLine(
      chartCalculator.domainRelative2windowX(0.0),
      chartCalculator.domainRelative2windowY(0.0),
      chartCalculator.domainRelative2windowX(1.0),
      chartCalculator.domainRelative2windowY(0.0),
    )

    //translate to center
    gc.translate(chartCalculator.domainRelative2windowX(0.5), 0.0)

    //paint the *right* bar
    gc.saved {
      //to the center of the bar
      gc.translate(style.gapBetweenBars / 2.0 + style.barWidthHalf, 0.0)

      //
      // Bottom bar: Savings own consumption
      //

      gc.fill(style.colorSavingsOwnConsumption)
      @Zoomed val startBottom = chartCalculator.domain2windowY(0.0, valueRange)
      @Zoomed val endBottom = chartCalculator.domain2windowY(savingsOwnConsumption.euros, valueRange) + style.gapBetweenSegments / 2.0
      gc.fillRectCoordinates(-style.barWidthHalf, startBottom, style.barWidthHalf, endBottom)

      //text
      gc.fillText("gesparte Stromkosten", style.barWidthHalf + style.labelGapX, endBottom.coerceIn(32.0, paintingContext.height - 32.0), Direction.TopLeft, 0.0, 0.0)//, maxHeight = paintingContext.height - endBottom)

      //Upper bar: feed in compensation

      gc.fill(style.colorFeedInCompensation)
      @Zoomed val startUpper = chartCalculator.domain2windowY(savingsOwnConsumption.euros, valueRange) - style.gapBetweenSegments / 2.0
      @Zoomed val endUpper = chartCalculator.domain2windowY((savingsOwnConsumption + feedInCompensation).euros, valueRange)
      gc.fillRectCoordinates(-style.barWidthHalf, startUpper, style.barWidthHalf, endUpper)

      //text
      gc.fillText("Einspeisevergütung", style.barWidthHalf + style.labelGapX, endUpper, Direction.TopLeft, 0.0, 0.0)
    }

    //Paint the *left* bar

    gc.saved {
      //to the center side of the bar
      gc.translate(-style.gapBetweenBars / 2.0 - style.barWidthHalf, 0.0)

      //
      // Bottom bar: Savings own consumption
      //

      gc.fill(style.colorPvSpending)
      @Zoomed val startBottom = chartCalculator.domain2windowY(0.0, valueRange)
      @Zoomed val endBottom = chartCalculator.domain2windowY(pvSpending.euros, valueRange)
      gc.fillRectCoordinates(-style.barWidthHalf, startBottom, style.barWidthHalf, endBottom)

      //text
      gc.fill(style.colorPvSpendingText)
      gc.fillText("Photovoltaik-Ausgaben", -style.barWidthHalf - style.labelGapX, endBottom.coerceAtMost(paintingContext.height - 32.0), Direction.TopRight, 0.0, 0.0)//, maxHeight = paintingContext.height - endBottom)

      //Upper bar: saved
      gc.stroke(style.colorSavings)
      gc.lineWidth = style.savingsLineWidth

      @Zoomed val startUpper = chartCalculator.domain2windowY(pvSpending.euros, valueRange)
      @Zoomed val endUpper = chartCalculator.domain2windowY((pvSpending + saved).euros, valueRange)

      //bottom horizontal
      val lineCenterStart = startUpper - style.savingsLineWidth / 2.0
      gc.strokeLine(-style.savingsEndMarkerWidthHalf, lineCenterStart, style.savingsEndMarkerWidthHalf, lineCenterStart)

      //top horizontal
      val lineCenterEnd = endUpper + style.savingsLineWidth / 2.0
      gc.strokeLine(-style.savingsEndMarkerWidthHalf, lineCenterEnd, style.savingsEndMarkerWidthHalf, lineCenterEnd)

      //Vertical line
      gc.strokeLine(0.0, lineCenterStart, 0.0, lineCenterEnd)

      gc.fill(style.colorSavings)

      gc.translate(-style.barWidthHalf - style.labelGapX, 0.0)
      gc.paintTextBox(listOf(saved.format(paintingContext.i18nConfiguration), "gespart"), Direction.TopRight, 0.0)
    }

  }

  class Style {
    /**
     * The gap between the label and the bar - horizontally
     */
    val labelGapX: @px Double = 10.0

    /**
     * The widths of the line that visualizes the saving
     */
    var savingsLineWidth: @px Double = 3.0

    /**
     * The (total) width of the marker at the start/end of the savings line
     */
    var savingsEndMarkerWidth: @px Double = 14.0

    val savingsEndMarkerWidthHalf: @px Double
      get() {
        return savingsEndMarkerWidth / 2.0
      }

    var gapBetweenBars: @px Double = 25.0
    var barWidth: @px Double = 20.0

    /**
     * The half width of the bar
     */
    val barWidthHalf: @px Double
      get() {
        return barWidth / 2.0
      }

    /**
     * The gap between two segments of one bar
     */
    var gapBetweenSegments: Double = @px 2.0

    var colorSavingsOwnConsumption: Color = Color.web("#4F7727")
    var colorFeedInCompensation: Color = Color.web("#75B73E")
    var colorPvSpending: Color = Color.web("#DADADA")
    var colorPvSpendingText: Color = Color.web("#373939")
    var colorSavings: Color = Color.web("#8AB056")

    val font: FontDescriptorFragment = FontDescriptorFragment(familyConfiguration = LizergyDesign.defaultFontFamily, size = FontSize(13.0))
  }
}
