/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.demo.descriptors


import com.benasher44.uuid.Uuid
import com.benasher44.uuid.uuidFrom
import com.meistercharts.charts.lizergy.solar.LizergyDesign
import com.meistercharts.charts.lizergy.solar.PvProfitGestalt
import com.meistercharts.demo.DemoCategory
import com.meistercharts.demo.DemoQuality
import com.meistercharts.demo.MeisterchartsDemo
import com.meistercharts.demo.MeisterchartsDemoDescriptor
import com.meistercharts.demo.PredefinedConfiguration
import com.meistercharts.demo.VariabilityType
import com.meistercharts.demo.configurableInsetsSeparate
import com.meistercharts.demo.configurableMoney
import com.meistercharts.design.setDefaultTheme
import it.neckar.open.provider.SizedProvider

class PvProfitDemoDescriptor : MeisterchartsDemoDescriptor<Nothing> {
  override val uuid: Uuid = uuidFrom("c9f5c645-f7e7-434c-986d-ba092a11ce7d")
  override val name: String = "Photovoltaics profit"
  override val category: DemoCategory = DemoCategory.Lizergy
  override val quality: DemoQuality = DemoQuality.High
  override val variabilityType: VariabilityType = VariabilityType.Stable

  override fun prepareDemo(configuration: PredefinedConfiguration<Nothing>?): MeisterchartsDemo {
    return MeisterchartsDemo {
      meistercharts {
        val data = PvProfitGestalt.Configuration()

        val pvProfitGestalt = PvProfitGestalt(data)
        pvProfitGestalt.configure(this)

        configure {
          setDefaultTheme(LizergyDesign)
          configurableInsetsSeparate("Margin", pvProfitGestalt.configuration.contentAreaMarginProperty) {
            min = 0.0
            max = 300.0
          }

          configurableMoney("Total Investment", data::totalInvestment) {
            max = 50_000.0
          }

          val demoCalculator = object {
            var profit = data.accumulatedCashFlowPerYear.last()
              set(value) {
                field = value

                //recalculate
                data.accumulatedCashFlowPerYear = SizedProvider.of(20) {
                  val basePoint = -data.totalInvestment
                  val delta = value - basePoint
                  return@of basePoint + delta / 20.0 * it
                }
              }
          }

          configurableMoney("Profit", demoCalculator::profit) {
            value = 10_000.0
            min = -20_000.0
            max = 50_000.0
          }
        }
      }
    }
  }

}
