/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.elektromeister

import com.meistercharts.algorithms.layers.LayerPaintingContext
import com.meistercharts.canvas.ChartSupport
import com.meistercharts.canvas.MouseCursorSupport
import com.meistercharts.canvas.mouseCursorSupport
import com.meistercharts.elektromeister.model.ElectricalComponentsFinder
import com.meistercharts.elektromeister.state.ElektromeisterUiStateMachine
import com.meistercharts.elektromeister.state.GestureContext
import com.meistercharts.events.EventConsumption
import it.neckar.events.KeyDownEvent
import it.neckar.events.MouseDownEvent
import it.neckar.events.MouseDragEvent
import it.neckar.events.MouseMoveEvent
import it.neckar.events.MouseUpEvent
import it.neckar.open.observable.select

/**
 * Handles all user interactions for Elektromeister
 */
class ElektromeisterInteractionHandler(
  /**
   * The model for the Elektromeister
   */
  val model: ElektromeisterMeisterchartsModel,
  /**
   * Finds the electrical component under the given coordinates
   */
  val electricalComponentsFinder: ElectricalComponentsFinder,
) {

  /**
   * Returns the state machine from the current model
   */
  val stateMachine: ElektromeisterUiStateMachine
    get() {
      return model.stateMachine
    }

  /**
   * Creates a new gesture context
   */
  private fun gestureContext(chartSupport: ChartSupport) = GestureContext(chartSupport, model, electricalComponentsFinder)

  /**
   * Initializes the interaction handler - must be called once at the start
   */
  fun initialize(paintingContext: LayerPaintingContext) {
    val cursorProperty = paintingContext.chartSupport.mouseCursorSupport.cursorProperty(this, MouseCursorSupport.Priority.Default)
    cursorProperty.bind(model.stateMachine.currentUiStateProperty.select { it.cursorProperty })
  }

  fun mouseMoved(event: MouseMoveEvent, chartSupport: ChartSupport): EventConsumption {
    return stateMachine.currentUiState.mouseMoved(event, gestureContext(chartSupport))
  }

  fun mouseDragged(event: MouseDragEvent, chartSupport: ChartSupport): EventConsumption {
    return stateMachine.currentUiState.mouseDragged(event, gestureContext(chartSupport))
  }

  fun mouseDown(event: MouseDownEvent, chartSupport: ChartSupport): EventConsumption {
    return stateMachine.currentUiState.mouseDown(event, gestureContext(chartSupport))
  }

  fun mouseUp(event: MouseUpEvent, chartSupport: ChartSupport): EventConsumption {
    return stateMachine.currentUiState.mouseUp(event, gestureContext(chartSupport))
  }

  fun onResetByKey(event: KeyDownEvent, chartSupport: ChartSupport): EventConsumption {
    return stateMachine.currentUiState.resetByKey(gestureContext(chartSupport))
  }

  fun onDeleteByKey(event: KeyDownEvent, chartSupport: ChartSupport): EventConsumption {
    return stateMachine.currentUiState.deleteByKey(gestureContext(chartSupport))
  }
}
