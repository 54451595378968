package com.meistercharts.charts.bioexp

import com.meistercharts.algorithms.layers.axis.MaxNumberOfTicksProvider
import com.meistercharts.algorithms.layers.axis.TickProvider
import com.meistercharts.algorithms.layers.barchart.GreedyCategoryAxisLabelPainter
import com.meistercharts.algorithms.layers.linechart.LineStyle
import com.meistercharts.algorithms.painter.SplineLinePainter
import com.meistercharts.canvas.MeisterchartBuilder
import com.meistercharts.charts.CategoryLineChartGestalt
import com.meistercharts.charts.ChartGestalt
import com.meistercharts.color.Color
import com.meistercharts.design.Theme
import com.meistercharts.design.valueAt
import com.meistercharts.model.Insets
import com.meistercharts.model.category.Category
import com.meistercharts.model.category.CategorySeriesModel
import com.meistercharts.model.category.DefaultCategorySeriesModel
import com.meistercharts.model.category.DefaultSeries
import com.meistercharts.painter.Cross45DegreesCategoryPointPainter
import com.meistercharts.painter.XyCategoryLinePainter
import com.meistercharts.range.ValueRange
import it.neckar.open.formatting.percentageFormat0digits
import it.neckar.open.i18n.TextKey
import it.neckar.open.kotlin.lang.fastMap
import it.neckar.open.math.FittedSigmoid.calculateRSquared
import it.neckar.open.math.FittedSigmoid.fitLogisticModel
import it.neckar.open.math.FittedSigmoid.generateSyntheticData
import it.neckar.open.math.FittedSigmoid.logistic
import it.neckar.open.provider.MultiProvider
import it.neckar.open.unit.other.pct

/**
 * A gestalt for BioExP, using the CategoryLineChartGestalt as a base. It displays the percentage of popped cells for each captured image.
 * @param initialCategorySeriesModel categories: Image 1, Image 2,... ; series: values in percentage
 */
class BioExPCategoryLineChartGestalt(
  /**
   * The current category model for the category line chart
   */
  initialCategorySeriesModel: CategorySeriesModel = createDefaultCategoryModel(),
) : ChartGestalt {
  val gestalt: CategoryLineChartGestalt = CategoryLineChartGestalt()

  init {
    gestalt.configuration.valueRange = ValueRange.percentage
    gestalt.configuration.crossWireValueLabelFormat = percentageFormat0digits
    gestalt.configuration.categorySeriesModel = initialCategorySeriesModel

    gestalt.categoryLinesLayer.configuration.pointPainters = MultiProvider.invoke { index ->
      if (index == 0) {
        Cross45DegreesCategoryPointPainter(snapXValues = false, snapYValues = false).also { painter ->
          painter.pointStylePainter.pointSize = 12.0
          painter.pointStylePainter.color = Color.black
        }
      } else {
        null
      }
    }

    gestalt.categoryLinesLayer.configuration.linePainters = MultiProvider.always(XyCategoryLinePainter(snapXValues = false, snapYValues = false, linePainterDelegate = SplineLinePainter(false, snapYValues = false)))

    //gestalt.categoryLinesLayer.configuration.lineStyles = MultiProvider.always(LineStyle(color = Theme.chartColors().valueAt(0), lineWidth = 1.0))
    gestalt.categoryLinesLayer.configuration.lineStyles = MultiProvider.invoke { index ->
      if (index == 0) {
        LineStyle(color = Theme.chartColors.valueAt(0), lineWidth = 0.001)
      } else {
        LineStyle(color = Theme.chartColors.valueAt(0), lineWidth = 1.0)
      }
    }

    gestalt.valueAxisLayer.configuration.ticks = MaxNumberOfTicksProvider(11, TickProvider.linear)

    gestalt.valueAxisLayer.configuration.ticksFormat = percentageFormat0digits
    gestalt.valueAxisLayer.configuration.titleProvider = { _, _ -> "Amount of burst cells in in %" }


    gestalt.categoryAxisLayer.configuration.showTicks()
    gestalt.categoryAxisLayer.configuration.axisLabelPainter = GreedyCategoryAxisLabelPainter()
    gestalt.contentViewportMargin = Insets.Companion.of(10.0, 10.0, 50.0, 80.0)
    gestalt.configuration.showValuesGrid = true
    gestalt.configuration.showCategoriesGrid = true

    gestalt.configuration.minCategorySize = 5.0
  }

  override fun configure(meisterChartBuilder: MeisterchartBuilder) {
    gestalt.configure(meisterChartBuilder)
  }

  val configuration: CategoryLineChartGestalt.Configuration by gestalt::configuration


  companion object {

    fun createDefaultCategoryModel(): CategorySeriesModel {
      val (x, p) = generateSyntheticData(variance = 0.2)
      val ydata = listOf(0.0656, 0.1232, 0.1312, 0.1008, 0.0, 0.1632, 0.1472, 0.1264, 0.0512, 0.2096, 0.4352, 0.5952, 0.6528, 0.7472, 0.9344, 0.9664, 0.9456)
      val xdata =  ydata.size.fastMap { it.toDouble() }

      val dataParams = fitLogisticModel(xdata, ydata)

      // Fit a logistic model to the data
      val params = fitLogisticModel(x, p)

      var y = x.map { logistic(it, params[0], params[1], params[2]) }

      var categories = x.map { Category(TextKey.simple("$it")) }
      var series = DefaultSeries(
        "Amount of burst cells in %",
        @pct p
      )

      //y = xdata.map { logistic(it, dataParams[0], dataParams[1], dataParams[2]) }
      //
      //categories = xdata.map { Category(TextKey.simple("$it")) }
      //series = DefaultSeries(
      //  "Amount of burst cells in %",
      //  @pct ydata
      //)

      val rSquared = calculateRSquared(p, y)
      println("R-squared: $rSquared")


      return DefaultCategorySeriesModel(
        categories,
        listOf(
          series,
          DefaultSeries(
            name = "Sigmoid",
            values = y
          )
        )
      )
    }
  }
}

