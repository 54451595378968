/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.demo.descriptors


import com.benasher44.uuid.Uuid
import com.benasher44.uuid.uuidFrom
import com.meistercharts.algorithms.layers.AbstractLayer
import com.meistercharts.algorithms.layers.LayerPaintingContext
import com.meistercharts.algorithms.layers.LayerType
import com.meistercharts.algorithms.layers.addBackgroundChecker
import com.meistercharts.algorithms.layers.addShowLoadingOnMissingResources
import com.meistercharts.demo.DemoCategory
import com.meistercharts.demo.MeisterchartsDemo
import com.meistercharts.demo.MeisterchartsDemoDescriptor
import com.meistercharts.demo.PredefinedConfiguration
import com.meistercharts.demo.configurableBoolean
import it.neckar.open.http.Url

/**
 *
 */
class MissingResourceHandlerDemoDescriptor : MeisterchartsDemoDescriptor<Nothing> {
  override val uuid: Uuid = uuidFrom("4d5f0479-f7ad-4b26-9924-b4bfe835a1e0")
  override val name: String = "Missing Resources Handler"

  override val category: DemoCategory = DemoCategory.LowLevelTests

  override fun prepareDemo(configuration: PredefinedConfiguration<Nothing>?): MeisterchartsDemo {
    return MeisterchartsDemo {
      meistercharts {
        configure {
          layers.addBackgroundChecker()

          val missingResourceLayer = object : AbstractLayer() {
            override val type: LayerType = LayerType.Content

            var resourceMissing = true

            override fun paint(paintingContext: LayerPaintingContext) {
              if (resourceMissing) {
                paintingContext.missingResources.reportMissing(Url.absolute("http://blafasel.com"))
              }
            }
          }
          layers.addLayer(missingResourceLayer)

          layers.addShowLoadingOnMissingResources()

          configurableBoolean("Resource missing", missingResourceLayer::resourceMissing)
        }
      }
    }
  }
}
