package com.meistercharts.charts.lizergy.solar

import com.meistercharts.canvas.paintable.ButtonColorProvider
import com.meistercharts.canvas.paintable.DefaultButtonColorProvider
import com.meistercharts.canvas.paintable.SingleButtonColorProvider
import com.meistercharts.color.Color
import com.meistercharts.color.ColorMapperNullable
import com.meistercharts.color.RgbaColor
import com.meistercharts.design.Theme
import com.meistercharts.font.FontDescriptorFragment
import com.meistercharts.font.FontFamily
import com.meistercharts.font.FontFamilyConfiguration
import com.meistercharts.font.FontSize
import com.meistercharts.font.FontStyle
import com.meistercharts.font.FontVariant
import com.meistercharts.font.FontWeight
import com.meistercharts.font.withSansSerif
import com.meistercharts.style.Palette
import it.neckar.open.kotlin.lang.asProvider
import it.neckar.open.provider.MultiProvider

/**
 * The theme for Lizergy
 */
object LizergyDesign : Theme {
  override val id: String = "Lizergy"

  val headlineFontFamily: FontFamilyConfiguration = FontFamily("myriad-pro").withSansSerif()
  val defaultFontFamily: FontFamilyConfiguration = FontFamily("myriad-pro").withSansSerif()

  override val primaryColor: RgbaColor = Color.web("#6EAC24").toRgba()

  override val primaryColorDarker: RgbaColor = Color.web("#4C781A").toRgba()
  override val primaryColorLighter: RgbaColor = Color.web("#79B825").toRgba()

  override val secondaryColor: RgbaColor = Color.web("#447618").toRgba()
  override val defaultLineColor: RgbaColor = Color.web("#447618").toRgba()

  override val h1: FontDescriptorFragment = FontDescriptorFragment(headlineFontFamily, FontSize(53.0), FontWeight.Normal, style = FontStyle.Normal, variant = FontVariant.Normal)
  override val h1Color: RgbaColor = primaryColorDarker

  override val h2: FontDescriptorFragment = FontDescriptorFragment(headlineFontFamily, FontSize(44.0), FontWeight.SemiBold, style = FontStyle.Normal, variant = FontVariant.Normal)
  override val h2Color: RgbaColor = primaryColorDarker

  override val h3: FontDescriptorFragment = FontDescriptorFragment(headlineFontFamily, FontSize(29.0), FontWeight.Normal, style = FontStyle.Normal, variant = FontVariant.Normal)
  override val h3Color: RgbaColor = primaryColor

  override val h4: FontDescriptorFragment = FontDescriptorFragment(headlineFontFamily, FontSize(22.0), FontWeight.Normal, style = FontStyle.Normal, variant = FontVariant.Normal)
  override val h4Color: RgbaColor = primaryColorDarker

  override val h5: FontDescriptorFragment = FontDescriptorFragment(headlineFontFamily, FontSize(15.0), FontWeight.SemiBold, style = FontStyle.Normal, variant = FontVariant.Normal)
  override val h5Color: RgbaColor = primaryColorDarker

  override val textFont: FontDescriptorFragment = FontDescriptorFragment(defaultFontFamily, FontSize(15.0), FontWeight.Normal, style = FontStyle.Normal, variant = FontVariant.Normal)
  override val textColor: RgbaColor = primaryColorDarker

  override val canvasBackgroundColor: RgbaColor = Color.white()
  override val primaryBackgroundColor: RgbaColor = Color.web("#F5F9ED").toRgba()
  override val secondaryBackgroundColor: RgbaColor = Color.web("#56891D").toRgba()

  override val backgroundColorActive: RgbaColor = Color.silver().withAlpha(0.5)
  override val backgroundZebraColors: MultiProvider<Any, RgbaColor> = MultiProvider.Companion.forListModulo(
    listOf(
      Color.web("#DBE1E5").toRgba(),
      Color.web("#F3F5F7").toRgba()
    )
  )

  override val chartColors: MultiProvider<Any, RgbaColor> = MultiProvider.forListModuloProvider(Palette.chartColors)

  override val enumColors: MultiProvider<Any, RgbaColor> = MultiProvider.forListModulo(
    listOf(
      Color("#8E989D").toRgba(),
      Color("#C5CACC").toRgba(),

      //own values
      Color("#5C6366").toRgba(),
      Color("#CFDEE6").toRgba(),
      Color("#DAEAF2").toRgba(),
    )
  )

  override val inactiveElementBorder: RgbaColor = Color("#C5CACC").toRgba()
  override val borderColorConverter: ColorMapperNullable = { fill ->
    fill?.toRgba()?.darker(0.2) ?: Color.darkgray()
  }

  override val primaryButtonBackgroundColors: ButtonColorProvider = DefaultButtonColorProvider(
    disabledColor = Color.rgb(59, 145, 129),
    pressedColor = Color.web("#9fd5d8").toRgba(),
    hoverColor = Color.rgba(59, 145, 129, 0.7),
    focusedColor = Color.rgba(59, 145, 129, 0.7), // guessed
    defaultColor = Color.rgb(59, 145, 129),
  )

  override val primaryButtonForegroundColors: ButtonColorProvider = SingleButtonColorProvider(Color.white)

  override val secondaryButtonBackgroundColors: ButtonColorProvider = DefaultButtonColorProvider(
    disabledColor = Color.gray(), // guessed
    pressedColor = Color.rgb(38, 79, 16).darker(0.2), // guessed
    hoverColor = Color.rgb(38, 79, 16),
    focusedColor = Color.rgb(38, 79, 16), // guessed
    defaultColor = Color.white(),
  )

  override val secondaryButtonForegroundColors: ButtonColorProvider = SingleButtonColorProvider(Color.web("#264F10").toRgba().asProvider())

  override val stateOk: RgbaColor = Color.web("#63b017").toRgba()
  override val stateWarning: RgbaColor = Color.web("#F5C413").toRgba()
  override val stateError: RgbaColor = Color.web("#EA0823").toRgba()
  override val stateUnknown: RgbaColor = Color.web("#737F85").toRgba()
  override val shadowColor: RgbaColor = Color.black().withAlpha(0.6)
}
