package it.neckar.elektromeister.rest.components

import com.benasher44.uuid.Uuid
import it.neckar.financial.currency.Money
import it.neckar.uuid.ValueClassUuid
import it.neckar.uuid.randomUuid4

/**
 * Represents a "steckdose"
 */
data class SocketOutlet(
  override val id: ElectricalComponent.Id,
  override val label: String,
  override val price: Money,
) : ElectricalComponent {
}
