/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.demo.descriptors


import com.benasher44.uuid.Uuid
import com.benasher44.uuid.uuidFrom
import com.meistercharts.algorithms.layers.addClearBackground
import com.meistercharts.algorithms.layers.text.addTexts
import com.meistercharts.demo.DemoCategory
import com.meistercharts.demo.MeisterchartsDemo
import com.meistercharts.demo.MeisterchartsDemoDescriptor
import com.meistercharts.demo.PredefinedConfiguration
import it.neckar.open.formatting.formatUtc
import it.neckar.open.time.ClockNowProvider
import it.neckar.open.time.VirtualNowProvider
import it.neckar.open.time.nowProvider

/**
 */
class NowProviderDemoDescriptor : MeisterchartsDemoDescriptor<Nothing> {
  override val uuid: Uuid = uuidFrom("88d4751c-e1f9-4290-9310-c4ef83e7707d")
  override val name: String = "Now Provider"

  override val category: DemoCategory = DemoCategory.Calculations

  override fun prepareDemo(configuration: PredefinedConfiguration<Nothing>?): MeisterchartsDemo {
    return MeisterchartsDemo {
      meistercharts {
        configure {
          layers.addClearBackground()

          layers.addTexts { _, _ ->
            val virtualNowProvider = nowProvider as? VirtualNowProvider

            listOf(
              "nowProvider: ${nowProvider.nowMillis().formatUtc()}",
              "ClockNowProvider: ${ClockNowProvider.nowMillis().formatUtc()}",
              "virtualNowProvider: ${virtualNowProvider?.virtualNow?.formatUtc()}",
            )
          }
        }
      }
    }
  }
}
