/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.elektromeister

import com.meistercharts.algorithms.layers.toolbar.ToolbarButtonFactory
import com.meistercharts.canvas.paintable.ButtonPressedEvent
import com.meistercharts.canvas.paintable.ButtonState
import com.meistercharts.charts.ToolbarGestalt
import com.meistercharts.color.Color
import com.meistercharts.color.ColorProvider
import com.meistercharts.elektromeister.model.ElectricalComponentsFinder
import com.meistercharts.elektromeister.state.GestureContext
import com.meistercharts.resources.Icons
import com.meistercharts.style.Palette
import it.neckar.elektromeister.rest.components.ElectricalComponentType
import it.neckar.geometry.Direction
import it.neckar.geometry.Orientation
import it.neckar.open.collections.fastForEach
import it.neckar.open.kotlin.lang.asProvider
import it.neckar.open.observable.or

/**
 * Contains the toolbar for elektromeister
 */
class ElektromeisterToolbarGestalt(configuration: Configuration) : ToolbarGestalt(configuration) {

  companion object {
    fun create(model: ElektromeisterMeisterchartsModel, electricalComponentsFinder: ElectricalComponentsFinder): ElektromeisterToolbarGestalt {
      fun gestureContext(event: ButtonPressedEvent) = GestureContext(event.chartSupport, model, electricalComponentsFinder)

      val fillProvider = object {
        var buttonFill: ColorProvider = Color.lightgray
        var buttonFillDisabled: ColorProvider = Color("#8A8A8A").asProvider()
        var buttonFillHoverSelected: ColorProvider = Palette.primaryColors[0]
        var buttonFillHover: ColorProvider = Color.darkgray
        var buttonFillSelected: ColorProvider = Palette.primaryColors[0]
        var buttonFillPressed: ColorProvider = Palette.primaryColors[1]

        fun color(state: ButtonState): ColorProvider {
          return when {
            state.disabled -> buttonFillDisabled
            state.pressed -> buttonFillPressed
            //Must be checked before hover and selected
            state.isHoverAndSelected() -> buttonFillHoverSelected
            state.hover -> buttonFillHover
            state.selected -> buttonFillSelected
            else -> buttonFill
          }
        }
      }

      //Configure the toolbar
      val buttonFactory = ToolbarButtonFactory(fillProvider = fillProvider::color)


      //Buttons to create the electrical components
      val buttons = buildList {
        //Cursor
        add(
          buttonFactory.button(Icons::arrow) { event ->
            model.stateMachine.transitionToDefault(event.coordinates, gestureContext(event))
          }.also {
            it.selectedProperty.bind(
              model.stateMachine.defaultModeEnabledProperty.or(
                model.stateMachine.selectionModeEnabledProperty,
                model.stateMachine.moveModeEnabledProperty,
              )
            )
          })

        ElectricalComponentType.entries
          .filter { it.productionReady }
          .fastForEach { type ->
          val button = buttonFactory.button(buttonPaintableResolver = ElektroMeisterSvgs.Button.getForType(type)) { event ->
            model.stateMachine.transitionToCreationMode(type, null, gestureContext(event))
          }.also {
            it.selectedProperty.bind(model.stateMachine.creationModeEnabledProperty(type))
          }

          add(button)
        }

        //Special buttons
        add(buttonFactory.button(ElektroMeisterSvgs.Button::ConnectionLines) { event ->
          model.stateMachine.transitionToConnectionLineMode(null, gestureContext(event))
        }.also {
          it.selectedProperty.bind(model.stateMachine.connectionLineModeEnabledProperty)
        })
      }

      val configuration = Configuration(
        buttons = buttons
      )

      return ElektromeisterToolbarGestalt(
        configuration
      ).also {
        it.toolbarLayer.configuration.anchorDirection = Direction.TopRight
        it.toolbarLayer.configuration.layoutOrientation = Orientation.Vertical
        it.toolbarLayer.configuration.buttonGap = 6.0
      }
    }
  }
}
