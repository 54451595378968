/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.demo.elevator.gestalt

import com.meistercharts.annotations.DomainRelative

/**
 * Converts a story to a domain relative value
 */
class FloorRange(val numberOfFloors: @Floor Int = 5) {

  /**
   * The value per floor
   */
  val perFloor: @DomainRelative Double = 1.0 / numberOfFloors

  /**
   * Returns the center of the given floor in domain relative
   */
  fun floorCenter2DomainRelative(floorIndex: @Floor Double): @DomainRelative Double {
    return perFloor * (floorIndex + 0.5)
  }

  /**
   * Returns the bottom of the given floor in domain relative
   */
  fun floorBottom2DomainRelative(floorIndex: @Floor Double): @DomainRelative Double {
    return perFloor * floorIndex
  }
}
