/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.demo.descriptors


import com.benasher44.uuid.Uuid
import com.benasher44.uuid.uuidFrom
import com.meistercharts.algorithms.layers.addClearBackground
import com.meistercharts.algorithms.layers.text.TextLayer
import com.meistercharts.color.Color
import com.meistercharts.demo.MeisterchartsDemoDescriptor
import com.meistercharts.demo.DemoCategory
import com.meistercharts.demo.MeisterchartsDemo
import com.meistercharts.demo.PredefinedConfiguration
import com.meistercharts.demo.configurableFont
import com.meistercharts.demo.configurableFontProvider
import com.meistercharts.model.Insets
import com.meistercharts.style.BoxStyle
import it.neckar.open.kotlin.lang.asProvider

/**
 */
class ConfigurableFontDemoDescriptor : MeisterchartsDemoDescriptor<Nothing> {
  override val uuid: Uuid = uuidFrom("31661930-e5cd-448e-97cb-054ae8b882cd")
  override val name: String = "Configurable Fonts"

  //language=HTML
  override val description: String = "## Different fonts"
  override val category: DemoCategory = DemoCategory.Text

  override fun prepareDemo(configuration: PredefinedConfiguration<Nothing>?): MeisterchartsDemo {
    return MeisterchartsDemo {
      meistercharts {
        configure {
          layers.addClearBackground()

          val layer = TextLayer({ _, _ -> listOf("The quick brown fox jumps over the lazy dog BpÅÁqÜgÖfÄPqLT") }) {
            margin = Insets.of(10.0)
            boxStyle = BoxStyle(Color.white, Color.gray, padding = Insets.of(5.0))
          }.also {
            layers.addLayer(it)
          }

          configurableFontProvider("Font", layer.configuration::font)
        }
      }
    }
  }
}
