package com.meistercharts.charts.sick.beams

import com.meistercharts.resize.KeepOriginOnWindowResize
import it.neckar.geometry.AxisOrientationY
import com.meistercharts.zoom.ZoomAndTranslationDefaults
import com.meistercharts.algorithms.layers.TransformingChartStateLayer
import com.meistercharts.algorithms.layers.addClearBackground
import com.meistercharts.algorithms.layers.toolbar.ToolbarButtonFactory
import com.meistercharts.algorithms.layers.toolbar.ToolbarLayer
import com.meistercharts.algorithms.layers.toolbar.resetZoomAndTranslationButton
import com.meistercharts.algorithms.layers.toolbar.zoomInButton
import com.meistercharts.algorithms.layers.toolbar.zoomOutButton
import com.meistercharts.state.withAdditionalTranslation
import com.meistercharts.state.withWindowSize
import com.meistercharts.annotations.Zoomed
import com.meistercharts.canvas.FixedContentAreaHeight
import com.meistercharts.canvas.MeisterchartBuilder
import com.meistercharts.canvas.ConfigurationDsl
import com.meistercharts.charts.ChartGestalt
import it.neckar.geometry.Distance

/**
 */
class BeamChartGestalt(
  val configuration: Configuration,
  additionalConfiguration: Configuration.() -> Unit = {},
) : ChartGestalt {

  constructor(
    beamProvider: BeamProvider = ExampleBeamProvider(),
    additionalConfiguration: Configuration.() -> Unit = {},
  ) : this(Configuration(beamProvider), additionalConfiguration)

  init {
    configuration.additionalConfiguration()
  }

  val beamsLayer: BeamsLayer = BeamsLayer(object : BeamProvider {
    override val count: Int
      get() = configuration.beamProvider.count

    override val crossBeamsConfig: CrossBeamsConfig
      get() = configuration.beamProvider.crossBeamsConfig

    override fun beamState(index: Int): BeamState {
      return configuration.beamProvider.beamState(index)
    }

    override fun label(index: Int): String? {
      return configuration.beamProvider.label(index)
    }
  }) {
  }

  val toolbarLayer: ToolbarLayer = ToolbarLayer(
    buildList {
      val toolbarButtonFactory = ToolbarButtonFactory()

      add(toolbarButtonFactory.zoomInButton())
      add(toolbarButtonFactory.zoomOutButton())
      add(toolbarButtonFactory.resetZoomAndTranslationButton())
    }
  )

  val beamLocationProvider: BeamsLocationProvider = { beamIndex: Int ->
    beamsLayer.getBeamLocation(beamIndex)
  }

  val zonesLayer: ZonesLayer = ZonesLayer(
    object : ZonesProvider {
      override val count: Int = 4

      override fun startIndex(zoneIndex: Int): Int {
        return zoneIndex
      }

      override fun endIndex(zoneIndex: Int): Int {
        return zoneIndex + 4
      }

      override fun isActive(zoneIndex: Int): Boolean {
        return zoneIndex == 1
      }
    }, beamLocationProvider
  )

  init {
  }

  override fun configure(meisterChartBuilder: MeisterchartBuilder) {
    with(meisterChartBuilder) {
      //Set a reasonable size that also works if there are 240 beams
      contentAreaSizingStrategy = FixedContentAreaHeight(7_500.0)

      zoomAndTranslationDefaults {
        ZoomAndTranslationDefaults.noTranslation
      }

      zoomAndTranslationConfiguration {
        zoomWithoutModifier()
      }

      zoomAndTranslationModifier {
        disableTranslationX()
        minZoom(1.0, 0.1)
        maxZoom(1.0, 4.0)
        disableZoomX()
      }

      configure {
        chartSupport.windowResizeBehavior = KeepOriginOnWindowResize
        chartSupport.rootChartState.axisOrientationY = AxisOrientationY.OriginAtTop

        layers.addClearBackground()

        layers.addLayer(TransformingChartStateLayer(beamsLayer) {
          val originalWindowSize = chartSupport.currentChartState.windowSize
          it.withWindowSize(originalWindowSize.withWidth(originalWindowSize.width - calculateZonesLayerWidth()))
        })

        layers.addLayer(TransformingChartStateLayer(zonesLayer) {
          it.withAdditionalTranslation(Distance.of(chartSupport.currentChartState.windowWidth - calculateZonesLayerWidth(), 0.0))
        })

        layers.addLayer(toolbarLayer)
      }
    }
  }

  private fun calculateZonesLayerWidth(): @Zoomed Double {
    if (configuration.zonesLayerVisible) {
      return zonesLayer.configuration.zonesProvider.count * zonesLayer.configuration.zonesGap
    }

    return 0.0
  }

  @ConfigurationDsl
  class Configuration(
    var beamProvider: BeamProvider = ExampleBeamProvider(),
  ) {
    var zonesLayerVisible: Boolean = true
  }
}
