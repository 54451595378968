/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.elektromeister

import com.meistercharts.algorithms.layers.AbstractLayer
import com.meistercharts.algorithms.layers.LayerPaintingContext
import com.meistercharts.algorithms.layers.LayerType
import com.meistercharts.algorithms.layers.PaintingVariables
import com.meistercharts.algorithms.layers.domainChartCalculator
import com.meistercharts.annotations.Window
import com.meistercharts.canvas.layout.cache.CoordinatesMulti2DCache
import com.meistercharts.canvas.layout.cache.CoordinatesMultiCache
import com.meistercharts.canvas.layout.cache.tracePath
import com.meistercharts.color.Color
import com.meistercharts.color.ColorProvider
import com.meistercharts.color.withAlpha
import com.meistercharts.elektromeister.state.ElektromeisterUiStateMachine
import it.neckar.elektromeister.rest.RelativeToFloorOrigin
import it.neckar.geometry.Coordinates
import it.neckar.open.collections.fastForEachWithIndex
import it.neckar.open.provider.MultiProvider
import it.neckar.open.provider.fastForEachIndexed
import it.neckar.open.unit.si.mm

/**
 * Paints the floor in gray to reduce noise
 */
class ElektromeisterRoomGrayBackgroundLayer(
  val configuration: Configuration,
) : AbstractLayer() {
  override val type: LayerType = LayerType.Content

  private val paintingVariables = object : PaintingVariables {
    /**
     * Contains the points of polyline for each room
     */
    val roomPolylinePointsForRooms: @Window CoordinatesMulti2DCache = CoordinatesMulti2DCache()

    override fun calculate(paintingContext: LayerPaintingContext) {
      val calculator = paintingContext.chartSupport.domainChartCalculator(configuration.rangeX, configuration.rangeY)

      roomPolylinePointsForRooms.prepare(configuration.rooms.size())
      configuration.rooms.fastForEachIndexed { roomIndex, room ->
        val roomBounds = room.bounds

        val roomPolylinePointsCache: CoordinatesMultiCache = roomPolylinePointsForRooms[roomIndex]
        roomPolylinePointsCache.prepare(roomBounds.verticesCount)

        room.bounds.vertices().fastForEachWithIndex { vertexIndex, vertex: @mm @RelativeToFloorOrigin Coordinates ->
          @Window val windowX = calculator.domain2windowX(vertex.x)
          @Window val windowY = calculator.domain2windowY(vertex.y)
          roomPolylinePointsCache.set(vertexIndex, windowX, windowY)
        }
      }
    }
  }

  override fun paintingVariables(): PaintingVariables? {
    return paintingVariables
  }

  override fun paint(paintingContext: LayerPaintingContext) {
    val gc = paintingContext.gc

    paintingVariables.roomPolylinePointsForRooms.fastForEachWithIndex { roomIndex, roomPolylinePointsCache ->
      gc.beginPath()
      gc.tracePath(roomPolylinePointsCache)
      gc.closePath()


      gc.fill(configuration.roomBackgroundFill.valueAt(index = roomIndex).invoke())
      gc.fill()
    }
  }

  class Configuration(
    override val model: ElektromeisterMeisterchartsModel,
  ) : AbstractElektromeisterConfiguration() {

    /**
     * The room background fill
     */
    var roomBackgroundFill: MultiProvider<RoomIndex, ColorProvider> = MultiProvider.always(Color.white.withAlpha(0.85))

  }
}
