/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.demo.descriptors


import com.benasher44.uuid.Uuid
import com.benasher44.uuid.uuidFrom
import com.meistercharts.range.ValueRange
import com.meistercharts.algorithms.layers.AbstractLayer
import com.meistercharts.algorithms.layers.LayerPaintingContext
import com.meistercharts.algorithms.layers.LayerType
import com.meistercharts.algorithms.layers.axis.ValueAxisLayer
import com.meistercharts.algorithms.layers.addClearBackground
import com.meistercharts.annotations.Window
import com.meistercharts.charts.FitContentInViewportGestalt
import com.meistercharts.demo.MeisterchartsDemo
import com.meistercharts.demo.MeisterchartsDemoDescriptor
import com.meistercharts.demo.DemoCategory
import com.meistercharts.demo.DemoQuality
import com.meistercharts.demo.PredefinedConfiguration
import com.meistercharts.demo.configurableDouble
import it.neckar.geometry.Coordinates
import it.neckar.geometry.Direction
import com.meistercharts.model.Insets
import it.neckar.geometry.Side
import com.meistercharts.model.Vicinity
import it.neckar.open.provider.BooleanProvider

/**
 *
 */
@Deprecated("currently not used")
class SickValueAxisTitleDemoDescriptor : MeisterchartsDemoDescriptor<Nothing> {
  override val uuid: Uuid = uuidFrom("c3109627-b95e-4cf1-8778-10ab33c744d9")
  override val name: String = "SICK Value Axis Title (deprecated)"
  override val category: DemoCategory = DemoCategory.Automation
  // Deprecated
  override val quality: DemoQuality = DemoQuality.Low

  override fun prepareDemo(configuration: PredefinedConfiguration<Nothing>?): MeisterchartsDemo {
    return MeisterchartsDemo {
      meistercharts {
        val withContentViewportGestalt = FitContentInViewportGestalt(Insets.of(50.0, 150.0, 40.0, 40.0)).also {
          it.configure(this)
        }

        configure {
          layers.addClearBackground()

          val yAxis = ValueAxisLayer("Bar", ValueRange.linear(0.0, 243.0)) {
            side = Side.Left
            tickOrientation = Vicinity.Outside
            titleVisible = BooleanProvider.False
            size = withContentViewportGestalt.contentViewportMargin.left
          }
          layers.addLayer(yAxis)

          val xAxis = ValueAxisLayer("Meters", ValueRange.linear(0.0, 100.0)) {
            side = Side.Bottom
            tickOrientation = Vicinity.Outside
            titleVisible = BooleanProvider.False
            size = withContentViewportGestalt.contentViewportMargin.bottom
          }

          layers.addLayer(xAxis)

          layers.addLayer(SickValueAxisLabelLayer() {
            val style = xAxis.configuration

            val y = it.gc.height - style.margin.bottom - style.size + style.axisLineWidth + style.tickLength + style.tickLabelGap
            Coordinates(xAxis.paintingVariables().axisEnd, y)
          })


          configurableDouble("size", xAxis.configuration::size) {
            max = 200.0
          }
          configurableDouble("axisLineWidth", xAxis.configuration::axisLineWidth) {
            max = 20.0
          }
          configurableDouble("tickLength", xAxis.configuration::tickLength) {
            max = 20.0
          }
          configurableDouble("tickLabelGap", xAxis.configuration::tickLabelGap) {
            max = 20.0
          }
        }
      }
    }
  }
}

@Deprecated("currently not used")
class SickValueAxisLabelLayer(val locationProvider: (paintingContext: LayerPaintingContext) -> @Window Coordinates) : AbstractLayer() {
  override val type: LayerType = LayerType.Content

  override fun paint(paintingContext: LayerPaintingContext) {
    //Paint the title
    val gc = paintingContext.gc

    locationProvider(paintingContext).let { location ->
      gc.translate(location.x, location.y)
    }

    gc.fillText("Axis Title", 0.0, 0.0, Direction.TopLeft)
  }
}
