package com.meistercharts.charts.lizergy.stringsPlanning

import com.benasher44.uuid.Uuid
import com.meistercharts.charts.lizergy.roofPlanning.Module
import it.neckar.open.observable.ObservableObject

/**
 * Represents a model for a roof planning
 */
class PvStringsPlanningModel {

  val roofStringsConfiguration: RoofStringsConfiguration = RoofStringsConfiguration()

  val selectedStringProperty: ObservableObject<RoofString?> = ObservableObject(null)
  val selectedString: RoofString? by selectedStringProperty


  fun addRoofString(roofString: RoofString) {
    roofStringsConfiguration.add(roofString)
    set(roofString.uuid)
  }

  fun set(roofStringUuid: Uuid?) {
    selectedStringProperty.value = roofStringUuid?.let { roofStringsConfiguration[it] }
  }

  fun containsModule(module: Module): Boolean {
    return roofStringsConfiguration.roofStrings.any { it.contains(module) }
  }

}
