/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.elektromeister

import com.meistercharts.algorithms.layers.toolbar.ButtonPaintableResolver
import com.meistercharts.canvas.paintable.Paintable
import com.meistercharts.color.Color
import com.meistercharts.color.ColorProviderNullable
import com.meistercharts.resources.svg.PathPaintable
import com.meistercharts.resources.svg.PathPaintableProvider
import com.meistercharts.resources.svg.SvgPath
import com.meistercharts.resources.svg.toProvider
import com.meistercharts.style.Palette
import it.neckar.elektromeister.rest.components.ElectricalComponentType
import it.neckar.geometry.Direction
import it.neckar.geometry.Size

/**
 * Contains SVG paths for all electrical components.
 */
object ElektroMeisterSvgs {
  val Socket: PathPaintable = PathPaintableProviderDefinitions.Socket.get(Size.PX_24, fill = Palette.primaryColors[0])
  val Switch: PathPaintable = PathPaintableProviderDefinitions.Switch.get(Size.PX_24, fill = Palette.primaryColors[0])
  val Lights: PathPaintable = PathPaintableProviderDefinitions.Lights.get(Size.PX_24, fill = Palette.primaryColors[0])
  val ConnectionLines: PathPaintable = PathPaintableProviderDefinitions.ConnectionLines.get(Size.PX_24, fill = Palette.primaryColors[0])
  val Thermostat: PathPaintable = PathPaintableProviderDefinitions.Thermostat.get(Size.PX_24, fill = Palette.primaryColors[0])

  object Button {
    fun Socket(size: Size = Size.PX_24, fill: ColorProviderNullable = Color.lightgray, stroke: ColorProviderNullable = null, alignment: Direction = Direction.Center): Paintable = PathPaintableProviderDefinitions.Socket.get(size, fill, stroke, alignment)
    fun Switch(size: Size = Size.PX_24, fill: ColorProviderNullable = Color.lightgray, stroke: ColorProviderNullable = null, alignment: Direction = Direction.Center): Paintable = PathPaintableProviderDefinitions.Switch.get(size, fill, stroke, alignment)
    fun Lights(size: Size = Size.PX_24, fill: ColorProviderNullable = Color.lightgray, stroke: ColorProviderNullable = null, alignment: Direction = Direction.Center): Paintable = PathPaintableProviderDefinitions.Lights.get(size, fill, stroke, alignment)
    fun ConnectionLines(size: Size = Size.PX_24, fill: ColorProviderNullable = Color.lightgray, stroke: ColorProviderNullable = null, alignment: Direction = Direction.Center): Paintable = PathPaintableProviderDefinitions.ConnectionLines.get(size, fill, stroke, alignment)
    fun Thermostat(size: Size = Size.PX_24, fill: ColorProviderNullable = Color.lightgray, stroke: ColorProviderNullable = null, alignment: Direction = Direction.Center): Paintable = PathPaintableProviderDefinitions.Thermostat.get(size, fill, stroke, alignment)


    fun getForType(type: ElectricalComponentType): ButtonPaintableResolver {
      return when (type) {
        ElectricalComponentType.LightFixture -> Button::Lights
        ElectricalComponentType.LightSwitch -> Button::Switch

        ElectricalComponentType.SocketOutlet -> Button::Socket
        ElectricalComponentType.Thermostat -> Button::Thermostat

        ElectricalComponentType.CircuitBreaker,
        ElectricalComponentType.Doorbell,
        ElectricalComponentType.EthernetSocket,
        ElectricalComponentType.GarageDoorOpener,
        ElectricalComponentType.PowerDistributionBoard,
        ElectricalComponentType.MotionSensor,
        ElectricalComponentType.SmokeDetector,
          -> Button::Socket
      }
    }
  }

  object PathPaintableProviderDefinitions {
    val Socket: PathPaintableProvider = SvgDefinition.Socket.parse().toProvider(pathSize = Size.PX_24)
    val Switch: PathPaintableProvider = SvgDefinition.Switch.parse().toProvider(pathSize = Size.PX_24)
    val Lights: PathPaintableProvider = SvgDefinition.Lights.parse().toProvider(pathSize = Size.PX_24)
    val ConnectionLines: PathPaintableProvider = SvgDefinition.ConnectionLines.parse().toProvider(pathSize = Size.PX_24)
    val Thermostat: PathPaintableProvider = SvgDefinition.Thermostat.parse().toProvider(pathSize = Size.PX_24)
  }

  /**
   * Contains the SVG paths.
   * Each path must have a size of 24x24.Aligned to the top left.
   */
  object SvgDefinition {
    val Socket: SvgPath = SvgPath(
      "M12.0127 19.2025C8.04242 19.1898 4.82291 15.9703 4.83563 12C4.84836 8.02969 8.06787 4.81018 12.0382 4.82291C16.0085 4.83563 19.2153 8.05514 19.2153 12.0127C19.2025 15.983 15.983 19.2025 12.0127 19.2025ZM12.0127 6.41357C8.92047 6.4263 6.4263 8.93319 6.43902 12.0254C6.45175 15.1177 8.95864 17.6119 12.0509 17.5992C15.1304 17.5864 17.6246 15.0923 17.6246 12.0127C17.5992 8.92047 15.0923 6.41357 12.0127 6.41357ZM7.45705 11.9364C7.45705 12.9162 8.24602 13.7052 9.22587 13.7052C9.82397 13.7052 10.3839 13.4125 10.702 12.9035C10.9565 12.5217 11.0583 12.0382 10.9565 11.5801C10.7911 10.7529 10.0657 10.1676 9.22587 10.1676C8.24602 10.1676 7.45705 10.9565 7.45705 11.9364ZM9.12407 11.4528C9.16225 11.4401 9.1877 11.4401 9.22587 11.4401C9.32768 11.4401 9.41675 11.4655 9.49311 11.5292C9.60764 11.6055 9.68399 11.72 9.70944 11.8473C9.73489 11.9745 9.70944 12.1018 9.63309 12.2163C9.48038 12.4454 9.17497 12.509 8.94592 12.3563C8.71686 12.2036 8.65323 11.8982 8.80594 11.6691C8.88229 11.5546 8.99682 11.4783 9.12407 11.4528ZM13.2725 12.9417C13.6034 13.438 14.1506 13.7306 14.7487 13.7306C15.105 13.7306 15.4358 13.6288 15.7285 13.438C16.5429 12.8908 16.7593 11.7964 16.2248 10.982C15.6776 10.1676 14.5832 9.95122 13.7688 10.4857C12.9544 11.0329 12.7381 12.1273 13.2725 12.9417ZM14.2651 11.9618C14.2524 11.6946 14.4687 11.4655 14.7487 11.4655C14.9141 11.4655 15.0668 11.5546 15.1686 11.6819C15.3213 11.9109 15.2577 12.2163 15.0286 12.369C14.9523 12.4199 14.8505 12.4454 14.7614 12.4454C14.4942 12.4581 14.2778 12.2291 14.2651 11.9618Z"
    )

    //Does not work at the moment. Parser seems incorrect
    val Switch: SvgPath = SvgPath(
      "M5.07862 8.01623C4.90625 5.64041 5.06921 3.79963 5.08175 3.66883L5.08488 3.66301C5.11936 3.28498 5.46097 3 5.8684 3H16.9725C16.9976 3 17.0227 3 17.0477 3.00291C17.452 3.0378 17.7623 3.35187 17.7623 3.7299V8.16745C17.7623 8.19653 17.7592 8.2227 17.756 8.25178C17.9472 10.552 18.4894 13.2971 19.8778 15.3472C19.9561 15.4635 20 15.5973 20 15.7369C20 15.8881 19.9467 16.0335 19.8558 16.1527L17.6494 19.6394C17.5053 19.872 17.242 20 16.9756 20H5.86829C5.72726 20 5.58936 19.9651 5.46713 19.9011C5.22894 19.7703 5.0785 19.5289 5.0785 19.2701L5.07862 8.01623ZM16.1386 4.4598H6.65518V8.30709C6.84009 10.4706 7.33527 13.0151 8.5513 15.0042H17.8911C16.8443 12.9337 16.393 10.4648 16.2144 8.35652C16.1956 8.29545 16.1862 8.23147 16.1862 8.1675V8.01628C16.0765 6.52158 16.101 5.23909 16.1386 4.4598ZM16.521 18.5402L17.831 16.4668H8.55456L7.24453 18.5402H16.521ZM6.65483 14.7424V16.5831L7.19075 15.7369C6.99644 15.417 6.8178 15.0855 6.65483 14.7424Z"
    )

    val Lights: SvgPath =
      SvgPath(
        "M14.8283 13.403L14.7734 13.6005C14.7296 13.7771 14.6638 13.9538 14.5871 14.1097C14.2473 14.7748 13.6224 15.2841 12.8879 15.5023L12.7454 15.5439C12.6139 15.5751 12.4714 15.5958 12.3179 15.6166C12.2302 15.627 12.1425 15.627 12.0329 15.627H11.8794C11.7808 15.6166 11.6931 15.6166 11.6054 15.5958H11.5834C11.4519 15.5751 11.3203 15.5543 11.1778 15.5023C10.246 15.2217 9.52253 14.4942 9.28136 13.6005L9.22655 13.403H7.82339L7.88916 13.7148C7.99878 14.2552 8.21803 14.754 8.53593 15.2009C8.62363 15.3256 8.70037 15.4296 8.78806 15.5231L8.91961 15.6686C9.00731 15.7621 9.10597 15.8557 9.20463 15.9388L9.3581 16.0635C9.66504 16.3025 10.0158 16.5104 10.3995 16.6663C10.4762 16.6975 10.542 16.7182 10.5968 16.739C10.9367 16.8637 11.2875 16.9365 11.6273 16.9573C11.7698 16.9677 11.9123 16.9781 12.0658 16.9781C12.3398 16.9781 12.6248 16.9469 12.9208 16.8949C13.4689 16.791 13.9732 16.5831 14.4336 16.2921C14.4994 16.2506 14.5542 16.2194 14.609 16.1674C14.7077 16.1051 14.8173 16.0219 14.9269 15.918C15.0804 15.7933 15.2229 15.6478 15.3435 15.5023C15.4093 15.4296 15.475 15.3464 15.5298 15.2737C15.5518 15.2529 15.5737 15.2217 15.5847 15.2009L15.5956 15.1905C15.76 14.9619 15.8916 14.7333 15.9903 14.5046C16.0451 14.3903 16.0889 14.2656 16.1328 14.1305L16.1657 14.037C16.1766 14.0162 16.1766 13.985 16.1876 13.9642V13.9434C16.2095 13.8603 16.2314 13.7771 16.2424 13.694L16.3082 13.3822H14.8283V13.403ZM13.447 17.9861L14.7296 17.4873L16.0231 20.5012L14.7406 21L13.447 17.9861ZM16.6699 15.6686L17.1851 14.4527L20.3313 15.6686L19.8161 16.8845L16.6699 15.6686ZM8.05359 20.47L9.34714 17.4561L10.6297 17.955L9.33618 20.9688L8.05359 20.47ZM3.72351 15.6374L6.86967 14.4215L7.3849 15.6374L4.23873 16.8533L3.72351 15.6374ZM11.3752 3H12.8222V7.57275H11.3752V3ZM12 8.22748C8.11937 8.22748 4.75396 9.80716 3 12.1455H21C19.246 9.80716 15.8806 8.22748 12 8.22748ZM7.27527 10.7113C8.1961 10.0774 9.96102 9.65127 12 9.65127C14.039 9.65127 15.8039 10.0774 16.7247 10.7113H7.27527Z"
      )

    val ConnectionLines: SvgPath = SvgPath("M15.5 5.5H18.5V8.5H15.5V5.5ZM15 10H20V4H14V8.87868L8.87868 14H4V20H10V15L15 10ZM5.5 15.5H8.5V18.5H5.5V15.5Z")

    val Thermostat: SvgPath = SvgPath(
      "M14.6723 4.66055V13.3428C15.1983 13.6931 15.6479 14.1351 16.0042 14.6606C17.4719 16.829 16.878 19.7648 14.6723 21.2077C12.4666 22.6505 9.48038 22.0667 8.01272 19.8982C6.54507 17.7298 7.13892 14.794 9.34464 13.3511V4.66055H9.35313V4.56881C9.37858 3.12594 10.5917 1.97498 12.0594 2C13.527 2.02502 14.6978 3.21768 14.6723 4.66055ZM8.22481 17.2711C8.22481 19.3311 9.92153 20.9908 12.0085 20.9908C13.4592 20.9908 14.7826 20.1735 15.4189 18.8891C16.3266 17.0375 15.5376 14.819 13.6543 13.9266V4.58549C13.6373 3.69308 12.8823 2.99249 11.9745 3.00917C11.0668 3.02585 10.3542 3.76814 10.3712 4.66055H10.3627V13.9266C9.0562 14.5438 8.22481 15.8449 8.22481 17.2711ZM20 8.36364H15.7328V9.41451H20V8.36364ZM20 6.26188H15.7328V7.31276H20V6.26188ZM12.509 6.23686V14.6939C13.5779 14.9024 14.4178 15.7198 14.6214 16.7706C14.9014 18.1968 13.9597 19.573 12.509 19.8482C11.0583 20.1234 9.65854 19.1977 9.37858 17.7715C9.09862 16.3453 10.0403 14.9691 11.491 14.6939V6.23686H12.509ZM15.7328 10.4654H20V11.5163H15.7328V10.4654ZM8.26723 10.4404H4V11.4912H8.26723V10.4404ZM6.67232 5.72811H8.26723V6.77064H6.67232V8.34696H5.60339V6.77064H4.01697V5.72811H5.60339V4.16013H6.67232V5.72811Z"
    )

    /**
     * Contains all svgs
     */
    val all: List<SvgPath> = listOf(
      Socket,
      Switch,
      Lights,
      ConnectionLines,
      Thermostat,
    )
  }
}
