package it.neckar.elektromeister.rest.quote

import it.neckar.financial.currency.Money
import it.neckar.rest.annotations.RestAPI
import kotlinx.serialization.Serializable
import kotlin.js.JsExport

/**
 * A complete quote for Elektromeister.
 * Contains all calculated values.
 */
@RestAPI
@JsExport
@Serializable
data class ElektromeisterQuote(
  val totalPrice: Money,
  val rooms: List<RoomQuote>,
) {
  companion object {
    val empty: ElektromeisterQuote = ElektromeisterQuote(totalPrice = Money.Zero, rooms = emptyList())
  }
}
