package com.meistercharts.charts.lizergy.roofPlanning

import it.neckar.geometry.Coordinates
import it.neckar.geometry.RightTriangleType.MissingCornerInFirstQuadrant
import it.neckar.geometry.RightTriangleType.MissingCornerInFourthQuadrant
import it.neckar.geometry.RightTriangleType.MissingCornerInSecondQuadrant
import it.neckar.geometry.RightTriangleType.MissingCornerInThirdQuadrant
import it.neckar.geometry.Size

/**
 *
 */
object PvRoofSampleModels {
  /**
   * A simple model that only contains modules in the grid
   */
  fun getSimpleGrid(): PvRoofPlanningModel {
    return PvRoofPlanningModel().also {
      it.addModuleArea()
    }
  }

  fun getGridWithHoles(): PvRoofPlanningModel {
    return PvRoofPlanningModel().also {
      it.addModuleArea()
      it.removeModules { module ->
        val gridLayout = module.modulePlacement
        gridLayout.moduleIndex.y % 3 == 1 &&
            gridLayout.moduleIndex.x % 3 == 1
      }
    }
  }

  fun onlyUnusableArea(): PvRoofPlanningModel {
    return PvRoofPlanningModel().also {
      it.unusableAreas.add(UnusableArea(location = Coordinates.of(500.0, 750.0), size = Size(1000.0, 1400.0)))
      it.unusableAreas.add(UnusableArea(location = Coordinates.of(2300.0, 3500.0), size = Size(2300.0, 800.0), rightTriangleType = MissingCornerInFirstQuadrant))
      it.unusableAreas.add(UnusableArea(location = Coordinates.of(7500.0, 2000.0), size = Size(2300.0, 800.0), rightTriangleType = MissingCornerInSecondQuadrant))
      it.unusableAreas.add(UnusableArea(location = Coordinates.of(1900.0, 5300.0), size = Size(2300.0, 800.0), rightTriangleType = MissingCornerInThirdQuadrant))
      it.unusableAreas.add(UnusableArea(location = Coordinates.of(8300.0, 4500.0), size = Size(2300.0, 800.0), rightTriangleType = MissingCornerInFourthQuadrant))
    }
  }

  fun realistic(): PvRoofPlanningModel {
    return PvRoofPlanningModel().also {
      it.unusableAreas.add(UnusableArea(location = Coordinates.of(2300.0, 1750.0), size = Size(1000.0, 1400.0)))
      it.unusableAreas.add(UnusableArea(location = Coordinates.of(2300.0, 3500.0), size = Size(2300.0, 800.0), rightTriangleType = MissingCornerInFirstQuadrant))
      it.unusableAreas.add(UnusableArea(location = Coordinates.of(7500.0, 2000.0), size = Size(2300.0, 800.0), rightTriangleType = MissingCornerInSecondQuadrant))
      it.unusableAreas.add(UnusableArea(location = Coordinates.of(1900.0, 5300.0), size = Size(2300.0, 800.0), rightTriangleType = MissingCornerInThirdQuadrant))
      it.unusableAreas.add(UnusableArea(location = Coordinates.of(8300.0, 4500.0), size = Size(2300.0, 800.0), rightTriangleType = MissingCornerInFourthQuadrant))

      it.addModuleArea().also { moduleArea ->
        moduleArea.modules.remove(ModuleIndex(1, 1))
        moduleArea.modules.remove(ModuleIndex(1, 2))
      }
    }
  }

  fun allTypes(): PvRoofPlanningModel {
    return PvRoofPlanningModel().also {
      it.unusableAreas.add(UnusableArea(location = Coordinates.of(1850.0, 3850.0), size = Size(1000.0, 1400.0)))
      it.unusableAreas.add(UnusableArea(location = Coordinates.of(2300.0, 1700.0), size = Size(2300.0, 800.0), rightTriangleType = MissingCornerInFirstQuadrant))
      it.unusableAreas.add(UnusableArea(location = Coordinates.of(7500.0, 2000.0), size = Size(2300.0, 800.0), rightTriangleType = MissingCornerInSecondQuadrant))
      it.unusableAreas.add(UnusableArea(location = Coordinates.of(1900.0, 5300.0), size = Size(2300.0, 800.0), rightTriangleType = MissingCornerInThirdQuadrant))
      it.unusableAreas.add(UnusableArea(location = Coordinates.of(8300.0, 4500.0), size = Size(2300.0, 800.0), rightTriangleType = MissingCornerInFourthQuadrant))

      it.addModuleArea().also { moduleArea ->
        moduleArea.modules.remove(ModuleIndex(1, 1))
        moduleArea.modules.remove(ModuleIndex(2, 1))
        moduleArea.modules.remove(ModuleIndex(1, 2))
        moduleArea.modules.remove(ModuleIndex(2, 2))
      }
    }
  }
}
