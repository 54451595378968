/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.demojs.descriptors

import com.benasher44.uuid.Uuid
import com.benasher44.uuid.uuidFrom
import com.meistercharts.algorithms.layers.addClearBackground
import com.meistercharts.algorithms.layers.text.addTexts
import com.meistercharts.canvas.DirtyReason
import com.meistercharts.config.ChartConfigParsingSupport
import com.meistercharts.demo.DemoCategory
import com.meistercharts.demo.MeisterchartsDemo
import com.meistercharts.demo.MeisterchartsDemoDescriptor
import com.meistercharts.demo.PredefinedConfiguration
import com.meistercharts.js.MeisterChartClasses
import com.meistercharts.js.MeisterchartJS
import it.neckar.open.kotlin.serializers.JsonPretty
import kotlinx.html.dom.append
import kotlinx.html.js.script
import kotlinx.html.unsafe
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.Json

/**
 * Depends on a script tag in the index.html file
 */
class ConfigurationFromElementDemoDescriptor : MeisterchartsDemoDescriptor<Nothing> {
  override val uuid: Uuid = uuidFrom("d847e68a-c0b9-42a0-a8e8-b40511519f97")
  override val name: String = "Configuration from element"
  override val category: DemoCategory = DemoCategory.Platform

  private val json = JsonPretty

  override fun prepareDemo(configuration: PredefinedConfiguration<Nothing>?): MeisterchartsDemo {
    return MeisterchartsDemo {
      meistercharts {
        configure {
          layers.addClearBackground()

          val parsingSupport = ChartConfigParsingSupport()

          //Read the preconfigured config from the script tag - that already exists in the HTML
          val myChartConfig = parsingSupport.parseChartsConfig<MyChartConfig>("meistercharts-config-myChart")

          val configObject = object {
            var message1: String = myChartConfig.message
            var message2: String = "unresolved"
            var message2Resolved = false
          }


          layers.addTexts { _, _ ->
            if (configObject.message2Resolved.not()) {

              val meisterchartJS = chartSupport.meisterchart as MeisterchartJS

              //Create the specific script tag here - this is specific for this demo. In production the script tag will be created in HTML
              run {
                val jsonContent = json.encodeToString(MyChartConfig.serializer(), MyChartConfig("message from HTML"))

                //First create the config script tag - specific for this meistercharts instance
                val holder = meisterchartJS.holder
                holder.append {
                  script(type = "application/json") {
                    unsafe { +jsonContent }
                    attributes["class"] = MeisterChartClasses.config
                  }
                }
              }

              //Load the config
              val foundConfig = parsingSupport.parseChartsConfig<MyChartConfig>(chartSupport)

              configObject.message2Resolved = true
              configObject.message2 = foundConfig.message
              chartSupport.markAsDirty(DirtyReason.ChartStateChanged)
            }

            listOf(configObject.message1, configObject.message2)
          }

        }
      }
    }
  }

  @Serializable
  data class MyChartConfig(val message: String)
}
