package com.meistercharts.charts.lizergy.roofPlanning

import kotlinx.serialization.Serializable

/**
 * The orientation of a module
 */
@Serializable
enum class ModuleOrientation {
  /**
   * Longer side on the y axis
   */
  Vertical,

  /**
   * Longer side on the x axis
   */
  Horizontal,
}

fun ModuleOrientation.rotated(): ModuleOrientation {
  return when (this) {
    ModuleOrientation.Vertical -> ModuleOrientation.Horizontal
    ModuleOrientation.Horizontal -> ModuleOrientation.Vertical
  }
}
