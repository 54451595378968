/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.demo.descriptors


import com.benasher44.uuid.Uuid
import com.benasher44.uuid.uuidFrom
import com.meistercharts.algorithms.layers.addClearBackground
import com.meistercharts.charts.lizergy.roofPlanning.RoofBackgroundLayer
import com.meistercharts.demo.DemoCategory
import com.meistercharts.demo.MeisterchartsDemo
import com.meistercharts.demo.MeisterchartsDemoDescriptor
import com.meistercharts.demo.PredefinedConfiguration
import com.meistercharts.demo.configurableDouble
import it.neckar.geometry.Size
import it.neckar.open.observable.ObservableDouble
import kotlin.math.roundToInt

/**
 *
 */
class RoofBackgroundLayerDemoDescriptor : MeisterchartsDemoDescriptor<Nothing> {
  override val uuid: Uuid = uuidFrom("2bd8500e-bb9d-458f-a8fe-39c845ff318d")
  override val name: String = "PV Roof background"
  override val category: DemoCategory = DemoCategory.Layers

  override fun prepareDemo(configuration: PredefinedConfiguration<Nothing>?): MeisterchartsDemo {
    return MeisterchartsDemo {
      meistercharts {
        configure {
          layers.addClearBackground()

          val roofWidth = ObservableDouble(15_000.0)
          val roofHeight = ObservableDouble(7_000.0)

          val layer = RoofBackgroundLayer({
            Size(roofWidth.value.roundToInt(), roofHeight.value.roundToInt())
          })
          layers.addLayer(layer)

          configurableDouble("Width", roofWidth) {
            max = 20_000.0
          }
          configurableDouble("Height", roofHeight) {
            max = 20_000.0
          }

          declare {
            section("Tiles color")
            button("Red") {
              layer.configuration.useRedRoofTiles()
              this@MeisterchartsDemo.markAsDirty()
            }
            button("Dark") {
              layer.configuration.useDarkRoofTiles()
              this@MeisterchartsDemo.markAsDirty()
            }
          }
        }
      }
    }
  }
}
