/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.demo.descriptors


import com.benasher44.uuid.Uuid
import com.benasher44.uuid.uuidFrom
import com.meistercharts.resize.KeepOriginOnWindowResize
import it.neckar.geometry.AxisOrientationY
import com.meistercharts.zoom.ZoomAndTranslationDefaults
import com.meistercharts.algorithms.layers.addClearBackground
import com.meistercharts.algorithms.layers.text.addTexts
import com.meistercharts.canvas.FixedContentAreaSize
import com.meistercharts.charts.sick.beams.BeamProvider
import com.meistercharts.charts.sick.beams.BeamState
import com.meistercharts.charts.sick.beams.BeamsLayer
import com.meistercharts.charts.sick.beams.CrossBeamsConfig
import com.meistercharts.demo.MeisterchartsDemo
import com.meistercharts.demo.MeisterchartsDemoDescriptor
import com.meistercharts.demo.DemoCategory
import com.meistercharts.demo.PredefinedConfiguration
import com.meistercharts.demo.configurableDouble
import com.meistercharts.demo.configurableEnum
import com.meistercharts.demo.configurableInt
import it.neckar.geometry.Size
import it.neckar.events.ModifierCombination
import it.neckar.open.kotlin.lang.getModulo

/**
 *
 */
class BeamsLayerDemoDescriptor : MeisterchartsDemoDescriptor<Nothing> {
  override val uuid: Uuid = uuidFrom("183ec4d8-5519-4c97-9f83-0caba4fc2508")
  override val name: String = "Beams Layer"
  override val category: DemoCategory = DemoCategory.Layers

  override fun prepareDemo(configuration: PredefinedConfiguration<Nothing>?): MeisterchartsDemo {
    return MeisterchartsDemo {
      meistercharts {
        contentAreaSizingStrategy = FixedContentAreaSize(Size(100.0, 100.0))

        zoomAndTranslationDefaults {
          ZoomAndTranslationDefaults.noTranslation
        }

        configure {
          chartSupport.windowResizeBehavior = KeepOriginOnWindowResize
          chartSupport.rootChartState.axisOrientationY = AxisOrientationY.OriginAtTop

          layers.addClearBackground()

          val myBeamProvider = MyBeamProvider()
          val beamsLayer = BeamsLayer(myBeamProvider)
          layers.addLayer(beamsLayer)


          var lastClickedBeam = -1

          layers.addTexts { _, _ ->
            if (lastClickedBeam >= 0) {
              listOf("Last Clicked beam: $lastClickedBeam")
            } else {
              listOf()
            }
          }

          beamsLayer.onBeamClicked { modelBeamIndex: Int, _: ModifierCombination ->
            lastClickedBeam = modelBeamIndex
            markAsDirty()
          }

          configurableInt("Beam count", myBeamProvider::count) {
            max = 30
          }
          configurableEnum("Cross Beams", myBeamProvider::crossBeamsConfig, kotlin.enums.enumEntries<CrossBeamsConfig>().toList()) {
          }

          configurableDouble("beams distance", beamsLayer.configuration::beamsDistance) {
            max = 50.0
          }

          configurableDouble("deviceBeamGap", beamsLayer.configuration::deviceBeamGap) {
            max = 30.0
          }
          configurableDouble("device min height", beamsLayer.configuration::deviceMinHeight) {
            max = 300.0
          }
          configurableDouble("device width", beamsLayer.configuration::deviceWidth) {
            max = 300.0
          }
          configurableDouble("Beam line width", beamsLayer.configuration::beamLineWidth) {
            max = 10.0
          }
          configurableDouble("beam label bottom gap", beamsLayer.configuration::beamLabelBottomGap) {
            max = 10.0
          }
          configurableDouble("Arrow head height", beamsLayer.configuration::beamArrowHeadHeight) {
            max = 20.0
          }
          configurableDouble("Arrow head width", beamsLayer.configuration::beamArrowHeadWidth) {
            max = 20.0
          }
          configurableDouble("Beam Arrow start Offset", beamsLayer.configuration::beamStartAtArrowOffset) {
            max = 20.0
          }
        }
      }
    }
  }
}

class MyBeamProvider : BeamProvider {
  override var count: Int = 17

  override var crossBeamsConfig: CrossBeamsConfig = CrossBeamsConfig.None

  override fun beamState(index: Int): BeamState {
    return BeamState.entries.getModulo(index)
  }

  override fun label(index: Int): String? {
    return index.toString()
  }

}

