/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.elektromeister.state

import com.meistercharts.annotations.Domain
import com.meistercharts.annotations.Window
import com.meistercharts.annotations.Zoomed
import com.meistercharts.calc.DomainChartCalculator
import com.meistercharts.canvas.MouseCursor
import com.meistercharts.elektromeister.model.MutablePlacedElectricalComponent
import com.meistercharts.events.EventConsumption
import it.neckar.events.MouseButton
import it.neckar.events.MouseDownEvent
import it.neckar.events.MouseDragEvent
import it.neckar.events.MouseMoveEvent
import it.neckar.events.MouseUpEvent
import it.neckar.geometry.Coordinates
import it.neckar.geometry.Distance
import it.neckar.open.unit.si.mm

/**
 * Moving a [PlacedElectricalComponent]
 */
class ElectricalComponentMoving(
  /**
   * The component that is moved
   */
  val movedComponent: MutablePlacedElectricalComponent,
  /**
   * The initial mouse coordinates, where moving started
   */
  val initialMouseCoordinates: @Window Coordinates,
  /**
   * The initial domain location
   */
  val initialLocation: @mm @Domain Coordinates,
) : AbstractUiState() {

  /**
   * Calculates the distance between the initial mouse coordinates and the given coordinates
   */
  fun distance(coordinates: @Window Coordinates): @Zoomed Distance {
    return coordinates.delta(initialMouseCoordinates)
  }

  init {
    cursor = MouseCursor.ClosedHand

    movedComponent.transient.startMoving(initialLocation)
  }

  override fun updateStateForMouseLocation(mouseLocation: Coordinates?, context: GestureContext) {
  }

  override fun mouseMoved(event: MouseMoveEvent, context: GestureContext): EventConsumption {
    throw IllegalStateException("Not possible!")
  }

  override fun mouseDown(event: MouseDownEvent, context: GestureContext): EventConsumption {
    val model = context.model

    when (event.button) {
      MouseButton.Primary -> {
        throw IllegalStateException("Not possible if already dragging!")
      }

      MouseButton.Secondary -> {
        context.stateMachine.transitionToDefault(event.coordinates, context)
        context.markAsDirty()
        return EventConsumption.Consumed
      }

      else -> {
        return EventConsumption.Ignored
      }
    }
  }

  override fun mouseUp(event: MouseUpEvent, context: GestureContext): EventConsumption {
    when (event.button) {
      MouseButton.Primary -> {
        //Switch back from moving to selected
        movedComponent.transient.finishMoving()
        context.stateMachine.transitionToSelectedComponent(movedComponent, event.coordinates, context)
        return EventConsumption.Consumed
      }
      MouseButton.Secondary -> {
        context.stateMachine.transitionToDefault(event.coordinates, context)
        context.markAsDirty()
        return EventConsumption.Consumed
      }

      else -> {
        return EventConsumption.Ignored
      }
    }
  }

  override fun mouseDragged(event: MouseDragEvent, context: GestureContext): EventConsumption {
    val chartSupport = context.chartSupport
    val model = context.model

    @Window val mouseCoordinates = event.coordinates
    @Window val movedDistance = distance(mouseCoordinates)

    val domainChartCalculator = DomainChartCalculator(chartSupport.rootChartState, model.floorPlanImage.rangeX, model.floorPlanImage.rangeY)
    @mm @Domain val movedDistanceMm = domainChartCalculator.zoomed2domainDelta(movedDistance)
    @mm @Domain val updatedLocation = initialLocation + movedDistanceMm

    //Update the "movement" location
    val placedElectricalComponent = model.floorPlan.placedElectricalComponent(movedComponent.id)
    placedElectricalComponent.transient.movementLocation = updatedLocation

    return EventConsumption.Consumed
  }

  override fun deleteByKey(context: GestureContext): EventConsumption {
    return EventConsumption.Ignored
  }

  override fun toString(): String {
    return "ElectricalComponentMoving(movedComponent=$movedComponent)"
  }
}
