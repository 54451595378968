package it.neckar.rest.tracking

import io.ktor.http.*
import it.neckar.rest.ClientRole


/**
 * The header key for the client ID
 */
@Suppress("UnusedReceiverParameter")
val HttpHeaders.XClientId: String
  get() {
    return "X-Client-Id"
  }

/**
 * Returns the client ID of the request (if there is one)
 */
val Headers.findClientId: ClientRole?
  get() {
    return this[HttpHeaders.XClientId]?.let { ClientRole(it) }
  }
