package it.neckar.elektromeister.rest.quote

import it.neckar.elektromeister.rest.components.ElectricalComponentType
import it.neckar.financial.currency.Money
import it.neckar.rest.annotations.RestAPI
import kotlinx.serialization.Serializable
import kotlin.js.JsExport

@RestAPI
@JsExport
@Serializable
data class RoomQuoteElement(
  val type: ElectricalComponentType,
  /**
   * The total count of items (usually included + extra)
   */
  val items: Int,
  /**
   * The included items
   */
  val included: Int,
  /**
   * The additional items
   */
  val extra: Int,

  val unitPrice: Money,
  val totalPrice: Money,
) {
}
