/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.elektromeister

import com.meistercharts.algorithms.layers.AbstractLayer
import com.meistercharts.algorithms.layers.LayerPaintingContext
import com.meistercharts.algorithms.layers.LayerType
import com.meistercharts.canvas.ChartSupport
import com.meistercharts.canvas.events.CanvasKeyEventHandler
import com.meistercharts.canvas.events.CanvasMouseEventHandler
import com.meistercharts.events.EventConsumption
import it.neckar.events.KeyCode
import it.neckar.events.KeyDownEvent
import it.neckar.events.KeyStroke
import it.neckar.events.MouseDownEvent
import it.neckar.events.MouseDragEvent
import it.neckar.events.MouseMoveEvent
import it.neckar.events.MouseUpEvent

/**
 * Handles the mouse gestures and other interactions
 */
class ElektromeisterInteractionHandlerLayer(
  val configuration: Configuration,
) : AbstractLayer() {
  override val type: LayerType = LayerType.Content

  override fun initialize(paintingContext: LayerPaintingContext) {
    super.initialize(paintingContext)

    configuration.interactionHandler.initialize(paintingContext)
  }

  override fun paint(paintingContext: LayerPaintingContext) {
  }

  override val mouseEventHandler: CanvasMouseEventHandler = object : CanvasMouseEventHandler {
    override fun onMove(event: MouseMoveEvent, chartSupport: ChartSupport): EventConsumption {
      return configuration.interactionHandler.mouseMoved(event, chartSupport)
    }

    override fun onDrag(event: MouseDragEvent, chartSupport: ChartSupport): EventConsumption {
      return configuration.interactionHandler.mouseDragged(event, chartSupport)
    }

    override fun onDown(event: MouseDownEvent, chartSupport: ChartSupport): EventConsumption {
      return configuration.interactionHandler.mouseDown(event, chartSupport)
    }

    override fun onUp(event: MouseUpEvent, chartSupport: ChartSupport): EventConsumption {
      return configuration.interactionHandler.mouseUp(event, chartSupport)
    }
  }

  override val keyEventHandler: CanvasKeyEventHandler = object : CanvasKeyEventHandler {
    override fun onDown(event: KeyDownEvent, chartSupport: ChartSupport): EventConsumption {
      if (event.keyStroke == configuration.resetKeyStroke) {
        return configuration.interactionHandler.onResetByKey(event, chartSupport)
      }

      if (event.keyStroke == configuration.deleteKeyStroke) {
        return configuration.interactionHandler.onDeleteByKey(event, chartSupport)
      }

      return EventConsumption.Ignored
    }
  }

  class Configuration(
    override val model: ElektromeisterMeisterchartsModel,
    val interactionHandler: ElektromeisterInteractionHandler,
  ) : AbstractElektromeisterConfiguration() {
    /**
     * The keystroke that resets to the default state
     */
    var resetKeyStroke: KeyStroke = KeyStroke(KeyCode.Escape)

    /**
     * The keystroke that deletes the current element
     */
    var deleteKeyStroke: KeyStroke = KeyStroke(KeyCode.Delete)
  }
}
