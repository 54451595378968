/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.style

import com.meistercharts.color.Color
import com.meistercharts.color.Color.Companion.web

/**
 * https://material.io/design/color/the-color-system.html#tools-for-picking-Color
 */
object MaterialColor {
  val RED_50: Color = web("#FFEBEE")
  val RED_100: Color = web("#FFCDD2")
  val RED_200: Color = web("#EF9A9A")
  val RED_300: Color = web("#E57373")
  val RED_400: Color = web("#EF5350")
  val RED_500: Color = web("#F44336")
  val RED_600: Color = web("#E53935")
  val RED_700: Color = web("#D32F2F")
  val RED_800: Color = web("#C62828")
  val RED_900: Color = web("#B71C1C")

  val RED_A100: Color = web("#FF8A80")
  val RED_A200: Color = web("#FF5252")
  val RED_A400: Color = web("#FF1744")
  val RED_A700: Color = web("#D50000")

  val PINK_50: Color = web("#FCE4EC")
  val PINK_100: Color = web("#F8BBD0")
  val PINK_200: Color = web("#F48FB1")
  val PINK_300: Color = web("#F06292")
  val PINK_400: Color = web("#EC407A")
  val PINK_500: Color = web("#E91E63")
  val PINK_600: Color = web("#D81B60")
  val PINK_700: Color = web("#C2185B")
  val PINK_800: Color = web("#AD1457")
  val PINK_900: Color = web("#880E4F")

  val PINK_A100: Color = web("#FF80AB")
  val PINK_A200: Color = web("#FF4081")
  val PINK_A400: Color = web("#F50057")
  val PINK_A700: Color = web("#C51162")

  val PURPLE_50: Color = web("#F3E5F5")
  val PURPLE_100: Color = web("#E1BEE7")
  val PURPLE_200: Color = web("#CE93D8")
  val PURPLE_300: Color = web("#BA68C8")
  val PURPLE_400: Color = web("#AB47BC")
  val PURPLE_500: Color = web("#9C27B0")
  val PURPLE_600: Color = web("#8E24AA")
  val PURPLE_700: Color = web("#7B1FA2")
  val PURPLE_800: Color = web("#6A1B9A")
  val PURPLE_900: Color = web("#4A148C")

  val PURPLE_A100: Color = web("#EA80FC")
  val PURPLE_A200: Color = web("#E040FB")
  val PURPLE_A400: Color = web("#D500F9")
  val PURPLE_A700: Color = web("#AA00FF")

  val DEEP_PURPLE_50: Color = web("#EDE7F6")
  val DEEP_PURPLE_100: Color = web("#D1C4E9")
  val DEEP_PURPLE_200: Color = web("#B39DDB")
  val DEEP_PURPLE_300: Color = web("#9575CD")
  val DEEP_PURPLE_400: Color = web("#7E57C2")
  val DEEP_PURPLE_500: Color = web("#673AB7")
  val DEEP_PURPLE_600: Color = web("#5E35B1")
  val DEEP_PURPLE_700: Color = web("#512DA8")
  val DEEP_PURPLE_800: Color = web("#4527A0")
  val DEEP_PURPLE_900: Color = web("#311B92")

  val DEEP_PURPLE_A100: Color = web("#B388FF")
  val DEEP_PURPLE_A200: Color = web("#7C4DFF")
  val DEEP_PURPLE_A400: Color = web("#651FFF")
  val DEEP_PURPLE_A700: Color = web("#6200EA")

  val BLUE_50: Color = web("#E3F2FD")
  val BLUE_100: Color = web("#BBDEFB")
  val BLUE_200: Color = web("#90CAF9")
  val BLUE_300: Color = web("#64B5F6")
  val BLUE_400: Color = web("#42A5F5")
  val BLUE_500: Color = web("#2196F3")
  val BLUE_600: Color = web("#1E88E5")
  val BLUE_700: Color = web("#1976D2")
  val BLUE_800: Color = web("#1565C0")
  val BLUE_900: Color = web("#0D47A1")

  val BLUE_A100: Color = web("#82B1FF")
  val BLUE_A200: Color = web("#448AFF")
  val BLUE_A400: Color = web("#2979FF")
  val BLUE_A700: Color = web("#2962FF")

  val LIGHT_BLUE_50: Color = web("#E1F5FE")
  val LIGHT_BLUE_100: Color = web("#B3E5FC")
  val LIGHT_BLUE_200: Color = web("#81D4FA")
  val LIGHT_BLUE_300: Color = web("#4FC3F7")
  val LIGHT_BLUE_400: Color = web("#29B6F6")
  val LIGHT_BLUE_500: Color = web("#03A9F4")
  val LIGHT_BLUE_600: Color = web("#039BE5")
  val LIGHT_BLUE_700: Color = web("#0288D1")
  val LIGHT_BLUE_800: Color = web("#0277BD")
  val LIGHT_BLUE_900: Color = web("#01579B")

  val LIGHT_BLUE_A100: Color = web("#80D8FF")
  val LIGHT_BLUE_A200: Color = web("#40C4FF")
  val LIGHT_BLUE_A400: Color = web("#00B0FF")
  val LIGHT_BLUE_A700: Color = web("#0091EA")

  val CYAN_50: Color = web("#E0F7FA")
  val CYAN_100: Color = web("#B2EBF2")
  val CYAN_200: Color = web("#80DEEA")
  val CYAN_300: Color = web("#4DD0E1")
  val CYAN_400: Color = web("#26C6DA")
  val CYAN_500: Color = web("#00BCD4")
  val CYAN_600: Color = web("#00ACC1")
  val CYAN_700: Color = web("#0097A7")
  val CYAN_800: Color = web("#00838F")
  val CYAN_900: Color = web("#006064")

  val CYAN_A100: Color = web("#84FFFF")
  val CYAN_A200: Color = web("#18FFFF")
  val CYAN_A400: Color = web("#00E5FF")
  val CYAN_A700: Color = web("#00B8D4")

  val TEAL_50: Color = web("#E0F2F1")
  val TEAL_100: Color = web("#B2DFDB")
  val TEAL_200: Color = web("#80CBC4")
  val TEAL_300: Color = web("#4DB6AC")
  val TEAL_400: Color = web("#26A69A")
  val TEAL_500: Color = web("#009688")
  val TEAL_600: Color = web("#00897B")
  val TEAL_700: Color = web("#00796B")
  val TEAL_800: Color = web("#00695C")
  val TEAL_900: Color = web("#004D40")

  val TEAL_A100: Color = web("#A7FFEB")
  val TEAL_A200: Color = web("#64FFDA")
  val TEAL_A400: Color = web("#1DE9B6")
  val TEAL_A700: Color = web("#00BFA5")

  val GREEN_50: Color = web("#E8F5E9")
  val GREEN_100: Color = web("#C8E6C9")
  val GREEN_200: Color = web("#A5D6A7")
  val GREEN_300: Color = web("#81C784")
  val GREEN_400: Color = web("#66BB6A")
  val GREEN_500: Color = web("#4CAF50")
  val GREEN_600: Color = web("#43A047")
  val GREEN_700: Color = web("#388E3C")
  val GREEN_800: Color = web("#2E7D32")
  val GREEN_900: Color = web("#1B5E20")

  val GREEN_A100: Color = web("#B9F6CA")
  val GREEN_A200: Color = web("#69F0AE")
  val GREEN_A400: Color = web("#00E676")
  val GREEN_A700: Color = web("#00C853")

  val LIGHT_GREEN_50: Color = web("#F1F8E9")
  val LIGHT_GREEN_100: Color = web("#DCEDC8")
  val LIGHT_GREEN_200: Color = web("#C5E1A5")
  val LIGHT_GREEN_300: Color = web("#AED581")
  val LIGHT_GREEN_400: Color = web("#9CCC65")
  val LIGHT_GREEN_500: Color = web("#8BC34A")
  val LIGHT_GREEN_600: Color = web("#7CB342")
  val LIGHT_GREEN_700: Color = web("#689F38")
  val LIGHT_GREEN_800: Color = web("#558B2F")
  val LIGHT_GREEN_900: Color = web("#33691E")

  val LIGHT_GREEN_A100: Color = web("#CCFF90")
  val LIGHT_GREEN_A200: Color = web("#B2FF59")
  val LIGHT_GREEN_A400: Color = web("#76FF03")
  val LIGHT_GREEN_A700: Color = web("#64DD17")

  val LIME_50: Color = web("#F9FBE7")
  val LIME_100: Color = web("#F0F4C3")
  val LIME_200: Color = web("#E6EE9C")
  val LIME_300: Color = web("#DCE775")
  val LIME_400: Color = web("#D4E157")
  val LIME_500: Color = web("#CDDC39")
  val LIME_600: Color = web("#C0CA33")
  val LIME_700: Color = web("#AFB42B")
  val LIME_800: Color = web("#9E9D24")
  val LIME_900: Color = web("#827717")

  val LIME_A100: Color = web("#F4FF81")
  val LIME_A200: Color = web("#EEFF41")
  val LIME_A400: Color = web("#C6FF00")
  val LIME_A700: Color = web("#AEEA00")

  val YELLOW_50: Color = web("#FFFDE7")
  val YELLOW_100: Color = web("#FFF9C4")
  val YELLOW_200: Color = web("#FFF59D")
  val YELLOW_300: Color = web("#FFF176")
  val YELLOW_400: Color = web("#FFEE58")
  val YELLOW_500: Color = web("#FFEB3B")
  val YELLOW_600: Color = web("#FDD835")
  val YELLOW_700: Color = web("#FBC02D")
  val YELLOW_800: Color = web("#F9A825")
  val YELLOW_900: Color = web("#F57F17")

  val YELLOW_A100: Color = web("#FFFF8D")
  val YELLOW_A200: Color = web("#FFFF00")
  val YELLOW_A400: Color = web("#FFEA00")
  val YELLOW_A700: Color = web("#FFD600")

  val AMBER_50: Color = web("#FFF8E1")
  val AMBER_100: Color = web("#FFECB3")
  val AMBER_200: Color = web("#FFE082")
  val AMBER_300: Color = web("#FFD54F")
  val AMBER_400: Color = web("#FFCA28")
  val AMBER_500: Color = web("#FFC107")
  val AMBER_600: Color = web("#FFB300")
  val AMBER_700: Color = web("#FFA000")
  val AMBER_800: Color = web("#FF8F00")
  val AMBER_900: Color = web("#FF6F00")

  val AMBER_A100: Color = web("#FFE57F")
  val AMBER_A200: Color = web("#FFD740")
  val AMBER_A400: Color = web("#FFC400")
  val AMBER_A700: Color = web("#FFAB00")

  val ORANGE_50: Color = web("#FFF3E0")
  val ORANGE_100: Color = web("#FFE0B2")
  val ORANGE_200: Color = web("#FFCC80")
  val ORANGE_300: Color = web("#FFB74D")
  val ORANGE_400: Color = web("#FFA726")
  val ORANGE_500: Color = web("#FF9800")
  val ORANGE_600: Color = web("#FB8C00")
  val ORANGE_700: Color = web("#F57C00")
  val ORANGE_800: Color = web("#EF6C00")
  val ORANGE_900: Color = web("#E65100")

  val ORANGE_A100: Color = web("#FFD180")
  val ORANGE_A200: Color = web("#FFAB40")
  val ORANGE_A400: Color = web("#FF9100")
  val ORANGE_A700: Color = web("#FF6D00")

  val DEEP_ORANGE_50: Color = web("#FBE9E7")
  val DEEP_ORANGE_100: Color = web("#FFCCBC")
  val DEEP_ORANGE_200: Color = web("#FFAB91")
  val DEEP_ORANGE_300: Color = web("#FF8A65")
  val DEEP_ORANGE_400: Color = web("#FF7043")
  val DEEP_ORANGE_500: Color = web("#FF5722")
  val DEEP_ORANGE_600: Color = web("#F4511E")
  val DEEP_ORANGE_700: Color = web("#E64A19")
  val DEEP_ORANGE_800: Color = web("#D84315")
  val DEEP_ORANGE_900: Color = web("#BF360C")

  val DEEP_ORANGE_A100: Color = web("#FF9E80")
  val DEEP_ORANGE_A200: Color = web("#FF6E40")
  val DEEP_ORANGE_A400: Color = web("#FF3D00")
  val DEEP_ORANGE_A700: Color = web("#DD2C00")

  val BROWN_50: Color = web("#EFEBE9")
  val BROWN_100: Color = web("#EFEBE9")
  val BROWN_200: Color = web("#D7CCC8")
  val BROWN_300: Color = web("#BCAAA4")
  val BROWN_400: Color = web("#8D6E63")
  val BROWN_500: Color = web("#795548")
  val BROWN_600: Color = web("#6D4C41")
  val BROWN_700: Color = web("#5D4037")
  val BROWN_800: Color = web("#4E342E")
  val BROWN_900: Color = web("#3E2723")

  val GRAY_50: Color = web("#FAFAFA")
  val GRAY_100: Color = web("#F5F5F5")
  val GRAY_200: Color = web("#EEEEEE")
  val GRAY_300: Color = web("#E0E0E0")
  val GRAY_400: Color = web("#BDBDBD")
  val GRAY_500: Color = web("#9E9E9E")
  val GRAY_600: Color = web("#757575")
  val GRAY_700: Color = web("#616161")
  val GRAY_800: Color = web("#424242")
  val GRAY_900: Color = web("#212121")

  val BLUE_GRAY_50: Color = web("#ECEFF1")
  val BLUE_GRAY_100: Color = web("#CFD8DC")
  val BLUE_GRAY_200: Color = web("#B0BEC5")
  val BLUE_GRAY_300: Color = web("#90A4AE")
  val BLUE_GRAY_400: Color = web("#78909C")
  val BLUE_GRAY_500: Color = web("#607D8B")
  val BLUE_GRAY_600: Color = web("#546E7A")
  val BLUE_GRAY_700: Color = web("#455A64")
  val BLUE_GRAY_800: Color = web("#37474F")
  val BLUE_GRAY_900: Color = web("#263238")
}
