package it.neckar.elektromeister.rest.components

import com.benasher44.uuid.Uuid
import it.neckar.financial.currency.Money
import it.neckar.uuid.ValueClassUuid
import it.neckar.uuid.randomUuid4

/**
 * Represents an electrical component that can be placed on a floor plan
 */
sealed interface ElectricalComponent {
  /**
   * The ID of the component
   */
  val id: Id
  val label: String
  val price: Money

  /**
   * Returns the type of this component
   */
  val type: ElectricalComponentType
    get() {
      return when (this) {
        is CircuitBreaker -> ElectricalComponentType.CircuitBreaker
        is Doorbell -> ElectricalComponentType.Doorbell
        is EthernetSocket -> ElectricalComponentType.EthernetSocket
        is GarageDoorOpener -> ElectricalComponentType.GarageDoorOpener
        is LightFixture -> ElectricalComponentType.LightFixture
        is LightSwitch -> ElectricalComponentType.LightSwitch
        is MotionSensor -> ElectricalComponentType.MotionSensor
        is PowerDistributionBoard -> ElectricalComponentType.PowerDistributionBoard
        is SmokeDetector -> ElectricalComponentType.SmokeDetector
        is SocketOutlet -> ElectricalComponentType.SocketOutlet
        is Thermostat -> ElectricalComponentType.Thermostat
      }
    }

  companion object {
    fun createComponent(
      id: Id = Id.random(),
      type: ElectricalComponentType,
      label: String,
      price: Money,
    ): ElectricalComponent {
      return when (type) {
        ElectricalComponentType.CircuitBreaker -> CircuitBreaker(id, label, price)
        ElectricalComponentType.Doorbell -> Doorbell(id, label, price)
        ElectricalComponentType.EthernetSocket -> EthernetSocket(id, label, price)
        ElectricalComponentType.GarageDoorOpener -> GarageDoorOpener(id, label, price)
        ElectricalComponentType.LightFixture -> LightFixture(id, label, price)
        ElectricalComponentType.LightSwitch -> LightSwitch(id, label, price)
        ElectricalComponentType.MotionSensor -> MotionSensor(id, label, price)
        ElectricalComponentType.PowerDistributionBoard -> PowerDistributionBoard(id, label, price)
        ElectricalComponentType.SmokeDetector -> SmokeDetector(id, label, price)
        ElectricalComponentType.SocketOutlet -> SocketOutlet(id, label, price)
        ElectricalComponentType.Thermostat -> Thermostat(id, label, price)
      }
    }
  }

  /**
   * The ID for any [ElectricalComponentType]
   */
  data class Id(override val uuid: Uuid) : ValueClassUuid {
    companion object {
      /**
       * Generates a random [Id]
       */
      fun random(): Id {
        return Id(randomUuid4())
      }
    }
  }

}
