/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.resources.svg

import com.meistercharts.algorithms.painter.Path
import com.meistercharts.svg.SVGPathParser
import kotlin.jvm.JvmInline

/**
 * Represents an SVG path
 */
@JvmInline
value class SvgPath(val value: String) {
  /**
   * Parses the SVG path to a [Path] that can be painted on the canvas
   */
  fun parse(): Path {
    return SVGPathParser(value).parse()
  }
}
