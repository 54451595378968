package com.meistercharts.charts.lizergy.stringsPlanning

import com.benasher44.uuid.Uuid
import com.meistercharts.annotations.Window
import com.meistercharts.charts.lizergy.roofPlanning.Clickable
import com.meistercharts.charts.lizergy.roofPlanning.Module
import it.neckar.geometry.Coordinates


/**
 * Sealed interface for all possible UI states for the [PvStringsPlanningLayer]
 */
sealed interface StringsPlanningUiState {
  val data: PvStringsPlanningLayer.Configuration
  val activeModule: Module?
  val planningLocation: Coordinates?

  /**
   * Is called on mouse up event
   */
  fun mouseUp(): StringsPlanningUiState {
    return this
  }

  /**
   * Mouse has been moved out of the canvas
   */
  fun movedOutOfCanvas(): StringsPlanningUiState {
    return this
  }

  /**
   * Move over nothing (but within the canvas)
   */
  fun moveOverNothing(coordinates: @Window Coordinates): StringsPlanningUiState {
    return this
  }

  /**
   * Mouse is hovering above the delete button of an unusable area
   */
  fun hoveringOver(clickable: Module, center: Coordinates): StringsPlanningUiState {
    return Hovering(data, clickable)
  }

  fun downOn(clickable: Module): StringsPlanningUiState {
    return this
  }

  /**
   * Down somewhere without any special areas
   */
  fun downOnNothing(): StringsPlanningUiState {
    return DefaultState(data)
  }

  fun stringSelected(roofStringUuid: Uuid?): StringsPlanningUiState {
    data.stringsPlanningModel.set(roofStringUuid)
    return if (roofStringUuid != null) {
      PlanningString(data, null, null)
    } else {
      DefaultState(data)
    }
  }

  /**
   * Formats the roof selection
   */
  fun formatSelection(): String {
    return "TODO"
  }
}

/**
 * Base class that holds an active element
 */
sealed interface UiStateWithActiveElement : StringsPlanningUiState {

  /**
   * Returns true if the active unusable area matches the given unusable area
   */
  fun isActive(clickable: Clickable): Boolean {
    val activeModule = this.activeModule
    return activeModule == clickable || activeModule is Module && activeModule.modulePlacement.moduleArea == clickable
  }

  /**
   * Returns true for an exact match or if the grid matches [matchesForGrid]
   */
  fun matchesLoose(module: Module): Boolean {
    val activeModule = this.activeModule
    return module.deleted.not() && (isActive(module) || (activeModule is Module && activeModule.modulePlacement.moduleArea == module.modulePlacement.moduleArea))
  }
}

/**
 * Default state that does not have any special elements selected or anything at all
 */
class DefaultState(override val data: PvStringsPlanningLayer.Configuration) : StringsPlanningUiState {
  override val activeModule: Module?
    get() = null
  override val planningLocation: Coordinates?
    get() = null

  override fun toString(): String {
    val formatSelection = formatSelection()
    return "${this::class.simpleName}: [$formatSelection]"
  }
}

/**
 * Hovering above an element with a visible buttons:
 * * remove button
 * * rotate for manual modules
 */
class Hovering(
  override val data: PvStringsPlanningLayer.Configuration,
  override val activeModule: Module,
) : UiStateWithActiveElement {
  override val planningLocation: Coordinates?
    get() = null

  override fun moveOverNothing(coordinates: @Window Coordinates): StringsPlanningUiState {
    return DefaultState(data)
  }

  override fun movedOutOfCanvas(): StringsPlanningUiState {
    return DefaultState(data)
  }

  override fun downOn(module: Module): StringsPlanningUiState {
    val selectedString = data.stringsPlanningModel.selectedString
    if (selectedString == null) {
      val newSelectedString = data.stringsPlanningModel.roofStringsConfiguration.roofStrings.firstOrNull { it.contains(module) }
      if (newSelectedString == null) return this
      data.stringsPlanningModel.set(newSelectedString.uuid)
    } else if (selectedString.contains(module).not()) {
      selectedString.add(module)
    }

    return PlanningString(data, module, null)
  }

  override fun toString(): String {
    val formatSelection = formatSelection()
    return "${this::class.simpleName}: [$formatSelection]"
  }
}

class PlanningString(
  override val data: PvStringsPlanningLayer.Configuration,
  override val activeModule: Module?,
  override val planningLocation: Coordinates?,
) : UiStateWithActiveElement {

  override fun hoveringOver(module: Module, coordinates: @Window Coordinates): StringsPlanningUiState {
    val newStringSelection = data.stringsPlanningModel.roofStringsConfiguration.roofStrings.firstOrNull { it.contains(module) }
    return PlanningString(data, module, if (newStringSelection == null) coordinates else null)
  }

  override fun moveOverNothing(coordinates: @Window Coordinates): StringsPlanningUiState {
    return PlanningString(data, null, coordinates)
  }

  override fun movedOutOfCanvas(): StringsPlanningUiState {
    return PlanningString(data, null, null)
  }

  override fun downOn(module: Module): StringsPlanningUiState {
    val currentlySelectedString = data.stringsPlanningModel.selectedString
    check(currentlySelectedString != null) { "No string selected" }
    if (currentlySelectedString.contains(module)) {
      currentlySelectedString.remove(module)
    } else {
      val newStringSelection = data.stringsPlanningModel.roofStringsConfiguration.roofStrings.firstOrNull { it.contains(module) }
      if (newStringSelection == null || newStringSelection.uuid == currentlySelectedString.uuid) {
        currentlySelectedString.add(module)
      } else {
        data.stringsPlanningModel.set(newStringSelection.uuid)
      }
    }
    return PlanningString(data, module, null)
  }

  override fun downOnNothing(): StringsPlanningUiState {
    data.stringsPlanningModel.set(null)
    return DefaultState(data)
  }

  override fun toString(): String {
    val formatSelection = formatSelection()
    return "${this::class.simpleName}: [$formatSelection]"
  }
}
