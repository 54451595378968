/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.demo.descriptors


import com.benasher44.uuid.Uuid
import com.benasher44.uuid.uuidFrom
import com.meistercharts.charts.lizergy.solar.PvElectricityPriceGestalt
import com.meistercharts.demo.DemoCategory
import com.meistercharts.demo.DemoQuality
import com.meistercharts.demo.MeisterchartsDemo
import com.meistercharts.demo.MeisterchartsDemoDescriptor
import com.meistercharts.demo.PredefinedConfiguration
import com.meistercharts.demo.VariabilityType
import com.meistercharts.demo.configurableDouble

class PvPdfCategoryLineChartDemoDescriptor : MeisterchartsDemoDescriptor<(gestalt: PvElectricityPriceGestalt) -> Unit> {
  override val uuid: Uuid = uuidFrom("90df7874-d769-4005-8eb3-10b2c60f17d8")
  override val name: String = "PV PDF Category Line Chart"
  override val category: DemoCategory = DemoCategory.Lizergy

  override val quality: DemoQuality = DemoQuality.High
  override val variabilityType: VariabilityType = VariabilityType.Stable

  override fun prepareDemo(configuration: PredefinedConfiguration<(gestalt: PvElectricityPriceGestalt) -> Unit>?): MeisterchartsDemo {
    return MeisterchartsDemo {
      val gestalt = PvElectricityPriceGestalt()
      configuration?.payload?.invoke(gestalt)

      meistercharts {
        gestalt.configure(this)
        configurableDouble("labelTickGap",gestalt.gestalt.categoryAxisLayer.configuration::tickLabelGap){
          max = 10.0
        }
        configurableDouble("tickLength",gestalt.gestalt.categoryAxisLayer.configuration::tickLength){
          max = 5.0
        }

      }
    }

  }


}
