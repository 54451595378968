package it.neckar.ktor.client

import io.ktor.client.*
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.http.*
import it.neckar.rest.ClientRole
import it.neckar.rest.tracking.XClientRole


/**
 * Adds the Client ID to the request
 */
fun HttpClientConfig<*>.installClientRoleHeader(clientRole: ClientRole) {
  install(DefaultRequest) {
    headers {
      append(HttpHeaders.XClientRole, clientRole.toString())
    }
  }
}
