/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.demo.descriptors

import com.benasher44.uuid.Uuid
import com.benasher44.uuid.uuidFrom
import com.meistercharts.canvas.DebugFeature
import com.meistercharts.demo.DemoCategory
import com.meistercharts.demo.MeisterchartsDemo
import com.meistercharts.demo.MeisterchartsDemoDescriptor
import com.meistercharts.demo.PredefinedConfiguration
import com.meistercharts.elektromeister.ElektromeisterDemoData
import com.meistercharts.elektromeister.ElektromeisterMeisterchartsModel
import com.meistercharts.elektromeister.ElektromeisterPocGestalt
import com.meistercharts.elektromeister.model.toMutableFloorPlan
import it.neckar.elektromeister.rest.quote.ElektromeisterQuote
import it.neckar.elektromeister.rest.quote.createQuote
import it.neckar.logging.Logger
import it.neckar.logging.LoggerFactory
import it.neckar.open.kotlin.serializers.JsonPretty

/**
 *
 */
class ElektroMeisterPocGestaltDemoDescriptor : MeisterchartsDemoDescriptor<Nothing> {
  override val uuid: Uuid = uuidFrom("6a773c65-9068-4d7a-a5aa-303f818b8082")
  override val name: String = "Elektromeister PoC Gestalt"

  override val category: DemoCategory = DemoCategory.Elektromeister

  override fun prepareDemo(configuration: PredefinedConfiguration<Nothing>?): MeisterchartsDemo {
    val floorPlan = ElektromeisterDemoData.floorPlan

    val model = ElektromeisterMeisterchartsModel(
      floorPlan = floorPlan.toMutableFloorPlan(),
      floorPlanImage = ElektromeisterPocGestalt.sampleFloorPlanImage,
    )

    model.floorPlan.consumeAllProperties {
      logger.info("Creating quote")
      val quote = model.floorPlan.toFloorPlan().createQuote()
      val json = JsonPretty.encodeToString(ElektromeisterQuote.serializer(), quote)
      logger.info("Quote created: $json")
    }

    return MeisterchartsDemo {
      meistercharts {
        val gestalt = ElektromeisterPocGestalt(
          ElektromeisterPocGestalt.Configuration(
            model = model,
          )
        )
        gestalt.configure(this)

        configure {
          //Enable the debug feature by default
          debug[DebugFeature.ShowUiState] = true
        }
      }
    }
  }

  companion object {
    private val logger: Logger = LoggerFactory.getLogger("com.meistercharts.demo.descriptors.ElektroMeisterPocGestaltDemoDescriptor")
  }
}
