/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.font

/**
 * Represents a generic family (fallback font) - as used by the browser
 *
 * See also:
 *  [https://developer.mozilla.org/en-US/docs/Web/CSS/generic-family]
 */
enum class GenericFontFamily(val keyword: String) {
  Serif("serif"),
  SansSerif("sans-serif"),
  Monospace("monospace"),
  Cursive("cursive"),
  Fantasy("fantasy"),
  SystemUi("system-ui"),
  UiSerif("ui-serif"),
  UiSansSerif("ui-sans-serif"),
  UiMonospace("ui-monospace"),
  UiRounded("ui-rounded"),
  Emoji("emoji"),
  Math("math"),
  Fangsong("fangsong"),
}
