/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.demo.descriptors


import com.benasher44.uuid.Uuid
import com.benasher44.uuid.uuidFrom
import com.meistercharts.charts.bioexp.BioExPCategoryLineChartGestalt
import com.meistercharts.demo.DemoCategory
import com.meistercharts.demo.MeisterchartsDemo
import com.meistercharts.demo.MeisterchartsDemoDescriptor
import com.meistercharts.demo.PredefinedConfiguration

/**
 * A demo descriptor for BioExP, using the BioExPCategoryLineChartGestalt. It displays the percentage of popped cells in relation to the elapsed time in milliseconds.
 */
class RbcCategoryLineChartDemoDescriptor : MeisterchartsDemoDescriptor<(gestalt: BioExPCategoryLineChartGestalt) -> Unit> {
  override val uuid: Uuid = uuidFrom("92871e8b-db78-4426-8dac-9467e590eff5")
  override val name: String = "RBC Category Line Chart"
  override val category: DemoCategory = DemoCategory.Bioexp

  override fun prepareDemo(configuration: PredefinedConfiguration<(gestalt: BioExPCategoryLineChartGestalt) -> Unit>?): MeisterchartsDemo {
    return MeisterchartsDemo {


      val gestalt = BioExPCategoryLineChartGestalt()

      configuration?.payload?.invoke(gestalt)

      meistercharts {
        gestalt.configure(this)
      }
    }

  }

}
