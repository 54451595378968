package it.neckar.csv

import it.neckar.open.http.Url
import it.neckar.resource.loading.loadResource

/**
 * Load a CSV file into a list of lists.
 */
@Deprecated("Use loadCSV with url instead", ReplaceWith("loadCSV(url, charset)"))
suspend inline fun loadCSV(url: String, charset: String = "UTF-8"): List<List<String>> {
  return loadCSV(Url.parse(url), charset)
}

suspend fun loadCSV(url: Url, charset: String = "UTF-8"): List<List<String>> {
  val content: ByteArray = loadResource(url)
  return convertCsv(content, charset)
}

/**
 * Parses a CSV file
 */
expect suspend fun convertCsv(csvByteArray: ByteArray, charset: String): List<List<String>>
