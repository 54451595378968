package it.neckar.elektromeister.rest.components

import com.benasher44.uuid.Uuid
import it.neckar.financial.currency.Money
import it.neckar.uuid.ValueClassUuid

/**
 * Represents a "light fixture" (German: "Leuchte")
 */
data class LightFixture(
  override val id: ElectricalComponent.Id,
  override val label: String,
  override val price: Money,
) : ElectricalComponent {

}
