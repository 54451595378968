/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.demo

/**
 * Quality of the demo. Only demos with high quality will be shown in the main demo list.
 */
enum class DemoQuality {
  /**
   * Demo does not work at all - needs to be fixed
   */
  Broken,

  /**
   * Demo is deprecated - will be removed soon
   */
  Deprecated,

  /**
   * Value for all demos that have no quality set
   */
  Untested,

  /**
   * Demo is of low quality - needs to be improved
   */
  Low,

  /**
   * Demo is of high quality - will be shown to the users
   */
  High,
}
