package it.neckar.elektromeister.rest

import it.neckar.elektromeister.rest.components.ElectricalComponent

/**
 * Represents a connection between two components
 */
data class ConnectionLine(
  val start: ElectricalComponent,
  val end: ElectricalComponent,
) {
  init {
    require(start != end) { "Cannot connect a component to itself" }
  }
}
