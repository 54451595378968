/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.elektromeister

import com.meistercharts.elektromeister.model.MutableConnectionLine
import com.meistercharts.elektromeister.model.MutableFloorPlan
import com.meistercharts.elektromeister.model.MutablePlacedElectricalComponent
import com.meistercharts.elektromeister.state.ElektromeisterUiStateMachine
import com.meistercharts.range.LinearValueRange
import it.neckar.elektromeister.rest.PlacedElectricalComponent
import it.neckar.elektromeister.rest.Room
import it.neckar.elektromeister.rest.components.ElectricalComponent
import it.neckar.geometry.Coordinates
import it.neckar.open.provider.SizedProvider
import it.neckar.open.unit.si.mm

/**
 * Mutable model for the Elektromeister Meistercharts
 */
class ElektromeisterMeisterchartsModel(
  /**
   * The floor plan itself
   */
  val floorPlan: MutableFloorPlan,

  /**
   * The image of the floor plan
   */
  val floorPlanImage: FloorPlanImage,
) {

  /**
   * The state machine for the UI
   */
  val stateMachine: ElektromeisterUiStateMachine = ElektromeisterUiStateMachine(this)

  /**
   * Updates the floor plan with the additional placed electrical component
   */
  fun addPlacedElectricalComponent(placedElectricalComponent: MutablePlacedElectricalComponent) {
    floorPlan.addPlacedElectricalComponent(placedElectricalComponent)
  }

  fun addConnectionLine(start: MutablePlacedElectricalComponent, end: MutablePlacedElectricalComponent) {
    floorPlan.addConnectionLine(start, end)
  }

  fun removePlacedElectricalComponent(componentToDelete: MutablePlacedElectricalComponent) {
    floorPlan.removePlacedElectricalComponent(componentToDelete)
  }

  /**
   * Returns the rooms
   */
  val rooms: SizedProvider<Room> = SizedProvider.forListProvider {
    floorPlan.rooms
  }

  val connectionLines: SizedProvider<MutableConnectionLine> = SizedProvider.forListProvider {
    floorPlan.connectionLines
  }

  /**
   * Provides the electrical components
   */
  val electricalComponents: SizedProvider<MutablePlacedElectricalComponent> = SizedProvider.forListProvider {
    floorPlan.placedElectricalComponents
  }

  val roomFinder: (coordinates: @mm Coordinates) -> Room? = { coordinates ->
    floorPlan.findRoom(coordinates)
  }

  val rangeX: @mm LinearValueRange
    get() {
      return floorPlanImage.rangeX
    }

  val rangeY: @mm LinearValueRange
    get() {
      return floorPlanImage.rangeY
    }
}
