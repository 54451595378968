/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.demo.descriptors


import com.benasher44.uuid.Uuid
import com.benasher44.uuid.uuidFrom
import com.meistercharts.algorithms.layers.text.addText
import com.meistercharts.charts.lizergy.modulePlanning.PvModulePlanningGestalt
import com.meistercharts.charts.lizergy.roofPlanning.ModuleSize
import com.meistercharts.charts.lizergy.roofPlanning.PvRoofPlanningLayer
import com.meistercharts.charts.lizergy.roofPlanning.PvRoofPlanningModel
import com.meistercharts.charts.lizergy.roofPlanning.PvRoofSampleModels
import com.meistercharts.charts.lizergy.roofPlanning.UnusableArea
import com.meistercharts.demo.DemoCategory
import com.meistercharts.demo.DemoQuality
import com.meistercharts.demo.MeisterchartsDemo
import com.meistercharts.demo.MeisterchartsDemoDescriptor
import com.meistercharts.demo.PredefinedConfiguration
import com.meistercharts.demo.VariabilityType
import com.meistercharts.demo.configurableDouble
import com.meistercharts.demo.configurableEnum
import com.meistercharts.demo.configurableInsetsSeparate
import com.meistercharts.demo.configurableSizeSeparate
import com.meistercharts.demo.section
import com.meistercharts.font.FontDescriptorFragment
import com.meistercharts.geometry.DirectionBasedBasePointProvider
import com.meistercharts.style.BoxStyle
import it.neckar.geometry.Coordinates
import it.neckar.geometry.Direction
import it.neckar.geometry.Size
import it.neckar.open.kotlin.lang.asProvider
import kotlin.math.roundToInt

/**
 *
 */
class PvRoofPlanningDemoDescriptor : MeisterchartsDemoDescriptor<() -> PvRoofPlanningModel> {
  override val uuid: Uuid = uuidFrom("0b49441a-cbab-487a-a013-4d4147348674")
  override val name: String = "Photovoltaics Roof Planning"
  override val category: DemoCategory = DemoCategory.Lizergy

  // insets (mm) no visual impact
  override val quality: DemoQuality = DemoQuality.Low
  override val variabilityType: VariabilityType = VariabilityType.Stable

  override val predefinedConfigurations: List<PredefinedConfiguration<() -> PvRoofPlanningModel>> = listOf(
    PredefinedConfiguration(uuidFrom("a65f2fad-75a1-4366-a1af-364a0228ab53"), { PvRoofSampleModels.getSimpleGrid() }, "Simple Grid"),
    PredefinedConfiguration(uuidFrom("d514d361-aaa3-4601-b238-6c2b2e969c80"), { PvRoofSampleModels.getGridWithHoles() }, "Grid with holes"),
    PredefinedConfiguration(uuidFrom("8b69521a-0edd-4e46-a9d9-d39c5c51ac79"), { PvRoofSampleModels.onlyUnusableArea() }, "Unusable Area"),
    PredefinedConfiguration(uuidFrom("6cecce49-7b97-4d1a-baec-0d2408f6884c"), { PvRoofSampleModels.realistic() }, "Realistic"),
    PredefinedConfiguration(uuidFrom("03e5d5e1-a2a5-4d85-ae69-e41d81626623"), { PvRoofSampleModels.allTypes() }, "All"),
  )

  override fun prepareDemo(configuration: PredefinedConfiguration<() -> PvRoofPlanningModel>?): MeisterchartsDemo {
    requireNotNull(configuration) { "Configuration required" }

    val model: PvRoofPlanningModel = configuration.payload()

    return MeisterchartsDemo {
      meistercharts {

        val gestalt = PvModulePlanningGestalt(model)
        gestalt.configure(this)

        this.configure {
          layers.addText({ _, _ ->
            listOf(gestalt.pvModulePlanningLayer.uiState.toString())
          }) {
            boxStyle = BoxStyle.gray
            anchorDirection = Direction.TopLeft
            anchorPointProvider = DirectionBasedBasePointProvider(Direction.TopLeft)
            font = FontDescriptorFragment.XS.asProvider()
          }
        }

        configurableEnum("Mode", gestalt.pvRoofPlanningLayer.configuration::mode, kotlin.enums.enumEntries<PvRoofPlanningLayer.Mode>().toList()) {
          onChange {
            gestalt.roofBackgroundLayer.configuration.mode = it
            gestalt.pvRoofPlanningLayer.configuration.mode = it
            gestalt.pvModulePlanningLayer.configuration.mode = it
            markAsDirty()
          }
        }

        section("Roof")

        configurableSizeSeparate("roof (mm)", model::roofSize) {
          max = 15_000.0
        }

        configurableInsetsSeparate("Insets (mm)", model::suggestedRoofInsets) {
          max = 1_000.0
        }

        section("Modules")

        configurableDouble("Module longer (mm)", model.modulesSize.longer.toDouble()) {
          max = 2_000.0

          onChange {
            model.modulesSize = ModuleSize(it.roundToInt(), model.modulesSize.shorter)
            markAsDirty()
          }
        }
        configurableDouble("Module shorter (mm)", model.modulesSize.shorter.toDouble()) {
          max = 2_000.0

          onChange {
            model.modulesSize = ModuleSize(model.modulesSize.longer, it.roundToInt())
            markAsDirty()
          }
        }

        section("New Areas")

        declare {

          button("Add module area") {
            model.addModuleArea()
            markAsDirty()
          }

          button("Add unusable area") {
            model.addUnusableArea(UnusableArea(location = Coordinates.origin, size = Size(1500, 1200)))
            markAsDirty()
          }
        }
      }
    }
  }
}
