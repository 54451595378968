/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.elektromeister.state

import com.meistercharts.annotations.Window
import com.meistercharts.canvas.MouseCursor
import com.meistercharts.elektromeister.model.MutablePlacedElectricalComponent
import com.meistercharts.events.EventConsumption
import it.neckar.events.MouseButton
import it.neckar.events.MouseDownEvent
import it.neckar.events.MouseDragEvent
import it.neckar.events.MouseUpEvent
import it.neckar.geometry.Coordinates

/**
 * One [MutablePlacedElectricalComponent] is selected
 */
data class ElectricalComponentSelected(val selected: MutablePlacedElectricalComponent) : AbstractUiStateWithMouseOver() {
  override fun updateStateForMouseLocation(mouseLocation: @Window Coordinates?, context: GestureContext) {
    val mouseOverComponent = updateMouseOverComponent(mouseLocation, context)

    if (mouseOverComponent == null) {
      cursor = null //no component under the mouse
      return
    }

    cursor = if (mouseOverComponent == selected) {
      //Only if the mouse is over the selected component
      MouseCursor.OpenHand
    } else {
      //Another component is selectable
      MouseCursor.Hand
    }
  }

  override fun mouseDown(event: MouseDownEvent, context: GestureContext): EventConsumption {
    val model = context.model
    @Window val mouseCoordinates = event.coordinates

    //Click on a selected component, start dragging
    //Left down on a selected component
    when (event.button) {
      MouseButton.Primary -> {
        when (val foundPlacedElectricalComponent: MutablePlacedElectricalComponent? = context.electricalComponentsFinder(mouseCoordinates)) {
          null -> {
            //Left down on the empty space
            model.stateMachine.transitionToDefault(event.coordinates, context)
            context.markAsDirty()
            return EventConsumption.Consumed
          }

          else -> {
            //Left down on a selected component
            if (selected == foundPlacedElectricalComponent) {
              //Click on a selected component, start dragging
              model.stateMachine.transitionToMoving(foundPlacedElectricalComponent, mouseCoordinates, context)
              context.markAsDirty()
              return EventConsumption.Consumed
            }
          }
        }
      }

      MouseButton.Secondary -> {
        //Unselect on right-click
        model.stateMachine.transitionToDefault(event.coordinates, context)
        context.markAsDirty()
        return EventConsumption.Consumed
      }

      else -> {
      }
    }

    return EventConsumption.Ignored
  }

  override fun mouseUp(event: MouseUpEvent, context: GestureContext): EventConsumption {
    val model = context.model
    @Window val mouseCoordinates = event.coordinates

    when (event.button) {
      MouseButton.Primary -> {
        when (val foundPlacedElectricalComponent: MutablePlacedElectricalComponent? = context.findElectricalComponent(mouseCoordinates)) {
          null -> {
            return EventConsumption.Consumed
          }

          else -> {
            //Left up on a selected component
            if (selected != foundPlacedElectricalComponent) {
              //Another component: Update selection
              model.stateMachine.transitionToSelectedComponent(foundPlacedElectricalComponent, mouseCoordinates, context)
              context.markAsDirty()
              return EventConsumption.Consumed
            }
          }
        }
      }

      MouseButton.Secondary -> {
        return EventConsumption.Consumed
      }

      else -> {
      }
    }

    return EventConsumption.Ignored
  }

  override fun deleteByKey(context: GestureContext): EventConsumption {
    val model = context.model
    model.removePlacedElectricalComponent(selected)

    model.stateMachine.transitionToDefault(null, context)
    context.markAsDirty()
    return EventConsumption.Consumed
  }

  override fun mouseDragged(event: MouseDragEvent, context: GestureContext): EventConsumption {
    return EventConsumption.Ignored
  }
}
