/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.elektromeister

import com.meistercharts.algorithms.layers.Layers.PaintingOrder
import com.meistercharts.algorithms.layers.addClearBackground
import com.meistercharts.algorithms.layers.gesture.MouseWheelZoomConfiguration
import com.meistercharts.algorithms.layers.text.TextLayer
import com.meistercharts.algorithms.layers.visibleIf
import com.meistercharts.canvas.DebugFeature
import com.meistercharts.canvas.FixedContentAreaSize
import com.meistercharts.canvas.ImmediateWindowSizeBindingStrategy
import com.meistercharts.charts.AbstractChartGestalt
import com.meistercharts.color.Color
import com.meistercharts.elektromeister.model.ElectricalComponentsFinder
import com.meistercharts.font.FontDescriptorFragment
import com.meistercharts.model.Insets
import com.meistercharts.resize.KeepCenterOnWindowResize
import com.meistercharts.resources.LocalResourcePaintable
import com.meistercharts.style.BoxStyle
import com.meistercharts.zoom.FittingInContentViewportAspectRatio
import it.neckar.events.ModifierCombination
import it.neckar.geometry.AxisOrientationY
import it.neckar.geometry.AxisSelection
import it.neckar.geometry.Direction
import it.neckar.geometry.Distance
import it.neckar.geometry.Size
import it.neckar.open.http.Url
import it.neckar.open.kotlin.lang.asProvider

/**
 * PoC for Elektromeister.
 *
 * ContentArea: in pixels - matches the size of the floor plan image
 */
class ElektromeisterPocGestalt(
  val configuration: Configuration,
  additionalConfiguration: Configuration.() -> Unit = {},
) : AbstractChartGestalt() {

  /**
   * The layer that paints the floor plan image
   */
  val floorImageLayer: ElektromeisterFloorImageLayer = ElektromeisterFloorImageLayer(
    ElektromeisterFloorImageLayer.Configuration(
      backgroundImage = LocalResourcePaintable(configuration.model.floorPlanImage.url)
    )
  )

  /**
   * This layer shows the coordinates for the current mouse cursor
   */
  val measureLayer: ElektromeisterMeasureLayer = ElektromeisterMeasureLayer(
    ElektromeisterMeasureLayer.Configuration(
      model = configuration.model,
    )
  )

  /**
   * The layer that paints the gray background for the rooms
   */
  val roomGrayBackgroundLayer: ElektromeisterRoomGrayBackgroundLayer = ElektromeisterRoomGrayBackgroundLayer(
    configuration = ElektromeisterRoomGrayBackgroundLayer.Configuration(
      model = configuration.model,
    )
  )

  /**
   * Highlights the room the mouse hovers above
   */
  val roomHighlightLayer: ElektromeisterRoomHighlightLayer = ElektromeisterRoomHighlightLayer(
    ElektromeisterRoomHighlightLayer.Configuration(
      model = configuration.model,
      activeRoom = { measureLayer.paintingVariables().activeRoom } //TODO extract to UI state(?)
    )
  )

  /**
   * Paints the connection lines (including the temporary line while creating a new connection)
   */
  val connectionLineLayer: ElektromeisterConnectionLineLayer = ElektromeisterConnectionLineLayer(
    ElektromeisterConnectionLineLayer.Configuration(
      model = configuration.model,
    )
  )

  /**
   * Shows the electrical components
   */
  val electricalComponentsLayer: ElektromeisterElectricalComponentsLayer = ElektromeisterElectricalComponentsLayer(
    ElektromeisterElectricalComponentsLayer.Configuration(
      model = configuration.model,
    )
  )

  /**
   * Returns the placed electrical component at the given window coordinates (if any)
   */
  val electricalComponentsFinder: ElectricalComponentsFinder = { coordinates ->
    electricalComponentsLayer.paintingVariables().findElectricalComponent(coordinates)
  }

  val interactionHandler: ElektromeisterInteractionHandler = ElektromeisterInteractionHandler(
    configuration.model,
    electricalComponentsFinder,
  )

  val mouseGesturesLayer: ElektromeisterInteractionHandlerLayer = ElektromeisterInteractionHandlerLayer(
    ElektromeisterInteractionHandlerLayer.Configuration(
      model = configuration.model,
      interactionHandler = interactionHandler,
    )
  )

  /**
   * Contains the toolbar
   */
  val toolbarGestalt: ElektromeisterToolbarGestalt = ElektromeisterToolbarGestalt.create(configuration.model, electricalComponentsFinder)

  //Debug state layer
  private val uiStateDebugLayer: TextLayer = TextLayer.forLines(lines = { _, _ ->
    listOf(
      "State: " + configuration.model.stateMachine.currentUiState,
      "Mouse over component: " + configuration.model.stateMachine.mouseOverComponent,
    )
  }) {
    bottomLeft(Distance(0.0, 0.0))
    textColor = Color.red
    font = FontDescriptorFragment.S.asProvider()
    boxStyle = BoxStyle.gray
  }

  init {
    configuration.additionalConfiguration()

    configureBuilder { builder ->
      toolbarGestalt.configure(builder)

      //
      //Zoom and panning
      //
      builder.windowSizeBindingStrategy = ImmediateWindowSizeBindingStrategy
      builder.enableZoomAndTranslation = true

      builder.zoomAndTranslationConfiguration {
        translateAxisSelection = AxisSelection.Both
        mouseWheelZoomConfiguration = MouseWheelZoomConfiguration.bothAxis

        translateOnMouseDragModifier = ModifierCombination.Ctrl
      }

      builder.zoomAndTranslationModifier {
        //TODO replace with 50% strategy?
        contentAlwaysBarelyVisible()
        minZoom(0.1, 0.1)
        maxZoom(2.0, 2.0)
      }

      builder.contentAreaSizingStrategy = FixedContentAreaSize {
        configuration.model.floorPlanImage.sizeInPixels
      }

      builder.zoomAndTranslationDefaults = FittingInContentViewportAspectRatio(alignment = { Direction.TopCenter })
    }

    configure {
      chartSupport.rootChartState.contentViewportMargin = Insets.of(50.0, 150.0, 20.0, 30.0)

      chartSupport.rootChartState.axisOrientationY = AxisOrientationY.OriginAtTop
      chartSupport.windowResizeBehavior = KeepCenterOnWindowResize

      layers.addClearBackground()

      layers.addLayer(mouseGesturesLayer)
      layers.addLayer(floorImageLayer)
      layers.addLayer(roomGrayBackgroundLayer)

      /**
       * The [measureLayer] must be layout first but painted after the [roomHighlightLayer]
       */
      @PaintingOrder val roomPaintingIndex = layers.addLayer(roomHighlightLayer)

      layers.addLayer(connectionLineLayer)
      layers.addLayer(electricalComponentsLayer)

      //Move up to ensure the toolbar is painted on top
      layers.toTop(toolbarGestalt.toolbarLayer)

      layers.addLayer(uiStateDebugLayer.visibleIf { debug[DebugFeature.ShowUiState] })
      layers.addLayerAt(measureLayer, roomPaintingIndex + 1, roomPaintingIndex) //layout before roomHighlightLayer
    }
  }

  class Configuration(
    /**
     * Returns the current floor plan that is displayed
     */
    override val model: ElektromeisterMeisterchartsModel,
  ) : AbstractElektromeisterConfiguration()

  companion object {
    /**
     * Sample image width in pixels
     */
    val sampleFloorPlanImage: FloorPlanImage = FloorPlanImage(
      url = Url.relative("elektromeister/floor-sample1.png"),
      sizeInPixels = Size(2028, 3822),
      /**
       * The ratio for pixel to meter for this image.
       *
       * Measured with a ruler:
       * * 752 pixels = 3.18 m
       */
      pixels2mm = 1.0 / 752 * (3.18 * 1000)
    )
  }
}
